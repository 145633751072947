import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Controls from './controls/Controls'
import Notification from './Notification'
import { Form, useForm } from './useForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  billingAddTagAction,
  billingTagsAction,
  billingUpdateTagAction,
} from '../actions/billingActions'
import {
  BILLING_ADD_TAG_RESET,
  BILLING_UPDATE_TAG_RESET,
} from '../constants/billingConstants'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const initialValues = {
  id: '',
  name: '',
  type: '',
  tagType: '',
  tagCategory: '',
}

const AddBillingTag = ({ setOpenPopup, tagForEdit }) => {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

  const classes = useStyles()
  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('name' in fieldValues)
      temp.name = fieldValues.name ? '' : 'This field is required'
    if ('type' in fieldValues)
      temp.type = fieldValues.type.length !== 0 ? '' : 'This field is required'
    if ('tagType' in fieldValues)
      temp.tagType =
        fieldValues.tagType.length !== 0 ? '' : 'This field is required'
    if ('tagCategory' in fieldValues)
      temp.tagCategory =
        fieldValues.tagCategory.length !== 0 ? '' : 'This field is required'
    setErrors({
      ...temp,
    })
    if (fieldValues === values) return Object.values(temp).every((x) => x == '')
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate)

  const dispatch = useDispatch()
  const addBillingTag = useSelector((state) => state.addBillingTag)
  const { loading, error, addTag } = addBillingTag

  const updateBillingTag = useSelector((state) => state.updateBillingTag)
  const { loading1, error1, updateTag } = updateBillingTag

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      if (tagForEdit) {
        dispatch(
          billingUpdateTagAction(
            values.id,
            values.name,
            values.type,
            values.tagType,
            values.tagCategory
          )
        )
      } else {
        dispatch(billingAddTagAction(values.name, values.type, values.tagType))
      }
    }
  }

  useEffect(() => {
    dispatch({ type: BILLING_ADD_TAG_RESET })
    if (addTag) {
      setNotify({
        isOpen: true,
        message: addTag.msg,
        type: 'success',
      })
      setTimeout(() => {
        setOpenPopup(false)
        dispatch(billingTagsAction())
      }, 2000)
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [dispatch, addTag, error])

  useEffect(() => {
    dispatch({ type: BILLING_UPDATE_TAG_RESET })
    if (updateTag) {
      setNotify({
        isOpen: true,
        message: updateTag.msg,
        type: 'success',
      })
      setTimeout(() => {
        setOpenPopup(false)
        dispatch(billingTagsAction())
      }, 2000)
    } else if (error1) {
      setNotify({
        isOpen: true,
        message: error1,
        type: 'error',
      })
    }
  }, [dispatch, updateTag, error1])

  useEffect(() => {
    if (tagForEdit != null) {
      setValues({
        ...tagForEdit,
      })
    }
  }, [tagForEdit])

  return (
    <>
      <Form autoComplete='off' onSubmit={handleSubmit}>
        <Controls.Input
          label='Name'
          name='name'
          value={values.name}
          onChange={handleInputChange}
          error={errors.name}
        />
        <Controls.Select
          label='Type'
          name='type'
          value={values.type}
          onChange={handleInputChange}
          error={errors.type}
          options={[
            { id: 'Number', title: 'Number' },
            // { id: 'Percentage', title: 'Percentage' },
          ]}
        />
        <Controls.Select
          label='Tag Type'
          name='tagType'
          value={values.tagType}
          onChange={handleInputChange}
          error={errors.tagType}
          options={[
            { id: 'Collections', title: 'Collections' },
            { id: 'Verifications', title: 'Verifications' },
          ]}
        />
        <Controls.Select
          label='Tag Category'
          name='tagCategory'
          value={values.tagCategory}
          onChange={handleInputChange}
          error={errors.tagCategory}
          options={
            values.tagType === 'Collections'
              ? [
                  { id: 'Both', title: 'Both' },
                  { id: 'Flows', title: 'Flows' },
                  { id: 'Recoveries', title: 'Recoveries' },
                ]
              : values.tagType === 'Verifications'
              ? [{ id: 'Verifications', title: 'Verifications' }]
              : []
          }
          disabled={
            values.tagType !== 'Collections' &&
            values.tagType !== 'Verifications'
          }
        />
        <Controls.Button
          type='submit'
          fullWidth
          text='submit'
          className={classes.submit}
          loading={loading || loading1}
        />
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}

export default AddBillingTag
