import { Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Controls from '../components/controls/Controls'
import Notification from '../components/Notification'
import Progress from '../components/Progress'
import { Form, useForm } from '../components/useForm'
import {
  dashboard,
  dialAction,
  submitEverydayReportingAction,
} from '../actions/userActions'
import {
  DIAL_RESET,
  SUBMIT_EVERYDAY_REPORTING_RESET,
} from '../constants/userConstants'

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(0, 1.5, 2, 1.5),
    padding: theme.spacing(1, 2),
  },
  summaryHead: {
    fontSize: '18px',
    display: 'block',
    fontWeight: 'bold',
  },
  summaryText: {
    marginTop: '8px',
    display: 'block',
    textDecoration: 'none',
    color: 'black',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}))

const initialValues = {
  number: '',
}

const Dashboard = ({ history }) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userDashboard = useSelector((state) => state.userDashboard)
  const { loading, userData } = userDashboard

  const [calling, setCalling] = useState(false)
  const [departments, setDepartments] = useState([])
  const [attendanceReview, setAttendanceReview] = useState([])
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  useEffect(() => {
    if (userData) {
      setCalling(userData.calling)
      setDepartments(userData.departments)
      setAttendanceReview(userData.attendanceReview)
    }
  }, [userData])

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('number' in fieldValues)
      temp.number = fieldValues.number ? '' : 'This field is required'
    setErrors({
      ...temp,
    })
    if (fieldValues === values) return Object.values(temp).every((x) => x == '')
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate)

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      dispatch(dialAction(values.number))
    }
  }

  const call = useSelector((state) => state.call)
  const { loading1, error1, dialData } = call

  useEffect(() => {
    dispatch({ type: DIAL_RESET })
    if (dialData) {
      setNotify({
        isOpen: true,
        message: dialData.msg,
        type: 'success',
      })
    } else if (error1) {
      setNotify({
        isOpen: true,
        message: error1,
        type: 'error',
      })
    }
  }, [dispatch, dialData, error1])

  const handleButton = (departmentID, supervisor, tags) => {
    // Loop through all the values and check if this department ID are blank
    let content = []

    tags.forEach((tag) => {
      let value = values[departmentID + '-' + tag._id]
      let name = tag._id
      if (value !== '') {
        content.push({
          [name]: Number(value),
        })
      }
    })

    // Check if length is same
    if (content.length === tags.length) {
      // Time
      let time = values[departmentID + '-time']

      if (time !== '') {
        const toSubmit = {
          department: departmentID,
          supervisor: supervisor,
          time,
          content,
        }

        dispatch(submitEverydayReportingAction(toSubmit))
      } else {
        setNotify({
          isOpen: true,
          message: 'Select valid time from this list',
          type: 'error',
        })
      }
    } else {
      setNotify({
        isOpen: true,
        message: 'All the fields are mandatory',
        type: 'error',
      })
    }
  }

  const submitEverydayReporting = useSelector(
    (state) => state.submitEverydayReporting
  )
  const { loading2, error2, result } = submitEverydayReporting

  useEffect(() => {
    dispatch({ type: SUBMIT_EVERYDAY_REPORTING_RESET })
    if (result) {
      setNotify({
        isOpen: true,
        message: result.msg,
        type: 'success',
      })
      setTimeout(() => {
        dispatch(dashboard())
      }, 1000)
    } else if (error2) {
      setNotify({
        isOpen: true,
        message: error2,
        type: 'error',
      })
    }
  }, [dispatch, result, error2])

  return (
    <>
      {loading ? (
        <Progress />
      ) : (
        <Grid container style={{ marginTop: 15 }}>
          {calling && (
            <Grid item xs={12} lg={6}>
              <Paper className={classes.pageContent}>
                <Typography className={classes.summaryHead}>Calling</Typography>
                <Form autoComplete='off' onSubmit={handleSubmit}>
                  <Controls.Input
                    label='Number'
                    name='number'
                    value={values.number}
                    onChange={handleInputChange}
                    error={errors.number}
                    type='number'
                    inputProps={{
                      min: 0,
                    }}
                  />
                  <Controls.Button
                    type='submit'
                    text='submit'
                    className={classes.submit}
                    loading={loading1}
                  />
                </Form>
              </Paper>
            </Grid>
          )}
          {attendanceReview.length > 0 && (
            <Grid item xs={12} lg={6}>
              <Paper className={classes.pageContent}>
                <Typography className={classes.summaryHead}>
                  Processed Attendances
                </Typography>
                {attendanceReview.map((item) => (
                  <Grid container style={{ marginTop: 15 }}>
                    <Grid item>
                      <Typography>{item.month + '/' + item.year}</Typography>
                    </Grid>
                    <Grid item sm />
                    <Grid item>
                      <a
                        href={`/accounts/salary/backend/process/${item._id}`}
                        target='_blank'
                      >
                        Process
                      </a>
                    </Grid>
                  </Grid>
                ))}
              </Paper>
            </Grid>
          )}
          {/* {departments.map((department) => (
            <Grid item xs={12} key={department.departmentID}>
              <Paper className={classes.pageContent}>
                <Typography className={classes.summaryHead}>
                  {department.name}
                </Typography>
                <Form autoComplete='off'>
                  <Grid container spacing={1}>
                    {department.tags.map((tag) => (
                      <Grid
                        item
                        xs={12}
                        key={tag._id + '-' + department.departmentID}
                      >
                        <Controls.Input
                          label={tag.name + ' (' + tag.type + ')' + ' *'}
                          name={department.departmentID + '-' + tag._id}
                          value={values[tag._id]}
                          onChange={handleInputChange}
                          type='number'
                          min='0.00'
                          step='0.001'
                          max='1.00'
                          presicion={2}
                        />
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <Controls.Select
                        label='Time'
                        name={department.departmentID + '-time'}
                        value={values.time}
                        onChange={handleInputChange}
                        options={department.timings}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Controls.Button
                        text='submit'
                        className={classes.submit}
                        loading={loading2}
                        onClick={() =>
                          handleButton(
                            department.departmentID,
                            department.supervisor,
                            department.tags
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </Form>
              </Paper>
            </Grid>
          ))}*/}
        </Grid>
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}

export default Dashboard
