import {
  BILLING_ADD_TAG_FAIL,
  BILLING_ADD_TAG_REQUEST,
  BILLING_ADD_TAG_SUCCESS,
  BILLING_DAILY_MIS_FAIL,
  BILLING_DAILY_MIS_REQUEST,
  BILLING_DAILY_MIS_SUCCESS,
  BILLING_DELETE_DATA_BY_ID_FAIL,
  BILLING_DELETE_DATA_BY_ID_REQUEST,
  BILLING_DELETE_DATA_BY_ID_SUCCESS,
  BILLING_GET_DEPARTMENTS_FAIL,
  BILLING_GET_DEPARTMENTS_REQUEST,
  BILLING_GET_DEPARTMENTS_SUCCESS,
  BILLING_GET_DEPARTMENT_BY_ID_FAIL,
  BILLING_GET_DEPARTMENT_BY_ID_REQUEST,
  BILLING_GET_DEPARTMENT_BY_ID_SUCCESS,
  BILLING_MASTER_REPORT_FAIL,
  BILLING_MASTER_REPORT_REQUEST,
  BILLING_MASTER_REPORT_SUCCESS,
  BILLING_REPORTS_FAIL,
  BILLING_REPORTS_REQUEST,
  BILLING_REPORTS_SUCCESS,
  BILLING_TAGS_FAIL,
  BILLING_TAGS_REQUEST,
  BILLING_TAGS_SUCCESS,
  BILLING_UPDATE_TAG_FAIL,
  BILLING_UPDATE_TAG_REQUEST,
  BILLING_UPDATE_TAG_SUCCESS,
  GET_BILLING_DAILY_MIS_FAIL,
  GET_BILLING_DAILY_MIS_REQUEST,
  GET_BILLING_DAILY_MIS_SUCCESS,
  GET_BILLING_DATA_BY_ID_FAIL,
  GET_BILLING_DATA_BY_ID_REQUEST,
  GET_BILLING_DATA_BY_ID_SUCCESS,
  GET_BILLING_REPORTS_FAIL,
  GET_BILLING_REPORTS_REQUEST,
  GET_BILLING_REPORTS_SUCCESS,
  SUBMIT_DEPARTMENT_BILLING_DATA_FAIL,
  SUBMIT_DEPARTMENT_BILLING_DATA_REQUEST,
  SUBMIT_DEPARTMENT_BILLING_DATA_SUCCESS,
  UPDATE_DEPARTMENT_BILLING_DATA_FAIL,
  UPDATE_DEPARTMENT_BILLING_DATA_REQUEST,
  UPDATE_DEPARTMENT_BILLING_DATA_SUCCESS,
} from '../constants/billingConstants'
import axios from 'axios'
import { logout } from '../actions/userActions'

export const billingTagsAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: BILLING_TAGS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/billing/tags/web', config)

    dispatch({
      type: BILLING_TAGS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: BILLING_TAGS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const billingAddTagAction =
  (name, type, tagType, tagCategory) => async (dispatch, getState) => {
    try {
      dispatch({
        type: BILLING_ADD_TAG_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        '/api/billing/tag/add/web',
        { name, type, tagType, tagCategory },
        config
      )

      dispatch({
        type: BILLING_ADD_TAG_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: BILLING_ADD_TAG_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const billingUpdateTagAction =
  (id, name, type, tagType, tagCategory) => async (dispatch, getState) => {
    try {
      dispatch({
        type: BILLING_UPDATE_TAG_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/billing/tag/${id}/update/web`,
        { name, type, tagType, tagCategory },
        config
      )

      dispatch({
        type: BILLING_UPDATE_TAG_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: BILLING_UPDATE_TAG_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const billingDepartmentsAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: BILLING_GET_DEPARTMENTS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      '/api/billing/departments/list/web',
      config
    )

    dispatch({
      type: BILLING_GET_DEPARTMENTS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: BILLING_GET_DEPARTMENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const billingDepartmentByIdAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: BILLING_GET_DEPARTMENT_BY_ID_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        `/api/billing/department/${id}/web`,
        config
      )

      dispatch({
        type: BILLING_GET_DEPARTMENT_BY_ID_SUCCESS,
        payload: data,
      })
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout())
        return
      }
      dispatch({
        type: BILLING_GET_DEPARTMENT_BY_ID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const billingDepartmentSubmitDataAction =
  (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SUBMIT_DEPARTMENT_BILLING_DATA_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/billing/department/${id}/data/web`,
        values,
        config
      )

      dispatch({
        type: SUBMIT_DEPARTMENT_BILLING_DATA_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: SUBMIT_DEPARTMENT_BILLING_DATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const billingDepartmentUpdateDataAction =
  (values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_DEPARTMENT_BILLING_DATA_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/billing/department/data/${values.id}/update/web`,
        values,
        config
      )

      dispatch({
        type: UPDATE_DEPARTMENT_BILLING_DATA_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: UPDATE_DEPARTMENT_BILLING_DATA_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const billingGetDataByIdAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_BILLING_DATA_BY_ID_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `/api/billing/department/${id}/dataById/web`,
      config
    )

    dispatch({
      type: GET_BILLING_DATA_BY_ID_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: GET_BILLING_DATA_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const billingGetReportsAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_BILLING_REPORTS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/billing/reports/web`, config)

    dispatch({
      type: GET_BILLING_REPORTS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: GET_BILLING_REPORTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const billingReportsAction =
  (
    dataType,
    from,
    to,
    departmentType,
    fetchType,
    category,
    masterDepartment,
    department,
    security,
    product,
    bankType
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: BILLING_REPORTS_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/billing/reports/web`,
        {
          dataType,
          from,
          to,
          departmentType,
          fetchType,
          category,
          masterDepartment,
          department,
          security,
          product,
          bankType,
        },
        config
      )

      dispatch({
        type: BILLING_REPORTS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: BILLING_REPORTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const billingDeleteDataByIdAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: BILLING_DELETE_DATA_BY_ID_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/billing/department/data/${id}/delete/web`,
        {},
        config
      )

      dispatch({
        type: BILLING_DELETE_DATA_BY_ID_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: BILLING_DELETE_DATA_BY_ID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const billingMasterReportAction =
  (
    from,
    to,
    departmentType,
    fetchType,
    category,
    masterDepartment,
    department
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: BILLING_MASTER_REPORT_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/billing/reports/master/web`,
        {
          from,
          to,
          departmentType,
          fetchType,
          category,
          masterDepartment,
          department,
        },
        config
      )

      dispatch({
        type: BILLING_MASTER_REPORT_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: BILLING_MASTER_REPORT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const billingGetDailyMISAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_BILLING_DAILY_MIS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/billing/mis/daily/web`, config)

    dispatch({
      type: GET_BILLING_DAILY_MIS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: GET_BILLING_DAILY_MIS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const billingDailyMISAction =
  (date, time, departmentType, department) => async (dispatch, getState) => {
    try {
      dispatch({
        type: BILLING_DAILY_MIS_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/billing/mis/daily/web`,
        { date, time, departmentType, department },
        config
      )

      dispatch({
        type: BILLING_DAILY_MIS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: BILLING_DAILY_MIS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
