import { Grid, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Controls from './controls/Controls'
import Notification from './Notification'
import { Form, useForm } from './useForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  finalizeAttendanceAction,
  updateFinalizeAttendanceAction,
} from '../actions/hrActions'
import { UPDATE_FINALIZE_ATTENDANCE_RESET } from '../constants/hrConstants'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(2, 0, 0),
  },
  process: {
    margin: theme.spacing(0, 0, 1),
  },
}))

const initialValues = {
  beforeAdjustment: 0,
  hrFinal: 0,
  afterAdjustment: 0,
  leavesAvailable: 0,
  updateBy: '',
  adjustmentType: '',
  comments: '',
}

const UpdateAttendanceFinalize = ({
  setOpenPopup,
  attendanceForUpdate,
  results,
}) => {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

  const classes = useStyles()
  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    setErrors({
      ...temp,
    })
    if (fieldValues === values) return Object.values(temp).every((x) => x == '')
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate)

  useEffect(() => {
    if (attendanceForUpdate != null) {
      setValues({
        ...attendanceForUpdate,
      })
    }
  }, [attendanceForUpdate])

  const updateHRFinal = () => {
    if (values.adjustmentType === 'Increment') {
      let calc = Number(values.beforeAdjustment) + Number(values.updateBy)

      // Check points
      let decimalValue = calc.toFixed(2)

      if (decimalValue > results.to_work_days) {
        decimalValue = results.to_work_days
      } else if (decimalValue < 0) {
        decimalValue = 0
      }

      setValues({
        ...values,
        hrFinal: decimalValue,
      })
    } else if (values.adjustmentType === 'Decrement') {
      let calc = Number(values.beforeAdjustment) - Number(values.updateBy)

      // Check points
      let decimalValue = calc.toFixed(2)

      if (decimalValue > results.to_work_days) {
        decimalValue = results.to_work_days
      } else if (decimalValue < 0) {
        decimalValue = 0
      }

      setValues({
        ...values,
        hrFinal: decimalValue,
      })
    } else {
      setValues({
        ...values,
        hrFinal: values.afterAdjustment.toFixed(2),
      })
    }
  }

  const dispatch = useDispatch()

  const process = () => {
    dispatch(
      updateFinalizeAttendanceAction(
        attendanceForUpdate.id,
        values.hrFinal,
        results.year,
        results.month,
        values.leavesAvailable,
        values.comments
      )
    )
  }

  const updateFinalizeAttendance = useSelector(
    (state) => state.updateFinalizeAttendance
  )
  const { loading, error, updateFinlaizedAttendances } =
    updateFinalizeAttendance

  useEffect(() => {
    dispatch({ type: UPDATE_FINALIZE_ATTENDANCE_RESET })
    if (updateFinlaizedAttendances) {
      setNotify({
        isOpen: true,
        message: updateFinlaizedAttendances.msg,
        type: 'success',
      })
      setTimeout(() => {
        setOpenPopup(false)
        dispatch(finalizeAttendanceAction(results.month, results.year))
      }, 1000)
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [dispatch, updateFinlaizedAttendances, error])

  const [showButton, setShowButton] = useState(true)

  const updateByLeaves = () => {
    let remaining = 0

    // Not checked with parse float
    const result =
      parseFloat(values.beforeAdjustment) +
      parseFloat(attendanceForUpdate.leavesAvailable)

    if (result > parseFloat(results.to_work_days)) {
      remaining = result - parseFloat(results.to_work_days)

      setValues({
        ...values,
        beforeAdjustment: parseFloat(results.to_work_days),
        leavesAvailable: parseFloat(remaining),
        comments: 'Leaves adjusted',
      })
      setShowButton(false)
    } else {
      setValues({
        ...values,
        beforeAdjustment: parseFloat(result),
        leavesAvailable: 0,
        comments: 'Leaves adjusted',
      })
      setShowButton(false)
    }
  }

  return (
    <>
      <Form autoComplete='off'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography>Leaves Available: {values.leavesAvailable}</Typography>
          {attendanceForUpdate.leavesAvailable > 0 && showButton && (
            <Controls.Button
              style={{ marginTop: '-5px' }}
              text='update'
              className={classes.submit}
              onClick={updateByLeaves}
            />
          )}
        </div>
        <Controls.Input
          label='Calucation Before Adjustement *'
          name='processed'
          value={values.beforeAdjustment}
          disabled
        />
        <Controls.Select
          label='Adjustment Type *'
          name='adjustmentType'
          value={values.adjustmentType}
          onChange={handleInputChange}
          options={[
            { id: 'Increment', title: 'Increment' },
            { id: 'Decrement', title: 'Decrement' },
          ]}
        />
        <Controls.Input
          label='Update By *'
          name='updateBy'
          value={values.updateBy}
          onChange={handleInputChange}
          disabled={values.adjustmentType === ''}
          type='number'
          inputProps={{
            min: 0,
          }}
          disabled={
            values.adjustmentType !== 'Increment' &&
            values.adjustmentType !== 'Decrement'
          }
        />
        <Controls.Input
          label='HR Final *'
          name='hrFinal'
          value={values.hrFinal}
          disabled
        />
        <Controls.Input
          label='Comments *'
          name='comments'
          value={values.comments}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <Controls.Button
              fullWidth
              text='final update'
              className={classes.submit}
              onClick={updateHRFinal}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Controls.Button
              fullWidth
              text='process'
              className={classes.submit}
              onClick={process}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}

export default UpdateAttendanceFinalize
