import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Controls from './controls/Controls'
import Notification from './Notification'
import { Form, useForm } from './useForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  hrGetEmployeeByIdAction,
  hrUploadDocAction,
} from '../actions/hrActions'
import { HR_UPLOAD_DOC_EMPLOYEE_RESET } from '../constants/hrConstants'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  file: {
    marginTop: '20px',
  },
}))

const initialValues = {
  type: '',
  name: '',
}

const types = [
  { id: 'Aadhar Card', title: 'Aadhar Card' },
  { id: 'PAN Card', title: 'PAN Card' },
  { id: 'Voter ID Card', title: 'Voter ID Card' },
  { id: 'Driving License', title: 'Driving License' },
  { id: 'House Document', title: 'House Document' },
  { id: 'Original Document', title: 'Original Document' },
  { id: 'Police Verification', title: 'Police Verification' },
  { id: 'DRA Certificate', title: 'DRA Certificate' },
  { id: 'Code of Conduct', title: 'Code of Conduct' },
  { id: 'Non Disclosure Agreement', title: 'Non Disclosure Agreement' },
  { id: 'Education Document', title: 'Education Document' },
  { id: 'Bank Document', title: 'Bank Document' },
  { id: 'Other', title: 'Other' },
]

const HRUploadDoc = ({ setOpenUploadDocPopup, id }) => {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
  const [file, setFile] = useState(null)

  const classes = useStyles()
  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('type' in fieldValues)
      temp.type = fieldValues.type.length !== 0 ? '' : 'This field is required'
    setErrors({
      ...temp,
    })
    if (fieldValues === values) return Object.values(temp).every((x) => x == '')
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate)

  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!values.type || !file) {
      setNotify({
        isOpen: true,
        message: 'All the fields are mandatory',
        type: 'error',
      })
    } else if (values.type === 'Other') {
      if (!values.name) {
        setNotify({
          isOpen: true,
          message: 'All the fields are mandatory',
          type: 'error',
        })
      } else {
        const formData = new FormData()
        formData.append('type', values.type)
        formData.append('name', values.name)
        formData.append('file', file[0])
        dispatch(hrUploadDocAction(id, formData))
      }
    } else {
      const formData = new FormData()
      formData.append('type', values.type)
      formData.append('name', values.name === '' ? values.type : values.name)
      formData.append('file', file[0])
      dispatch(hrUploadDocAction(id, formData))
    }
  }

  const hrUploadDoc = useSelector((state) => state.hrUploadDoc)
  const { loading, error, uploadDocEmployee } = hrUploadDoc

  useEffect(() => {
    dispatch({ type: HR_UPLOAD_DOC_EMPLOYEE_RESET })
    if (uploadDocEmployee) {
      setNotify({
        isOpen: true,
        message: uploadDocEmployee.msg,
        type: 'success',
      })
      setTimeout(() => {
        setOpenUploadDocPopup(false)
        dispatch(hrGetEmployeeByIdAction(id))
      }, 2000)
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [uploadDocEmployee, error, dispatch, id])

  return (
    <>
      <Form autoComplete='off' onSubmit={handleSubmit}>
        <Controls.Select
          label='Doc Type *'
          name='type'
          value={values.type}
          onChange={handleInputChange}
          error={errors.type}
          options={types}
        />
        <Controls.Input
          label='Name *'
          name='name'
          value={values.name}
          onChange={handleInputChange}
          error={errors.name}
          disabled={values.type !== 'Other'}
        />
        <input
          name='file'
          value={values.file}
          onChange={(e) => setFile(e.target.files)}
          type='file'
          accept='image/*, application/pdf'
          className={classes.file}
        />
        <Controls.Button
          type='submit'
          fullWidth
          text='submit'
          className={classes.submit}
          loading={loading}
        />
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}

export default HRUploadDoc
