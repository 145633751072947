import React, { useEffect, useState } from 'react'
import {
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import CallIcon from '@material-ui/icons/Call'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import StarBorder from '@material-ui/icons/StarBorder'
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary'
import DashboardIcon from '@material-ui/icons/Dashboard'
import SettingsIcon from '@material-ui/icons/Settings'
import ReceiptIcon from '@material-ui/icons/Receipt'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import PaymentIcon from '@material-ui/icons/Payment'

let drawerWidth = 280

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    overflow: 'hidden',
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#253053',
    overflow: 'hidden',
  },
  toolbar: theme.mixins.toolbar,
  accordian: {
    backgroundColor: 'transparent',
    color: 'white',
    border: 'none',

    '&:before': {
      height: 0,
    },
  },
  arrow: {
    color: 'white',
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: '#253053',
    color: 'white',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  icon: {
    color: 'white',
  },
  link: {
    display: 'flex',
    color: 'white',
    textDecoration: 'none',
  },
}))

const SideMenu = () => {
  const classes = useStyles()

  const userDashboard = useSelector((state) => state.userDashboard)
  const { userData } = userDashboard

  const [role, setRole] = useState('Employee')
  const [callingManage, setCallingManage] = useState(false)
  const [callingMIS, setCallingMIS] = useState(false)
  const [callingRecordings, setCallingRecordings] = useState(false)
  const [hrOpen, setHrOpen] = React.useState(false)
  const [billingOpen, setBillingOpen] = React.useState(false)
  const [callingOpen, setCallingOpen] = React.useState(false)
  const [settingsOpen, setSettingsOpen] = React.useState(false)
  const [accountsOpen, setAccountsOpen] = React.useState(false)

  useEffect(() => {
    if (userData) {
      setRole(userData.role)
      setCallingManage(userData.callingManage)
      setCallingMIS(userData.callingMIS)
      setCallingRecordings(userData.callingRecordings)
    }
  }, [userData])

  const handleHRClick = () => {
    setHrOpen(!hrOpen)
  }

  const handleBillingClick = () => {
    setBillingOpen(!billingOpen)
  }

  const handleCallingClick = () => {
    setCallingOpen(!callingOpen)
  }

  const handleSettingsClick = () => {
    setSettingsOpen(!settingsOpen)
  }

  const handleAccountsClick = () => {
    setAccountsOpen(!accountsOpen)
  }

  return (
    <Drawer
      className={classes.drawer}
      variant='permanent'
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor='left'
    >
      <div
        className={classes.toolbar}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
        }}
      >
        <Typography style={{ fontSize: '20px', fontWeight: 'bold' }}>
          HRMS
        </Typography>
      </div>
      <Divider style={{ backgroundColor: '#ccc' }} />
      <List
        component='nav'
        aria-labelledby='nested-list-subheader'
        className={classes.root}
      >
        <ListItem button>
          <Link className={classes.link} to='/dashboard'>
            <ListItemIcon>
              <DashboardIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary='Dashboard' />
          </Link>
        </ListItem>
        {(role === 'Admin' || role === 'HR') && (
          <>
            <ListItem button onClick={handleHRClick}>
              <ListItemIcon>
                <LocalLibraryIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary='HR' />
              {hrOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={hrOpen} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                <ListItem button className={classes.nested}>
                  <Link className={classes.link} to='/hr/dashboard'>
                    <ListItemIcon>
                      <StarBorder className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary='Dashboard' />
                  </Link>
                </ListItem>
                <ListItem button className={classes.nested}>
                  <Link className={classes.link} to='/hr/active-employees'>
                    <ListItemIcon>
                      <StarBorder className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary='Active Employees' />
                  </Link>
                </ListItem>
                <ListItem button className={classes.nested}>
                  <Link className={classes.link} to='/hr/not-active-employees'>
                    <ListItemIcon>
                      <StarBorder className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary='Not Active Employees' />
                  </Link>
                </ListItem>
                <ListItem button className={classes.nested}>
                  <Link className={classes.link} to='/hr/attendance'>
                    <ListItemIcon>
                      <StarBorder className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary='Attendance' />
                  </Link>
                </ListItem>
                <ListItem button className={classes.nested}>
                  <Link className={classes.link} to='/hr/finalize-attendance'>
                    <ListItemIcon>
                      <StarBorder className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary='Finalize Attendance' />
                  </Link>
                </ListItem>
                <ListItem button className={classes.nested}>
                  <Link className={classes.link} to='/hr/departments'>
                    <ListItemIcon>
                      <StarBorder className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary='Departments' />
                  </Link>
                </ListItem>
                <ListItem button className={classes.nested}>
                  <Link className={classes.link} to='/hr/master-departments'>
                    <ListItemIcon>
                      <StarBorder className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary='Master Departments' />
                  </Link>
                </ListItem>
              </List>
            </Collapse>
          </>
        )}
        {(role === 'Admin' || role === 'Billing') && (
          <>
            <ListItem button onClick={handleBillingClick}>
              <ListItemIcon>
                <ReceiptIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary='MIS' />
              {billingOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={billingOpen} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                <ListItem button className={classes.nested}>
                  <Link className={classes.link} to='/billing/dashboard'>
                    <ListItemIcon>
                      <StarBorder className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary='Dashboard' />
                  </Link>
                </ListItem>
                <ListItem button className={classes.nested}>
                  <Link className={classes.link} to='/billing/tags'>
                    <ListItemIcon>
                      <StarBorder className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary='Tags' />
                  </Link>
                </ListItem>
                <ListItem button className={classes.nested}>
                  <Link className={classes.link} to='/billing/departments'>
                    <ListItemIcon>
                      <StarBorder className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary='Departments' />
                  </Link>
                </ListItem>
                <ListItem button className={classes.nested}>
                  <Link className={classes.link} to='/billing/mis/daily'>
                    <ListItemIcon>
                      <StarBorder className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary='Daily MIS' />
                  </Link>
                </ListItem>
                <ListItem button className={classes.nested}>
                  <Link className={classes.link} to='/billing/reports'>
                    <ListItemIcon>
                      <StarBorder className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary='Reports' />
                  </Link>
                </ListItem>
                <ListItem button className={classes.nested}>
                  <Link className={classes.link} to='/billing/reports/master'>
                    <ListItemIcon>
                      <StarBorder className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary='Master Report' />
                  </Link>
                </ListItem>
              </List>
            </Collapse>
          </>
        )}
        {role === 'Accounts' ||
          (role === 'Admin' && (
            <>
              <ListItem button onClick={handleAccountsClick}>
                <ListItemIcon>
                  <PaymentIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText primary='Accounts' />
                {accountsOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={accountsOpen} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <ListItem button className={classes.nested}>
                    <Link className={classes.link} to='/accounts/dashboard'>
                      <ListItemIcon>
                        <StarBorder className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText primary='Dashboard' />
                    </Link>
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <Link className={classes.link} to='/accounts/employees'>
                      <ListItemIcon>
                        <StarBorder className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText primary='Employees' />
                    </Link>
                  </ListItem>
                </List>
              </Collapse>
            </>
          ))}
        {callingManage === true && (
          <>
            <ListItem button onClick={handleCallingClick}>
              <ListItemIcon>
                <CallIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary='Calling' />
              {callingOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={callingOpen} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                {role === 'Admin' && (
                  <ListItem button className={classes.nested}>
                    <Link className={classes.link} to='/extensions'>
                      <ListItemIcon>
                        <StarBorder className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText primary='Extensions' />
                    </Link>
                  </ListItem>
                )}
                {callingMIS === true && (
                  <>
                    <ListItem button className={classes.nested}>
                      <Link className={classes.link} to='/calls/live-calls'>
                        <ListItemIcon>
                          <StarBorder className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText primary='Live Calls' />
                      </Link>
                    </ListItem>
                    <ListItem button className={classes.nested}>
                      <Link className={classes.link} to='/calls/mis'>
                        <ListItemIcon>
                          <StarBorder className={classes.icon} />
                        </ListItemIcon>
                        <ListItemText primary='Calling MIS' />
                      </Link>
                    </ListItem>
                  </>
                )}
                {callingRecordings === true && (
                  <ListItem button className={classes.nested}>
                    <Link className={classes.link} to='/calls/recordings'>
                      <ListItemIcon>
                        <StarBorder className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText primary='Call Recordings' />
                    </Link>
                  </ListItem>
                )}
              </List>
            </Collapse>
          </>
        )}
        {role === 'Admin' && (
          <>
            <ListItem button onClick={handleSettingsClick}>
              <ListItemIcon>
                <SettingsIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary='Settings' />
              {settingsOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={settingsOpen} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                <ListItem button className={classes.nested}>
                  <Link className={classes.link} to='/organisations'>
                    <ListItemIcon>
                      <StarBorder className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary='Organisations' />
                  </Link>
                </ListItem>
                <ListItem button className={classes.nested}>
                  <Link className={classes.link} to='/roles'>
                    <ListItemIcon>
                      <StarBorder className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary='Roles' />
                  </Link>
                </ListItem>
                <ListItem button className={classes.nested}>
                  <Link className={classes.link} to='/settings'>
                    <ListItemIcon>
                      <StarBorder className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary='Settings' />
                  </Link>
                </ListItem>
                <ListItem button className={classes.nested}>
                  <Link className={classes.link} to='/data'>
                    <ListItemIcon>
                      <StarBorder className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary='Data Search' />
                  </Link>
                </ListItem>
              </List>
            </Collapse>
          </>
        )}
      </List>
    </Drawer>
  )
}

export default SideMenu
