import { makeStyles, Paper } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Notification from '../components/Notification'
import PageHeader from '../components/PageHeader'
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import { getAttendanceReviewAction } from '../actions/userActions'

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  searchInput: {
    width: '75%',
  },
  link: {
    textDecoration: 'none',
  },
  submit: {
    margin: theme.spacing(2, 0, 0),
  },
}))

const months = [
  { id: '01', title: 'January' },
  { id: '02', title: 'February' },
  { id: '03', title: 'March' },
  { id: '04', title: 'April' },
  { id: '05', title: 'May' },
  { id: '06', title: 'June' },
  { id: '07', title: 'July' },
  { id: '08', title: 'August' },
  { id: '09', title: 'September' },
  { id: '10', title: 'October' },
  { id: '11', title: 'November' },
  { id: '12', title: 'December' },
]

const headCells = [
  {
    id: 'employeeCode',
    label: 'Employee Code',
  },
  {
    id: 'employeeName',
    label: 'Employee Name',
  },
  {
    id: 'approved',
    label: 'Total Approved',
  },
  {
    id: 'onTime',
    label: 'On Time',
  },
  {
    id: 'late',
    label: 'Late',
  },
  {
    id: 'calcBeforeAdjustment',
    label: 'Before Ajustment',
  },
  {
    id: 'deduction',
    label: 'Deduction',
  },
  {
    id: 'processed',
    label: 'After Ajustment',
  },
  {
    id: 'update',
    label: 'Update',
  },
  {
    id: 'hrFinal',
    label: 'HR Final',
  },
]

const FinalizeAttendanceSingle = ({ match, history }) => {
  const {
    params: { id },
  } = match

  const classes = useStyles()

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  useEffect(() => {
    dispatch(getAttendanceReviewAction(id))
  }, [])

  const getAttendanceReview = useSelector((state) => state.getAttendanceReview)
  const { loading, error, result } = getAttendanceReview

  return (
    <div>
      <>
        <PageHeader
          title='Attendance Review'
          subtitle='Shows summary of attendance of employees working submitted by HR'
          icon={<PeopleOutlineTwoToneIcon fontSize='medium' />}
        />
        <Paper className={classes.pageContent}>
          {/* <Grid container>
            <Grid item xs={6} lg={2}>
              <Typography>
                Total Days: {result.finalizeAttendance.total_days}
              </Typography>
            </Grid>
            <Grid item xs={6} lg={2}>
              <Typography>
                Working Days: {result.finalizeAttendance.to_work_days}
              </Typography>
            </Grid>
            <Grid item xs={6} lg={2}>
              <Typography>
                Sundays: {result.finalizeAttendance.sundays}
              </Typography>
            </Grid>
            <Grid item xs={6} lg={2}>
              <Typography>
                Holidays: {result.finalizeAttendance.holidays}
              </Typography>
            </Grid>
            <Grid sm item />
          </Grid> */}
        </Paper>
      </>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  )
}

export default FinalizeAttendanceSingle
