import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteRoleAction, rolesAction } from '../actions/settingActions'
import Notification from '../components/Notification'
import Progress from '../components/Progress'
import Controls from '../components/controls/Controls'
import Popup from '../components/Popup'
import PageHeader from '../components/PageHeader'
import SettingsIcon from '@material-ui/icons/Settings'
import CloseIcon from '@material-ui/icons/Close'
import ConfirmDialog from '../components/ConfirmDialog'
import { DELETE_ROLE_RESET, ROLES_RESET } from '../constants/settingConstants'
import AddRole from '../components/AddRole'
import MaterialTable from 'material-table'

const Roles = ({ history }) => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const [notify2, setNotify2] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const settingsRoles = useSelector((state) => state.settingsRoles)
  const { loading, error, rolesData } = settingsRoles

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const [roles, setRoles] = useState([])
  const [employees, setEmployees] = useState([])

  useEffect(() => {
    dispatch(rolesAction())
  }, [])

  useEffect(() => {
    dispatch({ type: ROLES_RESET })
    if (rolesData) {
      if (rolesData.role !== 'Admin') {
        history.push('/dashboard')
      }
      setRoles(rolesData.existingRoles)
      setEmployees(rolesData.employees)
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [rolesData, error, history])

  const [openPopup, setOpenPopup] = useState(false)

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  })

  const onDelete = (id) => {
    dispatch(deleteRoleAction(id))
  }

  const settingsRoleDelete = useSelector((state) => state.settingsRoleDelete)
  const { error1, deleteRoleData } = settingsRoleDelete

  // use effect on delete
  useEffect(() => {
    dispatch({ type: DELETE_ROLE_RESET })
    if (deleteRoleData) {
      setConfirmDialog({ ...confirmDialog, isOpen: false })
      setNotify2({
        isOpen: true,
        message: deleteRoleData.msg,
        type: 'success',
      })
      setTimeout(() => {
        dispatch(rolesAction())
      }, 2000)
    } else {
      setNotify({
        isOpen: true,
        message: error1,
        type: 'error',
      })
    }
  }, [deleteRoleData, error1])

  // Table
  const headCells = [
    {
      field: 'employeeCode',
      title: 'Employee Code',
    },
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'role',
      title: 'Role',
    },
    {
      field: 'actions',
      title: 'Actions',
      render: (rowData) => (
        <Controls.ActionButton
          onClick={() =>
            setConfirmDialog({
              isOpen: true,
              title: 'Are you sure to delete this role?',
              subTitle: "You can't undo this operation",
              onConfirm: () => {
                onDelete(rowData.id)
              },
            })
          }
          color='secondary'
        >
          <CloseIcon fontSize='small' />
        </Controls.ActionButton>
      ),
    },
  ]

  return (
    <>
      {loading ? (
        <Progress />
      ) : (
        <>
          {error || error1 ? (
            <Notification notify={notify} setNotify={setNotify} />
          ) : (
            <>
              <PageHeader
                title='Roles'
                subtitle='Assign or delete roles to employees'
                icon={<SettingsIcon fontSize='medium' />}
              />
              <MaterialTable
                style={{ margin: '0 20px' }}
                title='Roles'
                columns={headCells}
                data={roles}
                options={{
                  exportButton: true,
                  search: true,
                  exportAllData: true,
                }}
                actions={[
                  {
                    icon: 'add',
                    tooltip: 'Add Role',
                    isFreeAction: true,
                    onClick: () => setOpenPopup(true),
                  },
                ]}
              />
              <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
              />
              <Popup
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title='Add Role'
              >
                <AddRole setOpenPopup={setOpenPopup} employees={employees} />
              </Popup>
              <Notification notify={notify2} setNotify={setNotify2} />
            </>
          )}
        </>
      )}
    </>
  )
}

export default Roles
