import {
  ADD_EXTENSION_FAIL,
  ADD_EXTENSION_REQUEST,
  ADD_EXTENSION_RESET,
  ADD_EXTENSION_SUCCESS,
  CALLS_MIS_FAIL,
  CALLS_MIS_REQUEST,
  CALLS_MIS_RESET,
  CALLS_MIS_SUCCESS,
  CALLS_RECORDINGS_FAIL,
  CALLS_RECORDINGS_GET_FAIL,
  CALLS_RECORDINGS_GET_REQUEST,
  CALLS_RECORDINGS_GET_RESET,
  CALLS_RECORDINGS_GET_SUCCESS,
  CALLS_RECORDINGS_REQUEST,
  CALLS_RECORDINGS_RESET,
  CALLS_RECORDINGS_SUCCESS,
  EXTENSIONS_FAIL,
  EXTENSIONS_REQUEST,
  EXTENSIONS_RESET,
  EXTENSIONS_SUCCESS,
  LIVE_CALLS_FAIL,
  LIVE_CALLS_REQUEST,
  LIVE_CALLS_RESET,
  LIVE_CALLS_SUCCESS,
  UPDATE_EXTENSION_FAIL,
  UPDATE_EXTENSION_REQUEST,
  UPDATE_EXTENSION_RESET,
  UPDATE_EXTENSION_SUCCESS,
} from '../constants/callingConstants'

export const extensionsReducer = (state = {}, action) => {
  switch (action.type) {
    case EXTENSIONS_REQUEST:
      return { loading: true }
    case EXTENSIONS_SUCCESS:
      return { loading: false, exts: action.payload }
    case EXTENSIONS_FAIL:
      return { loading: false, error: action.payload }
    case EXTENSIONS_RESET:
      return {}
    default:
      return state
  }
}

export const addExtensionsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_EXTENSION_REQUEST:
      return { loading: true }
    case ADD_EXTENSION_SUCCESS:
      return { loading: false, addExt: action.payload }
    case ADD_EXTENSION_FAIL:
      return { loading: false, error: action.payload }
    case ADD_EXTENSION_RESET:
      return {}
    default:
      return state
  }
}

export const updateExtensionsReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_EXTENSION_REQUEST:
      return { loading1: true }
    case UPDATE_EXTENSION_SUCCESS:
      return { loading1: false, updateExt: action.payload }
    case UPDATE_EXTENSION_FAIL:
      return { loading1: false, error1: action.payload }
    case UPDATE_EXTENSION_RESET:
      return {}
    default:
      return state
  }
}

export const liveCallsReducer = (state = {}, action) => {
  switch (action.type) {
    case LIVE_CALLS_REQUEST:
      return { loading: true }
    case LIVE_CALLS_SUCCESS:
      return { loading: false, calls: action.payload }
    case LIVE_CALLS_FAIL:
      return { loading: false, error: action.payload }
    case LIVE_CALLS_RESET:
      return {}
    default:
      return state
  }
}

export const callsMISReducer = (state = {}, action) => {
  switch (action.type) {
    case CALLS_MIS_REQUEST:
      return { loading: true }
    case CALLS_MIS_SUCCESS:
      return { loading: false, mis: action.payload }
    case CALLS_MIS_FAIL:
      return { loading: false, error: action.payload }
    case CALLS_MIS_RESET:
      return {}
    default:
      return state
  }
}

export const callsGetRecordingsReducer = (state = {}, action) => {
  switch (action.type) {
    case CALLS_RECORDINGS_GET_REQUEST:
      return { loading: true }
    case CALLS_RECORDINGS_GET_SUCCESS:
      return { loading: false, getRecordings: action.payload }
    case CALLS_RECORDINGS_GET_FAIL:
      return { loading: false, error: action.payload }
    case CALLS_RECORDINGS_GET_RESET:
      return {}
    default:
      return state
  }
}

export const callsRecordingsReducer = (state = {}, action) => {
  switch (action.type) {
    case CALLS_RECORDINGS_REQUEST:
      return { loading1: true }
    case CALLS_RECORDINGS_SUCCESS:
      return { loading1: false, recordings: action.payload }
    case CALLS_RECORDINGS_FAIL:
      return { loading1: false, error1: action.payload }
    case CALLS_RECORDINGS_RESET:
      return {}
    default:
      return state
  }
}
