import { makeStyles, Paper, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  callsGetRecordingsAction,
  callsRecordingsAction,
} from '../actions/callingActions'
import Notification from '../components/Notification'
import Controls from '../components/controls/Controls'
import PageHeader from '../components/PageHeader'
import { useForm, Form } from '../components/useForm'
import CallIcon from '@material-ui/icons/Call'
import {
  CALLS_RECORDINGS_GET_RESET,
  CALLS_RECORDINGS_RESET,
} from '../constants/callingConstants'
import MaterialTable from 'material-table'

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    overflowX: 'scroll',
  },
  link: {
    textDecoration: 'none',
  },
  submit: {
    margin: theme.spacing(2, 0, 0),
  },
}))

const headCells = [
  {
    field: 'employeeCode',
    title: 'Employee Code',
  },
  {
    field: 'name',
    title: 'Employee Name',
  },
  {
    field: 'caller',
    title: 'Caller',
  },
  {
    field: 'callee',
    title: 'Callee',
  },
  {
    field: 'start_date',
    title: 'Start Date',
  },
  {
    field: 'answer_seconds',
    title: 'Answer Seconds',
  },
  {
    field: 'call_status',
    title: 'Call Status',
  },
  {
    field: 'hangup_by',
    title: 'Hangup By',
  },
  {
    field: 'call_type',
    title: 'Call Type',
  },
  {
    field: 'link',
    title: 'Audio',
    render: (rowData) => (
      <audio controls controlslist='nodownload'>
        <source src={rowData.link} type='audio/mp3' />
      </audio>
    ),
  },
  {
    field: 'download',
    title: 'Download',
    render: (rowData) => (
      <a
        href={rowData.link}
        download={`${rowData.caller} ${rowData.callee} ${rowData.start_date}.mp3`}
      >
        Download
      </a>
    ),
  },
]

const initialValues = {
  type: '',
  employee: '',
  number: '',
  from: null,
  to: null,
}

const CallRecordings = ({ history }) => {
  const classes = useStyles()

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const userDashboard = useSelector((state) => state.userDashboard)
  const { userData } = userDashboard

  useEffect(() => {
    if (!userData || !userData.callingManage || !userData.callingRecordings) {
      history.push('/dashboard')
    }
  }, [dispatch, history, userData])

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('type' in fieldValues)
      temp.type = fieldValues.type.length !== 0 ? '' : 'This field is required'
    if ('from' in fieldValues)
      temp.from = fieldValues.from ? '' : 'This field is required'
    if ('to' in fieldValues)
      temp.to = fieldValues.to ? '' : 'This field is required'
    setErrors({
      ...temp,
    })
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '')
  }

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  )

  const [recordingsData, setRecordingsData] = useState([])
  const [employees, setEmployees] = useState([])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      if (values.type == 'Number' && !values.number) {
        setNotify({
          isOpen: true,
          message: 'Please enter a valid number',
          type: 'error',
        })
      } else if (values.type == 'Employee' && !values.employee) {
        setNotify({
          isOpen: true,
          message: 'Please select a valid emplouyee from the list',
          type: 'error',
        })
      } else {
        dispatch(
          callsRecordingsAction(
            values.type,
            values.employee,
            values.number,
            values.from,
            values.to
          )
        )
      }
    } else {
      setNotify({
        isOpen: true,
        message: 'All the fields are mandatory',
        type: 'error',
      })
    }
  }

  useEffect(() => {
    dispatch(callsGetRecordingsAction())
  }, [])

  const getCallRecordings = useSelector((state) => state.getCallRecordings)
  const { loading, error, getRecordings } = getCallRecordings

  useEffect(() => {
    dispatch({ type: CALLS_RECORDINGS_GET_RESET })
    if (getRecordings) {
      setEmployees(getRecordings.employees)
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [getRecordings, error])

  const callsRecordings = useSelector((state) => state.callsRecordings)
  const { loading1, error1, recordings } = callsRecordings

  useEffect(() => {
    dispatch({ type: CALLS_RECORDINGS_RESET })
    if (recordings) {
      setRecordingsData(recordings)
    } else if (error1) {
      setNotify({
        isOpen: true,
        message: error1,
        type: 'error',
      })
    }
  }, [recordings, error1])

  return (
    <>
      <PageHeader
        title='Call Recordings'
        subtitle='Fetches list of call recordings'
        icon={<CallIcon fontSize='medium' />}
      />
      <Paper className={classes.pageContent}>
        <Grid container spacing={2}>
          <Grid item>
            <Typography>Search</Typography>
          </Grid>
          <Grid sm item />
        </Grid>
        <Form autoComplete='off' onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <Controls.Select
                label='Search Type *'
                name='type'
                fullWidth
                value={values.type}
                onChange={handleInputChange}
                options={[
                  { id: 'Employee', title: 'Employee' },
                  { id: 'Number', title: 'Number' },
                ]}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Controls.Select
                label='Employee *'
                name='employee'
                fullWidth
                value={values.employee}
                onChange={handleInputChange}
                options={employees}
                disabled={values.type !== 'Employee'}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Controls.Input
                label='Number *'
                name='number'
                fullWidth
                value={values.number}
                onChange={handleInputChange}
                type='number'
                inputProps={{
                  min: 0,
                }}
                disabled={values.type !== 'Number'}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <Controls.DatePicker
                label='From Date *'
                name='from'
                fullWidth
                value={values.from}
                onChange={handleInputChange}
       //  maxDate={new Date('2023-11-30')} // Maximum date (November 30, 2023)  
	  />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Controls.DatePicker
                label='To Date *'
                name='to'
                fullWidth
                value={values.to}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Controls.Button
                type='submit'
                text='Search'
                className={classes.submit}
                loading={loading1}
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>
      <MaterialTable
        style={{ margin: '0 20px' }}
        title='Call Recordings'
        columns={headCells}
        data={recordingsData}
        options={{
          exportButton: true,
          search: true,
          exportAllData: true,
        }}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}

export default CallRecordings
