import {} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hrNaeAction } from '../actions/hrActions'
import Notification from '../components/Notification'
import Progress from '../components/Progress'
import PageHeader from '../components/PageHeader'
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import MaterialTable from 'material-table'

const headCells = [
  {
    field: 'employeeCode',
    title: 'Employee Code',
    render: (rowData) => (
      <a href={`/hr/employee/${rowData.id}`} target='_blank'>
        {rowData.employeeCode}
      </a>
    ),
  },
  {
    field: 'name',
    title: 'Employee Name',
  },
  {
    field: 'organisation',
    title: 'Organisation',
  },
  {
    field: 'mobile',
    title: 'Mobile',
  },
  {
    field: 'exit_status',
    title: 'Exit Status',
  },
  {
    field: 'exit_reason',
    title: 'Exit Reason',
  },
  {
    field: 'doj',
    title: 'Date of Joining',
  },
  {
    field: 'dol',
    title: 'Date of Leaving',
  },
]

const HRNotActiveEmployees = ({ history }) => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const hrNae = useSelector((state) => state.hrNae)
  const { loading, error, hrNotActiveEmployees } = hrNae

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const [notActiveEmployees, setNotActiveEmployees] = useState([])

  useEffect(() => {
    dispatch(hrNaeAction())
  }, [])

  useEffect(() => {
    if (hrNotActiveEmployees) {
      if (
        hrNotActiveEmployees.role === 'Admin' ||
        hrNotActiveEmployees.role === 'HR'
      ) {
        setNotActiveEmployees(hrNotActiveEmployees.employees)
      } else {
        history.push('/dashboard')
      }
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [hrNotActiveEmployees, error])

  return (
    <div>
      {loading ? (
        <Progress />
      ) : (
        <>
          {error ? (
            <Notification notify={notify} setNotify={setNotify} />
          ) : (
            <>
              <PageHeader
                title='Not Active Employees'
                subtitle='Shows list of not active employees working in the organisations'
                icon={<PeopleOutlineTwoToneIcon fontSize='medium' />}
              />
              <MaterialTable
                style={{ margin: '0 20px' }}
                title='Not Active Employees'
                columns={headCells}
                data={notActiveEmployees}
                options={{
                  exportButton: true,
                  search: true,
                  exportAllData: true,
                }}
              />
            </>
          )}
        </>
      )}
    </div>
  )
}

export default HRNotActiveEmployees
