import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  hrAttendanceReportsAction,
  hrReportsAction,
} from '../actions/hrActions'
import Notification from '../components/Notification'
import Progress from '../components/Progress'
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import PageHeader from '../components/PageHeader'
import MaterialTable from 'material-table'
import Controls from '../components/controls/Controls'
import { useForm, Form } from '../components/useForm'
import { makeStyles, Paper, Grid, Typography } from '@material-ui/core'
import { HR_ATTENDANCE_REPORTS_RESET } from '../constants/hrConstants'

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    overflowX: 'auto',
  },
  searchInput: {
    width: '75%',
  },
  submit: {
    margin: theme.spacing(1, 0, 0),
  },
}))

const months = [
  { id: '01', title: 'January' },
  { id: '02', title: 'February' },
  { id: '03', title: 'March' },
  { id: '04', title: 'April' },
  { id: '05', title: 'May' },
  { id: '06', title: 'June' },
  { id: '07', title: 'July' },
  { id: '08', title: 'August' },
  { id: '09', title: 'September' },
  { id: '10', title: 'October' },
  { id: '11', title: 'November' },
  { id: '12', title: 'December' },
]

const initialValues = {
  date: null,
  year: '',
  month: '',
}

const HRReportsAttendance = ({ match, location, history }) => {
  const {
    params: { type },
  } = match

  const classes = useStyles()

  const [heads, setHeads] = useState([])
  const [reportData, setReportData] = useState([])

  // Requirements
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    setErrors({
      ...temp,
    })
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '')
  }

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  )

  const handleSubmit = (e) => {
    e.preventDefault()
    if (type === 'day-wise') {
      if (!values.date) {
        setNotify({
          isOpen: true,
          message: 'Enter a valid date',
          type: 'error',
        })
      } else {
        dispatch(hrAttendanceReportsAction(type, values.date))
      }
    } else if (type === 'month-wise') {
      if (!values.month || !values.year) {
        setNotify({
          isOpen: true,
          message: 'All the fields are mandatory',
          type: 'error',
        })
      } else {
        dispatch(
          hrAttendanceReportsAction(
            type,
            values.date,
            values.month,
            values.year
          )
        )
      }
    }
  }

  const hrAttendanceReports = useSelector((state) => state.hrAttendanceReports)
  const { loading, error, report } = hrAttendanceReports

  useEffect(() => {
    dispatch({ type: HR_ATTENDANCE_REPORTS_RESET })
    if (report) {
      setHeads(report.headCells)
      setReportData(report.attendances)
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [report, error])

  return (
    <div>
      <PageHeader
        title='Attendance Report'
        subtitle='Shows fetched reports'
        icon={<PeopleOutlineTwoToneIcon fontSize='medium' />}
      />
      <Paper className={classes.pageContent}>
        <Grid container spacing={3}>
          <Grid item>
            <Typography>Search</Typography>
          </Grid>
          <Grid sm item />
        </Grid>
        {type === 'day-wise' ? (
          <Form autoComplete='off' onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4}>
                <Controls.DatePicker
                  label='Date *'
                  name='date'
                  fullWidth
                  value={values.date}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Controls.Button
                  type='submit'
                  text='Search'
                  className={classes.submit}
                  loading={loading}
                />
              </Grid>
            </Grid>
          </Form>
        ) : (
          <Form autoComplete='off' onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4}>
                <Controls.Select
                  label='Month *'
                  name='month'
                  fullWidth
                  value={values.month}
                  onChange={handleInputChange}
                  options={months}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Controls.Input
                  label='Year *'
                  name='year'
                  fullWidth
                  value={values.year}
                  onChange={handleInputChange}
                  type='number'
                  inputProps={{
                    min: 0,
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Controls.Button
                  type='submit'
                  text='Search'
                  className={classes.submit}
                  loading={loading}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Paper>
      <MaterialTable
        style={{ margin: '0 20px' }}
        title='Attendance Report'
        columns={heads}
        data={reportData}
        options={{
          exportButton: true,
          search: true,
          exportAllData: true,
        }}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  )
}

export default HRReportsAttendance
