import { Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import PersonIcon from '@material-ui/icons/Person'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import Controls from '../components/controls/Controls'
import { useDispatch, useSelector } from 'react-redux'
import {
  hrActivateExtensionAction,
  hrDeactivateExtensionAction,
  hrDeleteDocAction,
  hrDownloadDocAction,
  hrGetEmployeeByIdAction,
  hrMakeDepartmentPrimaryEmployeeAction,
  hrRemoveExtensionAction,
  hrResetDevIDIEAction,
  hrResetPIEAction,
  hrUpdtBIEAction,
  hrUpdtBkIEAction,
  hrUpdtDIEAction,
  hrUpdtEIEAction,
  hrUpdtEmgIEAction,
  hrUpdtEmpIEAction,
  hrUpdtExpIEAction,
  hrUpdtRIEAction,
  hrUpdtSIEAction,
  hrUpdtVIEAction,
} from '../actions/hrActions'
import { useForm } from '../components/useForm'
import Progress from '../components/Progress'
import {
  HR_ACTIVATE_EXTENSION_EMPLOYEE_RESET,
  HR_DEACTIVATE_EXTENSION_EMPLOYEE_RESET,
  HR_DELETE_DOC_EMPLOYEE_RESET,
  HR_DOWNLOAD_DOC_EMPLOYEE_RESET,
  HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_RESET,
  HR_REMOVE_EXTENSION_EMPLOYEE_RESET,
  HR_RESET_DEVICE_ID_INFO_EMPLOYEE_RESET,
  HR_RESET_PASSWORD_INFO_EMPLOYEE_RESET,
  HR_UPDATE_BANK_INFO_EMPLOYEE_RESET,
  HR_UPDATE_BASIC_INFO_EMPLOYEE_RESET,
  HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_RESET,
  HR_UPDATE_EDUCATION_INFO_EMPLOYEE_RESET,
  HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_RESET,
  HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_RESET,
  HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_RESET,
  HR_UPDATE_REFERENCE_INFO_EMPLOYEE_RESET,
  HR_UPDATE_SETTINGS_INFO_EMPLOYEE_RESET,
  HR_UPDATE_VEHICLE_INFO_EMPLOYEE_RESET,
} from '../constants/hrConstants'
import Notification from '../components/Notification'
import Popup from '../components/Popup'
import HRExtension from '../components/HRExtension'
import ConfirmDialog from '../components/ConfirmDialog'
import HRUploadDoc from '../components/HRUploadDoc'
import GetAppIcon from '@material-ui/icons/GetApp'
import HRAddDepartment from '../components/HRAddDepartment'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import CheckIcon from '@material-ui/icons/Check'
import HREditDepartment from '../components/HREditDepartment'
import HRExit from '../components/HRExit'
import HRRejoin from '../components/HRRejoin'
import MaterialTable from 'material-table'

const useStyles = makeStyles((theme) => ({
  pageContent_main: {
    padding: theme.spacing(2),
  },
  pageContent: {
    margin: theme.spacing(0),
    padding: theme.spacing(2, 2.5),
  },
  submit: {
    margin: theme.spacing(2, 0, 0),
  },
  exit: {
    padding: theme.spacing(0.5, 2),
  },
  download: {
    padding: theme.spacing(0.5, 2),
    marginRight: 10,
  },
  reset_password: {
    margin: theme.spacing(2, 1, 0),
  },
  attendance_status_in: {
    color: '#00A300',
    border: '2px solid #00A300',
    borderRadius: '2px',
    padding: '2px 10px',
  },
  attendance_status_out: {
    color: '#800000',
    border: '2px solid #800000',
    borderRadius: '2px',
    padding: '2px 10px',
  },
  label: {
    fontWeight: 'bold',
  },
  pic: {
    fontSize: '212px',
  },
  icon: {
    cursor: 'pointer',
  },
  select: {
    '& .MuiSelect-select': {
      minWidth: '200px',
    },
  },
  searchInput: {
    width: '75%',
  },
  link: {
    textDecoration: 'none',
  },
  addButton: {
    position: 'absolute',
    right: '10px',
  },
}))

const bloodGroups = [
  { id: 'A+', title: 'A+' },
  { id: 'A-', title: 'A-' },
  { id: 'B+', title: 'B+' },
  { id: 'B-', title: 'B-' },
  { id: 'O+', title: 'O+' },
  { id: 'O-', title: 'O-' },
  { id: 'AB+', title: 'AB+' },
  { id: 'AB-', title: 'AB-' },
]
const genders = [
  { id: 'Male', title: 'Male' },
  { id: 'Female', title: 'Female' },
  { id: 'Others', title: 'Others' },
]
const maritalStatus = [
  { id: 'Single', title: 'Single' },
  { id: 'Married', title: 'Married' },
  { id: 'Divorcee', title: 'Divorcee' },
  { id: 'Widow', title: 'Widow' },
]
const relations = [
  { id: 'Relative', title: 'Relative' },
  { id: 'Friend', title: 'Friend' },
]
const educationOptions = [
  { id: 'PhD', title: 'PhD' },
  { id: 'Masters', title: 'Masters' },
  { id: 'Bachelors', title: 'Bachelors' },
  { id: 'Diploma', title: 'Diploma' },
  { id: '12th', title: '12th' },
  { id: '10th', title: '10th' },
  { id: 'Below 10th', title: 'Below 10th' },
]
const employeeTypes = [
  { id: 'Office', title: 'Office' },
  { id: 'Field', title: 'Field' },
]
const employeeZones = [
  { id: 'North', title: 'North' },
  { id: 'South', title: 'South' },
  { id: 'East', title: 'East' },
  { id: 'West', title: 'West' },
  { id: 'Central', title: 'Central' },
  { id: 'Gurugram', title: 'Gurugram' },
  { id: 'Faridabad', title: 'Faridabad' },
  { id: 'Ghaziabad', title: 'Ghaziabad' },
]
const trackingPolicy = [
  { id: 'Image Capture', title: 'Image Capture' },
  // { id: 'Live Location', title: 'Live Location' },
]
const loginTypes = [
  { id: 'App', title: 'App' },
  { id: 'Web', title: 'Web' },
  { id: 'Both', title: 'Both' },
]

const jobTitles = [
  { id: 'Tele Caller', title: 'Tele Caller' },
  { id: 'Coordinator', title: 'Coordinator' },
  { id: 'Supervisor', title: 'Supervisor' },
  { id: 'HR Head', title: 'HR Head' },
  { id: 'Billing Head', title: 'Billing Head' },
  { id: 'Accountant', title: 'Accountant' },
  { id: 'IT Head', title: 'IT Head' },
  { id: 'Full Stack Developer', title: 'Full Stack Developer' },
  { id: 'Runner', title: 'Runner' },
  { id: 'Cashier', title: 'Cashier' },
  { id: 'Office Boy', title: 'Office Boy' },
  { id: 'Collection Process Head', title: 'Collection Process Head' },
  { id: 'Collection Process Head', title: 'Collection Process Head' },
  { id: 'Manager', title: 'Manager' },
  { id: 'CEO', title: 'CEO' },
]

const initialValues = {
  first_name: '',
  middle_name: '',
  last_name: '',
  current_address_line_1: '',
  current_address_line_2: '',
  current_state: '',
  current_country: '',
  current_pincode: '',
  permanent_address_line_1: '',
  permanent_address_line_2: '',
  permanent_state: '',
  permanent_country: '',
  permanent_pincode: '',
  dob: null,
  mobile: '',
  alternate_number: '',
  father_name: '',
  father_mobile: '',
  father_occupation: '',
  mother_name: '',
  mother_mobile: '',
  mother_occupation: '',
  personal_email_address: '',
  blood_group: '',
  gender: '',
  marital_status: '',
  spouse_name: '',
  spouse_mobile: '',
  marriage_date: null,
  reference_1_name: '',
  reference_1_mobile: '',
  reference_1_relation: '',
  reference_1_address: '',
  reference_2_name: '',
  reference_2_mobile: '',
  reference_2_relation: '',
  reference_2_address: '',
  name_as_per_bank: '',
  bank_name: '',
  account_number: '',
  ifsc_code: '',
  aadhar_card_number: '',
  pan_card_number: '',
  voter_id_card_number: '',
  driving_license_number: '',
  photos_collected: '',
  house_documents_status: 'No',
  house_documents_name: '',
  original_documents_isRequired: 'Not Required',
  original_documents_isSubmitted: '',
  original_documents_name: '',
  police_verification_isRequired: 'Not Required',
  police_verification_isSubmitted: '',
  police_verification_valid_upto: '',
  dra_isRequired: 'Not Required',
  dra_isSubmitted: '',
  dra_number: '',
  education: '',
  credential: '',
  school_name: '',
  graduated_on: null,
  organisation: '',
  punch_in_at: '',
  doj: null,
  employee_type: '',
  job_title: '',
  employee_zone: '',
  tracking_policy: '',
  experience_in_years: '',
  referred_by_name: '',
  referred_by_mobile: '',
  application_file: '',
  original_file: '',
  employee_code: '',
  email_address: '',
  access_control: '',
  previous_company_name: '',
  previous_designation: '',
  previous_left_on: null,
  previous_left_reason: '',
  vehicle_name: '',
  registered_name: '',
  registration_number: '',
  emergency_contact_name: '',
  emergency_contact_number: '',
  emergency_contact_relation: '',
  emergency_contact_address: '',
  attendance_status: false,
  default_department: '',
  login_type: '',
  verification_status: '',
  device_id: '',
  app_calling: false,
  web_calling: false,
  did: '',
  extension: '',
  extensions_status: '',
  extension_assigned_on: null,
}

const HREditEmployee = ({ match, history }) => {
  const {
    params: { id },
  } = match

  const classes = useStyles()

  // Basic Edit
  const [disableBasic, setDisableBasic] = useState(true)

  const editBasicInfoHandler = () => {
    setDisableBasic(!disableBasic)
  }

  // Reference Edit
  const [disableReference, setDisableReference] = useState(true)

  const editReferenceInfoHandler = () => {
    setDisableReference(!disableReference)
  }

  // Bank Edit
  const [disableBank, setDisableBank] = useState(true)

  const editBankInfoHandler = () => {
    setDisableBank(!disableBank)
  }

  // Documents Edit
  const [disableDocuments, setDisableDocuments] = useState(true)

  const editDocumentsHandler = () => {
    setDisableDocuments(!disableDocuments)
  }

  // Education Edit
  const [disableEducation, setDisableEducation] = useState(true)

  const editEducationHandler = () => {
    setDisableEducation(!disableEducation)
  }

  // Employee Edit
  const [disableEmployee, setDisableEmployee] = useState(true)

  const editEmployeeHandler = () => {
    setDisableEmployee(!disableEmployee)
  }

  // Previous Company Edit
  const [disablePrevCompany, setDisablePrevCompany] = useState(true)

  const editPrevCompanyHandler = () => {
    setDisablePrevCompany(!disablePrevCompany)
  }

  // Vehicle Details Edit
  const [disableVehicle, setDisableVehicle] = useState(true)

  const editVehicleHandler = () => {
    setDisableVehicle(!disableVehicle)
  }

  // Emergency Contact Edit
  const [disableEmeContact, setDisableEmeContact] = useState(true)

  const editEmeContactHandler = () => {
    setDisableEmeContact(!disableEmeContact)
  }

  // Settings Edit
  const [disableSettings, setDisableSettings] = useState(true)

  const editSettingsHandler = () => {
    setDisableSettings(!disableSettings)
  }

  // Extension Edit
  const [disableExtension, setDisableExtension] = useState(true)

  const editExtensionHandler = () => {
    setDisableExtension(!disableExtension)
  }

  const [banks, setBanks] = useState([])
  const [extensions, setExtensions] = useState([])
  const [uploadedDocuments, setUploadedDocuments] = useState([])
  const [empDepartments, setEmpDepartments] = useState([])
  const [organisations, setOrganisations] = useState([])
  const [departments, setDepartments] = useState([])
  const [attendances, setAttendances] = useState([])
  const [resignHistory, setResignHistory] = useState([])

  // Requirements
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('first_name' in fieldValues)
      temp.first_name = fieldValues.first_name ? '' : 'This field is required'
    if ('last_name' in fieldValues)
      temp.last_name = fieldValues.last_name ? '' : 'This field is required'
    if ('current_address_line_1' in fieldValues)
      temp.current_address_line_1 = fieldValues.current_address_line_1
        ? ''
        : 'This field is required'
    if ('current_state' in fieldValues)
      temp.current_state = fieldValues.current_state
        ? ''
        : 'This field is required'
    if ('current_country' in fieldValues)
      temp.current_country = fieldValues.current_country
        ? ''
        : 'This field is required'
    if ('current_pincode' in fieldValues)
      temp.current_pincode = fieldValues.current_pincode
        ? ''
        : 'This field is required'
    if ('permanent_address_line_1' in fieldValues)
      temp.permanent_address_line_1 = fieldValues.permanent_address_line_1
        ? ''
        : 'This field is required'
    if ('permanent_state' in fieldValues)
      temp.permanent_state = fieldValues.permanent_state
        ? ''
        : 'This field is required'
    if ('permanent_country' in fieldValues)
      temp.permanent_country = fieldValues.permanent_country
        ? ''
        : 'This field is required'
    if ('permanent_pincode' in fieldValues)
      temp.permanent_pincode = fieldValues.permanent_pincode
        ? ''
        : 'This field is required'
    if ('mobile' in fieldValues)
      temp.mobile =
        fieldValues.mobile.length > 9
          ? ''
          : 'Mobile number must be of 10 digits only'
    if ('alternate_number' in fieldValues)
      temp.alternate_number =
        fieldValues.alternate_number.length > 9
          ? ''
          : 'Alternate Mobile number must be of 10 digits only'
    if ('father_name' in fieldValues)
      temp.father_name = fieldValues.father_name ? '' : 'This field is required'
    if ('mother_name' in fieldValues)
      temp.mother_name = fieldValues.mother_name ? '' : 'This field is required'
    if ('gender' in fieldValues)
      temp.gender =
        fieldValues.gender.length !== 0 ? '' : 'This field is required'
    if ('marital_status' in fieldValues)
      temp.marital_status =
        fieldValues.marital_status.length !== 0 ? '' : 'This field is required'
    if ('reference_1_name' in fieldValues)
      temp.reference_1_name = fieldValues.reference_1_name
        ? ''
        : 'This field is required'
    if ('reference_1_mobile' in fieldValues)
      temp.reference_1_mobile = fieldValues.reference_1_mobile
        ? ''
        : 'This field is required'
    if ('reference_1_relation' in fieldValues)
      temp.reference_1_relation =
        fieldValues.reference_1_relation.length !== 0
          ? ''
          : 'This field is required'
    if ('reference_1_address' in fieldValues)
      temp.reference_1_address = fieldValues.reference_1_address
        ? ''
        : 'This field is required'
    if ('reference_2_name' in fieldValues)
      temp.reference_2_name = fieldValues.reference_2_name
        ? ''
        : 'This field is required'
    if ('reference_2_mobile' in fieldValues)
      temp.reference_2_mobile = fieldValues.reference_2_mobile
        ? ''
        : 'This field is required'
    if ('reference_2_relation' in fieldValues)
      temp.reference_2_relation =
        fieldValues.reference_2_relation.length !== 0
          ? ''
          : 'This field is required'
    if ('reference_2_address' in fieldValues)
      temp.reference_2_address = fieldValues.reference_2_address
        ? ''
        : 'This field is required'
    if ('name_as_per_bank' in fieldValues)
      temp.name_as_per_bank = fieldValues.name_as_per_bank
        ? ''
        : 'This field is required'
    if ('bank_name' in fieldValues)
      temp.bank_name = fieldValues.bank_name ? '' : 'This field is required'
    if ('account_number' in fieldValues)
      temp.account_number = fieldValues.account_number
        ? ''
        : 'This field is required'
    if ('ifsc_code' in fieldValues)
      temp.ifsc_code =
        fieldValues.ifsc_code.length > 10 ? '' : 'This field is required'
    if ('aadhar_card_number' in fieldValues)
      temp.aadhar_card_number =
        fieldValues.aadhar_card_number.length > 11
          ? ''
          : 'This field is required'
    if ('pan_card_number' in fieldValues)
      temp.pan_card_number =
        fieldValues.pan_card_number.length > 9 ? '' : 'This field is required'
    if ('photos_collected' in fieldValues)
      temp.photos_collected = fieldValues.photos_collected
        ? ''
        : 'This field is required'
    if ('house_documents_status' in fieldValues)
      temp.house_documents_status =
        fieldValues.house_documents_status.length !== 0
          ? ''
          : 'This field is required'
    if ('original_documents_isRequired' in fieldValues)
      temp.original_documents_isRequired =
        fieldValues.original_documents_isRequired.length !== 0
          ? ''
          : 'This field is required'
    if ('police_verification_isRequired' in fieldValues)
      temp.police_verification_isRequired =
        fieldValues.police_verification_isRequired.length !== 0
          ? ''
          : 'This field is required'
    if ('dra_isRequired' in fieldValues)
      temp.dra_isRequired =
        fieldValues.dra_isRequired.length !== 0 ? '' : 'This field is required'
    if ('employee_type' in fieldValues)
      temp.employee_type =
        fieldValues.employee_type.length !== 0 ? '' : 'This field is required'
    if ('job_title' in fieldValues)
      temp.job_title =
        fieldValues.job_title !== 0 ? '' : 'This field is required'
    if ('tracking_policy' in fieldValues)
      temp.tracking_policy =
        fieldValues.tracking_policy.length !== 0 ? '' : 'This field is required'
    if ('experience_in_years' in fieldValues)
      temp.experience_in_years = fieldValues.experience_in_years
        ? ''
        : 'This field is required'
    if ('application_file' in fieldValues)
      temp.application_file = fieldValues.application_file
        ? ''
        : 'This field is required'
    if ('employee_code' in fieldValues)
      temp.employee_code = fieldValues.employee_code
        ? ''
        : 'This field is required'
    if ('emergency_contact_name' in fieldValues)
      temp.emergency_contact_name = fieldValues.emergency_contact_name
        ? ''
        : 'This field is required'
    if ('emergency_contact_number' in fieldValues)
      temp.emergency_contact_number = fieldValues.emergency_contact_number
        ? ''
        : 'This field is required'
    if ('emergency_contact_relation' in fieldValues)
      temp.emergency_contact_relation = fieldValues.emergency_contact_relation
        ? ''
        : 'This field is required'
    if ('emergency_contact_address' in fieldValues)
      temp.emergency_contact_address = fieldValues.emergency_contact_address
        ? ''
        : 'This field is required'
    if ('personal_email_address' in fieldValues)
      temp.personal_email_address = /$^|.+@.+..+/.test(
        fieldValues.personal_email_address
      )
        ? ''
        : 'Email is not valid.'
    if ('email_address' in fieldValues)
      temp.email_address = /$^|.+@.+..+/.test(fieldValues.email_address)
        ? ''
        : 'Email is not valid.'
    if ('login_type' in fieldValues)
      temp.login_type =
        fieldValues.login_type.length !== 0 ? '' : 'This field is required.'
    if ('verification_status' in fieldValues)
      temp.verification_status =
        fieldValues.verification_status.length !== 0
          ? ''
          : 'This field is required.'
    if ('app_calling' in fieldValues)
      temp.app_calling =
        fieldValues.app_calling.length !== 0 ? '' : 'This field is required.'
    if ('web_calling' in fieldValues)
      temp.web_calling =
        fieldValues.web_calling.length !== 0 ? '' : 'This field is required.'

    setErrors({
      ...temp,
    })
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '')
  }

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  )

  // Fetch Employee Info
  const hrGetEmployee = useSelector((state) => state.hrGetEmployee)
  const { loading, error, hrGetEmp } = hrGetEmployee

  useEffect(() => {
    dispatch(hrGetEmployeeByIdAction(id))
  }, [dispatch, id])

  const [isAdmin, setIsAdmin] = useState(false)
  const [isActive, setIsActive] = useState('Active')

  const [leavesQuota, setLeavesQuota] = useState(0)
  const [leavesHistory, setLeavesHistory] = useState([])

  useEffect(() => {
    if (hrGetEmp) {
      if (hrGetEmp.role === 'Admin' || hrGetEmp.role === 'HR') {
        if (hrGetEmp.role === 'Admin') {
          setIsAdmin(true)
        }
        setBanks(hrGetEmp.banks)
        setExtensions(hrGetEmp.extensions)
        setDepartments(hrGetEmp.departments)
        setOrganisations(hrGetEmp.organisations)
        const { employee } = hrGetEmp
        setUploadedDocuments(employee.docs)
        setEmpDepartments(employee.departments)
        setIsActive(employee.master_state)
        setAttendances(employee.attendance)
        setResignHistory(employee.resign_history)
        setLeavesQuota(employee.employee_information.leaves_summary.quota)
        setLeavesHistory(employee.employee_information.leaves_summary.history)

        setValues({
          ...values,
          first_name: employee.basic_information.first_name,
          middle_name: employee.basic_information.middle_name,
          last_name: employee.basic_information.last_name,
          current_address_line_1:
            employee.basic_information.current_address_line_1,
          current_address_line_2:
            employee.basic_information.current_address_line_2,
          current_state: employee.basic_information.current_state,
          current_country: employee.basic_information.current_country,
          current_pincode: employee.basic_information.current_pincode,
          permanent_address_line_1:
            employee.basic_information.permanent_address_line_1,
          permanent_address_line_2:
            employee.basic_information.permanent_address_line_2,
          permanent_state: employee.basic_information.permanent_state,
          permanent_country: employee.basic_information.permanent_country,
          permanent_pincode: employee.basic_information.permanent_pincode,
          dob: employee.basic_information.dob,
          mobile: employee.mobile,
          alternate_number: employee.basic_information.alternate_number,
          father_name: employee.basic_information.father_name,
          father_mobile: employee.basic_information.father_mobile,
          father_occupation: employee.basic_information.father_occupation,
          mother_name: employee.basic_information.mother_name,
          mother_mobile: employee.basic_information.mother_mobile,
          mother_occupation: employee.basic_information.mother_occupation,
          personal_email_address:
            employee.basic_information.personal_email_address,
          blood_group: employee.basic_information.blood_group,
          gender: employee.basic_information.gender,
          marital_status: employee.basic_information.marital_status,
          spouse_name: employee.basic_information.spouse_name,
          spouse_mobile: employee.basic_information.spouse_mobile,
          marriage_date: employee.basic_information.marriage_date,
          reference_1_name: employee.reference_information.reference_1_name,
          reference_1_mobile: employee.reference_information.reference_1_mobile,
          reference_1_relation:
            employee.reference_information.reference_1_relation,
          reference_1_address:
            employee.reference_information.reference_1_address,
          reference_2_name: employee.reference_information.reference_2_name,
          reference_2_mobile: employee.reference_information.reference_2_mobile,
          reference_2_relation:
            employee.reference_information.reference_2_relation,
          reference_2_address:
            employee.reference_information.reference_2_address,
          name_as_per_bank: employee.banking_information.name_as_per_bank,
          bank_name: employee.banking_information.bank_name,
          account_number: employee.banking_information.account_number,
          ifsc_code: employee.banking_information.ifsc_code,
          aadhar_card_number: employee.documents.aadhar_card,
          pan_card_number: employee.documents.pan_card,
          voter_id_card_number: employee.documents.voter_id_card,
          driving_license_number: employee.documents.driving_license,
          photos_collected: employee.documents.photos_collected,
          house_documents_status: employee.documents.house.status,
          house_documents_name: employee.documents.house.name,
          original_documents_isRequired: employee.documents.original.isRequired,
          original_documents_isSubmitted:
            employee.documents.original.isSubmitted,
          original_documents_name: employee.documents.original.name,
          police_verification_isRequired:
            employee.documents.police_verification.isRequired,
          police_verification_isSubmitted:
            employee.documents.police_verification.isSubmitted,
          police_verification_valid_upto:
            employee.documents.police_verification.valid_upto,
          dra_isRequired: employee.documents.dra.isRequired,
          dra_isSubmitted: employee.documents.dra.isSubmitted,
          dra_number: employee.documents.dra.number,
          education: employee.highest_completed_education.education,
          school_name: employee.highest_completed_education.school_name,
          credential: employee.highest_completed_education.credential,
          graduated_on: employee.highest_completed_education.graduated_on,
          organisation: employee.organisation.name,
          doj: employee.employee_information.doj,
          employee_type: employee.employee_information.employee_type,
          job_title: employee.employee_information.job_title,
          employee_zone: employee.employee_information.employee_zone,
          punch_in_at: employee.employee_information.punch_in_at,
          tracking_policy: employee.employee_information.tracking_policy,
          experience_in_years:
            employee.employee_information.experience_in_years,
          referred_by_name: employee.employee_information.referred_by.name,
          referred_by_mobile: employee.employee_information.referred_by.mobile,
          application_file: employee.employee_information.application_file,
          original_file: employee.employee_information.original_file,
          employee_code: employee.employee_information.employee_code,
          email_address: employee.employee_information.email_address,
          access_control: employee.employee_information.access_control,
          previous_company_name: employee.experience_details.company_name,
          previous_designation: employee.experience_details.designation,
          previous_left_on: employee.experience_details.left_on,
          previous_left_reason: employee.experience_details.left_reason,
          vehicle_name: employee.vehicle_details.name,
          registered_name: employee.vehicle_details.registered_name,
          registration_number: employee.vehicle_details.registration_number,
          emergency_contact_name: employee.emergency_contact.name,
          emergency_contact_number: employee.emergency_contact.number,
          emergency_contact_relation: employee.emergency_contact.relation,
          emergency_contact_address: employee.emergency_contact.address,
          attendance_status: employee.attendance_status,
          default_department: employee.defaultDepartment.name,
          login_type: employee.login_type,
          verification_status: employee.verification_settings.status,
          device_id: employee.device_id,
          app_calling: employee.app_calling,
          web_calling: employee.web_calling,
          did: employee.extension.did,
          extension: employee.extension.extension,
          extension_status: employee.extension.is_active,
          extension_assigned_on: employee.extension.assigned_on || null,
        })
      } else {
        history.push('/dashboard')
      }
    }
  }, [hrGetEmp, history, id])

  // Update
  // Basic
  const updateBasic = () => {
    if (
      !values.first_name ||
      !values.last_name ||
      !values.current_address_line_1 ||
      !values.current_state ||
      !values.current_country ||
      !values.current_pincode ||
      !values.permanent_address_line_1 ||
      !values.permanent_state ||
      !values.permanent_country ||
      !values.permanent_pincode ||
      !values.dob ||
      !values.mobile ||
      !values.alternate_number ||
      !values.father_name ||
      !values.mother_name ||
      !values.gender ||
      !values.marital_status
    ) {
      setNotify({
        isOpen: true,
        message: 'All the fields with * are mandatory',
        type: 'error',
      })
    } else {
      dispatch(
        hrUpdtBIEAction(
          id,
          values.first_name,
          values.middle_name,
          values.last_name,
          values.current_address_line_1,
          values.current_address_line_2,
          values.current_state,
          values.current_country,
          values.current_pincode,
          values.permanent_address_line_1,
          values.permanent_address_line_2,
          values.permanent_state,
          values.permanent_country,
          values.permanent_pincode,
          values.dob,
          values.mobile,
          values.alternate_number,
          values.father_name,
          values.father_mobile,
          values.father_occupation,
          values.mother_name,
          values.mother_mobile,
          values.mother_occupation,
          values.personal_email_address,
          values.blood_group,
          values.gender,
          values.marital_status,
          values.spouse_name,
          values.spouse_mobile,
          values.marriage_date
        )
      )
    }
  }

  const hrUpdBIE = useSelector((state) => state.hrUpdBIE)
  const { loading1, error1, updateBasicInfoEmployee } = hrUpdBIE

  useEffect(() => {
    dispatch({ type: HR_UPDATE_BASIC_INFO_EMPLOYEE_RESET })
    if (updateBasicInfoEmployee) {
      setNotify({
        isOpen: true,
        message: updateBasicInfoEmployee.msg,
        type: 'success',
      })
      setTimeout(() => {
        setDisableBasic(true)
        dispatch(hrGetEmployeeByIdAction(id))
      }, 2000)
    } else if (error1) {
      setNotify({
        isOpen: true,
        message: error1,
        type: 'error',
      })
    }
  }, [updateBasicInfoEmployee, error1, dispatch, id])

  // Bank
  const updateBank = () => {
    if (
      !values.name_as_per_bank ||
      !values.bank_name ||
      !values.account_number ||
      !values.ifsc_code
    ) {
      setNotify({
        isOpen: true,
        message: 'All the fields with * are mandatory',
        type: 'error',
      })
    } else {
      dispatch(
        hrUpdtBkIEAction(
          id,
          values.name_as_per_bank,
          values.bank_name,
          values.account_number,
          values.ifsc_code
        )
      )
    }
  }

  const hrUpdBkIE = useSelector((state) => state.hrUpdBkIE)
  const { loading2, error2, updateBankInfoEmployee } = hrUpdBkIE

  useEffect(() => {
    dispatch({ type: HR_UPDATE_BANK_INFO_EMPLOYEE_RESET })
    if (updateBankInfoEmployee) {
      setNotify({
        isOpen: true,
        message: updateBankInfoEmployee.msg,
        type: 'success',
      })
      setTimeout(() => {
        setDisableBank(true)
        dispatch(hrGetEmployeeByIdAction(id))
      }, 2000)
    } else if (error2) {
      setNotify({
        isOpen: true,
        message: error2,
        type: 'error',
      })
    }
  }, [updateBankInfoEmployee, error2, dispatch, id])

  // Reference
  const updateReference = () => {
    if (
      !values.reference_1_name ||
      !values.reference_1_mobile ||
      !values.reference_1_relation ||
      !values.reference_1_address ||
      !values.reference_2_name ||
      !values.reference_2_mobile ||
      !values.reference_2_relation ||
      !values.reference_2_address
    ) {
      setNotify({
        isOpen: true,
        message: 'All the fields with * are mandatory',
        type: 'error',
      })
    } else {
      dispatch(
        hrUpdtRIEAction(
          id,
          values.reference_1_name,
          values.reference_1_mobile,
          values.reference_1_relation,
          values.reference_1_address,
          values.reference_2_name,
          values.reference_2_mobile,
          values.reference_2_relation,
          values.reference_2_address
        )
      )
    }
  }

  const hrUpdRIE = useSelector((state) => state.hrUpdRIE)
  const { loading3, error3, updateReferenceInfoEmployee } = hrUpdRIE

  useEffect(() => {
    dispatch({ type: HR_UPDATE_REFERENCE_INFO_EMPLOYEE_RESET })
    if (updateReferenceInfoEmployee) {
      setNotify({
        isOpen: true,
        message: updateReferenceInfoEmployee.msg,
        type: 'success',
      })
      setTimeout(() => {
        setDisableReference(true)
        dispatch(hrGetEmployeeByIdAction(id))
      }, 2000)
    } else if (error3) {
      setNotify({
        isOpen: true,
        message: error3,
        type: 'error',
      })
    }
  }, [updateReferenceInfoEmployee, error3, dispatch, id])

  // Education
  const updateEducation = () => {
    if (
      !values.education ||
      !values.school_name ||
      !values.credential ||
      !values.graduated_on
    ) {
      setNotify({
        isOpen: true,
        message: 'All the fields with * are mandatory',
        type: 'error',
      })
    } else {
      dispatch(
        hrUpdtEIEAction(
          id,
          values.education,
          values.school_name,
          values.credential,
          values.graduated_on
        )
      )
    }
  }

  const hrUpdEIE = useSelector((state) => state.hrUpdEIE)
  const { loading4, error4, updateEducationInfoEmployee } = hrUpdEIE

  useEffect(() => {
    dispatch({ type: HR_UPDATE_EDUCATION_INFO_EMPLOYEE_RESET })
    if (updateEducationInfoEmployee) {
      setNotify({
        isOpen: true,
        message: updateEducationInfoEmployee.msg,
        type: 'success',
      })
      setTimeout(() => {
        setDisableEducation(true)
        dispatch(hrGetEmployeeByIdAction(id))
      }, 2000)
    } else if (error4) {
      setNotify({
        isOpen: true,
        message: error4,
        type: 'error',
      })
    }
  }, [updateEducationInfoEmployee, error4, dispatch, id])

  // Employee
  const updateEmployee = () => {
    if (
      !values.doj ||
      !values.employee_type ||
      !values.job_title ||
      !values.punch_in_at ||
      !values.tracking_policy ||
      !values.experience_in_years ||
      !values.application_file ||
      !values.employee_code
    ) {
      setNotify({
        isOpen: true,
        message: 'All the fields with * are mandatory',
        type: 'error',
      })
    } else {
      dispatch(
        hrUpdtEmpIEAction(
          id,
          values.doj,
          values.employee_type,
          values.job_title,
          values.employee_zone,
          values.punch_in_at,
          values.tracking_policy,
          values.experience_in_years,
          values.referred_by_name,
          values.referred_by_mobile,
          values.application_file,
          values.original_file,
          values.employee_code,
          values.email_address,
          values.access_control
        )
      )
    }
  }

  const hrUpdEmpIE = useSelector((state) => state.hrUpdEmpIE)
  const { loading5, error5, updateEmployeeInfoEmployee } = hrUpdEmpIE

  useEffect(() => {
    dispatch({ type: HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_RESET })
    if (updateEmployeeInfoEmployee) {
      setNotify({
        isOpen: true,
        message: updateEmployeeInfoEmployee.msg,
        type: 'success',
      })
      setTimeout(() => {
        setDisableEmployee(true)
        dispatch(hrGetEmployeeByIdAction(id))
      }, 2000)
    } else if (error5) {
      setNotify({
        isOpen: true,
        message: error5,
        type: 'error',
      })
    }
  }, [updateEmployeeInfoEmployee, error5, dispatch, id])

  // Experience
  const updateExperience = () => {
    if (
      !values.previous_company_name ||
      !values.previous_designation ||
      !values.previous_left_on ||
      !values.previous_left_reason
    ) {
      setNotify({
        isOpen: true,
        message: 'All the fields with * are mandatory',
        type: 'error',
      })
    } else {
      dispatch(
        hrUpdtExpIEAction(
          id,
          values.previous_company_name,
          values.previous_designation,
          values.previous_left_on,
          values.previous_left_reason
        )
      )
    }
  }

  const hrUpdExpIE = useSelector((state) => state.hrUpdExpIE)
  const { loading6, error6, updateExperienceInfoEmployee } = hrUpdExpIE

  useEffect(() => {
    dispatch({ type: HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_RESET })
    if (updateExperienceInfoEmployee) {
      setNotify({
        isOpen: true,
        message: updateExperienceInfoEmployee.msg,
        type: 'success',
      })
      setTimeout(() => {
        setDisablePrevCompany(true)
        dispatch(hrGetEmployeeByIdAction(id))
      }, 2000)
    } else if (error6) {
      setNotify({
        isOpen: true,
        message: error6,
        type: 'error',
      })
    }
  }, [updateExperienceInfoEmployee, error6, dispatch, id])

  // Vehicle
  const updateVehicle = () => {
    if (
      !values.vehicle_name ||
      !values.registration_number ||
      !values.registered_name
    ) {
      setNotify({
        isOpen: true,
        message: 'All the fields with * are mandatory',
        type: 'error',
      })
    } else {
      dispatch(
        hrUpdtVIEAction(
          id,
          values.vehicle_name,
          values.registration_number,
          values.registered_name
        )
      )
    }
  }

  const hrUpdVIE = useSelector((state) => state.hrUpdVIE)
  const { loading7, error7, updateVehicleInfoEmployee } = hrUpdVIE

  useEffect(() => {
    dispatch({ type: HR_UPDATE_VEHICLE_INFO_EMPLOYEE_RESET })
    if (updateVehicleInfoEmployee) {
      setNotify({
        isOpen: true,
        message: updateVehicleInfoEmployee.msg,
        type: 'success',
      })
      setTimeout(() => {
        setDisableVehicle(true)
        dispatch(hrGetEmployeeByIdAction(id))
      }, 2000)
    } else if (error7) {
      setNotify({
        isOpen: true,
        message: error7,
        type: 'error',
      })
    }
  }, [updateVehicleInfoEmployee, error7, dispatch, id])

  // Emergency
  const updateEmergency = () => {
    if (
      !values.emergency_contact_name ||
      !values.emergency_contact_number ||
      !values.emergency_contact_relation ||
      !values.emergency_contact_address
    ) {
      setNotify({
        isOpen: true,
        message: 'All the fields with * are mandatory',
        type: 'error',
      })
    } else {
      dispatch(
        hrUpdtEmgIEAction(
          id,
          values.emergency_contact_name,
          values.emergency_contact_number,
          values.emergency_contact_relation,
          values.emergency_contact_address
        )
      )
    }
  }

  const hrUpdEmgIE = useSelector((state) => state.hrUpdEmgIE)
  const { loading8, error8, updateEmergencyInfoEmployee } = hrUpdEmgIE

  useEffect(() => {
    dispatch({ type: HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_RESET })
    if (updateEmergencyInfoEmployee) {
      setNotify({
        isOpen: true,
        message: updateEmergencyInfoEmployee.msg,
        type: 'success',
      })
      setTimeout(() => {
        setDisableEmeContact(true)
        dispatch(hrGetEmployeeByIdAction(id))
      }, 2000)
    } else if (error8) {
      setNotify({
        isOpen: true,
        message: error8,
        type: 'error',
      })
    }
  }, [updateEmergencyInfoEmployee, error8, dispatch, id])

  // Documents
  const updateDocuments = () => {
    if (
      !values.aadhar_card_number ||
      !values.pan_card_number ||
      !values.photos_collected ||
      !values.house_documents_status ||
      !values.original_documents_isRequired ||
      !values.police_verification_isRequired ||
      !values.dra_isRequired
    ) {
      setNotify({
        isOpen: true,
        message: 'All the fields with * are mandatory',
        type: 'error',
      })
    } else {
      dispatch(
        hrUpdtDIEAction(
          id,
          values.aadhar_card_number,
          values.pan_card_number,
          values.voter_id_card_number,
          values.driving_license_number,
          values.photos_collected,
          values.house_documents_status,
          values.house_documents_name,
          values.original_documents_isRequired,
          values.original_documents_isSubmitted,
          values.original_documents_name,
          values.police_verification_isRequired,
          values.police_verification_isSubmitted,
          values.police_verification_valid_upto,
          values.dra_isRequired,
          values.dra_isSubmitted,
          values.dra_number
        )
      )
    }
  }

  const hrUpdDIE = useSelector((state) => state.hrUpdDIE)
  const { loading9, error9, updateDocumentsInfoEmployee } = hrUpdDIE

  useEffect(() => {
    dispatch({ type: HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_RESET })
    if (updateDocumentsInfoEmployee) {
      setNotify({
        isOpen: true,
        message: updateDocumentsInfoEmployee.msg,
        type: 'success',
      })
      setTimeout(() => {
        setDisableDocuments(true)
        dispatch(hrGetEmployeeByIdAction(id))
      }, 2000)
    } else if (error9) {
      setNotify({
        isOpen: true,
        message: error9,
        type: 'error',
      })
    }
  }, [updateDocumentsInfoEmployee, error9, dispatch, id])

  // Settings
  const updateSettings = () => {
    if (!values.login_type || !values.verification_status) {
      setNotify({
        isOpen: true,
        message: 'All the fields with * are mandatory',
        type: 'error',
      })
    } else {
      dispatch(
        hrUpdtSIEAction(
          id,
          values.login_type,
          values.verification_status,
          values.app_calling,
          values.web_calling
        )
      )
    }
  }

  const hrUpdSIE = useSelector((state) => state.hrUpdSIE)
  const { loading10, error10, updateSettingsInfoEmployee } = hrUpdSIE

  useEffect(() => {
    dispatch({ type: HR_UPDATE_SETTINGS_INFO_EMPLOYEE_RESET })
    if (updateSettingsInfoEmployee) {
      setNotify({
        isOpen: true,
        message: updateSettingsInfoEmployee.msg,
        type: 'success',
      })
      setTimeout(() => {
        setDisableSettings(true)
        dispatch(hrGetEmployeeByIdAction(id))
      }, 2000)
    } else if (error10) {
      setNotify({
        isOpen: true,
        message: error10,
        type: 'error',
      })
    }
  }, [updateSettingsInfoEmployee, error10, dispatch, id])

  // Reset Password
  const resetPassword = () => {
    dispatch(hrResetPIEAction(id))
  }

  const hrResetPIE = useSelector((state) => state.hrResetPIE)
  const { loading11, error11, resetPasswordInfoEmployee } = hrResetPIE

  useEffect(() => {
    dispatch({ type: HR_RESET_PASSWORD_INFO_EMPLOYEE_RESET })
    if (resetPasswordInfoEmployee) {
      setNotify({
        isOpen: true,
        message: resetPasswordInfoEmployee.msg,
        type: 'success',
      })
      setTimeout(() => {
        setDisableSettings(true)
        dispatch(hrGetEmployeeByIdAction(id))
      }, 2000)
    } else if (error11) {
      setNotify({
        isOpen: true,
        message: error11,
        type: 'error',
      })
    }
  }, [resetPasswordInfoEmployee, error11, dispatch, id])

  // Reset Device ID
  const resetDeviceID = () => {
    dispatch(hrResetDevIDIEAction(id))
  }

  const hrResetDevIDIE = useSelector((state) => state.hrResetDevIDIE)
  const { loading12, error12, resetDeviceIDInfoEmployee } = hrResetDevIDIE

  useEffect(() => {
    dispatch({ type: HR_RESET_DEVICE_ID_INFO_EMPLOYEE_RESET })
    if (resetDeviceIDInfoEmployee) {
      setNotify({
        isOpen: true,
        message: resetDeviceIDInfoEmployee.msg,
        type: 'success',
      })
      setTimeout(() => {
        setDisableSettings(true)
        dispatch(hrGetEmployeeByIdAction(id))
      }, 2000)
    } else if (error12) {
      setNotify({
        isOpen: true,
        message: error12,
        type: 'error',
      })
    }
  }, [resetDeviceIDInfoEmployee, error12, dispatch, id])

  const [openExtPopup, setOpenExtPopup] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  })

  // Remove Extenion
  const hrRemoveExt = useSelector((state) => state.hrRemoveExt)
  const { loading13, error13, removeExtensionInfoEmployee } = hrRemoveExt

  useEffect(() => {
    dispatch({ type: HR_REMOVE_EXTENSION_EMPLOYEE_RESET })
    if (removeExtensionInfoEmployee) {
      setNotify({
        isOpen: true,
        message: removeExtensionInfoEmployee.msg,
        type: 'success',
      })
      setTimeout(() => {
        setConfirmDialog({ ...confirmDialog, isOpen: false })
        setDisableExtension(true)
        dispatch(hrGetEmployeeByIdAction(id))
      }, 2000)
    } else if (error13) {
      setNotify({
        isOpen: true,
        message: error13,
        type: 'error',
      })
    }
  }, [removeExtensionInfoEmployee, error13, dispatch, id])

  // // Activate Extenion
  // const activateExtension = () => {
  //   dispatch(hrActivateExtensionAction(id))
  // }

  // const hrActivateExt = useSelector((state) => state.hrActivateExt)
  // const { loading14, error14, activateExtensionInfoEmployee } = hrActivateExt

  // useEffect(() => {
  //   dispatch({ type: HR_ACTIVATE_EXTENSION_EMPLOYEE_RESET })
  //   if (activateExtensionInfoEmployee) {
  //     setNotify({
  //       isOpen: true,
  //       message: activateExtensionInfoEmployee.msg,
  //       type: 'success',
  //     })
  //     setTimeout(() => {
  //       setDisableExtension(true)
  //       dispatch(hrGetEmployeeByIdAction(id))
  //     }, 2000)
  //   } else if (error14) {
  //     setNotify({
  //       isOpen: true,
  //       message: error14,
  //       type: 'error',
  //     })
  //   }
  // }, [activateExtensionInfoEmployee, error14, dispatch, id])

  // // Deactivate Extenion
  // const deactivateExtension = () => {
  //   dispatch(hrDeactivateExtensionAction(id))
  // }

  // const hrDeactivateExt = useSelector((state) => state.hrDeactivateExt)
  // const { loading15, error15, deactivateExtensionInfoEmployee } =
  //   hrDeactivateExt

  // useEffect(() => {
  //   dispatch({ type: HR_DEACTIVATE_EXTENSION_EMPLOYEE_RESET })
  //   if (deactivateExtensionInfoEmployee) {
  //     setNotify({
  //       isOpen: true,
  //       message: deactivateExtensionInfoEmployee.msg,
  //       type: 'success',
  //     })
  //     setTimeout(() => {
  //       setDisableExtension(true)
  //       dispatch(hrGetEmployeeByIdAction(id))
  //     }, 2000)
  //   } else if (error15) {
  //     setNotify({
  //       isOpen: true,
  //       message: error15,
  //       type: 'error',
  //     })
  //   }
  // }, [deactivateExtensionInfoEmployee, error15, dispatch, id])

  // Uploaded Docuemnts
  const [openUploadDocPopup, setOpenUploadDocPopup] = useState(false)

  // Delete Doc
  const hrDeleteDoc = useSelector((state) => state.hrDeleteDoc)
  const { loading16, error16, deleteDocEmployee } = hrDeleteDoc

  useEffect(() => {
    dispatch({ type: HR_DELETE_DOC_EMPLOYEE_RESET })
    if (deleteDocEmployee) {
      setNotify({
        isOpen: true,
        message: deleteDocEmployee.msg,
        type: 'success',
      })
      setTimeout(() => {
        setConfirmDialog({ ...confirmDialog, isOpen: false })
        dispatch(hrGetEmployeeByIdAction(id))
      }, 2000)
    } else if (error16) {
      setNotify({
        isOpen: true,
        message: error16,
        type: 'error',
      })
    }
  }, [deleteDocEmployee, error16, dispatch, id])

  // Download Doc
  const hrDownloadDoc = useSelector((state) => state.hrDownloadDoc)
  const { error17, downloadDocEmployee } = hrDownloadDoc

  useEffect(() => {
    dispatch({ type: HR_DOWNLOAD_DOC_EMPLOYEE_RESET })
    if (downloadDocEmployee) {
      setNotify({
        isOpen: true,
        message: 'Doc downloaded successfully',
        type: 'success',
      })
    } else if (error17) {
      setNotify({
        isOpen: true,
        message: error17,
        type: 'error',
      })
    }
  }, [downloadDocEmployee, error17, dispatch, id])

  // Employee Departments
  const [openAddEmpDepartmentPopup, setOpenAddEmpDepartmentsPopup] =
    useState(false)

  const [openEditDepartment, setOpenEditDepartment] = useState(false)
  const [departmentForEdit, setDepartmentForEdit] = useState(null)

  const openInPopup = (item) => {
    setDepartmentForEdit(item)
    setOpenEditDepartment(true)
  }

  // Initiate Exit
  const [openExit, setOpenExit] = useState(false)

  const openExitModal = () => {
    setOpenExit(true)
  }

  // Rejoin
  const [openRejoin, setOpenRejoin] = useState(false)

  const openRejoinModal = () => {
    setOpenRejoin(true)
  }

  // Make Department Primary
  const makeDepartmentPrimary = (department) => {
    dispatch(hrMakeDepartmentPrimaryEmployeeAction(id, department))
  }

  const hrMakeDepartmentPrimary = useSelector(
    (state) => state.hrMakeDepartmentPrimary
  )
  const { loading20, error20, makeDepartmentPrimaryEmployee } =
    hrMakeDepartmentPrimary

  useEffect(() => {
    dispatch({ type: HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_RESET })
    if (makeDepartmentPrimaryEmployee) {
      setNotify({
        isOpen: true,
        message: makeDepartmentPrimaryEmployee.msg,
        type: 'success',
      })
      setTimeout(() => {
        dispatch(hrGetEmployeeByIdAction(id))
      }, 2000)
    } else if (error20) {
      setNotify({
        isOpen: true,
        message: error20,
        type: 'error',
      })
    }
  }, [makeDepartmentPrimaryEmployee, error20, dispatch, id])

  // Tables
  const headCellsDepartments = [
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'current_supervisor_name',
      title: 'Current Supervisor',
    },
    {
      field: 'joined_on',
      title: 'Joined On',
    },
    {
      field: 'status',
      title: 'Status',
    },
    {
      field: 'left_on',
      title: 'Left On',
    },
    {
      field: 'id_card_number',
      title: 'ID Card Number',
    },
    {
      field: 'id_card_issued_on',
      title: 'ID Card Issued On',
    },
    {
      field: 'id_card_expiry_on',
      title: 'ID Card Expiry On',
    },
    {
      field: 'id_card_returned_on',
      title: 'ID Card Returned On',
    },
    {
      field: 'actions',
      title: 'Actions',
      render: (rowData) => (
        <>
          <Controls.ActionButton color='primary'>
            <EditOutlinedIcon
              fontSize='small'
              onClick={() => openInPopup(rowData)}
            />
          </Controls.ActionButton>
          <Controls.ActionButton color='primary'>
            <CheckIcon
              fontSize='small'
              onClick={() => makeDepartmentPrimary(rowData.id)}
            />
          </Controls.ActionButton>
        </>
      ),
    },
  ]

  const headCellsUploadedDocuments = [
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'type',
      title: 'Type',
    },
    {
      field: 'actions',
      title: 'Actions',
      render: (rowData) => (
        <>
          <Controls.ActionButton
            onClick={() => dispatch(hrDownloadDocAction(rowData._id))}
            color='primary'
          >
            <GetAppIcon fontSize='small' />
          </Controls.ActionButton>
          <Controls.ActionButton
            onClick={() =>
              setConfirmDialog({
                isOpen: true,
                title: 'Are you sure to delete this doc?',
                subTitle: "You can't undo this operation",
                onConfirm: () => {
                  dispatch(hrDeleteDocAction(id, rowData._id))
                },
              })
            }
            color='secondary'
          >
            <CloseIcon fontSize='small' />
          </Controls.ActionButton>
        </>
      ),
    },
  ]

  const headCellsResignHistory = [
    {
      field: 'doj',
      title: 'DOJ',
    },
    {
      field: 'date',
      title: 'Resign Date',
    },
    {
      field: 'reason',
      title: 'Reason',
    },
    {
      field: 'exit_status',
      title: 'Exit Status',
    },
    {
      field: 'details',
      title: 'Details',
    },
  ]

  const headCellsAttendance = [
    {
      field: 'date',
      title: 'Date',
    },
    {
      field: 'punch_in_time',
      title: 'Time',
    },
    {
      field: 'punch_in_status',
      title: 'Punch Status',
    },
    {
      field: 'punch_in_address',
      title: 'Address',
    },
    {
      field: 'status',
      title: 'Status',
    },
  ]

  const headCellsLeaves = [
    {
      field: 'leaves',
      title: 'Leaves',
    },
    {
      field: 'remark',
      title: 'Remark',
    },
  ]

  return (
    <div className={classes.pageContent_main}>
      {loading ? (
        <Progress />
      ) : (
        <div id='to-download'>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <Paper
                className={classes.pageContent}
                elevation={4}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <PersonIcon className={classes.pic} />
              </Paper>
            </Grid>
            <Grid item xs={12} lg={9}>
              <Paper className={classes.pageContent} elevation={4}>
                <Grid container spacing={3} alignItems='center'>
                  <Grid item>
                    <Typography>
                      <span className={classes.label}>Name:</span>{' '}
                      {values.first_name +
                        ' ' +
                        values.middle_name +
                        ' ' +
                        values.last_name}
                    </Typography>
                  </Grid>
                  <Grid sm item></Grid>
                  <Grid item>
                    {values.attendance_status ? (
                      <Typography className={classes.attendance_status_in}>
                        In
                      </Typography>
                    ) : (
                      <Typography className={classes.attendance_status_out}>
                        Out
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    {/* <Controls.Button
                      type='button'
                      text='Download'
                      className={classes.download}
                      onClick={() => download()}
                    /> */}
                    {isActive === 'Active' ? (
                      <Controls.Button
                        type='button'
                        text='Initiate Exit'
                        className={classes.exit}
                        color='secondary'
                        onClick={() => openExitModal()}
                      />
                    ) : (
                      <Controls.Button
                        type='button'
                        text='Rejoin'
                        className={classes.exit}
                        onClick={() => openRejoinModal()}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography>
                      <span className={classes.label}>Org:</span>{' '}
                      {values.organisation}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      <span className={classes.label}>E Code:</span>{' '}
                      {values.employee_code}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography>
                      <span className={classes.label}>Mobile:</span>{' '}
                      {values.mobile}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      <span className={classes.label}>Email:</span>{' '}
                      {values.email_address}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography>
                      <span className={classes.label}>Job Title:</span>{' '}
                      {values.job_title}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      <span className={classes.label}>Department:</span>{' '}
                      {values.default_department}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography>
                      <span className={classes.label}>DOJ:</span> {values.doj}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography>
                      <span className={classes.label}>DID:</span> {values.did}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      <span className={classes.label}>Extension:</span>{' '}
                      {values.extension}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.pageContent} elevation={4}>
                <Grid container spacing={3}>
                  <Grid item>
                    <Typography>Basic Information</Typography>
                  </Grid>
                  <Grid sm item />
                  <Grid item>
                    {isActive === 'Active' && (
                      <>
                        {disableBasic ? (
                          <EditIcon
                            className={classes.icon}
                            onClick={() => editBasicInfoHandler()}
                          />
                        ) : (
                          <CloseIcon
                            className={classes.icon}
                            onClick={() => editBasicInfoHandler()}
                          />
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='First Name *'
                      name='first_name'
                      disabled={disableBasic}
                      value={values.first_name}
                      onChange={handleInputChange}
                      error={errors.first_name}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Middle Name'
                      name='middle_name'
                      disabled={disableBasic}
                      value={values.middle_name}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Last Name *'
                      name='last_name'
                      disabled={disableBasic}
                      value={values.last_name}
                      onChange={handleInputChange}
                      error={errors.last_name}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <Controls.Input
                      label='Current Address Line 1 *'
                      name='current_address_line_1'
                      disabled={disableBasic}
                      value={values.current_address_line_1}
                      onChange={handleInputChange}
                      error={errors.current_address_line_1}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Controls.Input
                      label='Current Address Line 2 *'
                      name='current_address_line_2'
                      disabled={disableBasic}
                      value={values.current_address_line_2}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Current State *'
                      name='current_state'
                      disabled={disableBasic}
                      value={values.current_state}
                      onChange={handleInputChange}
                      error={errors.current_state}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Current Country *'
                      name='current_country'
                      disabled={disableBasic}
                      value={values.current_country}
                      onChange={handleInputChange}
                      error={errors.current_country}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Current Pincode *'
                      name='current_pincode'
                      disabled={disableBasic}
                      value={values.current_pincode}
                      onChange={handleInputChange}
                      error={errors.current_pincode}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <Controls.Input
                      label='Permanent Address Line 1 *'
                      name='permanent_address_line_1'
                      disabled={disableBasic}
                      value={values.permanent_address_line_1}
                      onChange={handleInputChange}
                      error={errors.permanent_address_line_1}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Controls.Input
                      label='Permanent Address Line 2 *'
                      name='permanent_address_line_2'
                      disabled={disableBasic}
                      value={values.permanent_address_line_2}
                      onChange={handleInputChange}
                      error={errors.permanent_address_line_2}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Permanent State *'
                      name='permanent_state'
                      disabled={disableBasic}
                      value={values.permanent_state}
                      onChange={handleInputChange}
                      error={errors.permanent_state}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Permanent Country *'
                      name='permanent_country'
                      disabled={disableBasic}
                      value={values.permanent_country}
                      onChange={handleInputChange}
                      error={errors.permanent_country}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Permanent Pincode *'
                      name='permanent_pincode'
                      disabled={disableBasic}
                      value={values.permanent_pincode}
                      onChange={handleInputChange}
                      error={errors.permanent_pincode}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Controls.DatePicker
                      label='Date of Birth *'
                      name='dob'
                      disabled={disableBasic}
                      value={values.dob}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Mobile *'
                      name='mobile'
                      disabled={disableBasic && isAdmin}
                      value={values.mobile}
                      onChange={handleInputChange}
                      type='number'
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Alternate Number *'
                      name='alternate_number'
                      disabled={disableBasic}
                      value={values.alternate_number}
                      onChange={handleInputChange}
                      error={errors.alternate_number}
                      type='number'
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label="Father's Name *"
                      name='father_name'
                      disabled={disableBasic}
                      value={values.father_name}
                      onChange={handleInputChange}
                      error={errors.father_name}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label="Father's Mobile"
                      name='father_mobile'
                      disabled={disableBasic}
                      value={values.father_mobile}
                      onChange={handleInputChange}
                      error={errors.father_mobile}
                      type='number'
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label="Father's Occupation"
                      name='father_occupation'
                      disabled={disableBasic}
                      value={values.father_occupation}
                      onChange={handleInputChange}
                      error={errors.father_occupation}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label="Mother's Name *"
                      name='mother_name'
                      disabled={disableBasic}
                      value={values.mother_name}
                      onChange={handleInputChange}
                      error={errors.mother_name}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label="Mother's Mobile"
                      name='mother_mobile'
                      disabled={disableBasic}
                      value={values.mother_mobile}
                      onChange={handleInputChange}
                      error={errors.mother_mobile}
                      type='number'
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label="Mother's Occupation"
                      name='mother_occupation'
                      disabled={disableBasic}
                      value={values.mother_occupation}
                      onChange={handleInputChange}
                      error={errors.mother_occupation}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Personal Email Address'
                      name='personal_email_address'
                      disabled={disableBasic}
                      value={values.personal_email_address}
                      onChange={handleInputChange}
                      error={errors.personal_email_address}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Select
                      label='Blood Group'
                      name='blood_group'
                      disabled={disableBasic}
                      value={values.blood_group}
                      onChange={handleInputChange}
                      error={errors.blood_group}
                      options={bloodGroups}
                      className={classes.select}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Select
                      label='Gender *'
                      name='gender'
                      disabled={disableBasic}
                      value={values.gender}
                      onChange={handleInputChange}
                      error={errors.gender}
                      options={genders}
                      className={classes.select}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={3}>
                    <Controls.Select
                      label='Marital Status *'
                      name='marital_status'
                      disabled={disableBasic}
                      value={values.marital_status}
                      onChange={handleInputChange}
                      error={errors.marital_status}
                      options={maritalStatus}
                      className={classes.select}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Spouse Name'
                      name='spouse_name'
                      disabled={
                        disableBasic || values.marital_status !== 'Married'
                      }
                      value={values.spouse_name}
                      onChange={handleInputChange}
                      error={errors.spouse_name}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Spouse Mobile'
                      name='spouse_mobile'
                      disabled={
                        disableBasic || values.marital_status !== 'Married'
                      }
                      value={values.spouse_mobile}
                      onChange={handleInputChange}
                      error={errors.spouse_mobile}
                      type='number'
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.DatePicker
                      label='Marriage Date'
                      name='marriage_date'
                      disabled={
                        disableBasic || values.marital_status !== 'Married'
                      }
                      value={values.marriage_date}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
                {!disableBasic && (
                  <Controls.Button
                    type='submit'
                    text='Update'
                    className={classes.submit}
                    onClick={() => updateBasic()}
                    loading={loading1}
                  />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.pageContent} elevation={4}>
                <Grid container spacing={3}>
                  <Grid item>
                    <Typography>Reference Information</Typography>
                  </Grid>
                  <Grid sm item />
                  <Grid item>
                    {isActive === 'Active' && (
                      <>
                        {disableReference ? (
                          <EditIcon
                            className={classes.icon}
                            onClick={() => editReferenceInfoHandler()}
                          />
                        ) : (
                          <CloseIcon
                            className={classes.icon}
                            onClick={() => editReferenceInfoHandler()}
                          />
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Reference 1 Name *'
                      name='reference_1_name'
                      disabled={disableReference}
                      value={values.reference_1_name}
                      onChange={handleInputChange}
                      error={errors.reference_1_name}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Reference 1 Mobile *'
                      name='reference_1_mobile'
                      disabled={disableReference}
                      value={values.reference_1_mobile}
                      onChange={handleInputChange}
                      error={errors.reference_1_mobile}
                      type='number'
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Select
                      label='Reference 1 Relation *'
                      name='reference_1_relation'
                      disabled={disableReference}
                      value={values.reference_1_relation}
                      onChange={handleInputChange}
                      error={errors.reference_1_relation}
                      options={relations}
                      className={classes.select}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <Controls.Input
                      label='Reference 1 Address *'
                      name='reference_1_address'
                      disabled={disableReference}
                      value={values.reference_1_address}
                      onChange={handleInputChange}
                      error={errors.reference_1_address}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Reference 2 Name *'
                      name='reference_2_name'
                      disabled={disableReference}
                      value={values.reference_2_name}
                      onChange={handleInputChange}
                      error={errors.reference_2_name}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Reference 2 Mobile *'
                      name='reference_2_mobile'
                      disabled={disableReference}
                      value={values.reference_2_mobile}
                      onChange={handleInputChange}
                      error={errors.reference_2_mobile}
                      type='number'
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Select
                      label='Reference 2 Relation *'
                      name='reference_2_relation'
                      disabled={disableReference}
                      value={values.reference_2_relation}
                      onChange={handleInputChange}
                      error={errors.reference_2_relation}
                      options={relations}
                      className={classes.select}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <Controls.Input
                      label='Reference 2 Address *'
                      name='reference_2_address'
                      disabled={disableReference}
                      value={values.reference_2_address}
                      onChange={handleInputChange}
                      error={errors.reference_2_address}
                    />
                  </Grid>
                </Grid>
                {!disableReference && (
                  <Controls.Button
                    type='submit'
                    text='Update'
                    className={classes.submit}
                    onClick={() => updateReference()}
                    loading={loading3}
                  />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.pageContent} elevation={4}>
                <Grid container spacing={3}>
                  <Grid item>
                    <Typography>Bank Information</Typography>
                  </Grid>
                  <Grid sm item />
                  <Grid item>
                    {isActive === 'Active' && (
                      <>
                        {disableBank ? (
                          <EditIcon
                            className={classes.icon}
                            onClick={() => editBankInfoHandler()}
                          />
                        ) : (
                          <CloseIcon
                            className={classes.icon}
                            onClick={() => editBankInfoHandler()}
                          />
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Name as per Bank *'
                      name='name_as_per_bank'
                      disabled={disableBank}
                      value={values.name_as_per_bank}
                      onChange={handleInputChange}
                      error={errors.name_as_per_bank}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Select
                      label='Bank Name *'
                      name='bank_name'
                      disabled={disableBank}
                      value={values.bank_name}
                      onChange={handleInputChange}
                      error={errors.bank_name}
                      options={banks}
                      className={classes.select}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Account Number *'
                      name='account_number'
                      disabled={disableBank}
                      value={values.account_number}
                      onChange={handleInputChange}
                      error={errors.account_number}
                      type='number'
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='IFSC Code *'
                      name='ifsc_code'
                      disabled={disableBank}
                      value={values.ifsc_code}
                      onChange={handleInputChange}
                      error={errors.ifsc_code}
                      inputProps={{
                        max: 11,
                      }}
                    />
                  </Grid>
                </Grid>
                {!disableBank && (
                  <Controls.Button
                    type='submit'
                    text='Update'
                    className={classes.submit}
                    onClick={() => updateBank()}
                    laoding={loading2}
                  />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.pageContent} elevation={4}>
                <Grid container spacing={3}>
                  <Grid item>
                    <Typography>Documents</Typography>
                  </Grid>
                  <Grid sm item />
                  <Grid item>
                    {isActive === 'Active' && (
                      <>
                        {disableDocuments ? (
                          <EditIcon
                            className={classes.icon}
                            onClick={() => editDocumentsHandler()}
                          />
                        ) : (
                          <CloseIcon
                            className={classes.icon}
                            onClick={() => editDocumentsHandler()}
                          />
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Aadhar *'
                      name='aadhar_card_number'
                      disabled={disableDocuments}
                      value={values.aadhar_card_number}
                      onChange={handleInputChange}
                      error={errors.aadhar_card_number}
                      type='number'
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='PAN *'
                      name='pan_card_number'
                      disabled={disableDocuments}
                      value={values.pan_card_number}
                      onChange={handleInputChange}
                      error={errors.pan_card_number}
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Voter ID'
                      name='voter_id_card_number'
                      disabled={disableDocuments}
                      value={values.voter_id_card_number}
                      onChange={handleInputChange}
                      error={errors.voter_id_card_number}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Driving License'
                      name='driving_license_number'
                      disabled={disableDocuments}
                      value={values.driving_license_number}
                      onChange={handleInputChange}
                      error={errors.driving_license_number}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Photos Collected *'
                      name='photos_collected'
                      disabled={disableDocuments}
                      value={values.photos_collected}
                      onChange={handleInputChange}
                      error={errors.photos_collected}
                      type='number'
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Select
                      label='House Documents Collected *'
                      name='house_documents_status'
                      disabled={disableDocuments}
                      value={values.house_documents_status}
                      onChange={handleInputChange}
                      error={errors.house_documents_status}
                      options={[
                        { id: 'Yes', title: 'Yes' },
                        { id: 'No', title: 'No' },
                      ]}
                      className={classes.select}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='House Documents Name *'
                      name='house_documents_name'
                      disabled={
                        disableDocuments ||
                        values.house_documents_status !== 'Yes'
                      }
                      value={values.house_documents_name}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Controls.Select
                      label='Original Documents *'
                      name='original_documents_isRequired'
                      disabled={disableDocuments}
                      value={values.original_documents_isRequired}
                      onChange={handleInputChange}
                      error={errors.original_documents_isRequired}
                      options={[
                        { id: 'Required', title: 'Required' },
                        { id: 'Not Required', title: 'Not Required' },
                      ]}
                      className={classes.select}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Select
                      label='Original Documents Submitted'
                      name='original_documents_isSubmitted'
                      disabled={
                        disableDocuments ||
                        values.original_documents_isRequired !== 'Required'
                      }
                      value={values.original_documents_isSubmitted}
                      onChange={handleInputChange}
                      options={[
                        { id: 'Yes', title: 'Yes' },
                        { id: 'No', title: 'No' },
                      ]}
                      className={classes.select}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Original Documents Name'
                      name='original_documents_name'
                      disabled={
                        disableDocuments ||
                        values.original_documents_isSubmitted !== 'Yes'
                      }
                      value={values.original_documents_name}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Controls.Select
                      label='Police Verification *'
                      name='police_verification_isRequired'
                      disabled={disableDocuments}
                      value={values.police_verification_isRequired}
                      onChange={handleInputChange}
                      error={errors.police_verification_isRequired}
                      options={[
                        { id: 'Required', title: 'Required' },
                        { id: 'Not Required', title: 'Not Required' },
                      ]}
                      className={classes.select}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Select
                      label='Police Verification Submitted'
                      name='police_verification_isSubmitted'
                      disabled={
                        disableDocuments ||
                        values.police_verification_isRequired !== 'Required'
                      }
                      value={values.police_verification_isSubmitted}
                      onChange={handleInputChange}
                      error={errors.police_verification_isSubmitted}
                      options={[
                        { id: 'Yes', title: 'Yes' },
                        { id: 'No', title: 'No' },
                      ]}
                      className={classes.select}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Police Verifications Valid Upto'
                      name='police_verification_valid_upto'
                      disabled={
                        disableDocuments ||
                        values.police_verification_isSubmitted !== 'Yes'
                      }
                      value={values.police_verification_valid_upto}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Controls.Select
                      label='DRA *'
                      name='dra_isRequired'
                      disabled={disableDocuments}
                      value={values.dra_isRequired}
                      onChange={handleInputChange}
                      error={errors.dra_isRequired}
                      options={[
                        { id: 'Required', title: 'Required' },
                        { id: 'Not Required', title: 'Not Required' },
                      ]}
                      className={classes.select}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Select
                      label='DRA Submitted'
                      name='dra_isSubmitted'
                      disabled={
                        disableDocuments || values.dra_isRequired !== 'Required'
                      }
                      value={values.dra_isSubmitted}
                      onChange={handleInputChange}
                      error={errors.dra_isSubmitted}
                      options={[
                        { id: 'Yes', title: 'Yes' },
                        { id: 'No', title: 'No' },
                      ]}
                      className={classes.select}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='DRA Number'
                      name='dra_number'
                      disabled={
                        disableDocuments || values.dra_isSubmitted !== 'Yes'
                      }
                      value={values.dra_number}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
                {!disableDocuments && (
                  <Controls.Button
                    type='submit'
                    text='Update'
                    className={classes.submit}
                    onClick={() => updateDocuments()}
                    loading={loading9}
                  />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.pageContent} elevation={4}>
                <Grid container spacing={3}>
                  <Grid item>
                    <Typography>Education Information</Typography>
                  </Grid>
                  <Grid sm item />
                  <Grid item>
                    {isActive === 'Active' && (
                      <>
                        {disableEducation ? (
                          <EditIcon
                            className={classes.icon}
                            onClick={() => editEducationHandler()}
                          />
                        ) : (
                          <CloseIcon
                            className={classes.icon}
                            onClick={() => editEducationHandler()}
                          />
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={3}>
                    <Controls.Select
                      label='Education *'
                      name='education'
                      value={values.education}
                      onChange={handleInputChange}
                      error={errors.education}
                      disabled={disableEducation}
                      options={educationOptions}
                      className={classes.select}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='School Name *'
                      name='school_name'
                      value={values.school_name}
                      onChange={handleInputChange}
                      error={errors.school_name}
                      disabled={disableEducation}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Credential Name *'
                      name='credential'
                      value={values.credential}
                      onChange={handleInputChange}
                      error={errors.credential}
                      disabled={disableEducation}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.DatePicker
                      label='Graduated On *'
                      name='graduated_on'
                      disabled={disableEducation}
                      value={values.graduated_on}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
                {!disableEducation && (
                  <Controls.Button
                    type='submit'
                    text='Update'
                    className={classes.submit}
                    onClick={() => updateEducation()}
                    loading={loading4}
                  />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.pageContent} elevation={4}>
                <Grid container spacing={3}>
                  <Grid item>
                    <Typography>Employee Information</Typography>
                  </Grid>
                  <Grid sm item />
                  <Grid item>
                    {isActive === 'Active' && (
                      <>
                        {disableEmployee ? (
                          <EditIcon
                            className={classes.icon}
                            onClick={() => editEmployeeHandler()}
                          />
                        ) : (
                          <CloseIcon
                            className={classes.icon}
                            onClick={() => editEmployeeHandler()}
                          />
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Organisation *'
                      name='organisation'
                      disabled
                      value={values.organisation}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.DatePicker
                      label='Date of Joining *'
                      name='doj'
                      disabled
                      value={values.doj}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Select
                      label='Employee Type *'
                      name='employee_type'
                      disabled={disableEmployee}
                      value={values.employee_type}
                      onChange={handleInputChange}
                      error={errors.employee_type}
                      options={employeeTypes}
                      className={classes.select}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Select
                      label='Job Title *'
                      name='job_title'
                      disabled={disableEmployee}
                      value={values.job_title}
                      onChange={handleInputChange}
                      error={errors.job_title}
                      options={
                        values.employee_type === 'Office'
                          ? jobTitles
                          : values.employee_type === 'Field'
                          ? [{ id: 'Field Officer', title: 'Field Officer' }]
                          : []
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={3}>
                    <Controls.Select
                      label='Employee Zone'
                      name='employee_zone'
                      disabled={
                        disableEmployee || values.employee_type !== 'Field'
                      }
                      value={values.employee_zone}
                      onChange={handleInputChange}
                      error={errors.employee_zone}
                      options={employeeZones}
                      className={classes.select}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Punch In At *'
                      name='punch_in_at'
                      disabled={disableEmployee}
                      value={values.punch_in_at}
                      onChange={handleInputChange}
                      type='time'
                      inputProps={{
                        min: '08:00',
                        max: '19:00',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Select
                      label='Tracking Policy *'
                      name='tracking_policy'
                      disabled={disableEmployee}
                      value={values.tracking_policy}
                      onChange={handleInputChange}
                      error={errors.tracking_policy}
                      options={trackingPolicy}
                      className={classes.select}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Experience in Years *'
                      name='experience_in_years'
                      disabled={disableEmployee}
                      value={values.experience_in_years}
                      onChange={handleInputChange}
                      error={errors.experience_in_years}
                      type='number'
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Referred By Name'
                      name='referred_by_name'
                      disabled={disableEmployee}
                      value={values.referred_by_name}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Referred By Mobile'
                      name='referred_by_mobile'
                      disabled={disableEmployee}
                      value={values.referred_by_mobile}
                      onChange={handleInputChange}
                      type='number'
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Application File Number *'
                      name='application_file'
                      disabled={disableEmployee}
                      value={values.application_file}
                      onChange={handleInputChange}
                      error={errors.application_file}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Original Documents File Number'
                      name='original_file'
                      disabled={disableEmployee}
                      value={values.original_file}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Employee Code'
                      name='employee_code'
                      disabled={disableEmployee && isAdmin}
                      value={values.employee_code}
                      onChange={handleInputChange}
                      error={errors.employee_code}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Email Address'
                      name='email_address'
                      disabled={disableEmployee}
                      value={values.email_address}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Access Control'
                      name='access_control'
                      disabled={disableEmployee}
                      value={values.access_control}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
                {!disableEmployee && (
                  <Controls.Button
                    type='submit'
                    text='Update'
                    className={classes.submit}
                    onClick={() => updateEmployee()}
                    loading={loading5}
                  />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.pageContent} elevation={4}>
                <Grid container spacing={3}>
                  <Grid item>
                    <Typography>Previous Company Information</Typography>
                  </Grid>
                  <Grid sm item />
                  <Grid item>
                    {isActive === 'Active' && (
                      <>
                        {disablePrevCompany ? (
                          <EditIcon
                            className={classes.icon}
                            onClick={() => editPrevCompanyHandler()}
                          />
                        ) : (
                          <CloseIcon
                            className={classes.icon}
                            onClick={() => editPrevCompanyHandler()}
                          />
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Company Name'
                      name='previous_company_name'
                      disabled={
                        disablePrevCompany ||
                        values.experience_in_years === 0 ||
                        !values.experience_in_years
                      }
                      value={values.previous_company_name}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Company Designation'
                      name='previous_designation'
                      disabled={
                        disablePrevCompany ||
                        values.experience_in_years === 0 ||
                        !values.experience_in_years
                      }
                      value={values.previous_designation}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.DatePicker
                      label='Left On'
                      name='previous_left_on'
                      disabled={
                        disablePrevCompany ||
                        values.experience_in_years === 0 ||
                        !values.experience_in_years
                      }
                      value={values.previous_left_on}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Left Reason'
                      name='previous_left_reason'
                      disabled={
                        disablePrevCompany ||
                        values.experience_in_years === 0 ||
                        !values.experience_in_years
                      }
                      value={values.previous_left_reason}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
                {!disablePrevCompany && (
                  <Controls.Button
                    type='submit'
                    text='Update'
                    className={classes.submit}
                    onClick={() => updateExperience()}
                    loading={loading6}
                  />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.pageContent} elevation={4}>
                <Grid container spacing={3}>
                  <Grid item>
                    <Typography>Vehicle Information</Typography>
                  </Grid>
                  <Grid sm item />
                  <Grid item>
                    {isActive === 'Active' && (
                      <>
                        {disableVehicle ? (
                          <EditIcon
                            className={classes.icon}
                            onClick={() => editVehicleHandler()}
                          />
                        ) : (
                          <CloseIcon
                            className={classes.icon}
                            onClick={() => editVehicleHandler()}
                          />
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Vehicle Name'
                      name='vehicle_name'
                      disabled={disableVehicle}
                      value={values.vehicle_name}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Registered Name'
                      name='registered_name'
                      disabled={disableVehicle}
                      value={values.registered_name}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Registration Number'
                      name='registration_number'
                      disabled={disableVehicle}
                      value={values.registration_number}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
                {!disableVehicle && (
                  <Controls.Button
                    type='submit'
                    text='Update'
                    className={classes.submit}
                    onClick={() => updateVehicle()}
                    loading={loading7}
                  />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.pageContent} elevation={4}>
                <Grid container spacing={3}>
                  <Grid item>
                    <Typography>Emergency Contact Information</Typography>
                  </Grid>
                  <Grid sm item />
                  <Grid item>
                    {isActive === 'Active' && (
                      <>
                        {disableEmeContact ? (
                          <EditIcon
                            className={classes.icon}
                            onClick={() => editEmeContactHandler()}
                          />
                        ) : (
                          <CloseIcon
                            className={classes.icon}
                            onClick={() => editEmeContactHandler()}
                          />
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Person Name *'
                      name='emergency_contact_name'
                      disabled={disableEmeContact}
                      value={values.emergency_contact_name}
                      onChange={handleInputChange}
                      error={errors.emergency_contact_name}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Person Number *'
                      name='emergency_contact_number'
                      disabled={disableEmeContact}
                      value={values.emergency_contact_number}
                      onChange={handleInputChange}
                      error={errors.emergency_contact_number}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Person Relation *'
                      name='emergency_contact_relation'
                      disabled={disableEmeContact}
                      value={values.emergency_contact_relation}
                      onChange={handleInputChange}
                      error={errors.emergency_contact_relation}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <Controls.Input
                      label='Person Address *'
                      name='emergency_contact_address'
                      disabled={disableEmeContact}
                      value={values.emergency_contact_address}
                      onChange={handleInputChange}
                      error={errors.emergency_contact_address}
                    />
                  </Grid>
                </Grid>
                {!disableEmeContact && (
                  <Controls.Button
                    type='submit'
                    text='Update'
                    className={classes.submit}
                    onClick={() => updateEmergency()}
                    loading={loading8}
                  />
                )}
              </Paper>
            </Grid>
            {isAdmin && (
              <>
                <Grid item xs={12}>
                  <Paper className={classes.pageContent} elevation={4}>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Typography>Settings</Typography>
                      </Grid>
                      <Grid sm item />
                      <Grid item>
                        {disableSettings ? (
                          <EditIcon
                            className={classes.icon}
                            onClick={() => editSettingsHandler()}
                          />
                        ) : (
                          <CloseIcon
                            className={classes.icon}
                            onClick={() => editSettingsHandler()}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={3}>
                        <Controls.Select
                          label='Login Type'
                          name='login_type'
                          disabled={disableSettings}
                          value={values.login_type}
                          onChange={handleInputChange}
                          error={errors.login_type}
                          options={loginTypes}
                          className={classes.select}
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <Controls.Select
                          label='Verification Status'
                          name='verification_status'
                          disabled={disableSettings}
                          value={values.verification_status}
                          onChange={handleInputChange}
                          error={errors.verification_status}
                          options={[
                            { id: 'Yes', title: 'Yes' },
                            { id: 'No', title: 'No' },
                          ]}
                          className={classes.select}
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <Controls.Select
                          label='App Calling'
                          name='app_calling'
                          disabled={disableSettings}
                          value={values.app_calling}
                          onChange={handleInputChange}
                          error={errors.app_calling}
                          options={[
                            { id: 'true', title: 'true' },
                            { id: 'false', title: 'false' },
                          ]}
                          className={classes.select}
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <Controls.Select
                          label='Web Calling'
                          name='web_calling'
                          disabled={disableSettings}
                          value={values.web_calling}
                          onChange={handleInputChange}
                          error={errors.web_calling}
                          options={[
                            { id: 'true', title: 'true' },
                            { id: 'false', title: 'false' },
                          ]}
                          className={classes.select}
                        />
                      </Grid>
                    </Grid>
                    {!disableSettings && (
                      <>
                        <Controls.Button
                          type='submit'
                          text='Update'
                          className={classes.submit}
                          onClick={() => updateSettings()}
                          loading={loading10}
                        />
                        <Controls.Button
                          type='submit'
                          text='Reset Password'
                          className={classes.reset_password}
                          color='secondary'
                          onClick={() => resetPassword()}
                          loading={loading11}
                        />
                        <Controls.Button
                          type='submit'
                          text='Reset Device ID'
                          className={classes.submit}
                          color='secondary'
                          onClick={() => resetDeviceID()}
                          loading={loading12}
                        />
                      </>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.pageContent} elevation={4}>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Typography>Extension Information</Typography>
                      </Grid>
                      <Grid sm item />
                      <Grid item>
                        {disableExtension ? (
                          <EditIcon
                            className={classes.icon}
                            onClick={() => editExtensionHandler()}
                          />
                        ) : (
                          <CloseIcon
                            className={classes.icon}
                            onClick={() => editExtensionHandler()}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={4}>
                        <Controls.Input
                          label='DID'
                          name='did'
                          disabled
                          value={values.did}
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Controls.Input
                          label='Extensions'
                          name='extension'
                          disabled
                          value={values.extension}
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Controls.Input
                          label='Assigned On'
                          name='assigned_on'
                          disabled
                          value={values.extension_assigned_on || null}
                        />
                      </Grid>
                    </Grid>
                    {!disableExtension && (
                      <>
                        {values.did ? (
                          <>
                            <Controls.Button
                              type='submit'
                              text='Remove'
                              className={classes.submit}
                              color='secondary'
                              onClick={() =>
                                setConfirmDialog({
                                  isOpen: true,
                                  title:
                                    'Are you sure to remove this extension?',
                                  subTitle: "You can't undo this operation",
                                  onConfirm: () => {
                                    dispatch(hrRemoveExtensionAction(id))
                                  },
                                })
                              }
                            />
                            {/* {values.extension_status === true ? (
                              <Controls.Button
                                type='submit'
                                text='Deactivate'
                                className={classes.reset_password}
                                color='secondary'
                                onClick={() => deactivateExtension()}
                                loading={loading15}
                              />
                            ) : (
                              <Controls.Button
                                type='submit'
                                text='Activate'
                                className={classes.reset_password}
                                onClick={() => activateExtension()}
                                loading={loading14}
                              />
                            )} */}
                          </>
                        ) : (
                          <Controls.Button
                            type='submit'
                            text='Assign'
                            className={classes.submit}
                            onClick={() => setOpenExtPopup(true)}
                          />
                        )}
                      </>
                    )}
                  </Paper>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <MaterialTable
                title='Upload Documents'
                columns={headCellsUploadedDocuments}
                data={uploadedDocuments}
                options={{
                  exportButton: true,
                  search: true,
                  exportAllData: true,
                }}
                actions={[
                  {
                    icon: 'add',
                    tooltip: 'Upload Document',
                    isFreeAction: true,
                    onClick: () => setOpenUploadDocPopup(true),
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <MaterialTable
                title='Departments'
                columns={headCellsDepartments}
                data={empDepartments}
                options={{
                  exportButton: true,
                  search: true,
                  exportAllData: true,
                }}
                actions={[
                  {
                    icon: 'add',
                    tooltip: 'Add Department',
                    isFreeAction: true,
                    onClick: () => setOpenAddEmpDepartmentsPopup(true),
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <MaterialTable
                title='Attendance'
                columns={headCellsAttendance}
                data={attendances}
                options={{
                  exportButton: true,
                  search: true,
                  exportAllData: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MaterialTable
                title={`Leaves: ${leavesQuota}`}
                columns={headCellsLeaves}
                data={leavesHistory}
                options={{
                  exportButton: true,
                  search: true,
                  exportAllData: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MaterialTable
                title='Resign History'
                columns={headCellsResignHistory}
                data={resignHistory}
                options={{
                  exportButton: true,
                  search: true,
                  exportAllData: true,
                }}
              />
            </Grid>
          </Grid>
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <Popup
            openPopup={openExtPopup}
            setOpenPopup={setOpenExtPopup}
            title='Add Extension'
          >
            <HRExtension
              setOpenExtPopup={setOpenExtPopup}
              extensions={extensions}
              id={id}
            />
          </Popup>
          <Popup
            openPopup={openUploadDocPopup}
            setOpenPopup={setOpenUploadDocPopup}
            title='Upload Doc'
          >
            <HRUploadDoc
              setOpenUploadDocPopup={setOpenUploadDocPopup}
              id={id}
            />
          </Popup>
          <Popup
            openPopup={openAddEmpDepartmentPopup}
            setOpenPopup={setOpenAddEmpDepartmentsPopup}
            title='Add Department'
          >
            <HRAddDepartment
              setOpenAddEmpDepartmentsPopup={setOpenAddEmpDepartmentsPopup}
              id={id}
              departments={departments}
            />
          </Popup>
          <Popup
            openPopup={openEditDepartment}
            setOpenPopup={setOpenEditDepartment}
            title='Update Department'
          >
            <HREditDepartment
              setOpenEditDepartment={setOpenEditDepartment}
              employeeID={id}
              departmentForEdit={departmentForEdit}
            />
          </Popup>
          {openExit && (
            <Popup
              openPopup={openExit}
              setOpenPopup={setOpenExit}
              title='Exit Formalities'
            >
              <HRExit setOpenExit={setOpenExit} employeeID={id} />
            </Popup>
          )}
          {openRejoin && (
            <Popup
              openPopup={openRejoin}
              setOpenPopup={setOpenRejoin}
              title='Rejoin'
            >
              <HRRejoin setOpenRejoin={setOpenRejoin} employeeID={id} />
            </Popup>
          )}
          <Notification notify={notify} setNotify={setNotify} />
        </div>
      )}
    </div>
  )
}

export default HREditEmployee
