import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_CHANGE_PASSWORD_FAIL,
  USER_CHANGE_PASSWORD_REQUEST,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_DASHBOARD_REQUEST,
  USER_DASHBOARD_SUCCESS,
  USER_DASHBOARD_FAIL,
  DIAL_REQUEST,
  DIAL_SUCCESS,
  DIAL_FAIL,
  SUBMIT_EVERYDAY_REPORTING_REQUEST,
  SUBMIT_EVERYDAY_REPORTING_SUCCESS,
  SUBMIT_EVERYDAY_REPORTING_FAIL,
  GET_ATTENDANCE_REVIEW_REQUEST,
  GET_ATTENDANCE_REVIEW_SUCCESS,
  GET_ATTENDANCE_REVIEW_FAIL,
} from '../constants/userConstants'
import axios from 'axios'

export const login = (mobile, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.post(
      '/api/employees/login/web',
      { mobile, password },
      config
    )

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    })

    localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const logout = () => async (dispatch) => {
  localStorage.removeItem('userInfo')
  dispatch({
    type: USER_LOGOUT,
  })
}

export const userChangePassword =
  (old_password, new_password) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_CHANGE_PASSWORD_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        '/api/employees/change_password',
        { old_password, new_password },
        config
      )

      dispatch({
        type: USER_CHANGE_PASSWORD_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: USER_CHANGE_PASSWORD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const dashboard = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DASHBOARD_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/employees/dashboard/web', config)

    dispatch({
      type: USER_DASHBOARD_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: USER_DASHBOARD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const dialAction = (number) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DIAL_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      '/api/employees/call/app',
      { number },
      config
    )

    dispatch({
      type: DIAL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DIAL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const submitEverydayReportingAction =
  (values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SUBMIT_EVERYDAY_REPORTING_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        '/api/employees/everyday/reporting/web',
        values,
        config
      )

      dispatch({
        type: SUBMIT_EVERYDAY_REPORTING_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: SUBMIT_EVERYDAY_REPORTING_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getAttendanceReviewAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_ATTENDANCE_REVIEW_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `/api/employees/finalize-attendance/${id}/web`,
      config
    )

    dispatch({
      type: GET_ATTENDANCE_REVIEW_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: GET_ATTENDANCE_REVIEW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
