import { makeStyles, Paper, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  callsGetRecordingsAction,
  callsMISAction,
} from '../actions/callingActions'
import Notification from '../components/Notification'
import Controls from '../components/controls/Controls'
import PageHeader from '../components/PageHeader'
import { useForm, Form } from '../components/useForm'
import CallIcon from '@material-ui/icons/Call'
import {
  CALLS_MIS_RESET,
  CALLS_RECORDINGS_GET_RESET,
} from '../constants/callingConstants'
import MaterialTable from 'material-table'

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    overflowX: 'scroll',
  },
  searchInput: {
    width: '75%',
  },
  link: {
    textDecoration: 'none',
  },
  submit: {
    margin: theme.spacing(2, 0, 0),
  },
  download: {
    background: '#fff',
    padding: theme.spacing(1.25, 2),
    outline: 'none',
    border: '1px solid #333',
    borderRadius: 4,
    fontSize: 14,
    marginLeft: theme.spacing(2),
  },
}))

const headCells = [
  {
    field: 'employeeCode',
    title: 'Employee Code',
  },
  {
    field: 'name',
    title: 'Employee Name',
  },
  {
    field: 'master_department',
    title: 'Master Department',
  },
  {
    field: 'department',
    title: 'Department',
  },
  {
    field: 'did',
    title: 'Landline Number',
  },
  {
    field: 'totalCalls',
    title: 'Total Calls',
  },
  {
    field: 'uniqueCalls',
    title: 'Unique Calls',
  },
  {
    field: 'duration',
    title: 'Duration',
  },
  {
    field: 'connectedCalls',
    title: 'Connected Calls',
  },
  {
    field: 'notConnectedCalls',
    title: 'Not Connected Calls',
  },
  {
    field: 'average',
    title: 'Average Gap Between Calls',
  },
  {
    field: 'zeroToThree',
    title: '0-3 Min',
  },
  {
    field: 'threeToFive',
    title: '3-5 Min',
  },
  {
    field: 'fiveToTen',
    title: '5-10 Min',
  },
  {
    field: 'tenAndAbove',
    title: '10 and Above',
  },
]

const initialValues = {
  from: null,
  to: null,
  employee: '',
}

const CallMIS = ({ history }) => {
  const classes = useStyles()

  const [employees, setEmployees] = useState([])

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const userDashboard = useSelector((state) => state.userDashboard)
  const { userData } = userDashboard

  useEffect(() => {
    if (!userData || !userData.callingManage || !userData.callingMIS) {
      history.push('/dashboard')
    }
  }, [dispatch, history, userData])

  useEffect(() => {
    dispatch(callsGetRecordingsAction())
  }, [])

  const getCallRecordings = useSelector((state) => state.getCallRecordings)
  const { getRecordings } = getCallRecordings

  useEffect(() => {
    dispatch({ type: CALLS_RECORDINGS_GET_RESET })
    if (getRecordings) {
      setEmployees(getRecordings.employees)
    }
  }, [getRecordings])

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    setErrors({
      ...temp,
    })
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '')
  }

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  )

  const [misData, setMisData] = useState([])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!values.from || !values.to) {
      setNotify({
        isOpen: true,
        message: 'Please select a valid date',
        type: 'error',
      })
    } else {
      dispatch(callsMISAction(values.from, values.to, values.employee))
    }
  }

  const callMIS = useSelector((state) => state.callMIS)
  const { loading, error, mis } = callMIS

  useEffect(() => {
    dispatch({ type: CALLS_MIS_RESET })
    if (mis) {
      setMisData(mis)
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [mis, error])

  return (
    <div>
      {/* {loading ? (
        <Progress />
      ) : (
        <>
          {error ? (
            <Notification notify={notify} setNotify={setNotify} />
          ) : ( */}
      <>
        <PageHeader
          title='Call MIS'
          subtitle='Shows list of calling MIS department wise'
          icon={<CallIcon fontSize='medium' />}
        />
        <Paper className={classes.pageContent}>
          <Grid container spacing={3}>
            <Grid item>
              <Typography>Search</Typography>
            </Grid>
            <Grid sm item />
          </Grid>
          <Form autoComplete='off' onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={3}>
                <Controls.DatePicker
                  label='From Date *'
                  name='from'
                  fullWidth
                  value={values.from}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Controls.DatePicker
                  label='To Date *'
                  name='to'
                  fullWidth
                  value={values.to}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Controls.Select
                  label='Employee *'
                  name='employee'
                  fullWidth
                  value={values.employee}
                  onChange={handleInputChange}
                  options={employees}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Controls.Button
                  type='submit'
                  text='Search'
                  className={classes.submit}
                  loading={loading}
                />
              </Grid>
            </Grid>
          </Form>
        </Paper>
        <MaterialTable
          style={{ margin: '0 20px' }}
          title='Call MIS'
          columns={headCells}
          data={misData}
          options={{
            exportButton: true,
            search: true,
            exportAllData: true,
          }}
        />
      </>
      <Notification notify={notify} setNotify={setNotify} />
      {/* )}
        </>
      )} */}
    </div>
  )
}

export default CallMIS
