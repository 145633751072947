import {
  BILLING_ADD_TAG_FAIL,
  BILLING_ADD_TAG_REQUEST,
  BILLING_ADD_TAG_RESET,
  BILLING_ADD_TAG_SUCCESS,
  BILLING_DAILY_MIS_FAIL,
  BILLING_DAILY_MIS_REQUEST,
  BILLING_DAILY_MIS_RESET,
  BILLING_DAILY_MIS_SUCCESS,
  BILLING_DELETE_DATA_BY_ID_FAIL,
  BILLING_DELETE_DATA_BY_ID_REQUEST,
  BILLING_DELETE_DATA_BY_ID_RESET,
  BILLING_DELETE_DATA_BY_ID_SUCCESS,
  BILLING_GET_DEPARTMENTS_FAIL,
  BILLING_GET_DEPARTMENTS_REQUEST,
  BILLING_GET_DEPARTMENTS_RESET,
  BILLING_GET_DEPARTMENTS_SUCCESS,
  BILLING_GET_DEPARTMENT_BY_ID_FAIL,
  BILLING_GET_DEPARTMENT_BY_ID_REQUEST,
  BILLING_GET_DEPARTMENT_BY_ID_RESET,
  BILLING_GET_DEPARTMENT_BY_ID_SUCCESS,
  BILLING_MASTER_REPORT_FAIL,
  BILLING_MASTER_REPORT_REQUEST,
  BILLING_MASTER_REPORT_RESET,
  BILLING_MASTER_REPORT_SUCCESS,
  BILLING_REPORTS_FAIL,
  BILLING_REPORTS_REQUEST,
  BILLING_REPORTS_RESET,
  BILLING_REPORTS_SUCCESS,
  BILLING_TAGS_FAIL,
  BILLING_TAGS_REQUEST,
  BILLING_TAGS_RESET,
  BILLING_TAGS_SUCCESS,
  BILLING_UPDATE_TAG_FAIL,
  BILLING_UPDATE_TAG_REQUEST,
  BILLING_UPDATE_TAG_RESET,
  BILLING_UPDATE_TAG_SUCCESS,
  GET_BILLING_DAILY_MIS_FAIL,
  GET_BILLING_DAILY_MIS_REQUEST,
  GET_BILLING_DAILY_MIS_RESET,
  GET_BILLING_DAILY_MIS_SUCCESS,
  GET_BILLING_DATA_BY_ID_FAIL,
  GET_BILLING_DATA_BY_ID_REQUEST,
  GET_BILLING_DATA_BY_ID_RESET,
  GET_BILLING_DATA_BY_ID_SUCCESS,
  GET_BILLING_REPORTS_FAIL,
  GET_BILLING_REPORTS_REQUEST,
  GET_BILLING_REPORTS_RESET,
  GET_BILLING_REPORTS_SUCCESS,
  SUBMIT_DEPARTMENT_BILLING_DATA_FAIL,
  SUBMIT_DEPARTMENT_BILLING_DATA_REQUEST,
  SUBMIT_DEPARTMENT_BILLING_DATA_RESET,
  SUBMIT_DEPARTMENT_BILLING_DATA_SUCCESS,
  UPDATE_DEPARTMENT_BILLING_DATA_FAIL,
  UPDATE_DEPARTMENT_BILLING_DATA_REQUEST,
  UPDATE_DEPARTMENT_BILLING_DATA_RESET,
  UPDATE_DEPARTMENT_BILLING_DATA_SUCCESS,
} from '../constants/billingConstants'

export const billingTagsReducer = (state = {}, action) => {
  switch (action.type) {
    case BILLING_TAGS_REQUEST:
      return { loading: true }
    case BILLING_TAGS_SUCCESS:
      return { loading: false, tags: action.payload }
    case BILLING_TAGS_FAIL:
      return { loading: false, error: action.payload }
    case BILLING_TAGS_RESET:
      return {}
    default:
      return state
  }
}

export const billingAddTagReducer = (state = {}, action) => {
  switch (action.type) {
    case BILLING_ADD_TAG_REQUEST:
      return { loading: true }
    case BILLING_ADD_TAG_SUCCESS:
      return { loading: false, addTag: action.payload }
    case BILLING_ADD_TAG_FAIL:
      return { loading: false, error: action.payload }
    case BILLING_ADD_TAG_RESET:
      return {}
    default:
      return state
  }
}

export const billingUpdateTagReducer = (state = {}, action) => {
  switch (action.type) {
    case BILLING_UPDATE_TAG_REQUEST:
      return { loading1: true }
    case BILLING_UPDATE_TAG_SUCCESS:
      return { loading1: false, updateTag: action.payload }
    case BILLING_UPDATE_TAG_FAIL:
      return { loading1: false, error1: action.payload }
    case BILLING_UPDATE_TAG_RESET:
      return {}
    default:
      return state
  }
}

export const billingDepartmentsReducer = (state = {}, action) => {
  switch (action.type) {
    case BILLING_GET_DEPARTMENTS_REQUEST:
      return { loading: true }
    case BILLING_GET_DEPARTMENTS_SUCCESS:
      return { loading: false, departmentsList: action.payload }
    case BILLING_GET_DEPARTMENTS_FAIL:
      return { loading: false, error: action.payload }
    case BILLING_GET_DEPARTMENTS_RESET:
      return {}
    default:
      return state
  }
}

export const billingDepartmentByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case BILLING_GET_DEPARTMENT_BY_ID_REQUEST:
      return { loading: true }
    case BILLING_GET_DEPARTMENT_BY_ID_SUCCESS:
      return { loading: false, departmentById: action.payload }
    case BILLING_GET_DEPARTMENT_BY_ID_FAIL:
      return { loading: false, error: action.payload }
    case BILLING_GET_DEPARTMENT_BY_ID_RESET:
      return {}
    default:
      return state
  }
}

export const billingDepartmentSubmitDataReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBMIT_DEPARTMENT_BILLING_DATA_REQUEST:
      return { loading1: true }
    case SUBMIT_DEPARTMENT_BILLING_DATA_SUCCESS:
      return { loading1: false, submitData: action.payload }
    case SUBMIT_DEPARTMENT_BILLING_DATA_FAIL:
      return { loading1: false, error1: action.payload }
    case SUBMIT_DEPARTMENT_BILLING_DATA_RESET:
      return {}
    default:
      return state
  }
}

export const billingDepartmentUpdateDataReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_DEPARTMENT_BILLING_DATA_REQUEST:
      return { loading: true }
    case UPDATE_DEPARTMENT_BILLING_DATA_SUCCESS:
      return { loading: false, updateData: action.payload }
    case UPDATE_DEPARTMENT_BILLING_DATA_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_DEPARTMENT_BILLING_DATA_RESET:
      return {}
    default:
      return state
  }
}

export const billingGetDataByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BILLING_DATA_BY_ID_REQUEST:
      return { loading: true }
    case GET_BILLING_DATA_BY_ID_SUCCESS:
      return { loading: false, billingDataById: action.payload }
    case GET_BILLING_DATA_BY_ID_FAIL:
      return { loading: false, error: action.payload }
    case GET_BILLING_DATA_BY_ID_RESET:
      return {}
    default:
      return state
  }
}

export const billingGetReportsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BILLING_REPORTS_REQUEST:
      return { loading: true }
    case GET_BILLING_REPORTS_SUCCESS:
      return { loading: false, billingReports: action.payload }
    case GET_BILLING_REPORTS_FAIL:
      return { loading: false, error: action.payload }
    case GET_BILLING_REPORTS_RESET:
      return {}
    default:
      return state
  }
}

export const billingReportsReducer = (state = {}, action) => {
  switch (action.type) {
    case BILLING_REPORTS_REQUEST:
      return { loading1: true }
    case BILLING_REPORTS_SUCCESS:
      return { loading1: false, billingReportsData: action.payload }
    case BILLING_REPORTS_FAIL:
      return { loading1: false, error1: action.payload }
    case BILLING_REPORTS_RESET:
      return {}
    default:
      return state
  }
}

export const billingDeleteDataByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case BILLING_DELETE_DATA_BY_ID_REQUEST:
      return { loading2: true }
    case BILLING_DELETE_DATA_BY_ID_SUCCESS:
      return { loading2: false, billingDeleteData: action.payload }
    case BILLING_DELETE_DATA_BY_ID_FAIL:
      return { loading2: false, error2: action.payload }
    case BILLING_DELETE_DATA_BY_ID_RESET:
      return {}
    default:
      return state
  }
}

export const billingMasterReportReducer = (state = {}, action) => {
  switch (action.type) {
    case BILLING_MASTER_REPORT_REQUEST:
      return { loading1: true }
    case BILLING_MASTER_REPORT_SUCCESS:
      return { loading1: false, billingMasterReportData: action.payload }
    case BILLING_MASTER_REPORT_FAIL:
      return { loading1: false, error1: action.payload }
    case BILLING_MASTER_REPORT_RESET:
      return {}
    default:
      return state
  }
}

export const billingGetDailyMISReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BILLING_DAILY_MIS_REQUEST:
      return { loading: true }
    case GET_BILLING_DAILY_MIS_SUCCESS:
      return { loading: false, billingDailyMISData: action.payload }
    case GET_BILLING_DAILY_MIS_FAIL:
      return { loading: false, error: action.payload }
    case GET_BILLING_DAILY_MIS_RESET:
      return {}
    default:
      return state
  }
}

export const billingDailyMISReducer = (state = {}, action) => {
  switch (action.type) {
    case BILLING_DAILY_MIS_REQUEST:
      return { loading1: true }
    case BILLING_DAILY_MIS_SUCCESS:
      return { loading1: false, billingDailyMISResult: action.payload }
    case BILLING_DAILY_MIS_FAIL:
      return { loading1: false, error1: action.payload }
    case BILLING_DAILY_MIS_RESET:
      return {}
    default:
      return state
  }
}
