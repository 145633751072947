export const EXTENSIONS_REQUEST = 'EXTENSIONS_REQUEST'
export const EXTENSIONS_SUCCESS = 'EXTENSIONS_SUCCESS'
export const EXTENSIONS_FAIL = 'EXTENSIONS_FAIL'
export const EXTENSIONS_RESET = 'EXTENSIONS_RESET'

export const ADD_EXTENSION_REQUEST = 'ADD_EXTENSION_REQUEST'
export const ADD_EXTENSION_SUCCESS = 'ADD_EXTENSION_SUCCESS'
export const ADD_EXTENSION_FAIL = 'ADD_EXTENSION_FAIL'
export const ADD_EXTENSION_RESET = 'ADD_EXTENSION_RESET'

export const UPDATE_EXTENSION_REQUEST = 'UPDATE_EXTENSION_REQUEST'
export const UPDATE_EXTENSION_SUCCESS = 'UPDATE_EXTENSION_SUCCESS'
export const UPDATE_EXTENSION_FAIL = 'UPDATE_EXTENSION_FAIL'
export const UPDATE_EXTENSION_RESET = 'UPDATE_EXTENSION_RESET'

export const LIVE_CALLS_REQUEST = 'LIVE_CALLS_REQUEST'
export const LIVE_CALLS_SUCCESS = 'LIVE_CALLS_SUCCESS'
export const LIVE_CALLS_FAIL = 'LIVE_CALLS_FAIL'
export const LIVE_CALLS_RESET = 'LIVE_CALLS_RESET'

export const CALLS_MIS_REQUEST = 'CALLS_MIS_REQUEST'
export const CALLS_MIS_SUCCESS = 'CALLS_MIS_SUCCESS'
export const CALLS_MIS_FAIL = 'CALLS_MIS_FAIL'
export const CALLS_MIS_RESET = 'CALLS_MIS_RESET'

export const CALLS_RECORDINGS_GET_REQUEST = 'CALLS_RECORDINGS_GET_REQUEST'
export const CALLS_RECORDINGS_GET_SUCCESS = 'CALLS_RECORDINGS_GET_SUCCESS'
export const CALLS_RECORDINGS_GET_FAIL = 'CALLS_RECORDINGS_GET_FAIL'
export const CALLS_RECORDINGS_GET_RESET = 'CALLS_RECORDINGS_GET_RESET'

export const CALLS_RECORDINGS_REQUEST = 'CALLS_RECORDINGS_REQUEST'
export const CALLS_RECORDINGS_SUCCESS = 'CALLS_RECORDINGS_SUCCESS'
export const CALLS_RECORDINGS_FAIL = 'CALLS_RECORDINGS_FAIL'
export const CALLS_RECORDINGS_RESET = 'CALLS_RECORDINGS_RESET'
