import {
  AppBar,
  Grid,
  IconButton,
  InputBase,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import SearchIcon from '@material-ui/icons/Search'
import { logout } from '../actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import SettingsIcon from '@material-ui/icons/Settings'
import Popup from './Popup'
import ChangePassword from './ChangePassword'
import { dashboard } from '../actions/userActions'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    transform: 'translateZ(0)',
  },
  searchInput: {
    opacity: '0.6',
    padding: '0 8px',
    fontSize: '0.8rem',
    backgroundColor: '#f2f2f2',
    borderRadius: '5px',
    outline: 'none',

    '&:hover': {
      border: '2px solid black',
    },

    '& .MuiSvgIcon-root': {
      marginRight: '8px',
    },
  },
}))

const Header = () => {
  const classes = useStyles()
  const [openPopup, setOpenPopup] = useState(false)

  const dispatch = useDispatch()

  const logoutHandler = () => {
    dispatch(logout())
  }

  const [name, setName] = useState('')
  const [role, setRole] = useState('Employee')

  const userDashboard = useSelector((state) => state.userDashboard)
  const { loading, error, userData } = userDashboard

  useEffect(() => {
    dispatch(dashboard())
  }, [dispatch])

  useEffect(() => {
    if (userData) {
      setName(userData.name)
      setRole(userData.role)
    }
  }, [userData])

  return (
    <>
      <AppBar position='static' className={classes.root}>
        <Toolbar>
          <Grid container alignItems='center'>
            <Grid item>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                {(role === 'Admin' || role === 'HR') && (
                  <InputBase
                    placeholder='Search'
                    className={classes.searchInput}
                    startAdornment={<SearchIcon fontSize='small' />}
                  />
                )}
                <Typography style={{ color: '#000' }}>
                  Welcome, {name}
                </Typography>
              </div>
            </Grid>
            <Grid sm item></Grid>
            <Grid item>
              <IconButton onClick={() => setOpenPopup(true)}>
                <SettingsIcon fontSize='small' />
              </IconButton>
              <IconButton onClick={logoutHandler}>
                <PowerSettingsNewIcon fontSize='small' />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title='Change Password'
      >
        <ChangePassword setOpenPopup={setOpenPopup} />
      </Popup>
    </>
  )
}

export default Header
