import React from 'react'
import {
  Button as MuiButton,
  CircularProgress,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing(2),
    border: 'none',
  },
}))

const Button = ({
  text,
  size,
  color,
  variant,
  children,
  onClick,
  loading,
  ...other
}) => {
  const classes = useStyles()

  return (
    <MuiButton
      variant={variant || 'contained'}
      color={color || 'primary'}
      size={size || 'large'}
      onClick={onClick}
      {...other}
      disabled={loading}
    >
      {loading ? (
        <>
          <CircularProgress className={classes.circularProgress} size={20} />
          Loading
        </>
      ) : (
        <span>{text}</span>
      )}
    </MuiButton>
  )
}

export default Button
