import { CircularProgress, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    // marginLeft: 0,
    // marginRight: theme.spacing(2),
    // border: 'none',
    width: '100px',
    height: '100px',
    margin: 'auto',
    display: 'block',
    left: 150,
    right: 0,
    top: 0,
    bottom: 0,
    position: 'fixed',
    zIndex: 500,
  },
}))

const Progress = () => {
  const classes = useStyles()

  return <CircularProgress size={40} className={classes.circularProgress} />
}

export default Progress
