import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import {
  getAttendanceReviewReducer,
  userChangePasswordReducer,
  userDashboardReducer,
  userDialReducer,
  userLoginReducer,
  userSubmitEverydayReportingReducer,
} from './reducers/userReducers'
import {
  finalizeAttendanceReducer,
  hrActivateExtensionEmployeeReducer,
  hrAddDepartmentEmployeeReducer,
  hrAddDepartmentReducer,
  hrAddMasterDepartmentReducer,
  hrAeReducer,
  hrAsegReducer,
  hrAsepReducer,
  hrAssignExtensionEmployeeReducer,
  hrAttendanceReportsReducer,
  hrDashboardReducer,
  hrDeactivateExtensionEmployeeReducer,
  hrDeleteDocEmployeeReducer,
  hrDepartmentsReducer,
  hrDownloadDocEmployeeReducer,
  hrExitEmployeeReducer,
  hrGetEmployeeByIdReducer,
  hrMakeDepartmentPrimaryEmployeeReducer,
  hrMasterDepartmentsReducer,
  hrNaeReducer,
  hrRejoinEmployeeReducer,
  hrRemoveExtensionEmployeeReducer,
  hrReportsReducer,
  hrResetDeviceIDInfoEmployeeReducer,
  hrResetPasswordInfoEmployeeReducer,
  hrUpdateBankInfoEmployeeReducer,
  hrUpdateBasicInfoEmployeeReducer,
  hrUpdateDepartmentEmployeeReducer,
  hrUpdateDepartmentReducer,
  hrUpdateDocumentsInfoEmployeeReducer,
  hrUpdateEducationInfoEmployeeReducer,
  hrUpdateEmergencyInfoEmployeeReducer,
  hrUpdateEmployeeInfoEmployeeReducer,
  hrUpdateExperienceInfoEmployeeReducer,
  hrUpdateMasterDepartmentReducer,
  hrUpdateReferenceInfoEmployeeReducer,
  hrUpdateSettingsInfoEmployeeReducer,
  hrUpdateVehicleInfoEmployeeReducer,
  hrUploadDocEmployeeReducer,
  processFinalizeAttendanceReducer,
  searchAttendanceReducer,
  todaysAttendanceReducer,
  updateAttendanceReducer,
  updateFinalizeAttendanceReducer,
} from './reducers/hrReducers'
import {
  addExtensionsReducer,
  callsGetRecordingsReducer,
  callsMISReducer,
  callsRecordingsReducer,
  extensionsReducer,
  liveCallsReducer,
  updateExtensionsReducer,
} from './reducers/callingsReducer'
import {
  addRoleReducer,
  deleteRoleReducer,
  rolesReducer,
  settingsAddBankReducer,
  settingsDeleteBankReducer,
  settingsReducer,
  settingsUpdateTDSReducer,
} from './reducers/settingReducers'
import {
  billingAddTagReducer,
  billingDailyMISReducer,
  billingDeleteDataByIdReducer,
  billingDepartmentByIdReducer,
  billingDepartmentsReducer,
  billingDepartmentSubmitDataReducer,
  billingDepartmentUpdateDataReducer,
  billingGetDailyMISReducer,
  billingGetDataByIdReducer,
  billingGetReportsReducer,
  billingMasterReportReducer,
  billingReportsReducer,
  billingTagsReducer,
  billingUpdateTagReducer,
} from './reducers/billingReducers'
import {
  verificationAddSingleCallReducer,
  verificationGetCallsReducer,
} from './reducers/verificationReducers'
import { dataSearchReducer, dataUploadReducer } from './reducers/dataReducers'
import {
  accountsEmployeeByIdReducer,
  accountsEmployeesReducer,
  accountsFinalizeBackendSalariesReducer,
  accountsGetProcessBackendSalariesReducer,
  accountsUpdateProcessBackendSalariesReducer,
  accountsUpdateSalaryApprovalReducer,
  accountsUpdateSalaryReducer,
} from './reducers/accountsReducers'

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userChangePassword: userChangePasswordReducer,
  userDashboard: userDashboardReducer,
  hrDashboard: hrDashboardReducer,
  hrAseg: hrAsegReducer,
  hrAsep: hrAsepReducer,
  hrAe: hrAeReducer,
  hrNae: hrNaeReducer,
  hrDpts: hrDepartmentsReducer,
  hrAddDpt: hrAddDepartmentReducer,
  hrUpdateDpt: hrUpdateDepartmentReducer,
  extensionsData: extensionsReducer,
  addExtData: addExtensionsReducer,
  updateExtData: updateExtensionsReducer,
  settingsRoles: rolesReducer,
  settingsRoleDelete: deleteRoleReducer,
  settingsRoleAdd: addRoleReducer,
  hrGetEmployee: hrGetEmployeeByIdReducer,
  hrUpdBIE: hrUpdateBasicInfoEmployeeReducer,
  hrUpdBkIE: hrUpdateBankInfoEmployeeReducer,
  hrUpdRIE: hrUpdateReferenceInfoEmployeeReducer,
  hrUpdEIE: hrUpdateEducationInfoEmployeeReducer,
  hrUpdEmpIE: hrUpdateEmployeeInfoEmployeeReducer,
  hrUpdExpIE: hrUpdateExperienceInfoEmployeeReducer,
  hrUpdVIE: hrUpdateVehicleInfoEmployeeReducer,
  hrUpdEmgIE: hrUpdateEmergencyInfoEmployeeReducer,
  hrUpdDIE: hrUpdateDocumentsInfoEmployeeReducer,
  hrUpdSIE: hrUpdateSettingsInfoEmployeeReducer,
  hrResetPIE: hrResetPasswordInfoEmployeeReducer,
  hrResetDevIDIE: hrResetDeviceIDInfoEmployeeReducer,
  hrAssignExt: hrAssignExtensionEmployeeReducer,
  hrRemoveExt: hrRemoveExtensionEmployeeReducer,
  hrActivateExt: hrActivateExtensionEmployeeReducer,
  hrDeactivateExt: hrDeactivateExtensionEmployeeReducer,
  hrUploadDoc: hrUploadDocEmployeeReducer,
  hrDeleteDoc: hrDeleteDocEmployeeReducer,
  hrDownloadDoc: hrDownloadDocEmployeeReducer,
  hrAddDepartment: hrAddDepartmentEmployeeReducer,
  hrUpdateDepartment: hrUpdateDepartmentEmployeeReducer,
  hrMakeDepartmentPrimary: hrMakeDepartmentPrimaryEmployeeReducer,
  hrExitEmployee: hrExitEmployeeReducer,
  hrRejoinEmployee: hrRejoinEmployeeReducer,
  todaysAttendance: todaysAttendanceReducer,
  updateAttendance: updateAttendanceReducer,
  searchAttendance: searchAttendanceReducer,
  finalizeAttendance: finalizeAttendanceReducer,
  updateFinalizeAttendance: updateFinalizeAttendanceReducer,
  processFinalizeAttendance: processFinalizeAttendanceReducer,
  hrMasterDpts: hrMasterDepartmentsReducer,
  hrAddMasterDpt: hrAddMasterDepartmentReducer,
  hrUpdateMasterDpt: hrUpdateMasterDepartmentReducer,
  billingTags: billingTagsReducer,
  addBillingTag: billingAddTagReducer,
  updateBillingTag: billingUpdateTagReducer,
  getBillingDepartments: billingDepartmentsReducer,
  getBillingDepartmentById: billingDepartmentByIdReducer,
  submitBillingData: billingDepartmentSubmitDataReducer,
  updateBillingData: billingDepartmentUpdateDataReducer,
  liveCalls: liveCallsReducer,
  callMIS: callsMISReducer,
  settings: settingsReducer,
  settingsUpdateTDS: settingsUpdateTDSReducer,
  settingsAddBank: settingsAddBankReducer,
  settingsDeleteBank: settingsDeleteBankReducer,
  getBillingDataById: billingGetDataByIdReducer,
  getCallRecordings: callsGetRecordingsReducer,
  callsRecordings: callsRecordingsReducer,
  call: userDialReducer,
  hrReports: hrReportsReducer,
  hrAttendanceReports: hrAttendanceReportsReducer,
  billingGetReports: billingGetReportsReducer,
  billingPostReports: billingReportsReducer,
  billingDeleteDataById: billingDeleteDataByIdReducer,
  billingPostMasterReport: billingMasterReportReducer,
  getVerificationCalls: verificationGetCallsReducer,
  addVerificationSingleCall: verificationAddSingleCallReducer,
  submitEverydayReporting: userSubmitEverydayReportingReducer,
  billingGetDailyMIS: billingGetDailyMISReducer,
  billingDailyMISPost: billingDailyMISReducer,
  getAttendanceReview: getAttendanceReviewReducer,
  dataUpload: dataUploadReducer,
  dataSearch: dataSearchReducer,
  accountsEmployees: accountsEmployeesReducer,
  accountsEmployeeById: accountsEmployeeByIdReducer,
  accountsUpdateSalary: accountsUpdateSalaryReducer,
  getProcessBackendSalaries: accountsGetProcessBackendSalariesReducer,
  updateProcessBackendSalaries: accountsUpdateProcessBackendSalariesReducer,
  finalizeBackendSalaries: accountsFinalizeBackendSalariesReducer,
  accountsUpdateSalaryApprovalInfo: accountsUpdateSalaryApprovalReducer,
})

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const inititalState = {
  userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  inititalState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
