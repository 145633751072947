export const ACCOUNTS_GET_EMPLOYEES_REQUEST = 'ACCOUNTS_GET_EMPLOYEES_REQUEST'
export const ACCOUNTS_GET_EMPLOYEES_SUCCESS = 'ACCOUNTS_GET_EMPLOYEES_SUCCESS'
export const ACCOUNTS_GET_EMPLOYEES_FAIL = 'ACCOUNTS_GET_EMPLOYEES_FAIL'
export const ACCOUNTS_GET_EMPLOYEES_RESET = 'ACCOUNTS_GET_EMPLOYEES_RESET'

export const ACCOUNTS_GET_EMPLOYEE_BY_ID_REQUEST =
  'ACCOUNTS_GET_EMPLOYEE_BY_ID_REQUEST'
export const ACCOUNTS_GET_EMPLOYEE_BY_ID_SUCCESS =
  'ACCOUNTS_GET_EMPLOYEE_BY_ID_SUCCESS'
export const ACCOUNTS_GET_EMPLOYEE_BY_ID_FAIL =
  'ACCOUNTS_GET_EMPLOYEE_BY_ID_FAIL'
export const ACCOUNTS_GET_EMPLOYEE_BY_ID_RESET =
  'ACCOUNTS_GET_EMPLOYEE_BY_ID_RESET'

export const ACCOUNTS_UPDATE_SALARY_INFORMATION_REQUEST =
  'ACCOUNTS_UPDATE_SALARY_INFORMATION_REQUEST'
export const ACCOUNTS_UPDATE_SALARY_INFORMATION_SUCCESS =
  'ACCOUNTS_UPDATE_SALARY_INFORMATION_SUCCESS'
export const ACCOUNTS_UPDATE_SALARY_INFORMATION_FAIL =
  'ACCOUNTS_UPDATE_SALARY_INFORMATION_FAIL'
export const ACCOUNTS_UPDATE_SALARY_INFORMATION_RESET =
  'ACCOUNTS_UPDATE_SALARY_INFORMATION_RESET'

export const ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_REQUEST =
  'ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_REQUEST'
export const ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_SUCCESS =
  'ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_SUCCESS'
export const ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_FAIL =
  'ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_FAIL'
export const ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_RESET =
  'ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_RESET'

export const ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_REQUEST =
  'ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_REQUEST'
export const ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_SUCCESS =
  'ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_SUCCESS'
export const ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_FAIL =
  'ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_FAIL'
export const ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_RESET =
  'ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_RESET'

export const ACCOUNTS_FINALIZE_BACKEND_SALARIES_REQUEST =
  'ACCOUNTS_FINALIZE_BACKEND_SALARIES_REQUEST'
export const ACCOUNTS_FINALIZE_BACKEND_SALARIES_SUCCESS =
  'ACCOUNTS_FINALIZE_BACKEND_SALARIES_SUCCESS'
export const ACCOUNTS_FINALIZE_BACKEND_SALARIES_FAIL =
  'ACCOUNTS_FINALIZE_BACKEND_SALARIES_FAIL'
export const ACCOUNTS_FINALIZE_BACKEND_SALARIES_RESET =
  'ACCOUNTS_FINALIZE_BACKEND_SALARIES_RESET'

export const ACCOUNTS_UPDATE_SALARY_APPROVAL_REQUEST =
  'ACCOUNTS_UPDATE_SALARY_APPROVAL_REQUEST'
export const ACCOUNTS_UPDATE_SALARY_APPROVAL_SUCCESS =
  'ACCOUNTS_UPDATE_SALARY_APPROVAL_SUCCESS'
export const ACCOUNTS_UPDATE_SALARY_APPROVAL_FAIL =
  'ACCOUNTS_UPDATE_SALARY_APPROVAL_FAIL'
export const ACCOUNTS_UPDATE_SALARY_APPROVAL_RESET =
  'ACCOUNTS_UPDATE_SALARY_APPROVAL_RESET'
