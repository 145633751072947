import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { billingDepartmentsAction } from '../actions/billingActions'
import Notification from '../components/Notification'
import Progress from '../components/Progress'
import PageHeader from '../components/PageHeader'
import ReceiptIcon from '@material-ui/icons/Receipt'
import MaterialTable from 'material-table'

const BillingDepartments = ({ history }) => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const getBillingDepartments = useSelector(
    (state) => state.getBillingDepartments
  )
  const { loading, error, departmentsList } = getBillingDepartments

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const [tags, setTags] = useState([])
  const [departments, setDepartments] = useState([])

  useEffect(() => {
    dispatch(billingDepartmentsAction())
  }, [])

  useEffect(() => {
    if (departmentsList) {
      if (
        departmentsList.role === 'Admin' ||
        departmentsList.role === 'Billing'
      ) {
        setTags(departmentsList.tags)
        setDepartments(departmentsList.departments)
      } else {
        history.push('/dashboard')
      }
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [departmentsList, error])

  const [openPopup, setOpenPopup] = useState(false)
  const [departmentForEdit, setDepartmentForEdit] = useState(null)

  const openInPopup = (item) => {
    setDepartmentForEdit(item)
    setOpenPopup(true)
  }

  const openAdd = () => {
    setDepartmentForEdit(null)
    setOpenPopup(true)
  }

  // Table
  const headCells = [
    {
      field: 'master_department_name',
      title: 'Master Department',
    },
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'type',
      title: 'Type',
    },
    {
      field: 'view',
      title: 'View',
      render: (rowData) => (
        <a target='_blank' href={`/billing/department/${rowData.id}`}>
          View
        </a>
      ),
    },
  ]

  return (
    <>
      {loading ? (
        <Progress />
      ) : (
        <>
          {error ? (
            <Notification notify={notify} setNotify={setNotify} />
          ) : (
            <>
              <PageHeader
                title='Departments'
                subtitle='Shows list of departments (Collections/Verifications) in the organisations'
                icon={<ReceiptIcon fontSize='medium' />}
              />
              <MaterialTable
                style={{ margin: '0 20px' }}
                title='Departments'
                columns={headCells}
                data={departments}
                options={{
                  exportButton: true,
                  search: true,
                  exportAllData: true,
                }}
              />
            </>
          )}
        </>
      )}
    </>
  )
}

export default BillingDepartments
