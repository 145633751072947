import {
  FormControl,
  FormControlLabel,
  Checkbox as MuiCheckbox,
} from '@material-ui/core'
import React from 'react'

const Checkbox = ({ name, label, onChange, value }) => {
  const convertToDefaultEventParamter = (name, value) => ({
    target: {
      name,
      value,
    },
  })

  return (
    <FormControl>
      <FormControlLabel
        control={
          <MuiCheckbox
            name={name}
            color='primary'
            checked={value}
            onChange={(e) =>
              onChange(convertToDefaultEventParamter(name, e.target.checked))
            }
          />
        }
        label={label}
      />
    </FormControl>
  )
}

export default Checkbox
