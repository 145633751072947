import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Controls from './controls/Controls'
import Notification from './Notification'
import { Form, useForm } from './useForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  settingsUpdateTDSAction,
  settingsAction,
} from '../actions/settingActions'
import { SETTINGS_UPDATE_TDS_RESET } from '../constants/settingConstants'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const initialValues = {
  percentage: '',
}

const UpdateTDSSettings = ({ setOpenPopup }) => {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

  const classes = useStyles()
  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('percentage' in fieldValues)
      temp.percentage = fieldValues.percentage ? '' : 'This field is required'
    setErrors({
      ...temp,
    })
    if (fieldValues === values) return Object.values(temp).every((x) => x == '')
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate)

  const dispatch = useDispatch()
  const settingsUpdateTDS = useSelector((state) => state.settingsUpdateTDS)
  const { loading, error, updateTDS } = settingsUpdateTDS

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      dispatch(settingsUpdateTDSAction(values.percentage))
    }
  }

  useEffect(() => {
    dispatch({ type: SETTINGS_UPDATE_TDS_RESET })
    if (updateTDS) {
      setNotify({
        isOpen: true,
        message: updateTDS.msg,
        type: 'success',
      })
      setTimeout(() => {
        setOpenPopup(false)
        dispatch(settingsAction())
      }, 2000)
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [dispatch, updateTDS, error])

  return (
    <>
      <Form autoComplete='off' onSubmit={handleSubmit}>
        <Controls.Input
          label='Percentage'
          name='percentage'
          value={values.percentage}
          onChange={handleInputChange}
          error={errors.percentage}
        />
        <Controls.Button
          type='submit'
          fullWidth
          text='submit'
          className={classes.submit}
          loading={loading}
        />
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}

export default UpdateTDSSettings
