import {
  ROLES_REQUEST,
  ROLES_SUCCESS,
  ROLES_FAIL,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
  ADD_ROLE_REQUEST,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAIL,
  SETTINGS_REQUEST,
  SETTINGS_SUCCESS,
  SETTINGS_FAIL,
  SETTINGS_UPDATE_TDS_REQUEST,
  SETTINGS_UPDATE_TDS_SUCCESS,
  SETTINGS_UPDATE_TDS_FAIL,
  SETTINGS_ADD_BANK_REQUEST,
  SETTINGS_ADD_BANK_SUCCESS,
  SETTINGS_ADD_BANK_FAIL,
  SETTINGS_DELETE_BANK_REQUEST,
  SETTINGS_DELETE_BANK_SUCCESS,
  SETTINGS_DELETE_BANK_FAIL,
} from '../constants/settingConstants'
import axios from 'axios'
import { logout } from './userActions'

export const rolesAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ROLES_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/settings/roles/web', config)

    dispatch({
      type: ROLES_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: ROLES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteRoleAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_ROLE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/settings/role/${id}/web`, config)

    dispatch({
      type: DELETE_ROLE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DELETE_ROLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addRoleAction = (employee, role) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_ROLE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      `/api/settings/roles/add/web`,
      { employee, role },
      config
    )

    dispatch({
      type: ADD_ROLE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ADD_ROLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const settingsAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SETTINGS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/settings/web', config)

    dispatch({
      type: SETTINGS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: SETTINGS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const settingsUpdateTDSAction =
  (percentage) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SETTINGS_UPDATE_TDS_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        '/api/settings/update/tds/web',
        { percentage },
        config
      )

      dispatch({
        type: SETTINGS_UPDATE_TDS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: SETTINGS_UPDATE_TDS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const settingsAddBankAction = (name) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SETTINGS_ADD_BANK_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      '/api/settings/add/bank/web',
      { name },
      config
    )

    dispatch({
      type: SETTINGS_ADD_BANK_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SETTINGS_ADD_BANK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const settingsDeleteBankAction =
  (name) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SETTINGS_DELETE_BANK_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        '/api/settings/delete/bank/web',
        { name },
        config
      )

      dispatch({
        type: SETTINGS_DELETE_BANK_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: SETTINGS_DELETE_BANK_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
