import {
  Grid,
  InputAdornment,
  Link,
  makeStyles,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  billingDeleteDataByIdAction,
  billingDepartmentByIdAction,
  billingDepartmentSubmitDataAction,
} from '../actions/billingActions'
import Notification from '../components/Notification'
import Progress from '../components/Progress'
import useTable from '../components/useTable'
import Controls from '../components/controls/Controls'
import SearchIcon from '@material-ui/icons/Search'
import PageHeader from '../components/PageHeader'
import ReceiptIcon from '@material-ui/icons/Receipt'
import { Form, useForm } from '../components/useForm'
import {
  BILLING_DELETE_DATA_BY_ID_RESET,
  SUBMIT_DEPARTMENT_BILLING_DATA_RESET,
} from '../constants/billingConstants'
import ConfirmDialog from '../components/ConfirmDialog'
import CloseIcon from '@material-ui/icons/Close'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import EditBillingEntry from '../components/EditBillingEntry'
import Popup from '../components/Popup'
import MaterialTable from 'material-table'

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    overflowX: 'auto',
  },
  searchInput: {
    width: '75%',
  },
  link: {
    textDecoration: 'none',
  },
  addButton: {
    position: 'absolute',
    right: '10px',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const BillingDepartmentById = ({ match, history }) => {
  const {
    params: { id },
  } = match

  const classes = useStyles()

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const getBillingDepartmentById = useSelector(
    (state) => state.getBillingDepartmentById
  )
  const { loading, error, departmentById } = getBillingDepartmentById

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const [department, setDepartment] = useState({})
  const [masterDepartment, setMasterDepartment] = useState({})
  const [tags, setTags] = useState([])
  const [billingData, setBillingData] = useState([])
  const [headCells, setHeadCells] = useState([])
  const [supervisors, setSupervisors] = useState([])

  useEffect(() => {
    dispatch(billingDepartmentByIdAction(id))
  }, [])

  let initialValues = {
    type: '',
    period: null,
    supervisor: '',
  }

  useEffect(() => {
    if (departmentById) {
      if (
        departmentById.role === 'Admin' ||
        departmentById.role === 'Billing'
      ) {
        setDepartment(departmentById.department)
        setMasterDepartment(departmentById.master_department)
        setTags(departmentById.tags)
        setBillingData(departmentById.billingData)
        setHeadCells(departmentById.headCells)
        setSupervisors(departmentById.supervisors)
      } else {
        history.push('/dashboard')
      }
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [departmentById, error])

  const handleSubmit = (e) => {
    e.preventDefault()
    // Check if any value is empty
    let result = checkProperties(values)
    if (!result) {
      dispatch(billingDepartmentSubmitDataAction(id, values))
    } else {
      setNotify({
        isOpen: true,
        message: 'All the fields are mandatory',
        type: 'error',
      })
    }
  }

  useEffect(() => {
    tags.forEach((tag) => {
      let tagID = tag._id
      initialValues = { ...initialValues, [tagID]: '' }
    })
  }, [tags])

  function checkProperties(obj) {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] != '') return false
    }
    return true
  }

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('type' in fieldValues)
      temp.type = fieldValues.type.length !== 0 ? '' : 'This field is required'
    setErrors({
      ...temp,
    })
    if (fieldValues === values) return Object.values(temp).every((x) => x == '')
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate)

  const submitBillingData = useSelector((state) => state.submitBillingData)
  const { loading1, error1, submitData } = submitBillingData

  useEffect(() => {
    dispatch({ type: SUBMIT_DEPARTMENT_BILLING_DATA_RESET })
    if (submitData) {
      setNotify({
        isOpen: true,
        message: submitData.msg,
        type: 'success',
      })
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } else if (error1) {
      setNotify({
        isOpen: true,
        message: error1,
        type: 'error',
      })
    }
  }, [submitData, error1])

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  })

  const billingDeleteDataById = useSelector(
    (state) => state.billingDeleteDataById
  )
  const { loading2, error2, billingDeleteData } = billingDeleteDataById

  useEffect(() => {
    dispatch({ type: BILLING_DELETE_DATA_BY_ID_RESET })
    if (billingDeleteData) {
      setNotify({
        isOpen: true,
        message: billingDeleteData.msg,
        type: 'success',
      })
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    } else if (error2) {
      setNotify({
        isOpen: true,
        message: error2,
        type: 'error',
      })
    }
  }, [billingDeleteData, error2])

  const [openEditBillingEntry, setOpenEditBillingEntry] = useState(false)
  const [billingEntryForEdit, setBillingEntryForEdit] = useState(null)

  const openInPopup = (item) => {
    console.log(item)
    setBillingEntryForEdit(item)
    setOpenEditBillingEntry(true)
  }

  return (
    <>
      {/* {loading ? (
        <Progress />
      ) : (
        <>
          {error ? (
            <Notification notify={notify} setNotify={setNotify} />
          ) : ( */}
      <>
        <PageHeader
          title={
            department ? masterDepartment.name + '-' + department.name : ''
          }
          subtitle='Show list of Billing Data Numbers for MIS and Reports'
          icon={<ReceiptIcon fontSize='medium' />}
        />
        {/* Show list of tags to enter data */}
        <Paper className={classes.pageContent}>
          <Grid container spacing={3}>
            <Grid item>
              <Typography>
                Fill out the below details and click on submit
              </Typography>
            </Grid>
            <Grid sm item />
          </Grid>
          <Form autoComplete='off' onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              {tags.map((tag) => (
                <Grid item xs={4}>
                  <Controls.Input
                    label={tag.name + ' (' + tag.type + ')' + ' *'}
                    name={tag._id}
                    value={values[tag._id]}
                    onChange={handleInputChange}
                    type='number'
                    min='0.00'
                    step='0.001'
                    max='1.00'
                    presicion={2}
                  />
                </Grid>
              ))}
              <Grid item xs={4}>
                <Controls.Select
                  label='Type'
                  name='type'
                  value={values.type}
                  onChange={handleInputChange}
                  error={errors.type}
                  options={[
                    { id: 'Monthly', title: 'Monthly' },
                    { id: 'Daily', title: 'Daily' },
                  ]}
                />
              </Grid>
              <Grid item xs={4}>
                <Controls.DatePicker
                  label='Period'
                  name='period'
                  value={values.period}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={4}>
                <Controls.Select
                  label='Supervsor'
                  name='supervisor'
                  value={values.supervisor}
                  onChange={handleInputChange}
                  error={errors.supervisor}
                  options={supervisors}
                />
              </Grid>
            </Grid>
            <Controls.Button
              type='submit'
              text='submit'
              className={classes.submit}
              loading={loading1}
            />
          </Form>
        </Paper>
        <MaterialTable
          style={{ margin: '0 20px' }}
          title='Data'
          columns={headCells}
          data={billingData}
          options={{
            exportButton: true,
            search: true,
            exportAllData: true,
            actionsColumnIndex: -1,
          }}
          actions={[
            (rowData) => ({
              icon: 'delete',
              tooltip: 'Delete User',
              onClick: (event, rowData) =>
                setConfirmDialog({
                  isOpen: true,
                  title: 'Are you sure to delete this entry?',
                  subTitle: "You can't undo this operation",
                  onConfirm: () => {
                    dispatch(billingDeleteDataByIdAction(rowData.id))
                  },
                }),
            }),
          ]}
        />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      </>
      {/* )} */}
      <Notification notify={notify} setNotify={setNotify} />
      {/* </> */}
      {/* // )} */}
      <Popup
        openPopup={openEditBillingEntry}
        setOpenPopup={setOpenEditBillingEntry}
        title='Update Billing Entry'
      >
        <EditBillingEntry
          setOpenEditBillingEntry={setOpenEditBillingEntry}
          tags={tags}
          id={id}
          billingEntryForEdit={billingEntryForEdit}
        />
      </Popup>
    </>
  )
}

export default BillingDepartmentById
