import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_CHANGE_PASSWORD_FAIL,
  USER_CHANGE_PASSWORD_REQUEST,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_CHANGE_PASSWORD_RESET,
  USER_DASHBOARD_REQUEST,
  USER_DASHBOARD_SUCCESS,
  USER_DASHBOARD_FAIL,
  DIAL_REQUEST,
  DIAL_SUCCESS,
  DIAL_FAIL,
  DIAL_RESET,
  SUBMIT_EVERYDAY_REPORTING_REQUEST,
  SUBMIT_EVERYDAY_REPORTING_SUCCESS,
  SUBMIT_EVERYDAY_REPORTING_FAIL,
  SUBMIT_EVERYDAY_REPORTING_RESET,
  GET_ATTENDANCE_REVIEW_REQUEST,
  GET_ATTENDANCE_REVIEW_SUCCESS,
  GET_ATTENDANCE_REVIEW_FAIL,
  GET_ATTENDANCE_REVIEW_RESET,
} from '../constants/userConstants'

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true }
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload }
    case USER_LOGOUT:
      return {}
    default:
      return state
  }
}

export const userChangePasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CHANGE_PASSWORD_REQUEST:
      return { loading: true }
    case USER_CHANGE_PASSWORD_SUCCESS:
      return { loading: false, changePassword: action.payload }
    case USER_CHANGE_PASSWORD_FAIL:
      return { loading: false, error: action.payload }
    case USER_CHANGE_PASSWORD_RESET:
      return {}
    default:
      return state
  }
}

export const userDashboardReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DASHBOARD_REQUEST:
      return { loading: true }
    case USER_DASHBOARD_SUCCESS:
      return { loading: false, userData: action.payload }
    case USER_DASHBOARD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userDialReducer = (state = {}, action) => {
  switch (action.type) {
    case DIAL_REQUEST:
      return { loading1: true }
    case DIAL_SUCCESS:
      return { loading1: false, dialData: action.payload }
    case DIAL_FAIL:
      return { loading1: false, error1: action.payload }
    case DIAL_RESET:
      return {}
    default:
      return state
  }
}

export const userSubmitEverydayReportingReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBMIT_EVERYDAY_REPORTING_REQUEST:
      return { loading2: true }
    case SUBMIT_EVERYDAY_REPORTING_SUCCESS:
      return { loading2: false, result: action.payload }
    case SUBMIT_EVERYDAY_REPORTING_FAIL:
      return { loading2: false, error2: action.payload }
    case SUBMIT_EVERYDAY_REPORTING_RESET:
      return {}
    default:
      return state
  }
}

export const getAttendanceReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ATTENDANCE_REVIEW_REQUEST:
      return { loading: true }
    case GET_ATTENDANCE_REVIEW_SUCCESS:
      return { loading: false, result: action.payload }
    case GET_ATTENDANCE_REVIEW_FAIL:
      return { loading: false, error: action.payload }
    case GET_ATTENDANCE_REVIEW_RESET:
      return {}
    default:
      return state
  }
}
