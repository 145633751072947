import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Controls from './controls/Controls'
import Notification from './Notification'
import { Form, useForm } from './useForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  addExtensionsAction,
  extensionsAction,
  updateExtensionsAction,
} from '../actions/callingActions'
import {
  ADD_EXTENSION_RESET,
  UPDATE_EXTENSION_RESET,
} from '../constants/callingConstants'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const initialValues = {
  id: '',
  did: '',
  extension: '',
  is_active: '',
  password: '',
}

const AddExtension = ({ setOpenPopup, extensionForEdit }) => {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

  const classes = useStyles()
  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('did' in fieldValues)
      temp.did = fieldValues.did ? '' : 'This field is required'
    if ('extension' in fieldValues)
      temp.extension = fieldValues.extension ? '' : 'This field is required'
    if ('is_active' in fieldValues)
      temp.is_active =
        fieldValues.is_active.length !== 0 ? '' : 'This field is required'
    if ('password' in fieldValues)
      temp.password = fieldValues.password ? '' : 'This field is required'
    setErrors({
      ...temp,
    })
    if (fieldValues === values) return Object.values(temp).every((x) => x == '')
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate)

  const dispatch = useDispatch()
  const addExtData = useSelector((state) => state.addExtData)
  const { loading, error, addExt } = addExtData

  const updateExtData = useSelector((state) => state.updateExtData)
  const { loading1, error1, updateExt } = updateExtData

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      if (extensionForEdit) {
        dispatch(
          updateExtensionsAction(
            values.id,
            values.did,
            values.extension,
            values.is_active,
            values.password
          )
        )
      } else {
        dispatch(
          addExtensionsAction(
            values.did,
            values.extension,
            values.is_active,
            values.password
          )
        )
      }
    }
  }

  useEffect(() => {
    dispatch({ type: ADD_EXTENSION_RESET })
    if (addExt) {
      setNotify({
        isOpen: true,
        message: addExt.msg,
        type: 'success',
      })
      setTimeout(() => {
        setOpenPopup(false)
        dispatch(extensionsAction())
      }, 2000)
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [dispatch, addExt, error])

  useEffect(() => {
    dispatch({ type: UPDATE_EXTENSION_RESET })
    if (updateExt) {
      setNotify({
        isOpen: true,
        message: updateExt.msg,
        type: 'success',
      })
      setTimeout(() => {
        setOpenPopup(false)
        dispatch(extensionsAction())
      }, 2000)
    } else if (error1) {
      setNotify({
        isOpen: true,
        message: error1,
        type: 'error',
      })
    }
  }, [dispatch, updateExt, error1])

  useEffect(() => {
    if (extensionForEdit != null) {
      setValues({
        ...extensionForEdit,
      })
    }
  }, [extensionForEdit])

  return (
    <>
      <Form autoComplete='off' onSubmit={handleSubmit}>
        <Controls.Input
          label='DID *'
          name='did'
          value={values.did}
          onChange={handleInputChange}
          error={errors.did}
          type='number'
          inputProps={{
            min: 0,
          }}
        />
        <Controls.Input
          label='Extension Number *'
          name='extension'
          value={values.extension}
          onChange={handleInputChange}
          error={errors.extension}
          type='number'
          inputProps={{
            min: 0,
          }}
        />
        <Controls.Select
          label='Is Active *'
          name='is_active'
          value={values.is_active}
          onChange={handleInputChange}
          error={errors.is_active}
          options={[
            { id: 'true', title: 'true' },
            { id: 'false', title: 'false' },
          ]}
        />
        <Controls.Input
          label='Password *'
          name='password'
          value={values.password}
          onChange={handleInputChange}
          error={errors.password}
        />
        <Controls.Button
          type='submit'
          fullWidth
          text='submit'
          className={classes.submit}
          loading={loading || loading1}
        />
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}

export default AddExtension
