import {
  ACCOUNTS_FINALIZE_BACKEND_SALARIES_FAIL,
  ACCOUNTS_FINALIZE_BACKEND_SALARIES_REQUEST,
  ACCOUNTS_FINALIZE_BACKEND_SALARIES_SUCCESS,
  ACCOUNTS_GET_EMPLOYEES_FAIL,
  ACCOUNTS_GET_EMPLOYEES_REQUEST,
  ACCOUNTS_GET_EMPLOYEES_SUCCESS,
  ACCOUNTS_GET_EMPLOYEE_BY_ID_FAIL,
  ACCOUNTS_GET_EMPLOYEE_BY_ID_REQUEST,
  ACCOUNTS_GET_EMPLOYEE_BY_ID_SUCCESS,
  ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_FAIL,
  ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_REQUEST,
  ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_SUCCESS,
  ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_FAIL,
  ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_REQUEST,
  ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_SUCCESS,
  ACCOUNTS_UPDATE_SALARY_APPROVAL_FAIL,
  ACCOUNTS_UPDATE_SALARY_APPROVAL_REQUEST,
  ACCOUNTS_UPDATE_SALARY_APPROVAL_SUCCESS,
  ACCOUNTS_UPDATE_SALARY_INFORMATION_FAIL,
  ACCOUNTS_UPDATE_SALARY_INFORMATION_REQUEST,
  ACCOUNTS_UPDATE_SALARY_INFORMATION_SUCCESS,
} from '../constants/accountConstants'
import axios from 'axios'
import { logout } from '../actions/userActions'

export const accountsEmployeesAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ACCOUNTS_GET_EMPLOYEES_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/accounts/employees/web', config)

    dispatch({
      type: ACCOUNTS_GET_EMPLOYEES_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: ACCOUNTS_GET_EMPLOYEES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const accountsEmployeeByIdAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ACCOUNTS_GET_EMPLOYEE_BY_ID_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        `/api/accounts/employee/${id}/web`,
        config
      )

      dispatch({
        type: ACCOUNTS_GET_EMPLOYEE_BY_ID_SUCCESS,
        payload: data,
      })
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout())
        return
      }
      dispatch({
        type: ACCOUNTS_GET_EMPLOYEE_BY_ID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const accountsUpdateSalaryAction =
  (
    id,
    salary,
    isTDS,
    tdsType,
    tdsAmount,
    isPF,
    pfAmount,
    isESI,
    esiAmount,
    remark
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ACCOUNTS_UPDATE_SALARY_INFORMATION_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/accounts/employee/${id}/salary_information/web`,
        {
          salary,
          isTDS,
          tdsType,
          tdsAmount,
          isPF,
          pfAmount,
          isESI,
          esiAmount,
          remark,
        },
        config
      )

      dispatch({
        type: ACCOUNTS_UPDATE_SALARY_INFORMATION_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ACCOUNTS_UPDATE_SALARY_INFORMATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const accountsGetProcessBackendSalariesAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        `/api/accounts/salary/backend/process/${id}/web`,
        config
      )

      dispatch({
        type: ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_SUCCESS,
        payload: data,
      })
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout())
        return
      }
      dispatch({
        type: ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const accountsUpdateProcessBackendSalariesAction =
  (
    id,
    employee,
    adjustment_amount,
    adjustment_type,
    adjustment_reason,
    final_salary,
    status
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/accounts/salary/backend/process/${id}/web`,
        {
          employee,
          adjustment_amount,
          adjustment_type,
          adjustment_reason,
          final_salary,
          status,
        },
        config
      )

      dispatch({
        type: ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const accountsFinalizeBackendSalariesAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ACCOUNTS_FINALIZE_BACKEND_SALARIES_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/accounts/salary/backend/finalize/${id}/web`,
        config
      )

      dispatch({
        type: ACCOUNTS_FINALIZE_BACKEND_SALARIES_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ACCOUNTS_FINALIZE_BACKEND_SALARIES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const accountsUpdateSalaryApprovalAction =
  (id, status) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ACCOUNTS_UPDATE_SALARY_APPROVAL_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/accounts/employee/${id}/salary_information/approval-update/web`,
        { status },
        config
      )

      dispatch({
        type: ACCOUNTS_UPDATE_SALARY_APPROVAL_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ACCOUNTS_UPDATE_SALARY_APPROVAL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
