import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Controls from './controls/Controls'
import Notification from './Notification'
import { Form, useForm } from './useForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  hrRejoinEmployeeAction,
  hrGetEmployeeByIdAction,
} from '../actions/hrActions'
import { HR_REJOIN_EMPLOYEE_RESET } from '../constants/hrConstants'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  file: {
    marginTop: '20px',
  },
}))

const initialValues = {
  date: new Date(),
}

const HRRejoin = ({ setOpenRejoin, employeeID }) => {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

  const classes = useStyles()
  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('date' in fieldValues)
      temp.date = fieldValues.date ? '' : 'This field is required'
    setErrors({
      ...temp,
    })
    if (fieldValues === values) return Object.values(temp).every((x) => x == '')
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate)

  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!values.date) {
      setNotify({
        isOpen: true,
        message: 'All the with * fields are mandatory',
        type: 'error',
      })
    } else {
      dispatch(hrRejoinEmployeeAction(employeeID, values.date))
    }
  }

  const hrRejoinEmployee = useSelector((state) => state.hrRejoinEmployee)
  const { loading, error, rejoinEmployee } = hrRejoinEmployee

  useEffect(() => {
    dispatch({ type: HR_REJOIN_EMPLOYEE_RESET })
    if (rejoinEmployee) {
      setNotify({
        isOpen: true,
        message: rejoinEmployee.msg,
        type: 'success',
      })
      setTimeout(() => {
        setOpenRejoin(false)
        dispatch(hrGetEmployeeByIdAction(employeeID))
      }, 2000)
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [rejoinEmployee, error, dispatch, employeeID])

  return (
    <>
      <Form autoComplete='off' onSubmit={handleSubmit}>
        <Controls.DatePicker
          label='Date *'
          name='date'
          value={values.date}
          onChange={handleInputChange}
          error={errors.date}
        />
        <Controls.Button
          type='submit'
          fullWidth
          text='submit'
          className={classes.submit}
          loading={loading}
        />
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}

export default HRRejoin
