import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { liveCallsAction } from '../actions/callingActions'
import Notification from '../components/Notification'
import Progress from '../components/Progress'
import PageHeader from '../components/PageHeader'
import CallIcon from '@material-ui/icons/Call'
import MaterialTable from 'material-table'

const headCells = [
  {
    field: 'employee_code',
    title: 'Employee Code',
  },
  {
    field: 'employee_name',
    title: 'Employee Name',
  },
  {
    field: 'call_type',
    title: 'Call Type',
  },
  {
    field: 'caller',
    title: 'Caller',
  },
  {
    field: 'start_time',
    title: 'Started On',
  },
]

const LiveCalls = ({ history }) => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const userDashboard = useSelector((state) => state.userDashboard)
  const { userData } = userDashboard

  useEffect(() => {
    if (!userData || !userData.callingManage) {
      history.push('/dashboard')
    }
  }, [dispatch, history, userData])

  const liveCalls = useSelector((state) => state.liveCalls)
  const { loading, error, calls } = liveCalls

  const [callsList, setCallsList] = useState([])

  useEffect(() => {
    dispatch(liveCallsAction())
  }, [])

  useEffect(() => {
    if (calls) {
      if (calls.role === 'Admin' || calls.role === 'HR') {
        setCallsList(calls.liveCalls)
      } else {
        history.push('/dashboard')
      }
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [calls, error])

  return (
    <>
      {loading ? (
        <Progress />
      ) : (
        <>
          {error ? (
            <Notification notify={notify} setNotify={setNotify} />
          ) : (
            <>
              <PageHeader
                title='Live Calls'
                subtitle='Shows list of live calls in the organisations'
                icon={<CallIcon fontSize='medium' />}
              />
              <MaterialTable
                style={{ margin: '0 20px' }}
                title='Call Recordings'
                columns={headCells}
                data={callsList}
                options={{
                  exportButton: true,
                  search: true,
                  exportAllData: true,
                }}
              />
            </>
          )}
        </>
      )}
    </>
  )
}

export default LiveCalls
