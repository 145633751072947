export const GET_VERIFICATION_CALLING_REQUEST =
  'GET_VERIFICATION_CALLING_REQUEST'
export const GET_VERIFICATION_CALLING_SUCCESS =
  'GET_VERIFICATION_CALLING_SUCCESS'
export const GET_VERIFICATION_CALLING_FAIL = 'GET_VERIFICATION_CALLING_FAIL'
export const GET_VERIFICATION_CALLING_RESET = 'GET_VERIFICATION_CALLING_RESET'

export const ADD_VERIFICATION_SINGLE_CALL_REQUEST =
  'ADD_VERIFICATION_SINGLE_CALL_REQUEST'
export const ADD_VERIFICATION_SINGLE_CALL_SUCCESS =
  'ADD_VERIFICATION_SINGLE_CALL_SUCCESS'
export const ADD_VERIFICATION_SINGLE_CALL_FAIL =
  'ADD_VERIFICATION_SINGLE_CALL_FAIL'
export const ADD_VERIFICATION_SINGLE_CALL_RESET =
  'ADD_VERIFICATION_SINGLE_CALL_RESET'
