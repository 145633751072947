import {
  makeStyles,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Notification from '../components/Notification'
import Progress from '../components/Progress'
import {
  accountsFinalizeBackendSalariesAction,
  accountsGetProcessBackendSalariesAction,
} from '../actions/accountActions'
import useTable from '../components/useTable'
import PageHeader from '../components/PageHeader'
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import {
  ACCOUNTS_FINALIZE_BACKEND_SALARIES_RESET,
  ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_RESET,
} from '../constants/accountConstants'
import Controls from '../components/controls/Controls'
import Popup from '../components/Popup'
import AdjustSalary from '../components/AdjustSalary'

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(0, 1.5, 2, 1.5),
    padding: theme.spacing(1, 2),
    overflowX: 'auto',
  },
  submit: {
    margin: theme.spacing(2, 0, 0),
  },
}))

const headCells = [
  {
    id: 'employee_code',
    label: 'Employee Code',
  },
  {
    id: 'name',
    label: 'Employee Name',
  },
  {
    id: 'attendance',
    label: 'Attendance',
  },
  {
    id: 'salary',
    label: 'Salary',
  },
  {
    id: 'pf',
    label: 'PF',
  },
  {
    id: 'esi',
    label: 'ESI',
  },
  {
    id: 'tds',
    label: 'TDS',
  },
  {
    id: 'salary_as_per_attendance',
    label: 'Salary as per Attendance',
  },
  {
    id: 'adjustment',
    label: 'Adjustment',
  },
  {
    id: 'adjustment_amount',
    label: 'Adjustment Amount',
  },
  {
    id: 'adjustment_type',
    label: 'Adjustment Type',
  },
  {
    id: 'adjustment_reason',
    label: 'Adjustment Reason',
  },
  {
    id: 'final_salary',
    label: 'Final_salary',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'pan_card',
    label: 'PAN Card',
  },
  {
    id: 'name_as_per_bank',
    label: 'Name as per Bank',
  },
  {
    id: 'bank_name',
    label: 'Bank Name',
  },
  {
    id: 'account_number',
    label: 'Account Number',
  },
  {
    id: 'ifsc_code',
    label: 'IFSC Code',
  },
]

const ProcessBackendSalaries = ({ match, history }) => {
  const {
    params: { id },
  } = match

  const classes = useStyles()

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const [salaries, setSalaries] = useState([])
  const [showButton, setShowButton] = useState(false)

  useEffect(() => {
    dispatch(accountsGetProcessBackendSalariesAction(id))
  }, [])

  const getProcessBackendSalaries = useSelector(
    (state) => state.getProcessBackendSalaries
  )
  const { loading, error, result } = getProcessBackendSalaries

  useEffect(() => {
    dispatch({ type: ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_RESET })
    if (result) {
      if (result.role === 'Admin' || result.role === 'Accounts') {
        setMonth(result.month)
        setYear(result.year)
        setSalaries(result.salaries)
        setShowButton(result.showProcess)
      } else {
        history.push('/dashboard')
      }
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [result, error, dispatch])

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items
    },
  })

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(salaries, headCells, filterFn)

  const [openPopup, setOpenPopup] = useState(false)
  const [salaryForUpdate, setSalaryForUpdate] = useState(null)

  const openInPopup = (item) => {
    setSalaryForUpdate(item)
    setOpenPopup(true)
  }

  const process = () => {
    dispatch(accountsFinalizeBackendSalariesAction(id))
  }

  const finalizeBackendSalaries = useSelector(
    (state) => state.finalizeBackendSalaries
  )
  const { loading1, error1, resultFinalize } = finalizeBackendSalaries

  useEffect(() => {
    // dispatch({ type: ACCOUNTS_FINALIZE_BACKEND_SALARIES_RESET })
    if (resultFinalize) {
      setNotify({
        isOpen: true,
        message: resultFinalize.msg,
        type: 'success',
      })
      setTimeout(() => {
        history.push('/dashboard')
      }, 1000)
    } else if (error1) {
      setNotify({
        isOpen: true,
        message: error1,
        type: 'error',
      })
    }
  }, [resultFinalize, error1, dispatch])

  return (
    <>
      {loading ? (
        <Progress />
      ) : (
        <>
          <PageHeader
            title='Process Attendance'
            subtitle={`Processing the salaries for ${month} - ${year}`}
            icon={<PeopleOutlineTwoToneIcon fontSize='medium' />}
          />
          <Paper className={classes.pageContent}>
            {showButton && (
              <Controls.Button
                text='Process'
                className={classes.submit}
                onClick={process}
                loading={loading1}
              />
            )}
            <TblContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagingAndSorting().map((item) => (
                  <TableRow key={item.employee}>
                    <TableCell>{item.employee_code}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.attendance}</TableCell>
                    <TableCell>{item.salary}</TableCell>
                    <TableCell>{item.pf}</TableCell>
                    <TableCell>{item.esi}</TableCell>
                    <TableCell>{item.tds}</TableCell>
                    <TableCell>{item.salary_as_per_attendance}</TableCell>
                    <TableCell>
                      <Controls.Button
                        text='Update'
                        onClick={() => openInPopup(item)}
                      />
                    </TableCell>
                    <TableCell>{item.adjustment_amount}</TableCell>
                    <TableCell>{item.adjustment_type}</TableCell>
                    <TableCell>{item.adjustment_reason}</TableCell>
                    <TableCell>{item.final_salary}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>{item.pan_card}</TableCell>
                    <TableCell>{item.name_as_per_bank}</TableCell>
                    <TableCell>{item.bank_name}</TableCell>
                    <TableCell>{item.account_number}</TableCell>
                    <TableCell>{item.ifsc_code}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TblContainer>
            <TblPagination />
          </Paper>
          <Popup
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            title='Adjustment'
          >
            <AdjustSalary
              id={id}
              salaryForUpdate={salaryForUpdate}
              setOpenPopup={setOpenPopup}
            />
          </Popup>
        </>
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}

export default ProcessBackendSalaries
