import { makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Controls from './controls/Controls'
import Notification from './Notification'
import { Form } from './useForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  hrGetEmployeeByIdAction,
  hrUpdateDepartmentEmployeeAction,
} from '../actions/hrActions'
import { HR_UPDATE_DEPARTMENT_EMPLOYEE_RESET } from '../constants/hrConstants'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  file: {
    marginTop: '20px',
  },
}))

const HREditDepartment = ({
  setOpenEditDepartment,
  employeeID,
  departmentForEdit,
}) => {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

  const classes = useStyles()

  const [id, setId] = useState('')
  const [supervisor, setSupervisor] = useState('')
  const [status, setStatus] = useState('Not Active')
  const [joined_on, setJoinedOn] = useState(null)
  const [left_on, setLeftOn] = useState(null)
  const [id_card, setIdCard] = useState('Not Issued')
  const [id_card_number, setIdCardNumber] = useState('')
  const [id_card_issued_on, setIdCardIssuedOn] = useState(null)
  const [id_card_expiry_on, setIdCardExpiryOn] = useState(null)
  const [id_card_returned, setIdCardReturned] = useState('')
  const [id_card_returned_on, setIdCardReturnedOn] = useState(null)

  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!id || !joined_on || !status) {
      setNotify({
        isOpen: true,
        message: 'First 2 fields are mandatory',
        type: 'error',
      })
      return
    }
    if (status === 'Not Active') {
      if (!left_on) {
        setNotify({
          isOpen: true,
          message: 'Left on date is required',
          type: 'error',
        })
      } else if (id_card === 'Issued') {
        if (!id_card_returned || !id_card_returned_on) {
          setNotify({
            isOpen: true,
            message: 'ID Card returned values are required',
            type: 'error',
          })
        }
      }
      return
    }

    dispatch(
      hrUpdateDepartmentEmployeeAction(
        employeeID,
        id,
        departmentForEdit.current_supervisor.id,
        supervisor,
        joined_on,
        status,
        left_on,
        id_card,
        id_card_number,
        id_card_issued_on,
        id_card_expiry_on,
        id_card_returned,
        id_card_returned_on
      )
    )
  }

  const hrUpdateDepartment = useSelector((state) => state.hrUpdateDepartment)
  const { loading, error, updateDepartmentEmployee } = hrUpdateDepartment

  useEffect(() => {
    dispatch({ type: HR_UPDATE_DEPARTMENT_EMPLOYEE_RESET })
    if (updateDepartmentEmployee) {
      setNotify({
        isOpen: true,
        message: updateDepartmentEmployee.msg,
        type: 'success',
      })
      setTimeout(() => {
        setOpenEditDepartment(false)
        dispatch(hrGetEmployeeByIdAction(employeeID))
      }, 2000)
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [updateDepartmentEmployee, error, dispatch, employeeID])

  useEffect(() => {
    setId(departmentForEdit.id)
    setSupervisor(departmentForEdit.current_supervisor.id)
    setStatus(departmentForEdit.status)
    setJoinedOn(departmentForEdit.joined_on)
    setLeftOn(departmentForEdit.left_on)
    setIdCard(departmentForEdit.id_card)
    setIdCardNumber(departmentForEdit.id_card_number)
    setIdCardIssuedOn(departmentForEdit.id_card_issued_on)
    setIdCardExpiryOn(departmentForEdit.id_card_expiry_on)
    setIdCardReturned(departmentForEdit.id_card_returned)
    setIdCardReturnedOn(departmentForEdit.id_card_returned_on)
  }, [departmentForEdit])

  return (
    <>
      <Form autoComplete='off'>
        <Typography>
          Current Supervisor: {departmentForEdit.current_supervisor.name}
        </Typography>
        <select
          style={{ width: '100%', marginTop: '10px', height: '40px' }}
          label='Status *'
          name='status'
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          {status === 'Active' ? (
            <>
              <option value='Active'>Active</option>
              <option value='Not Active'>Not Active</option>
            </>
          ) : (
            <>
              <option value='Not Active'>Not Active</option>
              <option value='Active'>Active</option>
            </>
          )}
        </select>
        <input
          type='date'
          style={{ width: '100%', marginTop: '10px', height: '40px' }}
          label='Joined On *'
          name='joined_on'
          value={joined_on}
          onChange={(e) => setJoinedOn(e.target.value)}
        />
        <select
          style={{ width: '100%', marginTop: '10px', height: '40px' }}
          label='Supervisor *'
          name='supervisor'
          value={supervisor}
          onChange={(e) => setSupervisor(e.target.value)}
          disabled={status !== 'Active'}
        >
          <option value=''>Select</option>
          {departmentForEdit.supervisors.map((item) => (
            <option value={item.id}>{item.title}</option>
          ))}
        </select>
        <input
          type='date'
          style={{ width: '100%', marginTop: '10px', height: '40px' }}
          label='Left On'
          name='left_on'
          value={left_on}
          onChange={(e) => setLeftOn(e.target.value)}
          disabled={status !== 'Not Active'}
        />
        <select
          style={{ width: '100%', marginTop: '10px', height: '40px' }}
          label='ID Card Issued?'
          name='id_card'
          value={id_card}
          onChange={(e) => setIdCard(e.target.value)}
        >
          <option value=''>Select</option>
          {id_card === 'Issued' ? (
            <>
              <option value='Issued'>Issued</option>
              <option value='Not Issued'>Not Issued</option>
            </>
          ) : (
            <>
              <option value='Not Issued'>Not Issued</option>
              <option value='Issued'>Issued</option>
            </>
          )}
        </select>
        <input
          style={{ width: '100%', marginTop: '10px', height: '40px' }}
          type='text'
          placeholder='ID Card Number'
          label='ID Card Number'
          name='id_card_number'
          value={id_card_number}
          onChange={(e) => setIdCardNumber(e.target.value)}
          disabled={id_card !== 'Issued'}
        />
        <input
          type='date'
          style={{ width: '100%', marginTop: '10px', height: '40px' }}
          label='ID Card Issued On'
          name='id_card_issued_on'
          value={id_card_issued_on}
          onChange={(e) => setIdCardIssuedOn(e.target.value)}
          disabled={id_card !== 'Issued'}
        />
        <input
          type='date'
          style={{ width: '100%', marginTop: '10px', height: '40px' }}
          label='ID Card Expiry On'
          name='id_card_expiry_on'
          value={id_card_expiry_on}
          onChange={(e) => setIdCardExpiryOn(e.target.value)}
          disabled={id_card !== 'Issued'}
        />
        <select
          style={{ width: '100%', marginTop: '10px', height: '40px' }}
          label='ID Card Returned?'
          name='id_card_returned'
          value={id_card_returned}
          onChange={(e) => setIdCardReturned(e.target.value)}
          disabled={id_card !== 'Issued'}
        >
          <option value=''>Select</option>
          {id_card_returned ? (
            <>
              <option value='Returned'>Returned</option>
              <option value='Not Returned'>Not Returned</option>
            </>
          ) : (
            <>
              <option value='Not Returned'>Not Returned</option>
              <option value='Returned'>Returned</option>
            </>
          )}
        </select>
        <input
          type='date'
          style={{ width: '100%', marginTop: '10px', height: '40px' }}
          label='ID Card Returned On'
          name='id_card_returned_on'
          value={id_card_returned_on}
          onChange={(e) => setIdCardReturnedOn(e.target.validationMessage)}
          disabled={id_card_returned !== 'Returned'}
        />
        <Controls.Button
          type='submit'
          fullWidth
          text='submit'
          className={classes.submit}
          loading={loading}
          onClick={handleSubmit}
        />
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}

export default HREditDepartment
