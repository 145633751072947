import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Controls from './controls/Controls'
import Notification from './Notification'
import { Form, useForm } from './useForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  hrAddDepartmentEmployeeAction,
  hrGetEmployeeByIdAction,
  hrUploadDocAction,
} from '../actions/hrActions'
import {
  HR_ADD_DEPARTMENT_EMPLOYEE_RESET,
  HR_UPLOAD_DOC_EMPLOYEE_RESET,
} from '../constants/hrConstants'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  file: {
    marginTop: '20px',
  },
}))

const initialValues = {
  department: '',
  supervisor: '',
  joined_on: null,
}

const HRAddDepartment = ({
  setOpenAddEmpDepartmentsPopup,
  id,
  departments,
}) => {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

  const classes = useStyles()
  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('department' in fieldValues)
      temp.department =
        fieldValues.department.length !== 0 ? '' : 'This field is required'
    if ('supervisor' in fieldValues)
      temp.supervisor =
        fieldValues.supervisor.length !== 0 ? '' : 'This field is required'
    if ('joined_on' in fieldValues)
      temp.joined_on = fieldValues.joined_on ? '' : 'This field is required'
    setErrors({
      ...temp,
    })
    if (fieldValues === values) return Object.values(temp).every((x) => x == '')
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate)

  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      dispatch(
        hrAddDepartmentEmployeeAction(
          id,
          values.department,
          values.supervisor,
          values.joined_on
        )
      )
    }
    // if (!values.department || !values.joined_on) {
    //   setNotify({
    //     isOpen: true,
    //     message: 'All the fields are mandatory',
    //     type: 'error',
    //   })
    // } else {
    //   dispatch(
    //     hrAddDepartmentEmployeeAction(id, values.department, values.joined_on)
    //   )
    // }
  }

  const hrAddDepartment = useSelector((state) => state.hrAddDepartment)
  const { loading, error, addDepartmentEmployee } = hrAddDepartment

  useEffect(() => {
    dispatch({ type: HR_ADD_DEPARTMENT_EMPLOYEE_RESET })
    if (addDepartmentEmployee) {
      setNotify({
        isOpen: true,
        message: addDepartmentEmployee.msg,
        type: 'success',
      })
      setTimeout(() => {
        setOpenAddEmpDepartmentsPopup(false)
        dispatch(hrGetEmployeeByIdAction(id))
      }, 2000)
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [addDepartmentEmployee, error, dispatch, id])

  const [supervisors, setSupervisors] = useState([])

  const departmentChange = (e) => {
    setValues({
      ...values,
      department: e.target.value,
    })
    let departmentChosen = departments.filter((dpt) => dpt.id == e.target.value)
    setSupervisors(departmentChosen[0].supervisors)
  }

  return (
    <>
      <Form autoComplete='off' onSubmit={handleSubmit}>
        <Controls.Select
          label='Department *'
          name='department'
          value={values.department}
          onChange={departmentChange}
          error={errors.department}
          options={departments}
        />
        <Controls.Select
          label='Supervisor *'
          name='supervisor'
          value={values.supervisor}
          onChange={handleInputChange}
          error={errors.supervisor}
          options={supervisors}
        />
        <Controls.DatePicker
          label='Joined On *'
          name='joined_on'
          value={values.joined_on}
          onChange={handleInputChange}
          error={errors.joined_on}
        />
        <Controls.Button
          type='submit'
          fullWidth
          text='submit'
          className={classes.submit}
          loading={loading}
        />
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}

export default HRAddDepartment
