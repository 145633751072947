import {
  HR_DASHBOARD_REQUEST,
  HR_DASHBOARD_SUCCESS,
  HR_DASHBOARD_FAIL,
  HR_DASHBOARD_RESET,
  HR_ASEG_REQUEST,
  HR_ASEG_SUCCESS,
  HR_ASEG_FAIL,
  HR_ASEG_RESET,
  HR_ASEP_REQUEST,
  HR_ASEP_SUCCESS,
  HR_ASEP_FAIL,
  HR_ASEP_RESET,
  HR_AE_REQUEST,
  HR_AE_SUCCESS,
  HR_AE_FAIL,
  HR_AE_RESET,
  HR_NAE_REQUEST,
  HR_NAE_SUCCESS,
  HR_NAE_FAIL,
  HR_NAE_RESET,
  HR_GET_DEPARTMENTS_REQUEST,
  HR_GET_DEPARTMENTS_SUCCESS,
  HR_GET_DEPARTMENTS_FAIL,
  HR_GET_DEPARTMENTS_RESET,
  HR_ADD_DEPARTMENT_REQUEST,
  HR_ADD_DEPARTMENT_SUCCESS,
  HR_ADD_DEPARTMENT_FAIL,
  HR_ADD_DEPARTMENT_RESET,
  HR_UPDATE_DEPARTMENT_REQUEST,
  HR_UPDATE_DEPARTMENT_SUCCESS,
  HR_UPDATE_DEPARTMENT_FAIL,
  HR_UPDATE_DEPARTMENT_RESET,
  HR_GET_EMPLOYEE_BY_ID_REQUEST,
  HR_GET_EMPLOYEE_BY_ID_SUCCESS,
  HR_GET_EMPLOYEE_BY_ID_FAIL,
  HR_UPDATE_BASIC_INFO_EMPLOYEE_REQUEST,
  HR_UPDATE_BASIC_INFO_EMPLOYEE_SUCCESS,
  HR_UPDATE_BASIC_INFO_EMPLOYEE_FAIL,
  HR_UPDATE_BASIC_INFO_EMPLOYEE_RESET,
  HR_UPDATE_BANK_INFO_EMPLOYEE_REQUEST,
  HR_UPDATE_BANK_INFO_EMPLOYEE_SUCCESS,
  HR_UPDATE_BANK_INFO_EMPLOYEE_FAIL,
  HR_UPDATE_BANK_INFO_EMPLOYEE_RESET,
  HR_UPDATE_REFERENCE_INFO_EMPLOYEE_REQUEST,
  HR_UPDATE_REFERENCE_INFO_EMPLOYEE_SUCCESS,
  HR_UPDATE_REFERENCE_INFO_EMPLOYEE_FAIL,
  HR_UPDATE_REFERENCE_INFO_EMPLOYEE_RESET,
  HR_UPDATE_EDUCATION_INFO_EMPLOYEE_REQUEST,
  HR_UPDATE_EDUCATION_INFO_EMPLOYEE_SUCCESS,
  HR_UPDATE_EDUCATION_INFO_EMPLOYEE_FAIL,
  HR_UPDATE_EDUCATION_INFO_EMPLOYEE_RESET,
  HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_REQUEST,
  HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_SUCCESS,
  HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_FAIL,
  HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_RESET,
  HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_REQUEST,
  HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_SUCCESS,
  HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_FAIL,
  HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_RESET,
  HR_UPDATE_VEHICLE_INFO_EMPLOYEE_REQUEST,
  HR_UPDATE_VEHICLE_INFO_EMPLOYEE_SUCCESS,
  HR_UPDATE_VEHICLE_INFO_EMPLOYEE_FAIL,
  HR_UPDATE_VEHICLE_INFO_EMPLOYEE_RESET,
  HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_REQUEST,
  HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_SUCCESS,
  HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_FAIL,
  HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_RESET,
  HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_REQUEST,
  HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_SUCCESS,
  HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_FAIL,
  HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_RESET,
  HR_UPDATE_SETTINGS_INFO_EMPLOYEE_REQUEST,
  HR_UPDATE_SETTINGS_INFO_EMPLOYEE_SUCCESS,
  HR_UPDATE_SETTINGS_INFO_EMPLOYEE_FAIL,
  HR_UPDATE_SETTINGS_INFO_EMPLOYEE_RESET,
  HR_RESET_PASSWORD_INFO_EMPLOYEE_REQUEST,
  HR_RESET_PASSWORD_INFO_EMPLOYEE_SUCCESS,
  HR_RESET_PASSWORD_INFO_EMPLOYEE_FAIL,
  HR_RESET_PASSWORD_INFO_EMPLOYEE_RESET,
  HR_RESET_DEVICE_ID_INFO_EMPLOYEE_REQUEST,
  HR_RESET_DEVICE_ID_INFO_EMPLOYEE_SUCCESS,
  HR_RESET_DEVICE_ID_INFO_EMPLOYEE_FAIL,
  HR_RESET_DEVICE_ID_INFO_EMPLOYEE_RESET,
  HR_ASSIGN_EXTENSION_EMPLOYEE_REQUEST,
  HR_ASSIGN_EXTENSION_EMPLOYEE_SUCCESS,
  HR_ASSIGN_EXTENSION_EMPLOYEE_FAIL,
  HR_ASSIGN_EXTENSION_EMPLOYEE_RESET,
  HR_REMOVE_EXTENSION_EMPLOYEE_REQUEST,
  HR_REMOVE_EXTENSION_EMPLOYEE_SUCCESS,
  HR_REMOVE_EXTENSION_EMPLOYEE_FAIL,
  HR_REMOVE_EXTENSION_EMPLOYEE_RESET,
  HR_ACTIVATE_EXTENSION_EMPLOYEE_REQUEST,
  HR_ACTIVATE_EXTENSION_EMPLOYEE_SUCCESS,
  HR_ACTIVATE_EXTENSION_EMPLOYEE_FAIL,
  HR_ACTIVATE_EXTENSION_EMPLOYEE_RESET,
  HR_DEACTIVATE_EXTENSION_EMPLOYEE_REQUEST,
  HR_DEACTIVATE_EXTENSION_EMPLOYEE_SUCCESS,
  HR_DEACTIVATE_EXTENSION_EMPLOYEE_FAIL,
  HR_DEACTIVATE_EXTENSION_EMPLOYEE_RESET,
  HR_UPLOAD_DOC_EMPLOYEE_REQUEST,
  HR_UPLOAD_DOC_EMPLOYEE_SUCCESS,
  HR_UPLOAD_DOC_EMPLOYEE_FAIL,
  HR_UPLOAD_DOC_EMPLOYEE_RESET,
  HR_DELETE_DOC_EMPLOYEE_REQUEST,
  HR_DELETE_DOC_EMPLOYEE_SUCCESS,
  HR_DELETE_DOC_EMPLOYEE_FAIL,
  HR_DELETE_DOC_EMPLOYEE_RESET,
  HR_DOWNLOAD_DOC_EMPLOYEE_REQUEST,
  HR_DOWNLOAD_DOC_EMPLOYEE_SUCCESS,
  HR_DOWNLOAD_DOC_EMPLOYEE_FAIL,
  HR_DOWNLOAD_DOC_EMPLOYEE_RESET,
  HR_ADD_DEPARTMENT_EMPLOYEE_REQUEST,
  HR_ADD_DEPARTMENT_EMPLOYEE_SUCCESS,
  HR_ADD_DEPARTMENT_EMPLOYEE_FAIL,
  HR_ADD_DEPARTMENT_EMPLOYEE_RESET,
  HR_UPDATE_DEPARTMENT_EMPLOYEE_REQUEST,
  HR_UPDATE_DEPARTMENT_EMPLOYEE_SUCCESS,
  HR_UPDATE_DEPARTMENT_EMPLOYEE_FAIL,
  HR_UPDATE_DEPARTMENT_EMPLOYEE_RESET,
  HR_EXIT_EMPLOYEE_REQUEST,
  HR_EXIT_EMPLOYEE_SUCCESS,
  HR_EXIT_EMPLOYEE_FAIL,
  HR_EXIT_EMPLOYEE_RESET,
  HR_UPDATE_ATTENDANCE_REQUEST,
  HR_UPDATE_ATTENDANCE_SUCCESS,
  HR_UPDATE_ATTENDANCE_FAIL,
  HR_UPDATE_ATTENDANCE_RESET,
  HR_GET_EVERYDAY_ATTENDANCE_REQUEST,
  HR_GET_EVERYDAY_ATTENDANCE_SUCCESS,
  HR_GET_EVERYDAY_ATTENDANCE_FAIL,
  HR_GET_EVERYDAY_ATTENDANCE_RESET,
  GET_TODAY_ATTENDANCE_RESET,
  GET_TODAY_ATTENDANCE_REQUEST,
  GET_TODAY_ATTENDANCE_SUCCESS,
  GET_TODAY_ATTENDANCE_FAIL,
  UPDATE_ATTENDANCE_REQUEST,
  UPDATE_ATTENDANCE_SUCCESS,
  UPDATE_ATTENDANCE_FAIL,
  UPDATE_ATTENDANCE_RESET,
  SEARCH_ATTENDANCE_REQUEST,
  SEARCH_ATTENDANCE_SUCCESS,
  SEARCH_ATTENDANCE_FAIL,
  SEARCH_ATTENDANCE_RESET,
  FINALIZE_ATTENDANCE_REQUEST,
  FINALIZE_ATTENDANCE_SUCCESS,
  FINALIZE_ATTENDANCE_FAIL,
  FINALIZE_ATTENDANCE_RESET,
  UPDATE_FINALIZE_ATTENDANCE_REQUEST,
  UPDATE_FINALIZE_ATTENDANCE_SUCCESS,
  UPDATE_FINALIZE_ATTENDANCE_FAIL,
  UPDATE_FINALIZE_ATTENDANCE_RESET,
  HR_GET_MASTER_DEPARTMENTS_REQUEST,
  HR_GET_MASTER_DEPARTMENTS_SUCCESS,
  HR_GET_MASTER_DEPARTMENTS_FAIL,
  HR_GET_MASTER_DEPARTMENTS_RESET,
  HR_ADD_MASTER_DEPARTMENT_REQUEST,
  HR_ADD_MASTER_DEPARTMENT_SUCCESS,
  HR_ADD_MASTER_DEPARTMENT_FAIL,
  HR_ADD_MASTER_DEPARTMENT_RESET,
  HR_UPDATE_MASTER_DEPARTMENT_REQUEST,
  HR_UPDATE_MASTER_DEPARTMENT_SUCCESS,
  HR_UPDATE_MASTER_DEPARTMENT_FAIL,
  HR_UPDATE_MASTER_DEPARTMENT_RESET,
  PROCESS_FINALIZE_ATTENDANCE_REQUEST,
  PROCESS_FINALIZE_ATTENDANCE_SUCCESS,
  PROCESS_FINALIZE_ATTENDANCE_FAIL,
  PROCESS_FINALIZE_ATTENDANCE_RESET,
  CREATE_EMPLOYEE_PDF_REQUEST,
  CREATE_EMPLOYEE_PDF_SUCCESS,
  HR_REJOIN_EMPLOYEE_REQUEST,
  HR_REJOIN_EMPLOYEE_SUCCESS,
  HR_REJOIN_EMPLOYEE_FAIL,
  HR_REJOIN_EMPLOYEE_RESET,
  HR_REPORTS_REQUEST,
  HR_REPORTS_SUCCESS,
  HR_REPORTS_FAIL,
  HR_REPORTS_RESET,
  HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_REQUEST,
  HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_SUCCESS,
  HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_FAIL,
  HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_RESET,
  HR_ATTENDANCE_REPORTS_REQUEST,
  HR_ATTENDANCE_REPORTS_SUCCESS,
  HR_ATTENDANCE_REPORTS_FAIL,
  HR_ATTENDANCE_REPORTS_RESET,
} from '../constants/hrConstants'

export const hrDashboardReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_DASHBOARD_REQUEST:
      return { loading: true }
    case HR_DASHBOARD_SUCCESS:
      return { loading: false, hrData: action.payload }
    case HR_DASHBOARD_FAIL:
      return { loading: false, error: action.payload }
    case HR_DASHBOARD_RESET:
      return {}
    default:
      return state
  }
}

export const hrAsegReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_ASEG_REQUEST:
      return { loading: true }
    case HR_ASEG_SUCCESS:
      return { loading: false, hrAddEmployeeGet: action.payload }
    case HR_ASEG_FAIL:
      return { loading: false, error: action.payload }
    case HR_ASEG_RESET:
      return {}
    default:
      return state
  }
}

export const hrAsepReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_ASEP_REQUEST:
      return { loading1: true }
    case HR_ASEP_SUCCESS:
      return { loading1: false, hrAddEmployeePost: action.payload }
    case HR_ASEP_FAIL:
      return { loading1: false, error1: action.payload }
    case HR_ASEP_RESET:
      return {}
    default:
      return state
  }
}

export const hrAeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_AE_REQUEST:
      return { loading: true }
    case HR_AE_SUCCESS:
      return { loading: false, hrActiveEmployees: action.payload }
    case HR_AE_FAIL:
      return { loading: false, error: action.payload }
    case HR_AE_RESET:
      return {}
    default:
      return state
  }
}

export const hrNaeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_NAE_REQUEST:
      return { loading: true }
    case HR_NAE_SUCCESS:
      return { loading: false, hrNotActiveEmployees: action.payload }
    case HR_NAE_FAIL:
      return { loading: false, error: action.payload }
    case HR_NAE_RESET:
      return {}
    default:
      return state
  }
}

export const hrDepartmentsReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_GET_DEPARTMENTS_REQUEST:
      return { loading: true }
    case HR_GET_DEPARTMENTS_SUCCESS:
      return { loading: false, hrDepartments: action.payload }
    case HR_GET_DEPARTMENTS_FAIL:
      return { loading: false, error: action.payload }
    case HR_GET_DEPARTMENTS_RESET:
      return {}
    default:
      return state
  }
}

export const hrAddDepartmentReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_ADD_DEPARTMENT_REQUEST:
      return { loading: true }
    case HR_ADD_DEPARTMENT_SUCCESS:
      return { loading: false, addDepartment: action.payload }
    case HR_ADD_DEPARTMENT_FAIL:
      return { loading: false, error: action.payload }
    case HR_ADD_DEPARTMENT_RESET:
      return {}
    default:
      return state
  }
}

export const hrUpdateDepartmentReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_UPDATE_DEPARTMENT_REQUEST:
      return { loading1: true }
    case HR_UPDATE_DEPARTMENT_SUCCESS:
      return { loading1: false, updateDepartment: action.payload }
    case HR_UPDATE_DEPARTMENT_FAIL:
      return { loading1: false, error1: action.payload }
    case HR_UPDATE_DEPARTMENT_RESET:
      return {}
    default:
      return state
  }
}

export const hrGetEmployeeByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_GET_EMPLOYEE_BY_ID_REQUEST:
      return { loading: true }
    case HR_GET_EMPLOYEE_BY_ID_SUCCESS:
      return { loading: false, hrGetEmp: action.payload }
    case HR_GET_EMPLOYEE_BY_ID_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const hrUpdateBasicInfoEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_UPDATE_BASIC_INFO_EMPLOYEE_REQUEST:
      return { loading1: true }
    case HR_UPDATE_BASIC_INFO_EMPLOYEE_SUCCESS:
      return { loading1: false, updateBasicInfoEmployee: action.payload }
    case HR_UPDATE_BASIC_INFO_EMPLOYEE_FAIL:
      return { loading1: false, error1: action.payload }
    case HR_UPDATE_BASIC_INFO_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrUpdateBankInfoEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_UPDATE_BANK_INFO_EMPLOYEE_REQUEST:
      return { loading2: true }
    case HR_UPDATE_BANK_INFO_EMPLOYEE_SUCCESS:
      return { loading2: false, updateBankInfoEmployee: action.payload }
    case HR_UPDATE_BANK_INFO_EMPLOYEE_FAIL:
      return { loading2: false, error2: action.payload }
    case HR_UPDATE_BANK_INFO_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrUpdateReferenceInfoEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_UPDATE_REFERENCE_INFO_EMPLOYEE_REQUEST:
      return { loading3: true }
    case HR_UPDATE_REFERENCE_INFO_EMPLOYEE_SUCCESS:
      return { loading3: false, updateReferenceInfoEmployee: action.payload }
    case HR_UPDATE_REFERENCE_INFO_EMPLOYEE_FAIL:
      return { loading3: false, error3: action.payload }
    case HR_UPDATE_REFERENCE_INFO_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrUpdateEducationInfoEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_UPDATE_EDUCATION_INFO_EMPLOYEE_REQUEST:
      return { loading4: true }
    case HR_UPDATE_EDUCATION_INFO_EMPLOYEE_SUCCESS:
      return { loading4: false, updateEducationInfoEmployee: action.payload }
    case HR_UPDATE_EDUCATION_INFO_EMPLOYEE_FAIL:
      return { loading4: false, error4: action.payload }
    case HR_UPDATE_EDUCATION_INFO_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrUpdateEmployeeInfoEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_REQUEST:
      return { loading5: true }
    case HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_SUCCESS:
      return { loading5: false, updateEmployeeInfoEmployee: action.payload }
    case HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_FAIL:
      return { loading5: false, error5: action.payload }
    case HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrUpdateExperienceInfoEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_REQUEST:
      return { loading6: true }
    case HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_SUCCESS:
      return { loading6: false, updateExperienceInfoEmployee: action.payload }
    case HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_FAIL:
      return { loading6: false, error6: action.payload }
    case HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrUpdateVehicleInfoEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_UPDATE_VEHICLE_INFO_EMPLOYEE_REQUEST:
      return { loading7: true }
    case HR_UPDATE_VEHICLE_INFO_EMPLOYEE_SUCCESS:
      return { loading7: false, updateVehicleInfoEmployee: action.payload }
    case HR_UPDATE_VEHICLE_INFO_EMPLOYEE_FAIL:
      return { loading7: false, error7: action.payload }
    case HR_UPDATE_VEHICLE_INFO_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrUpdateEmergencyInfoEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_REQUEST:
      return { loading8: true }
    case HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_SUCCESS:
      return { loading8: false, updateEmergencyInfoEmployee: action.payload }
    case HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_FAIL:
      return { loading8: false, error8: action.payload }
    case HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrUpdateDocumentsInfoEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_REQUEST:
      return { loading9: true }
    case HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_SUCCESS:
      return { loading9: false, updateDocumentsInfoEmployee: action.payload }
    case HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_FAIL:
      return { loading9: false, error9: action.payload }
    case HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrUpdateSettingsInfoEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_UPDATE_SETTINGS_INFO_EMPLOYEE_REQUEST:
      return { loading10: true }
    case HR_UPDATE_SETTINGS_INFO_EMPLOYEE_SUCCESS:
      return { loading10: false, updateSettingsInfoEmployee: action.payload }
    case HR_UPDATE_SETTINGS_INFO_EMPLOYEE_FAIL:
      return { loading10: false, error10: action.payload }
    case HR_UPDATE_SETTINGS_INFO_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrResetPasswordInfoEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_RESET_PASSWORD_INFO_EMPLOYEE_REQUEST:
      return { loading11: true }
    case HR_RESET_PASSWORD_INFO_EMPLOYEE_SUCCESS:
      return { loading11: false, resetPasswordInfoEmployee: action.payload }
    case HR_RESET_PASSWORD_INFO_EMPLOYEE_FAIL:
      return { loading11: false, error11: action.payload }
    case HR_RESET_PASSWORD_INFO_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrResetDeviceIDInfoEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_RESET_DEVICE_ID_INFO_EMPLOYEE_REQUEST:
      return { loading12: true }
    case HR_RESET_DEVICE_ID_INFO_EMPLOYEE_SUCCESS:
      return { loading12: false, resetDeviceIDInfoEmployee: action.payload }
    case HR_RESET_DEVICE_ID_INFO_EMPLOYEE_FAIL:
      return { loading12: false, error12: action.payload }
    case HR_RESET_DEVICE_ID_INFO_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrAssignExtensionEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_ASSIGN_EXTENSION_EMPLOYEE_REQUEST:
      return { loading: true }
    case HR_ASSIGN_EXTENSION_EMPLOYEE_SUCCESS:
      return { loading: false, assignExtensionInfoEmployee: action.payload }
    case HR_ASSIGN_EXTENSION_EMPLOYEE_FAIL:
      return { loading: false, error: action.payload }
    case HR_ASSIGN_EXTENSION_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrRemoveExtensionEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_REMOVE_EXTENSION_EMPLOYEE_REQUEST:
      return { loading13: true }
    case HR_REMOVE_EXTENSION_EMPLOYEE_SUCCESS:
      return { loading13: false, removeExtensionInfoEmployee: action.payload }
    case HR_REMOVE_EXTENSION_EMPLOYEE_FAIL:
      return { loading13: false, error13: action.payload }
    case HR_REMOVE_EXTENSION_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrActivateExtensionEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_ACTIVATE_EXTENSION_EMPLOYEE_REQUEST:
      return { loading14: true }
    case HR_ACTIVATE_EXTENSION_EMPLOYEE_SUCCESS:
      return { loading14: false, activateExtensionInfoEmployee: action.payload }
    case HR_ACTIVATE_EXTENSION_EMPLOYEE_FAIL:
      return { loading14: false, error14: action.payload }
    case HR_ACTIVATE_EXTENSION_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrDeactivateExtensionEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_DEACTIVATE_EXTENSION_EMPLOYEE_REQUEST:
      return { loading15: true }
    case HR_DEACTIVATE_EXTENSION_EMPLOYEE_SUCCESS:
      return {
        loading15: false,
        deactivateExtensionInfoEmployee: action.payload,
      }
    case HR_DEACTIVATE_EXTENSION_EMPLOYEE_FAIL:
      return { loading15: false, error15: action.payload }
    case HR_DEACTIVATE_EXTENSION_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrUploadDocEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_UPLOAD_DOC_EMPLOYEE_REQUEST:
      return { loading: true }
    case HR_UPLOAD_DOC_EMPLOYEE_SUCCESS:
      return {
        loading: false,
        uploadDocEmployee: action.payload,
      }
    case HR_UPLOAD_DOC_EMPLOYEE_FAIL:
      return { loading: false, error: action.payload }
    case HR_UPLOAD_DOC_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrDeleteDocEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_DELETE_DOC_EMPLOYEE_REQUEST:
      return { loading16: true }
    case HR_DELETE_DOC_EMPLOYEE_SUCCESS:
      return {
        loading16: false,
        deleteDocEmployee: action.payload,
      }
    case HR_DELETE_DOC_EMPLOYEE_FAIL:
      return { loading16: false, error16: action.payload }
    case HR_DELETE_DOC_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrDownloadDocEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_DOWNLOAD_DOC_EMPLOYEE_REQUEST:
      return { loading17: true }
    case HR_DOWNLOAD_DOC_EMPLOYEE_SUCCESS:
      return {
        loading17: false,
        downloadDocEmployee: action.payload,
      }
    case HR_DOWNLOAD_DOC_EMPLOYEE_FAIL:
      return { loading17: false, error17: action.payload }
    case HR_DOWNLOAD_DOC_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrAddDepartmentEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_ADD_DEPARTMENT_EMPLOYEE_REQUEST:
      return { loading: true }
    case HR_ADD_DEPARTMENT_EMPLOYEE_SUCCESS:
      return {
        loading: false,
        addDepartmentEmployee: action.payload,
      }
    case HR_ADD_DEPARTMENT_EMPLOYEE_FAIL:
      return { loading: false, error: action.payload }
    case HR_ADD_DEPARTMENT_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrUpdateDepartmentEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_UPDATE_DEPARTMENT_EMPLOYEE_REQUEST:
      return { loading: true }
    case HR_UPDATE_DEPARTMENT_EMPLOYEE_SUCCESS:
      return {
        loading: false,
        updateDepartmentEmployee: action.payload,
      }
    case HR_UPDATE_DEPARTMENT_EMPLOYEE_FAIL:
      return { loading: false, error: action.payload }
    case HR_UPDATE_DEPARTMENT_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrMakeDepartmentPrimaryEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_REQUEST:
      return { loading20: true }
    case HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_SUCCESS:
      return {
        loading20: false,
        makeDepartmentPrimaryEmployee: action.payload,
      }
    case HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_FAIL:
      return { loading20: false, error20: action.payload }
    case HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrExitEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_EXIT_EMPLOYEE_REQUEST:
      return { loading: true }
    case HR_EXIT_EMPLOYEE_SUCCESS:
      return {
        loading: false,
        exitEmployee: action.payload,
      }
    case HR_EXIT_EMPLOYEE_FAIL:
      return { loading: false, error: action.payload }
    case HR_EXIT_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const hrRejoinEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_REJOIN_EMPLOYEE_REQUEST:
      return { loading: true }
    case HR_REJOIN_EMPLOYEE_SUCCESS:
      return {
        loading: false,
        rejoinEmployee: action.payload,
      }
    case HR_REJOIN_EMPLOYEE_FAIL:
      return { loading: false, error: action.payload }
    case HR_REJOIN_EMPLOYEE_RESET:
      return {}
    default:
      return state
  }
}

export const todaysAttendanceReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TODAY_ATTENDANCE_REQUEST:
      return { loading: true }
    case GET_TODAY_ATTENDANCE_SUCCESS:
      return { loading: false, attendance: action.payload }
    case GET_TODAY_ATTENDANCE_FAIL:
      return { loading: false, error: action.payload }
    case GET_TODAY_ATTENDANCE_RESET:
      return {}
    default:
      return state
  }
}

export const updateAttendanceReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ATTENDANCE_REQUEST:
      return { loading: true }
    case UPDATE_ATTENDANCE_SUCCESS:
      return { loading: false, updAttendance: action.payload }
    case UPDATE_ATTENDANCE_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_ATTENDANCE_RESET:
      return {}
    default:
      return state
  }
}

export const searchAttendanceReducer = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_ATTENDANCE_REQUEST:
      return { loading1: true }
    case SEARCH_ATTENDANCE_SUCCESS:
      return { loading1: false, results: action.payload }
    case SEARCH_ATTENDANCE_FAIL:
      return { loading1: false, error1: action.payload }
    case SEARCH_ATTENDANCE_RESET:
      return {}
    default:
      return state
  }
}

export const finalizeAttendanceReducer = (state = {}, action) => {
  switch (action.type) {
    case FINALIZE_ATTENDANCE_REQUEST:
      return { loading: true }
    case FINALIZE_ATTENDANCE_SUCCESS:
      return { loading: false, finlaizedAttendances: action.payload }
    case FINALIZE_ATTENDANCE_FAIL:
      return { loading: false, error: action.payload }
    case FINALIZE_ATTENDANCE_RESET:
      return {}
    default:
      return state
  }
}

export const updateFinalizeAttendanceReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_FINALIZE_ATTENDANCE_REQUEST:
      return { loading: true }
    case UPDATE_FINALIZE_ATTENDANCE_SUCCESS:
      return { loading: false, updateFinlaizedAttendances: action.payload }
    case UPDATE_FINALIZE_ATTENDANCE_FAIL:
      return { loading: false, error: action.payload }
    case UPDATE_FINALIZE_ATTENDANCE_RESET:
      return {}
    default:
      return state
  }
}

export const processFinalizeAttendanceReducer = (state = {}, action) => {
  switch (action.type) {
    case PROCESS_FINALIZE_ATTENDANCE_REQUEST:
      return { loading1: true }
    case PROCESS_FINALIZE_ATTENDANCE_SUCCESS:
      return { loading1: false, processFinlaizedAttendances: action.payload }
    case PROCESS_FINALIZE_ATTENDANCE_FAIL:
      return { loading1: false, error1: action.payload }
    case PROCESS_FINALIZE_ATTENDANCE_RESET:
      return {}
    default:
      return state
  }
}

export const hrMasterDepartmentsReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_GET_MASTER_DEPARTMENTS_REQUEST:
      return { loading: true }
    case HR_GET_MASTER_DEPARTMENTS_SUCCESS:
      return { loading: false, hrMasterDepartments: action.payload }
    case HR_GET_MASTER_DEPARTMENTS_FAIL:
      return { loading: false, error: action.payload }
    case HR_GET_MASTER_DEPARTMENTS_RESET:
      return {}
    default:
      return state
  }
}

export const hrAddMasterDepartmentReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_ADD_MASTER_DEPARTMENT_REQUEST:
      return { loading: true }
    case HR_ADD_MASTER_DEPARTMENT_SUCCESS:
      return { loading: false, addMasterDepartment: action.payload }
    case HR_ADD_MASTER_DEPARTMENT_FAIL:
      return { loading: false, error: action.payload }
    case HR_ADD_MASTER_DEPARTMENT_RESET:
      return {}
    default:
      return state
  }
}

export const hrUpdateMasterDepartmentReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_UPDATE_MASTER_DEPARTMENT_REQUEST:
      return { loading1: true }
    case HR_UPDATE_MASTER_DEPARTMENT_SUCCESS:
      return { loading1: false, updateMasterDepartment: action.payload }
    case HR_UPDATE_MASTER_DEPARTMENT_FAIL:
      return { loading1: false, error1: action.payload }
    case HR_UPDATE_MASTER_DEPARTMENT_RESET:
      return {}
    default:
      return state
  }
}

export const hrReportsReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_REPORTS_REQUEST:
      return { loading: true }
    case HR_REPORTS_SUCCESS:
      return { loading: false, report: action.payload }
    case HR_REPORTS_FAIL:
      return { loading: false, error: action.payload }
    case HR_REPORTS_RESET:
      return {}
    default:
      return state
  }
}

export const hrAttendanceReportsReducer = (state = {}, action) => {
  switch (action.type) {
    case HR_ATTENDANCE_REPORTS_REQUEST:
      return { loading: true }
    case HR_ATTENDANCE_REPORTS_SUCCESS:
      return { loading: false, report: action.payload }
    case HR_ATTENDANCE_REPORTS_FAIL:
      return { loading: false, error: action.payload }
    case HR_ATTENDANCE_REPORTS_RESET:
      return {}
    default:
      return state
  }
}
