import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Controls from './controls/Controls'
import Notification from './Notification'
import { Form, useForm } from './useForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  hrAddDepartmentAction,
  hrAddMasterDepartmentAction,
  hrDepartmentsAction,
  hrMasterDepartmentsAction,
  hrUpdateDepartmentAction,
  hrUpdateMasterDepartmentAction,
} from '../actions/hrActions'
import {
  HR_ADD_DEPARTMENT_RESET,
  HR_ADD_MASTER_DEPARTMENT_RESET,
  HR_UPDATE_DEPARTMENT_RESET,
  HR_UPDATE_MASTER_DEPARTMENT_RESET,
} from '../constants/hrConstants'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const initialValues = {
  id: '',
  name: '',
}

const AddMasterDepartment = ({ setOpenPopup, departmentForEdit }) => {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

  const classes = useStyles()
  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('name' in fieldValues)
      temp.name = fieldValues.name ? '' : 'This field is required'
    setErrors({
      ...temp,
    })
    if (fieldValues === values) return Object.values(temp).every((x) => x == '')
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate)

  const dispatch = useDispatch()
  const hrAddMasterDpt = useSelector((state) => state.hrAddMasterDpt)
  const { loading, error, addMasterDepartment } = hrAddMasterDpt

  const hrUpdateMasterDpt = useSelector((state) => state.hrUpdateMasterDpt)
  const { loading1, error1, updateMasterDepartment } = hrUpdateMasterDpt

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      if (departmentForEdit) {
        dispatch(hrUpdateMasterDepartmentAction(values.id, values.name))
      } else {
        dispatch(hrAddMasterDepartmentAction(values.name))
      }
    }
  }

  useEffect(() => {
    dispatch({ type: HR_ADD_MASTER_DEPARTMENT_RESET })
    if (addMasterDepartment) {
      setNotify({
        isOpen: true,
        message: addMasterDepartment.msg,
        type: 'success',
      })
      setTimeout(() => {
        setOpenPopup(false)
        dispatch(hrMasterDepartmentsAction())
      }, 2000)
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [dispatch, addMasterDepartment, error])

  useEffect(() => {
    dispatch({ type: HR_UPDATE_MASTER_DEPARTMENT_RESET })
    if (updateMasterDepartment) {
      setNotify({
        isOpen: true,
        message: updateMasterDepartment.msg,
        type: 'success',
      })
      setTimeout(() => {
        setOpenPopup(false)
        dispatch(hrMasterDepartmentsAction())
      }, 2000)
    } else if (error1) {
      setNotify({
        isOpen: true,
        message: error1,
        type: 'error',
      })
    }
  }, [dispatch, updateMasterDepartment, error1])

  useEffect(() => {
    if (departmentForEdit != null) {
      setValues({
        ...departmentForEdit,
      })
    }
  }, [departmentForEdit])

  return (
    <>
      <Form autoComplete='off' onSubmit={handleSubmit}>
        <Controls.Input
          label='Department Name'
          name='name'
          value={values.name}
          onChange={handleInputChange}
          error={errors.name}
        />
        <Controls.Button
          type='submit'
          fullWidth
          text='submit'
          className={classes.submit}
          loading={loading || loading1}
        />
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}

export default AddMasterDepartment
