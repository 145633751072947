import {
  makeStyles,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  finalizeAttendanceAction,
  processFinalizeAttendanceAction,
} from '../actions/hrActions'
import Notification from '../components/Notification'
import useTable from '../components/useTable'
import Controls from '../components/controls/Controls'
import PageHeader from '../components/PageHeader'
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import { useForm, Form } from '../components/useForm'
import {
  FINALIZE_ATTENDANCE_RESET,
  PROCESS_FINALIZE_ATTENDANCE_RESET,
} from '../constants/hrConstants'
import Popup from '../components/Popup'
import UpdateAttendanceFinalize from '../components/UpdateAttendanceFinalize'

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  searchInput: {
    width: '75%',
  },
  link: {
    textDecoration: 'none',
  },
  submit: {
    margin: theme.spacing(2, 0, 0),
  },
}))

const months = [
  { id: '01', title: 'January' },
  { id: '02', title: 'February' },
  { id: '03', title: 'March' },
  { id: '04', title: 'April' },
  { id: '05', title: 'May' },
  { id: '06', title: 'June' },
  { id: '07', title: 'July' },
  { id: '08', title: 'August' },
  { id: '09', title: 'September' },
  { id: '10', title: 'October' },
  { id: '11', title: 'November' },
  { id: '12', title: 'December' },
]

const headCells = [
  {
    id: 'employeeCode',
    label: 'Employee Code',
  },
  {
    id: 'employeeName',
    label: 'Employee Name',
  },
  {
    id: 'approved',
    label: 'Total Approved',
  },
  {
    id: 'onTime',
    label: 'On Time',
  },
  {
    id: 'late',
    label: 'Late',
  },
  {
    id: 'calcBeforeAdjustment',
    label: 'Before Ajustment',
  },
  {
    id: 'deduction',
    label: 'Deduction',
  },
  {
    id: 'leavesAvailable',
    label: 'Leaves Available',
  },
  {
    id: 'update',
    label: 'Update',
  },
  {
    id: 'hrFinal',
    label: 'HR Final',
  },
]

const initialValues = {
  year: '',
  month: '',
}

const FinalizeAttendance = ({ history }) => {
  const classes = useStyles()

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('month' in fieldValues)
      temp.month = fieldValues.month !== 0 ? '' : 'This field is required'
    if ('year' in fieldValues)
      temp.year = fieldValues.year ? '' : 'This field is required'
    setErrors({
      ...temp,
    })
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '')
  }

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  )

  const [attendances, setAttendances] = useState([])
  const [results, setResults] = useState({
    month: '',
    year: '',
    holidays: '',
    sundays: '',
    total_days: '',
    to_work_days: '',
    ready_to_process: false,
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      dispatch(finalizeAttendanceAction(values.month, values.year))
    }
  }

  const finalizeAttendance = useSelector((state) => state.finalizeAttendance)
  const { loading, error, finlaizedAttendances } = finalizeAttendance

  useEffect(() => {
    dispatch({ type: FINALIZE_ATTENDANCE_RESET })
    if (finlaizedAttendances) {
      setAttendances(finlaizedAttendances.data)
      setResults({
        month: finlaizedAttendances.month,
        year: finlaizedAttendances.year,
        holidays: Number(finlaizedAttendances.holidays),
        sundays: Number(finlaizedAttendances.sundays),
        total_days: Number(finlaizedAttendances.total_days),
        to_work_days: Number(finlaizedAttendances.to_work_days),
        ready_to_process: finlaizedAttendances.readyToProcess,
      })
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [finlaizedAttendances, error])

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items
    },
  })

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(attendances, headCells, filterFn)

  const [openPopup, setOpenPopup] = useState(false)
  const [attendanceForUpdate, setAttendanceForUpdate] = useState(null)

  const openInPopup = (item) => {
    setAttendanceForUpdate(item)
    setOpenPopup(true)
  }

  const process = () => {
    dispatch(processFinalizeAttendanceAction(results.year, results.month))
  }

  const processFinalizeAttendance = useSelector(
    (state) => state.processFinalizeAttendance
  )
  const { loading1, error1, processFinlaizedAttendances } =
    processFinalizeAttendance

  useEffect(() => {
    dispatch({ type: PROCESS_FINALIZE_ATTENDANCE_RESET })
    if (processFinlaizedAttendances) {
      setNotify({
        isOpen: true,
        message: processFinlaizedAttendances.msg,
        type: 'success',
      })
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } else if (error1) {
      setNotify({
        isOpen: true,
        message: error1,
        type: 'error',
      })
    }
  }, [processFinlaizedAttendances, error])

  return (
    <div>
      <>
        <PageHeader
          title='Finalize Attendance'
          subtitle='Shows summary of attendance of employees working in the organisations'
          icon={<PeopleOutlineTwoToneIcon fontSize='medium' />}
        />
        <Paper className={classes.pageContent}>
          <Grid container spacing={3}>
            <Grid item>
              <Typography>Search</Typography>
            </Grid>
            <Grid sm item />
          </Grid>
          <Form autoComplete='off' onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4}>
                <Controls.Select
                  label='Month *'
                  name='month'
                  fullWidth
                  value={values.month}
                  onChange={handleInputChange}
                  options={months}
                  error={errors.month}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Controls.Input
                  label='Year *'
                  name='year'
                  fullWidth
                  value={values.year}
                  onChange={handleInputChange}
                  error={errors.year}
                  type='number'
                  inputProps={{
                    min: 0,
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Controls.Button
                  type='submit'
                  text='Search'
                  className={classes.submit}
                  loading={loading}
                />
              </Grid>
            </Grid>
          </Form>
        </Paper>
        <Paper className={classes.pageContent}>
          {attendances.length > 0 && (
            <Grid container>
              <Grid item xs={6} lg={2}>
                <Typography>Total Days: {results.total_days}</Typography>
              </Grid>
              <Grid item xs={6} lg={2}>
                <Typography>Working Days: {results.to_work_days}</Typography>
              </Grid>
              <Grid item xs={6} lg={2}>
                <Typography>Sundays: {results.sundays}</Typography>
              </Grid>
              <Grid item xs={6} lg={2}>
                <Typography>Holidays: {results.holidays}</Typography>
              </Grid>
              <Grid sm item />
              {results.ready_to_process && (
                <Grid item xs={6} lg={2} style={{ marginRight: -55 }}>
                  <Controls.Button
                    color='warning'
                    text='Process'
                    onClick={process}
                  />
                </Grid>
              )}
            </Grid>
          )}
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <a href={`/hr/employee/${item.id}`} target='_blank'>
                      {item.employeeCode}
                    </a>
                  </TableCell>
                  <TableCell>{item.employeeName}</TableCell>
                  <TableCell>{item.approved}</TableCell>
                  <TableCell>{item.onTime}</TableCell>
                  <TableCell>{item.late}</TableCell>
                  <TableCell>{item.beforeAdjustment}</TableCell>
                  <TableCell>{item.deduction}</TableCell>
                  <TableCell>{item.leavesAvailable}</TableCell>
                  <TableCell>
                    <Controls.Button
                      text='Update'
                      onClick={() => openInPopup(item)}
                    />
                  </TableCell>
                  <TableCell>{item.hrFinal}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Paper>
        <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} title='Update'>
          <UpdateAttendanceFinalize
            attendanceForUpdate={attendanceForUpdate}
            results={results}
            setOpenPopup={setOpenPopup}
          />
        </Popup>
      </>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  )
}

export default FinalizeAttendance
