import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { extensionsAction } from '../actions/callingActions'
import Notification from '../components/Notification'
import Progress from '../components/Progress'
import Controls from '../components/controls/Controls'
import Popup from '../components/Popup'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import AddExtension from '../components/AddExtension'
import { EXTENSIONS_RESET } from '../constants/callingConstants'
import PageHeader from '../components/PageHeader'
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import MaterialTable from 'material-table'

const Extensions = ({ history }) => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const extensionsData = useSelector((state) => state.extensionsData)
  const { loading, error, exts } = extensionsData

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const [extensions, setExtensions] = useState([])

  useEffect(() => {
    dispatch(extensionsAction())
  }, [])

  useEffect(() => {
    dispatch({ type: EXTENSIONS_RESET })
    if (exts) {
      setExtensions(exts.extensions)
      if (exts.role !== 'Admin') {
        history.push('/dashboard')
      }
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [exts, error])

  const [openPopup, setOpenPopup] = useState(false)
  const [extensionForEdit, setExtensionForEdit] = useState(null)

  const openInPopup = (item) => {
    setExtensionForEdit(item)
    setOpenPopup(true)
  }

  const openAdd = () => {
    setExtensionForEdit(null)
    setOpenPopup(true)
  }

  // Table
  const headCells = [
    {
      field: 'did',
      title: 'DID',
    },
    {
      field: 'extension',
      title: 'Extension',
    },
    {
      field: 'status',
      title: 'Status',
    },
    {
      field: 'password',
      title: 'Password',
    },
    {
      field: 'assigned_to',
      title: 'Assigned To',
    },
    {
      field: 'assigned_on',
      title: 'Assigned On',
    },
    {
      field: 'actions',
      title: 'Actions',
      render: (rowData) => (
        <Controls.ActionButton color='primary'>
          <EditOutlinedIcon
            fontSize='small'
            onClick={() => openInPopup(rowData)}
          />
        </Controls.ActionButton>
      ),
    },
  ]

  return (
    <>
      {loading ? (
        <Progress />
      ) : (
        <>
          {error ? (
            <Notification notify={notify} setNotify={setNotify} />
          ) : (
            <>
              <PageHeader
                title='Extensions'
                subtitle='Shows list of extensions in the organisations'
                icon={<PeopleOutlineTwoToneIcon fontSize='medium' />}
              />
              <MaterialTable
                style={{ margin: '0 20px' }}
                title='Extensions'
                columns={headCells}
                data={extensions}
                options={{
                  exportButton: true,
                  search: true,
                  exportAllData: true,
                }}
                actions={[
                  {
                    icon: 'add',
                    tooltip: 'Add Extensions',
                    isFreeAction: true,
                    onClick: () => openAdd(),
                  },
                ]}
              />
              <Popup
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title='Add Extension'
              >
                <AddExtension
                  extensionForEdit={extensionForEdit}
                  setOpenPopup={setOpenPopup}
                />
              </Popup>
            </>
          )}
        </>
      )}
    </>
  )
}

export default Extensions
