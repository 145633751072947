import { CssBaseline } from '@material-ui/core'
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Header from './components/Header'
import SideMenu from './components/SideMenu'
import Dashboard from './screens/Dashboard'
import HRDashboard from './screens/HRDashboard'
import HRAddEmployee from './screens/HRAddEmployee'
import HRActiveEmployees from './screens/HRActiveEmployees'
import SignIn from './screens/SignIn'
import HREditEmployee from './screens/HREditEmployee'
import HRNotActiveEmployees from './screens/HRNotActiveEmployees'
import HRDepartments from './screens/HRDepartments'
import Extensions from './screens/Extensions'
import Roles from './screens/Roles'
import EverydayAttendance from './screens/EverydayAttendance'
import FinalizeAttendance from './screens/FinalizeAttendance'
import HRMasterDepartments from './screens/HRMasterDepartments'
import BillingDashboard from './screens/BillingDashboard'
import BillingTags from './screens/BillingTags'
import BillingDepartments from './screens/BillingDepartments'
import BillingDepartmentById from './screens/BillingDepartmentById'
import LiveCalls from './screens/LiveCalls'
import CallMIS from './screens/CallMIS'
import Settings from './screens/Settings'
import CallRecordings from './screens/CallRecordings'
import HRReports from './screens/HRReports'
import BillingReports from './screens/BillingReports'
import BillingMasterReport from './screens/BillingMasterReport'
import BillingDailyMIS from './screens/BillingDailyMIS'
import FinalizeAttendanceSingle from './screens/FinalizeAttendanceSingle'
import DataSearch from './screens/DataSearch'
import DataUpload from './screens/DataUpload'
import AccountsEmployees from './screens/AccountsEmployees'
import AccountsEmployeeById from './screens/AccountsEmployeeById'
import ProcessBackendSalaries from './screens/ProcessBackendSalaries'
import HRReportsAttendance from './screens/HRReportsAttendance'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path='/' component={SignIn} exact />
        <Route component={DefaultContainer} />
        <Route component={() => <div>404 Not found </div>} />
      </Switch>
      <CssBaseline />
    </Router>
  )
}

const DefaultContainer = () => {
  return (
    <div>
      <SideMenu />
      <div className='appMain'>
        <Header />
        <Route path='/dashboard' component={Dashboard} exact />
        <Route path='/hr/dashboard' component={HRDashboard} exact />
        <Route path='/hr/reports/:type' component={HRReports} />
        <Route
          path='/hr/attendance/reports/:type'
          component={HRReportsAttendance}
        />
        <Route path='/hr/employees/add' component={HRAddEmployee} exact />
        <Route path='/hr/employee/:id' component={HREditEmployee} />
        <Route path='/hr/attendance' component={EverydayAttendance} exact />
        <Route
          path='/hr/finalize-attendance'
          component={FinalizeAttendance}
          exact
        />
        <Route
          path='/hr/active-employees'
          component={HRActiveEmployees}
          exact
        />
        <Route
          path='/hr/not-active-employees'
          component={HRNotActiveEmployees}
          exact
        />
        <Route path='/hr/departments' component={HRDepartments} exact />
        <Route
          path='/hr/master-departments'
          component={HRMasterDepartments}
          exact
        />
        <Route path='/extensions' component={Extensions} exact />
        <Route path='/calls/live-calls' component={LiveCalls} exact />
        <Route path='/calls/mis' component={CallMIS} exact />
        <Route path='/calls/recordings' component={CallRecordings} exact />
        <Route path='/roles' component={Roles} exact />
        <Route path='/settings' component={Settings} exact />
        <Route path='/billing/dashboard' component={BillingDashboard} exact />
        <Route path='/billing/tags' component={BillingTags} exact />
        <Route
          path='/billing/departments'
          component={BillingDepartments}
          exact
        />
        <Route
          path='/billing/department/:id'
          component={BillingDepartmentById}
        />
        <Route path='/billing/mis/daily' component={BillingDailyMIS} exact />
        <Route path='/billing/reports' component={BillingReports} exact />
        <Route
          path='/billing/reports/master'
          component={BillingMasterReport}
          exact
        />
        <Route
          path='/finalize-attendance/:id'
          component={FinalizeAttendanceSingle}
        />
        <Route path='/data' component={DataSearch} exact />
        <Route path='/data/upload' component={DataUpload} exact />
        <Route path='/accounts/employees' component={AccountsEmployees} exact />
        <Route path='/accounts/employee/:id' component={AccountsEmployeeById} />
        <Route
          path='/accounts/salary/backend/process/:id'
          component={ProcessBackendSalaries}
        />
      </div>
    </div>
  )
}

export default App
