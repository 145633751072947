import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  settingsAction,
  settingsDeleteBankAction,
} from '../actions/settingActions'
import Notification from '../components/Notification'
import Progress from '../components/Progress'
import Controls from '../components/controls/Controls'
import PageHeader from '../components/PageHeader'
import SettingsIcon from '@material-ui/icons/Settings'
import Popup from '../components/Popup'
import UpdateTDSSettings from '../components/UpdateTDSSettings'
import ConfirmDialog from '../components/ConfirmDialog'
import CloseIcon from '@material-ui/icons/Close'
import AddBank from '../components/AddBank'
import { SETTINGS_DELETE_BANK_RESET } from '../constants/settingConstants'
import MaterialTable from 'material-table'

const Settings = ({ history }) => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const settings = useSelector((state) => state.settings)
  const { loading, error, settingsData } = settings

  const settingsDeleteBank = useSelector((state) => state.settingsDeleteBank)
  const { error1, deleteBank } = settingsDeleteBank

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const [tds, setTds] = useState('')
  const [tdsHistory, setTDSHistory] = useState([])
  const [banks, setBanks] = useState([])

  useEffect(() => {
    dispatch(settingsAction())
  }, [])

  useEffect(() => {
    if (settingsData) {
      if (settingsData.role === 'Admin') {
        setTds(settingsData.tds_percentage)
        setTDSHistory(settingsData.tds_percentage_history)
        setBanks(settingsData.banks)
      } else {
        history.push('/dashboard')
      }
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [settingsData, error, history])

  const [openTDSPopup, setOpenTDSPopup] = useState(false)
  const [openBankPopup, setOpenBankPopup] = useState(false)

  const openAdd = () => {
    setOpenTDSPopup(true)
  }

  const openAddBank = () => {
    setOpenBankPopup(true)
  }

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  })

  useEffect(() => {
    dispatch({ type: SETTINGS_DELETE_BANK_RESET })
    if (deleteBank) {
      setNotify({
        isOpen: true,
        message: deleteBank.msg,
        type: 'success',
      })
      setTimeout(() => {
        setConfirmDialog({ ...confirmDialog, isOpen: false })
        dispatch(settingsAction())
      }, 2000)
    } else if (error1) {
      setNotify({
        isOpen: true,
        message: error1,
        type: 'error',
      })
    }
  }, [dispatch, deleteBank, error1])

  // Tables
  const headCellsTDS = [
    {
      field: 'percentage',
      title: 'Percentage',
    },
    {
      field: 'changed_on',
      title: 'Updated On',
    },
  ]

  const headCellsBanks = [
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'action',
      title: 'Action',
      render: (rowData) => (
        <Controls.ActionButton
          onClick={() =>
            setConfirmDialog({
              isOpen: true,
              title: 'Are you sure to delete this bank?',
              subTitle: "You can't undo this operation",
              onConfirm: () => {
                dispatch(settingsDeleteBankAction(rowData.name))
              },
            })
          }
          color='secondary'
        >
          <CloseIcon fontSize='small' />
        </Controls.ActionButton>
      ),
    },
  ]

  return (
    <>
      {loading ? (
        <Progress />
      ) : (
        <>
          <PageHeader
            title='Settings'
            subtitle='Shows list of settings available in the organisations'
            icon={<SettingsIcon fontSize='medium' />}
          />
          <Grid container>
            <Grid item xs={12} md={6}>
              <MaterialTable
                style={{ margin: '0 20px' }}
                title={`TDS: ${tds}`}
                columns={headCellsTDS}
                data={tdsHistory}
                options={{
                  exportButton: true,
                  search: true,
                  exportAllData: true,
                }}
                actions={[
                  {
                    icon: 'add',
                    tooltip: 'Update TDS Percentage',
                    isFreeAction: true,
                    onClick: () => openAdd(),
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MaterialTable
                style={{ marginRight: '20px' }}
                title='Banks'
                columns={headCellsBanks}
                data={banks}
                options={{
                  exportButton: true,
                  search: true,
                  exportAllData: true,
                }}
                actions={[
                  {
                    icon: 'add',
                    tooltip: 'Add Bank',
                    isFreeAction: true,
                    onClick: () => openAddBank(),
                  },
                ]}
              />
            </Grid>
          </Grid>
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <Popup
            openPopup={openTDSPopup}
            setOpenPopup={setOpenTDSPopup}
            title='Update TDS Percentage'
          >
            <UpdateTDSSettings setOpenPopup={setOpenTDSPopup} />
          </Popup>
          <Popup
            openPopup={openBankPopup}
            setOpenPopup={setOpenBankPopup}
            title='Add Bank'
          >
            <AddBank setOpenPopup={setOpenBankPopup} />
          </Popup>
        </>
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}

export default Settings
