export const ROLES_REQUEST = 'ROLES_REQUEST'
export const ROLES_SUCCESS = 'ROLES_SUCCESS'
export const ROLES_FAIL = 'ROLES_FAIL'
export const ROLES_RESET = 'ROLES_RESET'

export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST'
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS'
export const DELETE_ROLE_FAIL = 'DELETE_ROLE_FAIL'
export const DELETE_ROLE_RESET = 'DELETE_ROLE_RESET'

export const ADD_ROLE_REQUEST = 'ADD_ROLE_REQUEST'
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS'
export const ADD_ROLE_FAIL = 'ADD_ROLE_FAIL'
export const ADD_ROLE_RESET = 'ADD_ROLE_RESET'

export const SETTINGS_REQUEST = 'SETTINGS_REQUEST'
export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS'
export const SETTINGS_FAIL = 'SETTINGS_FAIL'
export const SETTINGS_RESET = 'SETTINGS_RESET'

export const SETTINGS_UPDATE_TDS_REQUEST = 'SETTINGS_UPDATE_TDS_REQUEST'
export const SETTINGS_UPDATE_TDS_SUCCESS = 'SETTINGS_UPDATE_TDS_SUCCESS'
export const SETTINGS_UPDATE_TDS_FAIL = 'SETTINGS_UPDATE_TDS_FAIL'
export const SETTINGS_UPDATE_TDS_RESET = 'SETTINGS_UPDATE_TDS_RESET'

export const SETTINGS_ADD_BANK_REQUEST = 'SETTINGS_ADD_BANK_REQUEST'
export const SETTINGS_ADD_BANK_SUCCESS = 'SETTINGS_ADD_BANK_SUCCESS'
export const SETTINGS_ADD_BANK_FAIL = 'SETTINGS_ADD_BANK_FAIL'
export const SETTINGS_ADD_BANK_RESET = 'SETTINGS_ADD_BANK_RESET'

export const SETTINGS_DELETE_BANK_REQUEST = 'SETTINGS_DELETE_BANK_REQUEST'
export const SETTINGS_DELETE_BANK_SUCCESS = 'SETTINGS_DELETE_BANK_SUCCESS'
export const SETTINGS_DELETE_BANK_FAIL = 'SETTINGS_DELETE_BANK_FAIL'
export const SETTINGS_DELETE_BANK_RESET = 'SETTINGS_DELETE_BANK_RESET'
