import { Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import PageHeader from '../components/PageHeader'
import React, { useEffect, useState } from 'react'
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import { hrDashboardAction } from '../actions/hrActions'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Notification from '../components/Notification'
import Progress from '../components/Progress'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(0, 1.5, 2, 1.5),
    padding: theme.spacing(2),
  },
  summaryHead: {
    fontSize: '18px',
    display: 'block',
    fontWeight: 'bold',
  },
  summaryText: {
    marginTop: '8px',
    display: 'block',
    textDecoration: 'none',
    color: 'black',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
}))

const HRDashboard = ({ history }) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const hrDashboard = useSelector((state) => state.hrDashboard)
  const { loading, error, hrData } = hrDashboard

  useEffect(() => {
    dispatch(hrDashboardAction())
  }, [dispatch])

  const [active, setActive] = useState(0)
  const [notActive, setNotActive] = useState(0)
  const [office, setOffice] = useState(0)
  const [field, setField] = useState(0)
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

  useEffect(() => {
    if (hrData) {
      if (hrData.role === 'Admin' || hrData.role === 'HR') {
        setActive(hrData.employees.active)
        setNotActive(hrData.employees.not_active)
        setOffice(hrData.employees.office)
        setField(hrData.employees.field)
      } else {
        history.push('/dashboard')
      }
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [hrData, error, history])

  return (
    <>
      {loading ? (
        <Progress />
      ) : (
        <>
          <PageHeader
            title='HR Dashboard'
            subtitle='Shows list overall summary of the employees and departments with reports'
            icon={<PeopleOutlineTwoToneIcon fontSize='medium' />}
          />
          <Grid container>
            <Grid item xs={12} lg={4}>
              <Paper className={classes.pageContent}>
                <Typography className={classes.summaryHead}>
                  Organisations
                </Typography>
                <Typography className={classes.summaryText}>
                  Management Services
                </Typography>
                <Typography className={classes.summaryText}>
                  MSER Ventures Private Limited
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Paper className={classes.pageContent}>
                <Typography className={classes.summaryHead}>
                  Employees: {active + notActive}
                </Typography>
                <Typography className={classes.summaryText}>
                  Active: {active}
                </Typography>
                <Typography className={classes.summaryText}>
                  Not Active: {notActive}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Paper className={classes.pageContent}>
                <div className={classes.row}>
                  <Typography className={classes.summaryHead}>
                    Employees
                  </Typography>
                  <Link className={classes.link} to='/hr/employees/add'>
                    Add
                  </Link>
                </div>
                <Typography className={classes.summaryText}>
                  Office: {office}
                </Typography>
                <Typography className={classes.summaryText}>
                  Field: {field}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} lg={6}>
              <Paper className={classes.pageContent}>
                <Typography className={classes.summaryHead}>
                  HR Reports
                </Typography>
                <Typography className={classes.summaryText}>
                  <Link className={classes.summaryText} to='/hr/reports/master'>
                    Master Employees Report
                  </Link>
                </Typography>
                <Typography className={classes.summaryText}>
                  <Link
                    className={classes.summaryText}
                    to='/hr/reports/working-employees'
                  >
                    Working Employees Report
                  </Link>
                </Typography>
                <Typography className={classes.summaryText}>
                  <Link
                    className={classes.summaryText}
                    to='/hr/reports/not-working-employees'
                  >
                    Not Working Employees Report
                  </Link>
                </Typography>
                {/* <Typography className={classes.summaryText}>
                  <Link className={classes.summaryText} to='/hr/reports/master'>
                    Master Departments Report
                  </Link>
                </Typography>
                <Typography className={classes.summaryText}>
                  <Link className={classes.summaryText} to='/hr/reports/master'>
                    Departments Report
                  </Link>
                </Typography> */}
                <Typography className={classes.summaryText}>
                  <Link
                    className={classes.summaryText}
                    to='/hr/reports/basic-information'
                  >
                    Employees Basic Information Report
                  </Link>
                </Typography>
                <Typography className={classes.summaryText}>
                  <Link
                    className={classes.summaryText}
                    to='/hr/reports/banking-information'
                  >
                    Employees Bank Information Report
                  </Link>
                </Typography>
                <Typography className={classes.summaryText}>
                  <Link
                    className={classes.summaryText}
                    to='/hr/reports/reference-information'
                  >
                    Employees Reference Information Report
                  </Link>
                </Typography>
                <Typography className={classes.summaryText}>
                  <Link
                    className={classes.summaryText}
                    to='/hr/reports/emergency-contact-information'
                  >
                    Employees Emergency Contact Information Report
                  </Link>
                </Typography>
                <Typography className={classes.summaryText}>
                  <Link
                    className={classes.summaryText}
                    to='/hr/reports/education-information'
                  >
                    Employees Education Information Report
                  </Link>
                </Typography>
                <Typography className={classes.summaryText}>
                  <Link
                    className={classes.summaryText}
                    to='/hr/reports/previous-company-information'
                  >
                    Employees Previous Company Information Report
                  </Link>
                </Typography>
                <Typography className={classes.summaryText}>
                  <Link
                    className={classes.summaryText}
                    to='/hr/reports/documents'
                  >
                    Employees Documents Report
                  </Link>
                </Typography>
                <Typography className={classes.summaryText}>
                  <Link
                    className={classes.summaryText}
                    to='/hr/reports/vehicle-information'
                  >
                    Employees Vehicle Information Report
                  </Link>
                </Typography>
                <Typography className={classes.summaryText}>
                  <Link
                    className={classes.summaryText}
                    to='/hr/reports/extensions'
                  >
                    Employees Extension Information Report
                  </Link>
                </Typography>
                <Typography className={classes.summaryText}>
                  <Link className={classes.summaryText} to='/hr/reports/hdfc'>
                    HDFC Working Staff Report
                  </Link>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Paper className={classes.pageContent}>
                <Typography className={classes.summaryHead}>
                  Attendance Reports
                </Typography>
                <Typography className={classes.summaryText}>
                  <Link
                    className={classes.summaryText}
                    to='/hr/attendance/reports/day-wise'
                  >
                    Day Wise Attendance Report
                  </Link>
                </Typography>
                <Typography className={classes.summaryText}>
                  <Link
                    className={classes.summaryText}
                    to='/hr/attendance/reports/month-wise'
                  >
                    Monthly Attendance Report
                  </Link>
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}

export default HRDashboard
