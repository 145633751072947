import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { billingTagsAction } from '../actions/billingActions'
import Notification from '../components/Notification'
import Progress from '../components/Progress'
import Controls from '../components/controls/Controls'
import Popup from '../components/Popup'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import PageHeader from '../components/PageHeader'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import AddBillingTag from '../components/AddBillingTag'
import MaterialTable from 'material-table'

const BillingTags = ({ history }) => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const billingTags = useSelector((state) => state.billingTags)
  const { loading, error, tags } = billingTags

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const [tagsList, setTagsList] = useState([])
  const [employees, setEmployees] = useState([])

  useEffect(() => {
    dispatch(billingTagsAction())
  }, [])

  useEffect(() => {
    if (tags) {
      if (tags.role === 'Admin' || tags.role === 'Billing') {
        setTagsList(tags.data)
      } else {
        history.push('/dashboard')
      }
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [tags, error])

  const [openPopup, setOpenPopup] = useState(false)
  const [tagForEdit, setTagForEdit] = useState(null)

  const openInPopup = (item) => {
    setTagForEdit(item)
    setOpenPopup(true)
  }

  const openAdd = () => {
    setTagForEdit(null)
    setOpenPopup(true)
  }

  // Table
  const headCells = [
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'type',
      title: 'Type',
    },
    {
      field: 'tagType',
      title: 'Tag Type',
    },
    {
      field: 'tagCategory',
      title: 'Tag Category',
    },
    {
      field: 'addedOn',
      title: 'Added On',
    },
    {
      field: 'actions',
      title: 'Actions',
      render: (rowData) => (
        <Controls.ActionButton color='primary'>
          <EditOutlinedIcon
            fontSize='small'
            onClick={() => openInPopup(rowData)}
          />
        </Controls.ActionButton>
      ),
    },
  ]

  return (
    <>
      {loading ? (
        <Progress />
      ) : (
        <>
          {error ? (
            <Notification notify={notify} setNotify={setNotify} />
          ) : (
            <>
              <PageHeader
                title='Tags'
                subtitle='Shows list of billing tags in the organisations'
                icon={<LocalOfferIcon fontSize='medium' />}
              />
              <MaterialTable
                style={{ margin: '0 20px' }}
                title='Tags'
                columns={headCells}
                data={tagsList}
                options={{
                  exportButton: true,
                  search: true,
                  exportAllData: true,
                }}
                actions={[
                  {
                    icon: 'add',
                    tooltip: 'Add Tag',
                    isFreeAction: true,
                    onClick: () => openAdd(),
                  },
                ]}
              />
              <Popup
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title='Add Billing Tag'
              >
                <AddBillingTag
                  tagForEdit={tagForEdit}
                  setOpenPopup={setOpenPopup}
                />
              </Popup>
            </>
          )}
        </>
      )}
    </>
  )
}

export default BillingTags
