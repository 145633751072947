import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  accountsEmployeeByIdAction,
  accountsUpdateSalaryAction,
  accountsUpdateSalaryApprovalAction,
} from '../actions/accountActions'
import Notification from '../components/Notification'
import Progress from '../components/Progress'
import PersonIcon from '@material-ui/icons/Person'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import Controls from '../components/controls/Controls'
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import { useForm } from '../components/useForm'
import MaterialTable from 'material-table'
import { HR_UPDATE_BANK_INFO_EMPLOYEE_RESET } from '../constants/hrConstants'
import { hrUpdtBkIEAction } from '../actions/hrActions'
import {
  ACCOUNTS_UPDATE_SALARY_APPROVAL_RESET,
  ACCOUNTS_UPDATE_SALARY_INFORMATION_RESET,
} from '../constants/accountConstants'

const useStyles = makeStyles((theme) => ({
  pageContent_main: {
    padding: theme.spacing(2),
  },
  pageContent: {
    margin: theme.spacing(0),
    padding: theme.spacing(2, 2.5),
  },
  submit: {
    margin: theme.spacing(2, 0, 0),
  },
  exit: {
    padding: theme.spacing(0.5, 2),
  },
  download: {
    padding: theme.spacing(0.5, 2),
    marginRight: 10,
  },
  reset_password: {
    margin: theme.spacing(2, 1, 0),
  },
  attendance_status_in: {
    color: '#00A300',
    border: '2px solid #00A300',
    borderRadius: '2px',
    padding: '2px 10px',
  },
  attendance_status_out: {
    color: '#800000',
    border: '2px solid #800000',
    borderRadius: '2px',
    padding: '2px 10px',
  },
  label: {
    fontWeight: 'bold',
  },
  pic: {
    fontSize: '212px',
  },
  icon: {
    cursor: 'pointer',
  },
  select: {
    '& .MuiSelect-select': {
      minWidth: '200px',
    },
  },
  searchInput: {
    width: '75%',
  },
  link: {
    textDecoration: 'none',
  },
  addButton: {
    position: 'absolute',
    right: '10px',
  },
  select: {
    '& .MuiSelect-select': {
      minWidth: '200px',
    },
  },
}))

const initialValues = {
  name_as_per_bank: '',
  bank_name: '',
  account_number: '',
  ifsc_code: '',
  salary: 0,
  isTDS: false,
  tdsType: '',
  tdsAmount: '',
  isPF: '',
  pfAmount: 0,
  isESI: '',
  esiAmount: 0,
  remark: '',
  status: '',
}

const AccountsEmployeeById = ({ match, history }) => {
  const {
    params: { id },
  } = match

  const classes = useStyles()

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const accountsEmployeeById = useSelector(
    (state) => state.accountsEmployeeById
  )
  const { loading, error, data } = accountsEmployeeById

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const [banks, setBanks] = useState([])

  const [isActive, setIsActive] = useState(false)
  const [name, setName] = useState('')
  const [organisation, setOrganisation] = useState('')
  const [employeeCode, setEmployeeCode] = useState('')
  const [mobile, setMobile] = useState('')
  const [email, setEmail] = useState('')
  const [jobTitle, setJobTitle] = useState('')
  const [department, setDepartment] = useState({})
  const [doj, setDoj] = useState('')
  const [extension, setExtension] = useState({})
  const [aadharCard, setAadharCard] = useState('')
  const [panCard, setPanCard] = useState('')
  const [voterIdCard, setVoterIdCard] = useState('')
  const [dol, setDol] = useState('')
  const [resignHistory, setResignHistory] = useState([])
  const [bankHistory, setBankHistory] = useState([])
  const [salaryHistory, setSalaryHistory] = useState([])
  const [processedSalaries, setProcessedSalaries] = useState([])
  const [pendingSalary, setPendingSalary] = useState(null)
  const [role, setRole] = useState('')
  const [salaryStatus, setSalaryStatus] = useState('Pending')

  useEffect(() => {
    dispatch(accountsEmployeeByIdAction(id))
  }, [])

  useEffect(() => {
    if (data) {
      setRole(data.role)
      if (data.role === 'Admin' || data.role === 'Accounts') {
        setIsActive(data.employee.master_state)
        setName(data.employee.name)
        setOrganisation(data.employee.organisation)
        setEmployeeCode(data.employee.employee_code)
        setMobile(data.employee.mobile)
        setEmail(data.employee.email)
        setJobTitle(data.employee.job_title)
        setDepartment(data.employee.department)
        setDoj(data.employee.doj)
        setExtension(data.employee.extension)
        setAadharCard(data.employee.aadhar_card)
        setPanCard(data.employee.pan_card)
        setVoterIdCard(data.employee.voter_id_card)
        setDol(data.employee.dol)
        setResignHistory(data.employee.resign_history)
        setBankHistory(data.employee.banking_information.history)
        setSalaryHistory(data.employee.salary_information.history)
        setPendingSalary(
          data.employee.salary_information.history[
            data.employee.salary_information.history.length - 1
          ]
        )
        setSalaryStatus(data.employee.salary_status)

        setBanks(data.banks)
        setProcessedSalaries(data.salaries)

        setValues({
          ...values,
          name_as_per_bank: data.employee.banking_information.name_as_per_bank,
          bank_name: data.employee.banking_information.bank_name,
          account_number: data.employee.banking_information.account_number,
          ifsc_code: data.employee.banking_information.ifsc_code,
          salary: data.employee.salary_information.salary,
          isTDS: data.employee.salary_information.isTDS,
          tdsType: data.employee.salary_information.tdsType,
          tdsAmount: data.employee.salary_information.tdsAmount,
          isPF: data.employee.salary_information.isPF,
          pfAmount: data.employee.salary_information.pfAmount,
          isESI: data.employee.salary_information.isESI,
          esiAmount: data.employee.salary_information.esiAmount,
          status: data.employee.salary_status,
        })
      } else {
        history.push('/dashboard')
      }
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [data, error])

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('name_as_per_bank' in fieldValues)
      temp.name_as_per_bank = fieldValues.name_as_per_bank
        ? ''
        : 'This field is required'
    if ('bank_name' in fieldValues)
      temp.bank_name = fieldValues.bank_name ? '' : 'This field is required'
    if ('account_number' in fieldValues)
      temp.account_number = fieldValues.account_number
        ? ''
        : 'This field is required'
    if ('ifsc_code' in fieldValues)
      temp.ifsc_code =
        fieldValues.ifsc_code.length > 10 ? '' : 'This field is required'

    setErrors({
      ...temp,
    })
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '')
  }

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  )

  // Bank Edit
  const [disableBank, setDisableBank] = useState(true)
  const editBankInfoHandler = () => {
    setDisableBank(!disableBank)
  }

  // Update Bank
  const updateBank = () => {
    if (
      !values.name_as_per_bank ||
      !values.bank_name ||
      !values.account_number ||
      !values.ifsc_code
    ) {
      setNotify({
        isOpen: true,
        message: 'All the fields with * are mandatory',
        type: 'error',
      })
    } else {
      dispatch(
        hrUpdtBkIEAction(
          id,
          values.name_as_per_bank,
          values.bank_name,
          values.account_number,
          values.ifsc_code
        )
      )
    }
  }

  const hrUpdBkIE = useSelector((state) => state.hrUpdBkIE)
  const { loading2, error2, updateBankInfoEmployee } = hrUpdBkIE

  useEffect(() => {
    dispatch({ type: HR_UPDATE_BANK_INFO_EMPLOYEE_RESET })
    if (updateBankInfoEmployee) {
      setNotify({
        isOpen: true,
        message: updateBankInfoEmployee.msg,
        type: 'success',
      })
      setTimeout(() => {
        setDisableBank(true)
        dispatch(accountsEmployeeByIdAction(id))
      }, 2000)
    } else if (error2) {
      setNotify({
        isOpen: true,
        message: error2,
        type: 'error',
      })
    }
  }, [updateBankInfoEmployee, error2, dispatch, id])

  // Bank Table
  const bankHeadCells = [
    {
      field: 'name_as_per_bank',
      title: 'Name as Per Bank',
    },
    {
      field: 'bank_name',
      title: 'Bank Name',
    },
    {
      field: 'account_number',
      title: 'Account Number',
    },
    {
      field: 'ifsc_code',
      title: 'IFSC Code',
    },
    {
      field: 'updated_on',
      title: 'Updated On',
    },
  ]

  // Salary Edit
  const [disableSalary, setDisableSalary] = useState(true)
  const editSalaryInfoHandler = () => {
    setDisableSalary(!disableSalary)
  }

  // Update Salary
  const updateSalary = () => {
    if (!values.salary || !values.remark) {
      setNotify({
        isOpen: true,
        message: 'Salary and remarks are mandatory',
        type: 'error',
      })
      return
    }
    if (values.isTDS === true) {
      if (!values.tdsType) {
        setNotify({
          isOpen: true,
          message: 'TDS Type is mandatory',
          type: 'error',
        })
        return
      } else if (values.tdsType === 'Amount') {
        if (!values.tdsAmount) {
          setNotify({
            isOpen: true,
            message: 'TDS Amount is mandatory',
            type: 'error',
          })
          return
        }
      }
    }
    if (values.isPF === true) {
      if (!values.pfAmount) {
        setNotify({
          isOpen: true,
          message: 'PF Amount is mandatory',
          type: 'error',
        })
        return
      }
    }
    if (values.isESI === true) {
      if (!values.esiAmount) {
        setNotify({
          isOpen: true,
          message: 'ESI Amount is mandatory',
          type: 'error',
        })
        return
      }
    }

    // dispatch
    dispatch(
      accountsUpdateSalaryAction(
        id,
        values.salary,
        values.isTDS,
        values.tdsType,
        values.tdsAmount,
        values.isPF,
        values.pfAmount,
        values.isESI,
        values.esiAmount,
        values.remark
      )
    )
  }

  const accountsUpdateSalary = useSelector(
    (state) => state.accountsUpdateSalary
  )
  const { loading3, error3, result } = accountsUpdateSalary

  useEffect(() => {
    dispatch({ type: ACCOUNTS_UPDATE_SALARY_INFORMATION_RESET })
    if (result) {
      setNotify({
        isOpen: true,
        message: result.msg,
        type: 'success',
      })
      setTimeout(() => {
        setDisableBank(true)
        dispatch(accountsEmployeeByIdAction(id))
      }, 2000)
    } else if (error3) {
      setNotify({
        isOpen: true,
        message: error3,
        type: 'error',
      })
    }
  }, [result, error3, dispatch, id])

  const updateSalaryUpdation = () => {
    if (values.status === 'Pending') {
      setNotify({
        isOpen: true,
        message: "Can't update pending status",
        type: 'error',
      })
    } else {
      dispatch(accountsUpdateSalaryApprovalAction(id, values.status))
    }
  }

  const accountsUpdateSalaryApprovalInfo = useSelector(
    (state) => state.accountsUpdateSalaryApprovalInfo
  )
  const { loading4, resultData, error4 } = accountsUpdateSalaryApprovalInfo

  useEffect(() => {
    dispatch({ type: ACCOUNTS_UPDATE_SALARY_APPROVAL_RESET })
    if (resultData) {
      setNotify({
        isOpen: true,
        message: resultData.msg,
        type: 'success',
      })
      setTimeout(() => {
        dispatch(accountsEmployeeByIdAction(id))
      }, 2000)
    } else if (error4) {
      setNotify({
        isOpen: true,
        message: error4,
        type: 'error',
      })
    }
  }, [resultData, error4, dispatch, id])

  // Salary Table
  const salartHeadCells = [
    {
      field: 'salary',
      title: 'Salary',
    },
    {
      field: 'isTDS',
      title: 'Is TDS?',
    },
    {
      field: 'tdsType',
      title: 'TDS Type',
    },
    {
      field: 'tdsAmount',
      title: 'TDS Amount',
    },
    {
      field: 'isESI',
      title: 'Is ESI?',
    },
    {
      field: 'esiAmount',
      title: 'ESI Amount',
    },
    {
      field: 'isPF',
      title: 'Is PF?',
    },
    {
      field: 'pfAmount',
      title: 'PF Amount',
    },
    {
      field: 'remark',
      title: 'Remark',
    },
    {
      field: 'status',
      title: 'Status',
    },
    {
      field: 'created_on',
      title: 'Created On',
    },
    {
      field: 'updated_on',
      title: 'Updated On',
    },
  ]

  // Processed Salaries Table
  const processedSalariesHeadCells = [
    {
      field: 'date',
      title: 'Date',
    },
    {
      field: 'salary_as_per_attendance',
      title: 'Salary as per Attendance',
    },
    {
      field: 'pf',
      title: 'PF',
    },
    {
      field: 'esi',
      title: 'ESI',
    },
    {
      field: 'tds',
      title: 'TDS',
    },
    {
      field: 'adjustment_amount',
      title: 'Adjustment Amount',
    },
    {
      field: 'adjustment_type',
      title: 'Adjustment Type',
    },
    {
      field: 'adjustment_reason',
      title: 'Adjustment Reason',
    },
    {
      field: 'final_salary',
      title: 'Final Salary',
    },
    {
      field: 'status',
      title: 'Status',
    },
    {
      field: 'processed_on',
      title: 'Processed On',
    },
    {
      field: 'released_on',
      title: 'Released On',
    },
  ]

  // Resign History Table
  const resignaHistoryHeadCells = [
    {
      field: 'doj',
      title: 'DOJ',
    },
    {
      field: 'date',
      title: 'Resign Date',
    },
    {
      field: 'reason',
      title: 'Reason',
    },
    {
      field: 'exit_status',
      title: 'Exit Status',
    },
    {
      field: 'details',
      title: 'Details',
    },
  ]

  return (
    <div className={classes.pageContent_main}>
      {loading ? (
        <Progress />
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <Paper
                className={classes.pageContent}
                elevation={4}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <PersonIcon className={classes.pic} />
              </Paper>
            </Grid>
            <Grid item xs={12} lg={9}>
              <Paper className={classes.pageContent} elevation={4}>
                <Grid container spacing={3} alignItems='center'>
                  <Grid item>
                    <Typography>
                      <span className={classes.label}>Name:</span> {name}
                    </Typography>
                  </Grid>
                  <Grid sm item />
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography>
                      <span className={classes.label}>Org:</span> {organisation}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      <span className={classes.label}>E Code:</span>{' '}
                      {employeeCode}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography>
                      <span className={classes.label}>Mobile:</span> {mobile}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      <span className={classes.label}>Email:</span> {email}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography>
                      <span className={classes.label}>Job Title:</span>{' '}
                      {jobTitle}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      <span className={classes.label}>Department:</span>{' '}
                      {department.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography>
                      <span className={classes.label}>DOJ:</span> {doj}
                    </Typography>
                  </Grid>
                  {isActive === 'Not Active' && (
                    <Grid item xs={6}>
                      <Typography>
                        <span className={classes.label}>DOL:</span> {dol}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography>
                      <span className={classes.label}>DID:</span>{' '}
                      {extension.did}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      <span className={classes.label}>Extension:</span>{' '}
                      {extension.extension}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.pageContent} elevation={4}>
                <Grid container spacing={3}>
                  <Grid item>
                    <Typography>Documents</Typography>
                  </Grid>
                  <Grid sm item />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Aadhar Card'
                      name='aadhar_card'
                      disabled
                      value={aadharCard}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Pan Card'
                      name='pan_card'
                      disabled
                      value={panCard}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Controls.Input
                      label='Voter Id Card'
                      name='voter_id_card'
                      disabled
                      value={voterIdCard}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.pageContent} elevation={4}>
                <Grid container spacing={3}>
                  <Grid item>
                    <Typography>Bank Information</Typography>
                  </Grid>
                  <Grid sm item />
                  <Grid item>
                    {isActive === 'Active' && (
                      <>
                        {disableBank ? (
                          <EditIcon
                            className={classes.icon}
                            onClick={() => editBankInfoHandler()}
                          />
                        ) : (
                          <CloseIcon
                            className={classes.icon}
                            onClick={() => editBankInfoHandler()}
                          />
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Name as per Bank *'
                      name='name_as_per_bank'
                      disabled={disableBank}
                      value={values.name_as_per_bank}
                      onChange={handleInputChange}
                      error={errors.name_as_per_bank}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Select
                      label='Bank Name *'
                      name='bank_name'
                      disabled={disableBank}
                      value={values.bank_name}
                      onChange={handleInputChange}
                      error={errors.bank_name}
                      options={banks}
                      className={classes.select}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Account Number *'
                      name='account_number'
                      disabled={disableBank}
                      value={values.account_number}
                      onChange={handleInputChange}
                      error={errors.account_number}
                      type='number'
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='IFSC Code *'
                      name='ifsc_code'
                      disabled={disableBank}
                      value={values.ifsc_code}
                      onChange={handleInputChange}
                      error={errors.ifsc_code}
                      inputProps={{
                        max: 11,
                      }}
                    />
                  </Grid>
                </Grid>
                {!disableBank && (
                  <Controls.Button
                    type='submit'
                    text='Update'
                    className={classes.submit}
                    onClick={() => updateBank()}
                    laoding={loading2}
                  />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <MaterialTable
                title='Bank History'
                columns={bankHeadCells}
                data={bankHistory}
                options={{
                  exportButton: true,
                  search: true,
                  exportAllData: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.pageContent} elevation={4}>
                <Grid container spacing={3}>
                  <Grid item>
                    <Typography>Salary Information</Typography>
                  </Grid>
                  <Grid sm item />
                  <Grid item>
                    {isActive === 'Active' && (
                      <>
                        {disableSalary ? (
                          <EditIcon
                            className={classes.icon}
                            onClick={() => editSalaryInfoHandler()}
                          />
                        ) : (
                          <CloseIcon
                            className={classes.icon}
                            onClick={() => editSalaryInfoHandler()}
                          />
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Salary *'
                      name='salary'
                      disabled={disableSalary}
                      value={values.salary}
                      onChange={handleInputChange}
                      error={errors.salary}
                      type='number'
                      inputProps={{
                        min: 0,
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} lg={3}>
                    <Controls.Select
                      label='Is TDS? *'
                      name='isTDS'
                      disabled={disableSalary}
                      value={values.isTDS}
                      onChange={handleInputChange}
                      error={errors.isTDS}
                      options={[
                        { id: true, title: 'true' },
                        { id: false, title: 'false' },
                      ]}
                      className={classes.select}
                    />
                  </Grid>
                  {values.isTDS === true && (
                    <>
                      <Grid item xs={12} lg={3}>
                        <Controls.Select
                          label='TDS Type *'
                          name='tdsType'
                          disabled={disableSalary}
                          value={values.tdsType}
                          onChange={handleInputChange}
                          error={errors.tdsType}
                          options={[
                            { id: 'Percentage', title: 'Percentage' },
                            { id: 'Amount', title: 'Amount' },
                          ]}
                          className={classes.select}
                        />
                      </Grid>
                      {values.tdsType === 'Amount' && (
                        <Grid item xs={12} lg={3}>
                          <Controls.Input
                            label='TDS Amount *'
                            name='tdsAmount'
                            disabled={disableSalary}
                            value={values.tdsAmount}
                            onChange={handleInputChange}
                            error={errors.tdsAmount}
                            type='number'
                            inputProps={{
                              min: 0,
                            }}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                  <Grid item xs={12} lg={3}>
                    <Controls.Select
                      label='Is ESI? *'
                      name='isESI'
                      disabled={disableSalary}
                      value={values.isESI}
                      onChange={handleInputChange}
                      error={errors.isESI}
                      options={[
                        { id: true, title: 'true' },
                        { id: false, title: 'false' },
                      ]}
                      className={classes.select}
                    />
                  </Grid>
                  {values.isESI === true && (
                    <Grid item xs={12} lg={3}>
                      <Controls.Input
                        label='ESI Amount *'
                        name='esiAmount'
                        disabled={disableSalary}
                        value={values.esiAmount}
                        onChange={handleInputChange}
                        error={errors.esiAmount}
                        type='number'
                        inputProps={{
                          min: 0,
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} lg={3}>
                    <Controls.Select
                      label='Is PF? *'
                      name='isPF'
                      disabled={disableSalary}
                      value={values.isPF}
                      onChange={handleInputChange}
                      error={errors.isPF}
                      options={[
                        { id: true, title: 'true' },
                        { id: false, title: 'false' },
                      ]}
                      className={classes.select}
                    />
                  </Grid>
                  {values.isPF === true && (
                    <Grid item xs={12} lg={3}>
                      <Controls.Input
                        label='PF Amount *'
                        name='pfAmount'
                        disabled={disableSalary}
                        value={values.pfAmount}
                        onChange={handleInputChange}
                        error={errors.pfAmount}
                        type='number'
                        inputProps={{
                          min: 0,
                        }}
                      />
                    </Grid>
                  )} */}
                  <Grid item xs={12} lg={3}>
                    <Controls.Input
                      label='Remark *'
                      name='remark'
                      disabled={disableSalary}
                      value={values.remark}
                      onChange={handleInputChange}
                      error={errors.remark}
                    />
                  </Grid>
                </Grid>
                {!disableSalary && (
                  <Controls.Button
                    type='submit'
                    text='Update'
                    className={classes.submit}
                    onClick={() => updateSalary()}
                    laoding={loading3}
                  />
                )}
              </Paper>
              {role === 'Admin' && salaryStatus === 'Pending' && (
                <Paper
                  className={classes.pageContent}
                  style={{ marginTop: 20 }}
                  elevation={4}
                >
                  <Grid container spacing={3}>
                    <Grid item>
                      <Typography>Salary Updation Approval</Typography>
                    </Grid>
                    <Grid sm item />
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={3}>
                      <Controls.Input
                        label='New Salary *'
                        name='newSalary'
                        disabled={true}
                        value={pendingSalary ? pendingSalary.salary : ''}
                      />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                      <Controls.Select
                        label='Status *'
                        name='status'
                        value={values.status}
                        onChange={handleInputChange}
                        error={errors.status}
                        options={[
                          { id: 'Pending', title: 'Pending' },
                          { id: 'Approved', title: 'Approve' },
                          { id: 'Rejected', title: 'Reject' },
                        ]}
                        className={classes.select}
                      />
                    </Grid>
                  </Grid>
                  <Controls.Button
                    type='submit'
                    text='Update'
                    className={classes.submit}
                    onClick={() => updateSalaryUpdation()}
                    laoding={loading4}
                  />
                </Paper>
              )}
            </Grid>
            <Grid item xs={12}>
              <MaterialTable
                title='Salary History'
                columns={salartHeadCells}
                data={salaryHistory}
                options={{
                  exportButton: true,
                  search: true,
                  exportAllData: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MaterialTable
                title='Processed Salaries'
                columns={processedSalariesHeadCells}
                data={processedSalaries}
                options={{
                  exportButton: true,
                  search: true,
                  exportAllData: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MaterialTable
                title='Resign History'
                columns={resignaHistoryHeadCells}
                data={resignHistory}
                options={{
                  exportButton: true,
                  search: true,
                  exportAllData: true,
                }}
              />
            </Grid>
          </Grid>
          <Notification notify={notify} setNotify={setNotify} />
        </>
      )}
    </div>
  )
}

export default AccountsEmployeeById
