import { Grid, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Controls from './controls/Controls'
import Notification from './Notification'
import { Form, useForm } from './useForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  hrAddDepartmentEmployeeAction,
  hrGetEmployeeByIdAction,
  hrUpdateDepartmentEmployeeAction,
  hrUploadDocAction,
} from '../actions/hrActions'
import {
  HR_ADD_DEPARTMENT_EMPLOYEE_RESET,
  HR_UPDATE_DEPARTMENT_EMPLOYEE_RESET,
  HR_UPLOAD_DOC_EMPLOYEE_RESET,
} from '../constants/hrConstants'
import {
  billingDepartmentByIdAction,
  billingDepartmentUpdateDataAction,
} from '../actions/billingActions'
import { UPDATE_DEPARTMENT_BILLING_DATA_RESET } from '../constants/billingConstants'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  file: {
    marginTop: '20px',
  },
}))

const EditBillingEntry = ({
  setOpenEditBillingEntry,
  tags,
  id,
  billingEntryForEdit,
}) => {
  let initialValues = {}

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

  const classes = useStyles()
  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    setErrors({
      ...temp,
    })
    if (fieldValues === values) return Object.values(temp).every((x) => x == '')
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate)

  const dispatch = useDispatch()

  useEffect(() => {
    setValues({
      ...billingEntryForEdit,
    })
  }, [billingEntryForEdit])

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(billingDepartmentUpdateDataAction(values))
  }

  const updateBillingData = useSelector((state) => state.updateBillingData)
  const { loading, error, updateData } = updateBillingData

  useEffect(() => {
    dispatch({ type: UPDATE_DEPARTMENT_BILLING_DATA_RESET })
    if (updateData) {
      setNotify({
        isOpen: true,
        message: updateData.msg,
        type: 'success',
      })
      setTimeout(() => {
        setOpenEditBillingEntry(false)
        dispatch(billingDepartmentByIdAction(id))
      }, 2000)
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [dispatch, updateData, error])

  return (
    <>
      <Form autoComplete='off' onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          {tags.map((tag) => (
            <Grid item sx={3}>
              <Controls.Input
                label={tag.name + ' (' + tag.type + ')'}
                name={tag._id}
                value={values[tag._id]}
                onChange={handleInputChange}
                type='number'
                min='0.00'
                step='0.001'
                max='1.00'
                presicion={2}
                // focused
              />
            </Grid>
          ))}
        </Grid>
        <Controls.Button
          type='submit'
          fullWidth
          text='submit'
          className={classes.submit}
          // loading={loading}
        />
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}

export default EditBillingEntry
