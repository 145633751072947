import { makeStyles, Paper, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { searchAttendanceAction } from '../actions/hrActions'
import Notification from '../components/Notification'
import Controls from '../components/controls/Controls'
import PageHeader from '../components/PageHeader'
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import { useForm, Form } from '../components/useForm'
import { SEARCH_ATTENDANCE_RESET } from '../constants/hrConstants'
import Popup from '../components/Popup'
import UpdateAttendance from '../components/UpdateAttendance'
import MaterialTable from 'material-table'

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  searchInput: {
    width: '75%',
  },
  submit: {
    margin: theme.spacing(2, 0, 0),
  },
}))

const initialValues = {
  date: null,
}

const EverydayAttendance = ({ history }) => {
  const classes = useStyles()

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    setErrors({
      ...temp,
    })
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '')
  }

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  )

  const [attendances, setAttendances] = useState([])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (values.date) {
      dispatch(searchAttendanceAction(values.date))
    } else {
      setNotify({
        isOpen: true,
        message: 'Please select a valid date',
        type: 'error',
      })
    }
  }

  const searchAttendance = useSelector((state) => state.searchAttendance)
  const { loading1, error1, results } = searchAttendance

  useEffect(() => {
    dispatch({ type: SEARCH_ATTENDANCE_RESET })
    if (results) {
      if (results.role === 'Admin' || results.role === 'HR') {
        setAttendances(results.attendances)
      } else {
        history.push('/dashboard')
      }
    } else if (error1) {
      setNotify({
        isOpen: true,
        message: error1,
        type: 'error',
      })
    }
  }, [results, error1])

  // useEffect(() => {
  //   dispatch(todaysAttendanceAction())
  // }, [])

  // const todaysAttendance = useSelector((state) => state.todaysAttendance)
  // const { loading, error, attendance } = todaysAttendance

  // useEffect(() => {
  //   dispatch({ type: GET_TODAY_ATTENDANCE_RESET })
  //   if (attendance) {
  //     if (attendance.role === 'Admin' || attendance.role === 'HR') {
  //       setAttendances(attendance.attendances)
  //     } else {
  //       history.push('/dashboard')
  //     }
  //   } else if (error) {
  //     setNotify({
  //       isOpen: true,
  //       message: error,
  //       type: 'error',
  //     })
  //   }
  // }, [attendance, error])

  const [openPopup, setOpenPopup] = useState(false)
  const [attendanceForEdit, setAttendanceForEdit] = useState(null)

  const openInPopup = (item) => {
    setAttendanceForEdit(item)
    setOpenPopup(true)
  }

  const headCells = [
    {
      field: 'employeeCode',
      title: 'Employee Code',
    },
    {
      field: 'name',
      title: 'Employee Name',
    },
    {
      field: 'time',
      title: 'Time',
    },
    {
      field: 'punch_in_status',
      title: 'Punch In Status',
    },
    {
      field: 'address',
      title: 'Address',
    },
    {
      field: 'punch_in_image',
      title: 'Image',
      render: (rowData) => (
        <a href={rowData.link} target='_blank'>
          View
        </a>
      ),
    },
    {
      field: 'status',
      title: 'Final Status',
      render: (rowData) => (
        <Controls.Button
          text={rowData.status}
          onClick={() => openInPopup(rowData)}
        />
      ),
    },
  ]

  return (
    <div>
      {/* {loading ? (
        <Progress />
      ) : (
        <>
          {error ? (
            <Notification notify={notify} setNotify={setNotify} />
          ) : ( */}
      <>
        <PageHeader
          title='Everyday Attendance'
          subtitle="Shows list of everyday's attendance of employees working in the organisations"
          icon={<PeopleOutlineTwoToneIcon fontSize='medium' />}
        />
        <Paper className={classes.pageContent}>
          <Grid container spacing={3}>
            <Grid item>
              <Typography>Search</Typography>
            </Grid>
            <Grid sm item />
          </Grid>
          <Form autoComplete='off' onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4}>
                <Controls.DatePicker
                  label='Date *'
                  name='date'
                  fullWidth
                  value={values.date}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Controls.Button
                  type='submit'
                  text='Search'
                  className={classes.submit}
                  loading={loading1}
                />
              </Grid>
            </Grid>
          </Form>
        </Paper>
        <MaterialTable
          style={{ margin: '0 20px' }}
          title='Everyday Attendance'
          columns={headCells}
          data={attendances}
          options={{
            exportButton: true,
            search: true,
            exportAllData: true,
          }}
        />
        <Popup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          title='Update Attendance'
        >
          <UpdateAttendance
            attendanceForEdit={attendanceForEdit}
            date={values.date}
            setOpenPopup={setOpenPopup}
          />
        </Popup>
      </>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  )
}

export default EverydayAttendance
