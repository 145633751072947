import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Notification from '../components/Notification'
import Progress from '../components/Progress'
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import PageHeader from '../components/PageHeader'
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import Controls from '../components/controls/Controls'
import { useForm, Form } from '../components/useForm'
import {
  billingGetReportsAction,
  billingMasterReportAction,
} from '../actions/billingActions'
import { BILLING_MASTER_REPORT_RESET } from '../constants/billingConstants'
import MaterialTable from 'material-table'

const initialValues = {
  from: null,
  to: null,
  category: '', // Flows or Recoveries
  masterDepartment: [],
  department: [],
  departmentType: '', // Collections or Verificatios
  fetchType: '', // Depending on this it will enable category, master department or department
}

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    overflowX: 'auto',
  },
  searchInput: {
    width: '75%',
  },
  download: {
    background: '#fff',
    padding: theme.spacing(1.25, 2),
    outline: 'none',
    border: '1px solid #333',
    borderRadius: 4,
    fontSize: 14,
    marginLeft: theme.spacing(2),
  },
}))

const BillingMasterReport = ({ history }) => {
  const classes = useStyles()

  const [reportData, setReportData] = useState([])
  const [masterDepartments, setMasterDepartments] = useState([])
  const [departments, setDepartments] = useState([])

  // Requirements
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const billingGetReports = useSelector((state) => state.billingGetReports)
  const { loading, error, billingReports } = billingGetReports

  useEffect(() => {
    dispatch(billingGetReportsAction())
  }, [])

  useEffect(() => {
    if (billingReports) {
      if (
        billingReports.role === 'Admin' ||
        billingReports.role === 'Billing'
      ) {
        setMasterDepartments(billingReports.masterDpts)
        setDepartments(billingReports.dpts)
      } else {
        history.push('/dashboard')
      }
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [billingReports, error])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      dispatch(
        billingMasterReportAction(
          values.from,
          values.to,
          values.departmentType,
          values.fetchType,
          values.category,
          values.masterDepartment,
          values.department
        )
      )
    }
  }

  const billingPostMasterReport = useSelector(
    (state) => state.billingPostMasterReport
  )
  const { loading1, error1, billingMasterReportData } = billingPostMasterReport

  const [tags, setTags] = useState([])
  const [headCells, setHeadCells] = useState([])

  useEffect(() => {
    dispatch({ type: BILLING_MASTER_REPORT_RESET })
    if (billingMasterReportData) {
      setReportData(billingMasterReportData.finalItems)
      setHeadCells(billingMasterReportData.headCells)
      setTags(billingMasterReportData.tags)
    } else if (error1) {
      setNotify({
        isOpen: true,
        message: error1,
        type: 'error',
      })
    }
  }, [billingMasterReportData, error1])

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('from' in fieldValues)
      temp.from = fieldValues.from ? '' : 'This field is required'
    if ('to' in fieldValues)
      temp.to = fieldValues.to ? '' : 'This field is required'
    if ('departmentType' in fieldValues)
      temp.departmentType =
        fieldValues.departmentType.length !== 0 ? '' : 'This field is required'
    if ('fetchType' in fieldValues)
      temp.fetchType =
        fieldValues.fetchType.length !== 0 ? '' : 'This field is required'
    setErrors({
      ...temp,
    })
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '')
  }

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  )

  return (
    <div>
      <PageHeader
        title='Master Report'
        subtitle='Shows fetched reports'
        icon={<PeopleOutlineTwoToneIcon fontSize='medium' />}
      />
      <Paper className={classes.pageContent}>
        <Grid container spacing={3}>
          <Grid item>
            <Typography>Search</Typography>
          </Grid>
          <Grid sm item />
        </Grid>
        <Form autoComplete='off' onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <Controls.DatePicker
                label='From *'
                name='from'
                fullWidth
                value={values.from}
                onChange={handleInputChange}
                error={errors.from}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Controls.DatePicker
                label='To *'
                name='to'
                fullWidth
                value={values.to}
                onChange={handleInputChange}
                error={errors.to}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Controls.Select
                label='Department Type *'
                name='departmentType'
                fullWidth
                value={values.departmentType}
                onChange={handleInputChange}
                error={errors.departmentType}
                options={[
                  { id: 'Collections', title: 'Collections' },
                  { id: 'Verifications', title: 'Verifications' },
                ]}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Controls.Select
                label='Fetch Type *'
                name='fetchType'
                fullWidth
                value={values.fetchType}
                onChange={handleInputChange}
                error={errors.fetchType}
                options={[
                  { id: 'Category Wise', title: 'Category Wise' },
                  {
                    id: 'Master Department Wise',
                    title: 'Master Department Wise',
                  },
                  { id: 'Department Wise', title: 'Department Wise' },
                ]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {values.fetchType === 'Category Wise' && (
              <Grid item xs={12} lg={3}>
                <Controls.Select
                  label='Category *'
                  name='category'
                  fullWidth
                  value={values.category}
                  onChange={handleInputChange}
                  options={
                    values.departmentType === 'Collections'
                      ? [
                          { id: 'Flows', title: 'Flows' },
                          {
                            id: 'Recoveries',
                            title: 'Recoveries',
                          },
                        ]
                      : [{ id: 'Verifications', title: 'Verifications' }]
                  }
                />
              </Grid>
            )}
            {values.fetchType === 'Master Department Wise' && (
              <Grid item xs={12} lg={3}>
                <Controls.Select
                  label='Master Department *'
                  name='masterDepartment'
                  fullWidth
                  value={values.masterDepartment}
                  onChange={handleInputChange}
                  options={masterDepartments}
                  multiple
                />
              </Grid>
            )}
            {values.fetchType === 'Department Wise' && (
              <Grid item xs={12} lg={3}>
                <Controls.Select
                  label='Department *'
                  name='department'
                  fullWidth
                  value={values.department}
                  onChange={handleInputChange}
                  options={departments}
                  multiple
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <Controls.Button
                type='submit'
                text='Search'
                className={classes.submit}
                loading={loading1}
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>
      <>
        {loading1 ? (
          <Progress />
        ) : (
          <>
            {error1 ? (
              <Notification notify={notify} setNotify={setNotify} />
            ) : (
              <>
                <MaterialTable
                  style={{ margin: '0 20px' }}
                  title='Master Report'
                  columns={headCells}
                  data={reportData}
                  options={{
                    exportButton: true,
                    search: true,
                    exportAllData: true,
                  }}
                />
              </>
            )}
          </>
        )}
      </>
    </div>
  )
}

export default BillingMasterReport
