import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { accountsEmployeesAction } from '../actions/accountActions'
import Notification from '../components/Notification'
import Progress from '../components/Progress'
import PageHeader from '../components/PageHeader'
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import MaterialTable from 'material-table'

const headCells = [
  {
    field: 'employee_code',
    title: 'Employee Code',
    render: (rowData) => (
      <a href={`/accounts/employee/${rowData.id}`} target='_blank'>
        {rowData.employee_code}
      </a>
    ),
  },
  {
    field: 'name',
    title: 'Employee Name',
  },
  {
    field: 'organisation',
    title: 'Organisation',
  },
  {
    field: 'department',
    title: 'Department',
  },
  {
    field: 'doj',
    title: 'Date of Joining',
  },
  {
    field: 'employee_type',
    title: 'Employee Type',
  },
  {
    field: 'job_title',
    title: 'Job Title',
  },
  {
    field: 'dol',
    title: 'Date of Leaving',
  },
]

const AccountsEmployees = ({ history }) => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const accountsEmployees = useSelector((state) => state.accountsEmployees)
  const { loading, error, data } = accountsEmployees

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const [employees, setEmployees] = useState([])

  useEffect(() => {
    dispatch(accountsEmployeesAction())
  }, [])

  useEffect(() => {
    if (data) {
      if (data.role === 'Admin' || data.role === 'Accounts') {
        setEmployees(data.employees)
      } else {
        history.push('/dashboard')
      }
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [data, error])

  return (
    <div>
      {loading ? (
        <Progress />
      ) : (
        <>
          {error ? (
            <Notification notify={notify} setNotify={setNotify} />
          ) : (
            <>
              <PageHeader
                title='Employees'
                subtitle='Shows list of employees working in the organisations'
                icon={<PeopleOutlineTwoToneIcon fontSize='medium' />}
              />
              <MaterialTable
                style={{ margin: '0 20px' }}
                title='Employees'
                columns={headCells}
                data={employees}
                options={{
                  exportButton: true,
                  search: true,
                  exportAllData: true,
                }}
              />
            </>
          )}
        </>
      )}
    </div>
  )
}

export default AccountsEmployees
