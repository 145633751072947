import {
  EXTENSIONS_REQUEST,
  EXTENSIONS_SUCCESS,
  EXTENSIONS_FAIL,
  ADD_EXTENSION_REQUEST,
  ADD_EXTENSION_SUCCESS,
  ADD_EXTENSION_FAIL,
  UPDATE_EXTENSION_REQUEST,
  UPDATE_EXTENSION_SUCCESS,
  UPDATE_EXTENSION_FAIL,
  LIVE_CALLS_REQUEST,
  LIVE_CALLS_SUCCESS,
  LIVE_CALLS_FAIL,
  CALLS_MIS_REQUEST,
  CALLS_MIS_SUCCESS,
  CALLS_MIS_FAIL,
  CALLS_RECORDINGS_GET_REQUEST,
  CALLS_RECORDINGS_GET_SUCCESS,
  CALLS_RECORDINGS_GET_FAIL,
  CALLS_RECORDINGS_REQUEST,
  CALLS_RECORDINGS_SUCCESS,
  CALLS_RECORDINGS_FAIL,
} from '../constants/callingConstants'
import axios from 'axios'
import { logout } from './userActions'

export const extensionsAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: EXTENSIONS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/extensions/web', config)

    dispatch({
      type: EXTENSIONS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: EXTENSIONS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addExtensionsAction =
  (did, extension, is_active, password) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_EXTENSION_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        '/api/extensions/add/web',
        { did, extension, is_active, password },
        config
      )

      dispatch({
        type: ADD_EXTENSION_SUCCESS,
        payload: data,
      })
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout())
        return
      }
      dispatch({
        type: ADD_EXTENSION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateExtensionsAction =
  (id, did, extension, is_active, password) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_EXTENSION_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/extensions/${id}/update/web`,
        { did, extension, is_active, password },
        config
      )

      dispatch({
        type: UPDATE_EXTENSION_SUCCESS,
        payload: data,
      })
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logout())
        return
      }
      dispatch({
        type: UPDATE_EXTENSION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const liveCallsAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: LIVE_CALLS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/extensions/live-calls/web', config)

    dispatch({
      type: LIVE_CALLS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: LIVE_CALLS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const callsMISAction =
  (from, to, employee) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CALLS_MIS_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        '/api/extensions/mis/web',
        { from, to, employee },
        config
      )

      dispatch({
        type: CALLS_MIS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: CALLS_MIS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const callsGetRecordingsAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CALLS_RECORDINGS_GET_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/extensions/recordings/web', config)

    dispatch({
      type: CALLS_RECORDINGS_GET_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CALLS_RECORDINGS_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const callsRecordingsAction =
  (type, employee, number, from, to) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CALLS_RECORDINGS_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        '/api/extensions/recordings/web',
        { type, employee, number, from, to },
        config
      )

      dispatch({
        type: CALLS_RECORDINGS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: CALLS_RECORDINGS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
