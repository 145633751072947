import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@material-ui/core'
import React from 'react'

const Select = ({
  name,
  value,
  label,
  onChange,
  options,
  error = null,
  ...other
}) => {
  return (
    <FormControl
      variant='outlined'
      {...(error && { error: true })}
      size='small'
      style={{ marginTop: '8px' }}
    >
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        {...other}
      >
        <MenuItem value=''>None</MenuItem>
        {options.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default Select
