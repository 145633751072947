import {
  ACCOUNTS_FINALIZE_BACKEND_SALARIES_FAIL,
  ACCOUNTS_FINALIZE_BACKEND_SALARIES_REQUEST,
  ACCOUNTS_FINALIZE_BACKEND_SALARIES_RESET,
  ACCOUNTS_FINALIZE_BACKEND_SALARIES_SUCCESS,
  ACCOUNTS_GET_EMPLOYEES_FAIL,
  ACCOUNTS_GET_EMPLOYEES_REQUEST,
  ACCOUNTS_GET_EMPLOYEES_RESET,
  ACCOUNTS_GET_EMPLOYEES_SUCCESS,
  ACCOUNTS_GET_EMPLOYEE_BY_ID_FAIL,
  ACCOUNTS_GET_EMPLOYEE_BY_ID_REQUEST,
  ACCOUNTS_GET_EMPLOYEE_BY_ID_RESET,
  ACCOUNTS_GET_EMPLOYEE_BY_ID_SUCCESS,
  ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_FAIL,
  ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_REQUEST,
  ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_RESET,
  ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_SUCCESS,
  ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_FAIL,
  ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_REQUEST,
  ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_RESET,
  ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_SUCCESS,
  ACCOUNTS_UPDATE_SALARY_APPROVAL_FAIL,
  ACCOUNTS_UPDATE_SALARY_APPROVAL_REQUEST,
  ACCOUNTS_UPDATE_SALARY_APPROVAL_RESET,
  ACCOUNTS_UPDATE_SALARY_APPROVAL_SUCCESS,
  ACCOUNTS_UPDATE_SALARY_INFORMATION_FAIL,
  ACCOUNTS_UPDATE_SALARY_INFORMATION_REQUEST,
  ACCOUNTS_UPDATE_SALARY_INFORMATION_RESET,
  ACCOUNTS_UPDATE_SALARY_INFORMATION_SUCCESS,
} from '../constants/accountConstants'

export const accountsEmployeesReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNTS_GET_EMPLOYEES_REQUEST:
      return { loading: true }
    case ACCOUNTS_GET_EMPLOYEES_SUCCESS:
      return { loading: false, data: action.payload }
    case ACCOUNTS_GET_EMPLOYEES_FAIL:
      return { loading: false, error: action.payload }
    case ACCOUNTS_GET_EMPLOYEES_RESET:
      return {}
    default:
      return state
  }
}

export const accountsEmployeeByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNTS_GET_EMPLOYEE_BY_ID_REQUEST:
      return { loading: true }
    case ACCOUNTS_GET_EMPLOYEE_BY_ID_SUCCESS:
      return { loading: false, data: action.payload }
    case ACCOUNTS_GET_EMPLOYEE_BY_ID_FAIL:
      return { loading: false, error: action.payload }
    case ACCOUNTS_GET_EMPLOYEE_BY_ID_RESET:
      return {}
    default:
      return state
  }
}

export const accountsUpdateSalaryReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNTS_UPDATE_SALARY_INFORMATION_REQUEST:
      return { loading3: true }
    case ACCOUNTS_UPDATE_SALARY_INFORMATION_SUCCESS:
      return { loading3: false, result: action.payload }
    case ACCOUNTS_UPDATE_SALARY_INFORMATION_FAIL:
      return { loading3: false, error3: action.payload }
    case ACCOUNTS_UPDATE_SALARY_INFORMATION_RESET:
      return {}
    default:
      return state
  }
}

export const accountsGetProcessBackendSalariesReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_REQUEST:
      return { loading: true }
    case ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_SUCCESS:
      return { loading: false, result: action.payload }
    case ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_FAIL:
      return { loading: false, error: action.payload }
    case ACCOUNTS_GET_PROCESS_BACKEND_SALARIES_RESET:
      return {}
    default:
      return state
  }
}

export const accountsUpdateProcessBackendSalariesReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_REQUEST:
      return { loading: true }
    case ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_SUCCESS:
      return { loading: false, result: action.payload }
    case ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_FAIL:
      return { loading: false, error: action.payload }
    case ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_RESET:
      return {}
    default:
      return state
  }
}

export const accountsFinalizeBackendSalariesReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNTS_FINALIZE_BACKEND_SALARIES_REQUEST:
      return { loading1: true }
    case ACCOUNTS_FINALIZE_BACKEND_SALARIES_SUCCESS:
      return { loading1: false, resultFinalize: action.payload }
    case ACCOUNTS_FINALIZE_BACKEND_SALARIES_FAIL:
      return { loading1: false, error1: action.payload }
    case ACCOUNTS_FINALIZE_BACKEND_SALARIES_RESET:
      return {}
    default:
      return state
  }
}

export const accountsUpdateSalaryApprovalReducer = (state = {}, action) => {
  switch (action.type) {
    case ACCOUNTS_UPDATE_SALARY_APPROVAL_REQUEST:
      return { loading4: true }
    case ACCOUNTS_UPDATE_SALARY_APPROVAL_SUCCESS:
      return { loading4: false, resultData: action.payload }
    case ACCOUNTS_UPDATE_SALARY_APPROVAL_FAIL:
      return { loading4: false, error4: action.payload }
    case ACCOUNTS_UPDATE_SALARY_APPROVAL_RESET:
      return {}
    default:
      return state
  }
}
