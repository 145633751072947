import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Controls from './controls/Controls'
import Notification from './Notification'
import { Form, useForm } from './useForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  settingsAction,
  settingsAddBankAction,
} from '../actions/settingActions'
import { SETTINGS_ADD_BANK_RESET } from '../constants/settingConstants'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const initialValues = {
  name: '',
}

const AddBank = ({ setOpenPopup }) => {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

  const classes = useStyles()
  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('name' in fieldValues)
      temp.name = fieldValues.name ? '' : 'This field is required'
    setErrors({
      ...temp,
    })
    if (fieldValues === values) return Object.values(temp).every((x) => x == '')
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate)

  const dispatch = useDispatch()

  const settingsAddBank = useSelector((state) => state.settingsAddBank)
  const { loading, error, addBank } = settingsAddBank

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      dispatch(settingsAddBankAction(values.name))
    }
  }

  useEffect(() => {
    dispatch({ type: SETTINGS_ADD_BANK_RESET })
    if (addBank) {
      setNotify({
        isOpen: true,
        message: addBank.msg,
        type: 'success',
      })
      setTimeout(() => {
        setOpenPopup(false)
        dispatch(settingsAction())
      }, 2000)
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [dispatch, addBank, error])

  return (
    <>
      <Form autoComplete='off' onSubmit={handleSubmit}>
        <Controls.Input
          label='Name *'
          name='name'
          value={values.name}
          onChange={handleInputChange}
          error={errors.name}
        />
        <Controls.Button
          type='submit'
          fullWidth
          text='submit'
          className={classes.submit}
          loading={loading}
        />
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}

export default AddBank
