import React, { useEffect, useState } from 'react'
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import Notification from '../components/Notification'
import PageHeader from '../components/PageHeader'
import { useDispatch, useSelector } from 'react-redux'
import SearchIcon from '@material-ui/icons/Search'
import XLSX from 'xlsx'
import { make_cols } from '../components/ExcelMakeColumns'
import { SheetJSFT } from '../components/ExcelTypes'
import { dataUploadAction } from '../actions/dataActions'
import { DATA_UPLOAD_RESET } from '../constants/dataConstants'

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    overflowX: 'scroll',
  },
  searchInput: {
    width: '75%',
  },
  link: {
    textDecoration: 'none',
  },
  submit: {
    margin: theme.spacing(2, 0, 0),
  },
  download: {
    background: '#fff',
    padding: theme.spacing(1.25, 2),
    outline: 'none',
    border: '1px solid #333',
    borderRadius: 4,
    fontSize: 14,
    marginLeft: theme.spacing(2),
  },
  upload: {
    background: '#253053',
    padding: theme.spacing(1.25, 2),
    outline: 'none',
    borderRadius: 4,
    fontSize: 14,
    color: 'white',
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
}))

const initialValues = {
  number: '',
  name: '',
  address: '',
}

const DataUpload = ({ history }) => {
  const classes = useStyles()

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const userDashboard = useSelector((state) => state.userDashboard)
  const { userData } = userDashboard

  useEffect(() => {
    if (!userData || userData.role !== 'Admin') {
      history.push('/dashboard')
    }
  }, [dispatch, history, userData])

  // Excel read
  const [file, setFile] = useState({})
  const [data, setData] = useState([])
  const [cols, setCols] = useState([])

  const handleChange = (e) => {
    const files = e.target.files
    if (files && files[0]) {
      setFile(files[0])
    }
  }

  const handleFile = () => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result
      const wb = XLSX.read(bstr, {
        type: rABS ? 'binary' : 'array',
        bookVBA: true,
      })
      /* Get first worksheet */
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      /* Convert array of arrays */
      const dataFound = XLSX.utils.sheet_to_json(ws)
      /* Update state */
      setData(dataFound)
      setCols(make_cols(ws['!ref']))

      // dispatch
      if (dataFound.length > 0) {
        dispatch(dataUploadAction(dataFound))
      }
    }

    if (rABS) {
      reader.readAsBinaryString(file)
    } else {
      reader.readAsArrayBuffer(file)
    }
  }

  const dataUpload = useSelector((state) => state.dataUpload)
  const { loading, error, result } = dataUpload

  useEffect(() => {
    dispatch({ type: DATA_UPLOAD_RESET })
    if (result) {
      setNotify({
        isOpen: true,
        message: result.msg,
        type: 'success',
      })
      setTimeout(() => {
        history.push('/data')
      }, 2000)
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [result, error])

  return (
    <div>
      <>
        <PageHeader
          title='Data Upload'
          subtitle='Uploads data for later on searches'
          icon={<SearchIcon fontSize='medium' />}
        />
        <Paper className={classes.pageContent}>
          <Grid container spacing={3}>
            <Grid item>
              <Typography>Search</Typography>
            </Grid>
            <Grid sm item />
          </Grid>
          <div style={{ display: 'flex', marginTop: '10px' }}>
            <input
              type='file'
              className='form-control'
              id='file'
              accept={SheetJSFT}
              onChange={handleChange}
            />
            <br />
            <input type='submit' value='Upload' onClick={handleFile} />
          </div>
        </Paper>
        <Notification notify={notify} setNotify={setNotify} />
      </>
    </div>
  )
}

export default DataUpload
