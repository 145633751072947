export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_CHANGE_PASSWORD_REQUEST = 'USER_CHANGE_PASSWORD_REQUEST'
export const USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS'
export const USER_CHANGE_PASSWORD_FAIL = 'USER_CHANGE_PASSWORD_FAIL'
export const USER_CHANGE_PASSWORD_RESET = 'USER_CHANGE_PASSWORD_RESET'

export const USER_DASHBOARD_REQUEST = 'USER_DASHBOARD_REQUEST'
export const USER_DASHBOARD_SUCCESS = 'USER_DASHBOARD_SUCCESS'
export const USER_DASHBOARD_FAIL = 'USER_DASHBOARD_FAIL'

export const DIAL_REQUEST = 'DIAL_REQUEST'
export const DIAL_SUCCESS = 'DIAL_SUCCESS'
export const DIAL_FAIL = 'DIAL_FAIL'
export const DIAL_RESET = 'DIAL_RESET'

export const SUBMIT_EVERYDAY_REPORTING_REQUEST =
  'SUBMIT_EVERYDAY_REPORTING_REQUEST'
export const SUBMIT_EVERYDAY_REPORTING_SUCCESS =
  'SUBMIT_EVERYDAY_REPORTING_SUCCESS'
export const SUBMIT_EVERYDAY_REPORTING_FAIL = 'SUBMIT_EVERYDAY_REPORTING_FAIL'
export const SUBMIT_EVERYDAY_REPORTING_RESET = 'SUBMIT_EVERYDAY_REPORTING_RESET'

export const GET_ATTENDANCE_REVIEW_REQUEST = 'GET_ATTENDANCE_REVIEW_REQUEST'
export const GET_ATTENDANCE_REVIEW_SUCCESS = 'GET_ATTENDANCE_REVIEW_SUCCESS'
export const GET_ATTENDANCE_REVIEW_FAIL = 'GET_ATTENDANCE_REVIEW_FAIL'
export const GET_ATTENDANCE_REVIEW_RESET = 'GET_ATTENDANCE_REVIEW_RESET'
