import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Controls from './controls/Controls'
import Notification from './Notification'
import { Form, useForm } from './useForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  hrExitEmployeeAction,
  hrGetEmployeeByIdAction,
} from '../actions/hrActions'
import { HR_EXIT_EMPLOYEE_RESET } from '../constants/hrConstants'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  file: {
    marginTop: '20px',
  },
}))

const initialValues = {
  date: new Date(),
  reason: '',
  exit_status: '',
  details: '',
}

const HRExit = ({ setOpenExit, employeeID }) => {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
  const [file, setFile] = useState(null)

  const classes = useStyles()
  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('date' in fieldValues)
      temp.date = fieldValues.date ? '' : 'This field is required'
    if ('reason' in fieldValues)
      temp.reason =
        fieldValues.reason.length !== 0 ? '' : 'This field is required'
    if ('exit_status' in fieldValues)
      temp.exit_status =
        fieldValues.exit_status.length !== 0 ? '' : 'This field is required'
    setErrors({
      ...temp,
    })
    if (fieldValues === values) return Object.values(temp).every((x) => x == '')
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate)

  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!values.date || !values.reason || !values.exit_status) {
      setNotify({
        isOpen: true,
        message: 'All the with * fields are mandatory',
        type: 'error',
      })
    } else {
      dispatch(
        hrExitEmployeeAction(
          employeeID,
          values.date,
          values.reason,
          values.exit_status,
          values.details
        )
      )
    }
  }

  const hrExitEmployee = useSelector((state) => state.hrExitEmployee)
  const { loading, error, exitEmployee } = hrExitEmployee

  useEffect(() => {
    dispatch({ type: HR_EXIT_EMPLOYEE_RESET })
    if (exitEmployee) {
      setNotify({
        isOpen: true,
        message: exitEmployee.msg,
        type: 'success',
      })
      setTimeout(() => {
        setOpenExit(false)
        dispatch(hrGetEmployeeByIdAction(employeeID))
      }, 2000)
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [exitEmployee, error, dispatch, employeeID])

  return (
    <>
      <Form autoComplete='off' onSubmit={handleSubmit}>
        <Controls.DatePicker
          label='Date *'
          name='date'
          value={values.date}
          onChange={handleInputChange}
          error={errors.date}
        />
        <Controls.Select
          label='Reason *'
          name='reason'
          value={values.reason}
          onChange={handleInputChange}
          error={errors.reason}
          options={[
            { id: 'Resign', title: 'Resign' },
            { id: 'Terminate', title: 'Terminate' },
          ]}
        />
        <Controls.Select
          label='Exit Status *'
          name='exit_status'
          value={values.exit_status}
          onChange={handleInputChange}
          error={errors.exit_status}
          options={[
            { id: 'Full & Final Pending', title: 'Full & Final Pending' },
            { id: 'Full & Final Complete', title: 'Full & Final Complete' },
          ]}
        />
        <Controls.Input
          label='Details'
          name='details'
          value={values.details}
          onChange={handleInputChange}
        />
        <Controls.Button
          type='submit'
          fullWidth
          text='submit'
          className={classes.submit}
          loading={loading}
        />
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}

export default HRExit
