import { Grid, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Controls from './controls/Controls'
import Notification from './Notification'
import { Form, useForm } from './useForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  accountsGetProcessBackendSalariesAction,
  accountsUpdateProcessBackendSalariesAction,
} from '../actions/accountActions'
import { ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_RESET } from '../constants/accountConstants'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(2, 0, 0),
  },
  process: {
    margin: theme.spacing(0, 0, 1),
  },
}))

const initialValues = {
  salary_as_per_attendance: 0,
  final_salary: 0,
  adjustment_amount: '',
  adjustment_type: '',
  adjustment_reason: '',
  status: '',
}

const AdjustSalary = ({ setOpenPopup, id, salaryForUpdate, results }) => {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

  const classes = useStyles()
  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    setErrors({
      ...temp,
    })
    if (fieldValues === values) return Object.values(temp).every((x) => x == '')
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate)

  useEffect(() => {
    if (salaryForUpdate != null) {
      setValues({
        ...salaryForUpdate,
      })
    }
  }, [salaryForUpdate])

  const dispatch = useDispatch()

  const process = () => {
    if (values.status !== '') {
      if (values.adjustment_type === 'Increment') {
        // Check if amount or reason blank
        if (
          values.adjustment_amount !== '' ||
          values.adjustment_reason !== ''
        ) {
          let newSalary =
            parseFloat(values.adjustment_amount) +
            parseFloat(values.salary_as_per_attendance)

          // dispatch
          dispatch(
            accountsUpdateProcessBackendSalariesAction(
              id,
              salaryForUpdate.employee,
              values.adjustment_amount,
              values.adjustment_type,
              values.adjustment_reason,
              newSalary,
              values.status
            )
          )
        } else {
          setNotify({
            isOpen: true,
            message: 'Amount and Reason are mandatory',
            type: 'error',
          })
        }
      } else if (values.adjustment_type === 'Decrement') {
        // Check if amount is blank
        if (
          values.adjustment_amount !== '' ||
          values.adjustment_reason !== ''
        ) {
          let newSalary =
            parseFloat(values.salary_as_per_attendance) -
            parseFloat(values.adjustment_amount)

          // dispatch
          dispatch(
            accountsUpdateProcessBackendSalariesAction(
              id,
              salaryForUpdate.employee,
              values.adjustment_amount,
              values.adjustment_type,
              values.adjustment_reason,
              newSalary,
              values.status
            )
          )
        } else {
          setNotify({
            isOpen: true,
            message: 'Amount and Reason are mandatory',
            type: 'error',
          })
        }
      } else {
        // dispatch
        dispatch(
          accountsUpdateProcessBackendSalariesAction(
            id,
            salaryForUpdate.employee,
            0,
            '',
            '',
            values.salary_as_per_attendance,
            values.status
          )
        )
      }
    } else {
      setNotify({
        isOpen: true,
        message: 'Status is mandatory',
        type: 'error',
      })
    }
  }

  const updateProcessBackendSalaries = useSelector(
    (state) => state.updateProcessBackendSalaries
  )
  const { loading, error, result } = updateProcessBackendSalaries

  useEffect(() => {
    dispatch({ type: ACCOUNTS_UPDATE_PROCESS_BACKEND_SALARIES_RESET })
    if (result) {
      setNotify({
        isOpen: true,
        message: result.msg,
        type: 'success',
      })
      setTimeout(() => {
        setOpenPopup(false)
        dispatch(accountsGetProcessBackendSalariesAction(id))
      }, 1000)
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [dispatch, result, error])

  return (
    <>
      <Form autoComplete='off'>
        <Controls.Select
          label='Adjustment Type'
          name='adjustment_type'
          value={values.adjustment_type}
          onChange={handleInputChange}
          options={[
            { id: 'Increment', title: 'Increment' },
            { id: 'Decrement', title: 'Decrement' },
          ]}
        />
        <Controls.Input
          label='Amount'
          name='adjustment_amount'
          value={values.adjustment_amount}
          onChange={handleInputChange}
          disabled={values.adjustment_type === ''}
          type='number'
          inputProps={{
            min: 0,
          }}
          disabled={
            values.adjustment_type !== 'Increment' &&
            values.adjustment_type !== 'Decrement'
          }
        />
        <Controls.Input
          label='Reason'
          name='adjustment_reason'
          value={values.adjustment_reason}
          onChange={handleInputChange}
          disabled={
            values.adjustment_type !== 'Increment' &&
            values.adjustment_type !== 'Decrement'
          }
        />
        <Controls.Select
          label='Status *'
          name='status'
          value={values.status}
          onChange={handleInputChange}
          options={[
            { id: 'Processed', title: 'Processed' },
            { id: 'On Hold', title: 'On Hold' },
          ]}
        />
        <Controls.Button
          fullWidth
          text='process'
          className={classes.submit}
          onClick={process}
          loading={loading}
        />
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}

export default AdjustSalary
