import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Notification from '../components/Notification'
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import PageHeader from '../components/PageHeader'
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import Controls from '../components/controls/Controls'
import { useForm, Form } from '../components/useForm'
import {
  billingGetReportsAction,
  billingReportsAction,
} from '../actions/billingActions'
import { BILLING_REPORTS_RESET } from '../constants/billingConstants'
import MaterialTable from 'material-table'

const initialValues = {
  dataType: '', // Monthly or Daily
  from: null,
  to: null,
  category: '', // Flows or Recoveries
  masterDepartment: [],
  department: [],
  departmentType: '', // Collections or Verificatios
  // reportType: [], // POS, Resolution, Rollback, Normalisation, Total Cases, Settlement, Penal, Total Collection
  // filterType: '', // Average or Sum
  fetchType: '', // Depending on this it will enable category, master department or department
  security: '',
  product: [],
  bankType: '',
}

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    overflowX: 'auto',
  },
  searchInput: {
    width: '75%',
  },
  download: {
    background: '#fff',
    padding: theme.spacing(1.25, 2),
    outline: 'none',
    border: '1px solid #333',
    borderRadius: 4,
    fontSize: 14,
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

const BillingReports = ({ history }) => {
  const classes = useStyles()

  const [reportData, setReportData] = useState([])
  const [collectionTags, setCollectionsTags] = useState([])
  const [verificationTags, setVerificationTags] = useState([])
  const [masterDepartments, setMasterDepartments] = useState([])
  const [departments, setDepartments] = useState([])
  const [tags, setTags] = useState([])

  // Requirements
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const billingGetReports = useSelector((state) => state.billingGetReports)
  const { loading, error, billingReports } = billingGetReports

  useEffect(() => {
    dispatch(billingGetReportsAction())
  }, [])

  useEffect(() => {
    if (billingReports) {
      if (
        billingReports.role === 'Admin' ||
        billingReports.role === 'Billing'
      ) {
        setCollectionsTags(billingReports.collectionTags)
        setVerificationTags(billingReports.verificationTags)
        setMasterDepartments(billingReports.masterDpts)
        setDepartments(billingReports.dpts)
      } else {
        history.push('/dashboard')
      }
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [billingReports, error])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      if (values.fetchType === 'Category Wise' && !values.category) {
        setNotify({
          isOpen: true,
          message: 'Please select a valid category from the list',
          type: 'error',
        })
        return
      }
      if (
        values.fetchType === 'Master Department Wise' &&
        values.masterDepartment.length === 0
      ) {
        setNotify({
          isOpen: true,
          message: 'Please select a valid master department from the list',
          type: 'error',
        })
        return
      }
      if (values.fetchType === 'Security Wise' && !values.security) {
        setNotify({
          isOpen: true,
          message: 'Please select a valid security type from the list',
          type: 'error',
        })
        return
      }

      dispatch(
        billingReportsAction(
          values.dataType,
          values.from,
          values.to,
          values.departmentType,
          values.fetchType,
          values.category,
          values.masterDepartment,
          values.department,
          values.security,
          values.product,
          values.bankType
        )
      )
    }
  }

  const billingPostReports = useSelector((state) => state.billingPostReports)
  const { loading1, error1, billingReportsData } = billingPostReports

  const [headCells, setHeadCells] = useState([])
  const [totals, setTotals] = useState([])
  const [numberOfCases, setNumberOfCases] = useState(0)
  const [averageNumberOfCases, setAverageNumberOfCases] = useState(0)
  const [pos, setPos] = useState(0)
  const [averagePos, setAveragePos] = useState(0)
  const [searchType, setSearchType] = useState('Sum')
  const [departmentsList, setDepartmentsList] = useState([])

  useEffect(() => {
    dispatch({ type: BILLING_REPORTS_RESET })
    if (billingReportsData) {
      setReportData(billingReportsData.finalItems)
      setHeadCells(billingReportsData.headCells)
      setTotals(billingReportsData.totals)
      setTags(billingReportsData.tags)
      setNumberOfCases(billingReportsData.numberOfCases)
      setAverageNumberOfCases(billingReportsData.averageNumberOfCases)
      setPos(billingReportsData.pos)
      setAveragePos(billingReportsData.averagePos)
      setSearchType(billingReportsData.searchType)
    } else if (error1) {
      setNotify({
        isOpen: true,
        message: error1,
        type: 'error',
      })
    }
  }, [billingReportsData, error1])

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('dataType' in fieldValues)
      temp.dataType =
        fieldValues.dataType.length !== 0 ? '' : 'This field is required'
    if ('from' in fieldValues)
      temp.from = fieldValues.from ? '' : 'This field is required'
    if ('to' in fieldValues)
      temp.to = fieldValues.to ? '' : 'This field is required'
    if ('departmentType' in fieldValues)
      temp.departmentType =
        fieldValues.departmentType.length !== 0 ? '' : 'This field is required'
    if ('fetchType' in fieldValues)
      temp.fetchType =
        fieldValues.fetchType.length !== 0 ? '' : 'This field is required'
    setErrors({
      ...temp,
    })
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '')
  }

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  )

  const handleFetchType = (e) => {
    setValues({
      ...values,
      fetchType: e.target.value,
      category: '',
      masterDepartment: [],
      security: '',
      product: [],
      department: [],
      bankType: '',
    })
    setDepartmentsList([])
    if (e.target.value == 'Department Wise') {
      setDepartmentsList(departments)
    }
  }

  const handleCategory = (e) => {
    setValues({
      ...values,
      category: e.target.value,
    })
    if (e.target.value == 'All') {
      setDepartmentsList(departments)
    } else if (e.target.value == 'Flows') {
      setDepartmentsList(departments.filter((dpt) => dpt.category == 'Flows'))
    } else if (e.target.value == 'Recoveries') {
      setDepartmentsList(
        departments.filter((dpt) => dpt.category == 'Recoveries')
      )
    } else {
      setDepartmentsList([])
    }
  }

  const handleMasterDpt = (e) => {
    setValues({
      ...values,
      masterDepartment: e.target.value,
    })
    if (e.target.value) {
      let list = []
      e.target.value.forEach((item) => {
        let array = departments.filter((dpt) => dpt.master_department == item)
        array.forEach((obj) => {
          list.push(obj)
        })
      })
      setDepartmentsList(list)
    } else {
      setDepartmentsList([])
    }
  }

  const handleSecurity = (e) => {
    setValues({
      ...values,
      security: e.target.value,
    })
    if (e.target.value == 'All') {
      setDepartmentsList(departments)
    } else if (e.target.value == 'Secured') {
      setDepartmentsList(departments.filter((dpt) => dpt.security == 'Secured'))
    } else if (e.target.value == 'Unsecured') {
      setDepartmentsList(
        departments.filter((dpt) => dpt.security == 'Unsecured')
      )
    } else {
      setDepartmentsList([])
    }
  }

  const handleProduct = (e) => {
    setValues({
      ...values,
      product: e.target.value,
    })
    if (e.target.value) {
      let list = []
      e.target.value.forEach((item) => {
        let array = departments.filter((dpt) => dpt.product == item)
        array.forEach((obj) => {
          list.push(obj)
        })
      })
      setDepartmentsList(list)
    } else {
      setDepartmentsList([])
    }
  }

  const handleEntity = (e) => {
    setValues({
      ...values,
      bankType: e.target.value,
    })
    if (e.target.value == 'All') {
      setDepartmentsList(departments)
    } else if (e.target.value == 'Bank') {
      setDepartmentsList(departments.filter((dpt) => dpt.bankType == 'Bank'))
    } else if (e.target.value == 'NBFC') {
      setDepartmentsList(departments.filter((dpt) => dpt.bankType == 'NBFC'))
    } else {
      setDepartmentsList([])
    }
  }

  return (
    <div>
      <PageHeader
        title='Reports'
        subtitle='Shows fetched reports'
        icon={<PeopleOutlineTwoToneIcon fontSize='medium' />}
      />
      <Paper className={classes.pageContent}>
        <Grid container spacing={3}>
          <Grid item>
            <Typography>Search</Typography>
          </Grid>
          <Grid sm item />
        </Grid>
        <Form autoComplete='off' onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <Controls.Select
                label='Data Type *'
                name='dataType'
                fullWidth
                value={values.dataType}
                onChange={handleInputChange}
                error={errors.dataType}
                options={[
                  { id: 'Monthly', title: 'Monthly' },
                  { id: 'Daily', title: 'Daily' },
                ]}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Controls.DatePicker
                label='From *'
                name='from'
                fullWidth
                value={values.from}
                onChange={handleInputChange}
                error={errors.from}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Controls.DatePicker
                label='To *'
                name='to'
                fullWidth
                value={values.to}
                onChange={handleInputChange}
                error={errors.to}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Controls.Select
                label='Department Type *'
                name='departmentType'
                fullWidth
                value={values.departmentType}
                onChange={handleInputChange}
                error={errors.departmentType}
                options={[
                  { id: 'Collections', title: 'Collections' },
                  // { id: 'Verifications', title: 'Verifications' },
                ]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <Controls.Select
                label='Fetch Type *'
                name='fetchType'
                fullWidth
                value={values.fetchType}
                onChange={handleFetchType}
                error={errors.fetchType}
                options={[
                  { id: 'Category Wise', title: 'Category Wise' },
                  {
                    id: 'Master Department Wise',
                    title: 'Master Department Wise',
                  },
                  { id: 'Department Wise', title: 'Department Wise' },
                  { id: 'Security Wise', title: 'Product Type Wise' },
                  { id: 'Product Wise', title: 'Product Wise' },
                  { id: 'Bank Type Wise', title: 'Entity Wise' },
                ]}
              />
            </Grid>
            {values.fetchType === 'Category Wise' && (
              <Grid item xs={12} lg={3}>
                <Controls.Select
                  label='Category *'
                  name='category'
                  fullWidth
                  value={values.category}
                  options={
                    values.departmentType === 'Collections'
                      ? [
                          { id: 'All', title: 'All' },
                          { id: 'Flows', title: 'Flows' },
                          {
                            id: 'Recoveries',
                            title: 'Recoveries',
                          },
                        ]
                      : values.departmentType === 'Verifications'
                      ? [{ id: 'Verifications', title: 'Verifications' }]
                      : []
                  }
                  onChange={handleCategory}
                />
              </Grid>
            )}
            {values.fetchType === 'Master Department Wise' && (
              <Grid item xs={12} lg={3}>
                <Controls.Select
                  label='Master Department *'
                  name='masterDepartment'
                  fullWidth
                  value={values.masterDepartment}
                  options={masterDepartments}
                  onChange={handleMasterDpt}
                  multiple
                />
              </Grid>
            )}
            {values.fetchType === 'Security Wise' && (
              <Grid item xs={12} lg={3}>
                <Controls.Select
                  label='Product Type *'
                  name='security'
                  fullWidth
                  value={values.security}
                  onChange={handleSecurity}
                  options={[
                    { id: 'All', title: 'All' },
                    { id: 'Secured', title: 'Secured' },
                    { id: 'Unsecured', title: 'Unsecured' },
                  ]}
                />
              </Grid>
            )}
            {values.fetchType === 'Product Wise' && (
              <Grid item xs={12} lg={3}>
                <Controls.Select
                  label='Product *'
                  name='product'
                  fullWidth
                  value={values.product}
                  onChange={handleProduct}
                  options={[
                    { id: 'Auto Loan', title: 'Auto Loan' },
                    { id: 'Commercial Vehicle', title: 'Commercial Vehicle' },
                    { id: 'Personal Loan', title: 'Personal Loan' },
                    { id: 'Business Loan', title: 'Business Loan' },
                    { id: 'Credit Card', title: 'Credit Card' },
                  ]}
                  multiple
                />
              </Grid>
            )}
            {values.fetchType === 'Bank Type Wise' && (
              <Grid item xs={12} lg={3}>
                <Controls.Select
                  label='Bank Type *'
                  name='bankType'
                  fullWidth
                  value={values.bankType}
                  onChange={handleEntity}
                  options={[
                    { id: 'All', title: 'All' },
                    { id: 'Bank', title: 'Bank' },
                    { id: 'NBFC', title: 'NBFC' },
                  ]}
                />
              </Grid>
            )}
            <Grid item xs={12} lg={3}>
              <Controls.Select
                label='Department *'
                name='department'
                fullWidth
                value={values.department}
                onChange={handleInputChange}
                options={departmentsList}
                multiple
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <Controls.Button
                type='submit'
                text='Search'
                className={classes.submit}
                loading={loading1}
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>

      <Paper className={classes.pageContent}>
        {reportData.length > 0 && (
          <>
            <Typography>Number of Cases - {numberOfCases}</Typography>
            <Typography>
              Average Number of Cases - {averageNumberOfCases}
            </Typography>
            <Typography>POS - {pos}</Typography>
            <Typography>Average POS - {averagePos}</Typography>
            {totals.map((item) => (
              <>
                {searchType == 'Sum' ? (
                  <Typography>
                    {Object.keys(item)} - {Object.values(item)[0].count} ||{' '}
                    {Object.values(item)[0].percentage}%
                  </Typography>
                ) : (
                  <Typography>
                    {Object.keys(item)} - {Object.values(item)}
                  </Typography>
                )}
              </>
            ))}
          </>
        )}
      </Paper>
      <MaterialTable
        style={{ margin: '0 20px' }}
        title='Report'
        columns={headCells}
        data={reportData}
        options={{
          exportButton: true,
          search: true,
          exportAllData: true,
        }}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  )
}

export default BillingReports
