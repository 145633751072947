import { TextField } from '@material-ui/core'
import React from 'react'

const Input = ({ label, name, value, onChange, error = null, ...other }) => {
  return (
    <TextField
      variant='outlined'
      fullWidth
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      size='small'
      style={{ marginTop: 8 }}
      {...other}
      {...(error && { error: true, helperText: error })}
    />
  )
}

export default Input
