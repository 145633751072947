import {
  GET_VERIFICATION_CALLING_REQUEST,
  GET_VERIFICATION_CALLING_SUCCESS,
  GET_VERIFICATION_CALLING_FAIL,
  GET_VERIFICATION_CALLING_RESET,
  ADD_VERIFICATION_SINGLE_CALL_REQUEST,
  ADD_VERIFICATION_SINGLE_CALL_SUCCESS,
  ADD_VERIFICATION_SINGLE_CALL_FAIL,
  ADD_VERIFICATION_SINGLE_CALL_RESET,
} from '../constants/verificationConstants'

export const verificationGetCallsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_VERIFICATION_CALLING_REQUEST:
      return { loading: true }
    case GET_VERIFICATION_CALLING_SUCCESS:
      return { loading: false, calls: action.payload }
    case GET_VERIFICATION_CALLING_FAIL:
      return { loading: false, error: action.payload }
    case GET_VERIFICATION_CALLING_RESET:
      return {}
    default:
      return state
  }
}

export const verificationAddSingleCallReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_VERIFICATION_SINGLE_CALL_REQUEST:
      return { loading: true }
    case ADD_VERIFICATION_SINGLE_CALL_SUCCESS:
      return { loading: false, addSingleCall: action.payload }
    case ADD_VERIFICATION_SINGLE_CALL_FAIL:
      return { loading: false, error: action.payload }
    case ADD_VERIFICATION_SINGLE_CALL_RESET:
      return {}
    default:
      return state
  }
}
