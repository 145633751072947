export const HR_DASHBOARD_REQUEST = 'HR_DASHBOARD_REQUEST'
export const HR_DASHBOARD_SUCCESS = 'HR_DASHBOARD_SUCCESS'
export const HR_DASHBOARD_FAIL = 'HR_DASHBOARD_FAIL'
export const HR_DASHBOARD_RESET = 'HR_DASHBOARD_RESET'

export const HR_ASEG_REQUEST = 'HR_ASEG_REQUEST'
export const HR_ASEG_SUCCESS = 'HR_ASEG_SUCCESS'
export const HR_ASEG_FAIL = 'HR_ASEG_FAIL'
export const HR_ASEG_RESET = 'HR_ASEG_RESET'

export const HR_ASEP_REQUEST = 'HR_ASEP_REQUEST'
export const HR_ASEP_SUCCESS = 'HR_ASEP_SUCCESS'
export const HR_ASEP_FAIL = 'HR_ASEP_FAIL'
export const HR_ASEP_RESET = 'HR_ASEP_RESET'

export const HR_AE_REQUEST = 'HR_AE_REQUEST'
export const HR_AE_SUCCESS = 'HR_AE_SUCCESS'
export const HR_AE_FAIL = 'HR_AE_FAIL'
export const HR_AE_RESET = 'HR_AE_RESET'

export const HR_NAE_REQUEST = 'HR_NAE_REQUEST'
export const HR_NAE_SUCCESS = 'HR_NAE_SUCCESS'
export const HR_NAE_FAIL = 'HR_NAE_FAIL'
export const HR_NAE_RESET = 'HR_NAE_RESET'

export const HR_GET_DEPARTMENTS_REQUEST = 'HR_GET_DEPARTMENTS_REQUEST'
export const HR_GET_DEPARTMENTS_SUCCESS = 'HR_GET_DEPARTMENTS_SUCCESS'
export const HR_GET_DEPARTMENTS_FAIL = 'HR_GET_DEPARTMENTS_FAIL'
export const HR_GET_DEPARTMENTS_RESET = 'HR_GET_DEPARTMENTS_RESET'

export const HR_ADD_DEPARTMENT_REQUEST = 'HR_ADD_DEPARTMENT_REQUEST'
export const HR_ADD_DEPARTMENT_SUCCESS = 'HR_ADD_DEPARTMENT_SUCCESS'
export const HR_ADD_DEPARTMENT_FAIL = 'HR_ADD_DEPARTMENT_FAIL'
export const HR_ADD_DEPARTMENT_RESET = 'HR_ADD_DEPARTMENT_RESET'

export const HR_UPDATE_DEPARTMENT_REQUEST = 'HR_UPDATE_DEPARTMENT_REQUEST'
export const HR_UPDATE_DEPARTMENT_SUCCESS = 'HR_UPDATE_DEPARTMENT_SUCCESS'
export const HR_UPDATE_DEPARTMENT_FAIL = 'HR_UPDATE_DEPARTMENT_FAIL'
export const HR_UPDATE_DEPARTMENT_RESET = 'HR_UPDATE_DEPARTMENT_RESET'

export const HR_GET_EMPLOYEE_BY_ID_REQUEST = 'HR_GET_EMPLOYEE_BY_ID_REQUEST'
export const HR_GET_EMPLOYEE_BY_ID_SUCCESS = 'HR_GET_EMPLOYEE_BY_ID_SUCCESS'
export const HR_GET_EMPLOYEE_BY_ID_FAIL = 'HR_GET_EMPLOYEE_BY_ID_FAIL'

export const HR_UPDATE_BASIC_INFO_EMPLOYEE_REQUEST =
  'HR_UPDATE_BASIC_INFO_EMPLOYEE_REQUEST'
export const HR_UPDATE_BASIC_INFO_EMPLOYEE_SUCCESS =
  'HR_UPDATE_BASIC_INFO_EMPLOYEE_SUCCESS'
export const HR_UPDATE_BASIC_INFO_EMPLOYEE_FAIL =
  'HR_UPDATE_BASIC_INFO_EMPLOYEE_FAIL'
export const HR_UPDATE_BASIC_INFO_EMPLOYEE_RESET =
  'HR_UPDATE_BASIC_INFO_EMPLOYEE_RESET'

export const HR_UPDATE_BANK_INFO_EMPLOYEE_REQUEST =
  'HR_UPDATE_BANK_INFO_EMPLOYEE_REQUEST'
export const HR_UPDATE_BANK_INFO_EMPLOYEE_SUCCESS =
  'HR_UPDATE_BANK_INFO_EMPLOYEE_SUCCESS'
export const HR_UPDATE_BANK_INFO_EMPLOYEE_FAIL =
  'HR_UPDATE_BANK_INFO_EMPLOYEE_FAIL'
export const HR_UPDATE_BANK_INFO_EMPLOYEE_RESET =
  'HR_UPDATE_BANK_INFO_EMPLOYEE_RESET'

export const HR_UPDATE_REFERENCE_INFO_EMPLOYEE_REQUEST =
  'HR_UPDATE_REFERENCE_INFO_EMPLOYEE_REQUEST'
export const HR_UPDATE_REFERENCE_INFO_EMPLOYEE_SUCCESS =
  'HR_UPDATE_REFERENCE_INFO_EMPLOYEE_SUCCESS'
export const HR_UPDATE_REFERENCE_INFO_EMPLOYEE_FAIL =
  'HR_UPDATE_REFERENCE_INFO_EMPLOYEE_FAIL'
export const HR_UPDATE_REFERENCE_INFO_EMPLOYEE_RESET =
  'HR_UPDATE_REFERENCE_INFO_EMPLOYEE_RESET'

export const HR_UPDATE_EDUCATION_INFO_EMPLOYEE_REQUEST =
  'HR_UPDATE_EDUCATION_INFO_EMPLOYEE_REQUEST'
export const HR_UPDATE_EDUCATION_INFO_EMPLOYEE_SUCCESS =
  'HR_UPDATE_EDUCATION_INFO_EMPLOYEE_SUCCESS'
export const HR_UPDATE_EDUCATION_INFO_EMPLOYEE_FAIL =
  'HR_UPDATE_EDUCATION_INFO_EMPLOYEE_FAIL'
export const HR_UPDATE_EDUCATION_INFO_EMPLOYEE_RESET =
  'HR_UPDATE_EDUCATION_INFO_EMPLOYEE_RESET'

export const HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_REQUEST =
  'HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_REQUEST'
export const HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_SUCCESS =
  'HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_SUCCESS'
export const HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_FAIL =
  'HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_FAIL'
export const HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_RESET =
  'HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_RESET'

export const HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_REQUEST =
  'HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_REQUEST'
export const HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_SUCCESS =
  'HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_SUCCESS'
export const HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_FAIL =
  'HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_FAIL'
export const HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_RESET =
  'HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_RESET'

export const HR_UPDATE_VEHICLE_INFO_EMPLOYEE_REQUEST =
  'HR_UPDATE_VEHICLE_INFO_EMPLOYEE_REQUEST'
export const HR_UPDATE_VEHICLE_INFO_EMPLOYEE_SUCCESS =
  'HR_UPDATE_VEHICLE_INFO_EMPLOYEE_SUCCESS'
export const HR_UPDATE_VEHICLE_INFO_EMPLOYEE_FAIL =
  'HR_UPDATE_VEHICLE_INFO_EMPLOYEE_FAIL'
export const HR_UPDATE_VEHICLE_INFO_EMPLOYEE_RESET =
  'HR_UPDATE_VEHICLE_INFO_EMPLOYEE_RESET'

export const HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_REQUEST =
  'HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_REQUEST'
export const HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_SUCCESS =
  'HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_SUCCESS'
export const HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_FAIL =
  'HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_FAIL'
export const HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_RESET =
  'HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_RESET'

export const HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_REQUEST =
  'HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_REQUEST'
export const HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_SUCCESS =
  'HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_SUCCESS'
export const HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_FAIL =
  'HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_FAIL'
export const HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_RESET =
  'HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_RESET'

export const HR_UPDATE_SETTINGS_INFO_EMPLOYEE_REQUEST =
  'HR_UPDATE_SETTINGS_INFO_EMPLOYEE_REQUEST'
export const HR_UPDATE_SETTINGS_INFO_EMPLOYEE_SUCCESS =
  'HR_UPDATE_SETTINGS_INFO_EMPLOYEE_SUCCESS'
export const HR_UPDATE_SETTINGS_INFO_EMPLOYEE_FAIL =
  'HR_UPDATE_SETTINGS_INFO_EMPLOYEE_FAIL'
export const HR_UPDATE_SETTINGS_INFO_EMPLOYEE_RESET =
  'HR_UPDATE_SETTINGS_INFO_EMPLOYEE_RESET'

export const HR_RESET_PASSWORD_INFO_EMPLOYEE_REQUEST =
  'HR_RESET_PASSWORD_INFO_EMPLOYEE_REQUEST'
export const HR_RESET_PASSWORD_INFO_EMPLOYEE_SUCCESS =
  'HR_RESET_PASSWORD_INFO_EMPLOYEE_SUCCESS'
export const HR_RESET_PASSWORD_INFO_EMPLOYEE_FAIL =
  'HR_RESET_PASSWORD_INFO_EMPLOYEE_FAIL'
export const HR_RESET_PASSWORD_INFO_EMPLOYEE_RESET =
  'HR_RESET_PASSWORD_INFO_EMPLOYEE_RESET'

export const HR_RESET_DEVICE_ID_INFO_EMPLOYEE_REQUEST =
  'HR_RESET_DEVICE_ID_INFO_EMPLOYEE_REQUEST'
export const HR_RESET_DEVICE_ID_INFO_EMPLOYEE_SUCCESS =
  'HR_RESET_DEVICE_ID_INFO_EMPLOYEE_SUCCESS'
export const HR_RESET_DEVICE_ID_INFO_EMPLOYEE_FAIL =
  'HR_RESET_DEVICE_ID_INFO_EMPLOYEE_FAIL'
export const HR_RESET_DEVICE_ID_INFO_EMPLOYEE_RESET =
  'HR_RESET_DEVICE_ID_INFO_EMPLOYEE_RESET'

export const HR_ASSIGN_EXTENSION_EMPLOYEE_REQUEST =
  'HR_ASSIGN_EXTENSION_EMPLOYEE_REQUEST'
export const HR_ASSIGN_EXTENSION_EMPLOYEE_SUCCESS =
  'HR_ASSIGN_EXTENSION_EMPLOYEE_SUCCESS'
export const HR_ASSIGN_EXTENSION_EMPLOYEE_FAIL =
  'HR_ASSIGN_EXTENSION_EMPLOYEE_FAIL'
export const HR_ASSIGN_EXTENSION_EMPLOYEE_RESET =
  'HR_ASSIGN_EXTENSION_EMPLOYEE_RESET'

export const HR_REMOVE_EXTENSION_EMPLOYEE_REQUEST =
  'HR_REMOVE_EXTENSION_EMPLOYEE_REQUEST'
export const HR_REMOVE_EXTENSION_EMPLOYEE_SUCCESS =
  'HR_REMOVE_EXTENSION_EMPLOYEE_SUCCESS'
export const HR_REMOVE_EXTENSION_EMPLOYEE_FAIL =
  'HR_REMOVE_EXTENSION_EMPLOYEE_FAIL'
export const HR_REMOVE_EXTENSION_EMPLOYEE_RESET =
  'HR_REMOVE_EXTENSION_EMPLOYEE_RESET'

export const HR_ACTIVATE_EXTENSION_EMPLOYEE_REQUEST =
  'HR_ACTIVATE_EXTENSION_EMPLOYEE_REQUEST'
export const HR_ACTIVATE_EXTENSION_EMPLOYEE_SUCCESS =
  'HR_ACTIVATE_EXTENSION_EMPLOYEE_SUCCESS'
export const HR_ACTIVATE_EXTENSION_EMPLOYEE_FAIL =
  'HR_ACTIVATE_EXTENSION_EMPLOYEE_FAIL'
export const HR_ACTIVATE_EXTENSION_EMPLOYEE_RESET =
  'HR_ACTIVATE_EXTENSION_EMPLOYEE_RESET'

export const HR_DEACTIVATE_EXTENSION_EMPLOYEE_REQUEST =
  'HR_DEACTIVATE_EXTENSION_EMPLOYEE_REQUEST'
export const HR_DEACTIVATE_EXTENSION_EMPLOYEE_SUCCESS =
  'HR_DEACTIVATE_EXTENSION_EMPLOYEE_SUCCESS'
export const HR_DEACTIVATE_EXTENSION_EMPLOYEE_FAIL =
  'HR_DEACTIVATE_EXTENSION_EMPLOYEE_FAIL'
export const HR_DEACTIVATE_EXTENSION_EMPLOYEE_RESET =
  'HR_DEACTIVATE_EXTENSION_EMPLOYEE_RESET'

export const HR_UPLOAD_DOC_EMPLOYEE_REQUEST = 'HR_UPLOAD_DOC_EMPLOYEE_REQUEST'
export const HR_UPLOAD_DOC_EMPLOYEE_SUCCESS = 'HR_UPLOAD_DOC_EMPLOYEE_SUCCESS'
export const HR_UPLOAD_DOC_EMPLOYEE_FAIL = 'HR_UPLOAD_DOC_EMPLOYEE_FAIL'
export const HR_UPLOAD_DOC_EMPLOYEE_RESET = 'HR_UPLOAD_DOC_EMPLOYEE_RESET'

export const HR_DELETE_DOC_EMPLOYEE_REQUEST = 'HR_DELETE_DOC_EMPLOYEE_REQUEST'
export const HR_DELETE_DOC_EMPLOYEE_SUCCESS = 'HR_DELETE_DOC_EMPLOYEE_SUCCESS'
export const HR_DELETE_DOC_EMPLOYEE_FAIL = 'HR_DELETE_DOC_EMPLOYEE_FAIL'
export const HR_DELETE_DOC_EMPLOYEE_RESET = 'HR_DELETE_DOC_EMPLOYEE_RESET'

export const HR_DOWNLOAD_DOC_EMPLOYEE_REQUEST =
  'HR_DOWNLOAD_DOC_EMPLOYEE_REQUEST'
export const HR_DOWNLOAD_DOC_EMPLOYEE_SUCCESS =
  'HR_DOWNLOAD_DOC_EMPLOYEE_SUCCESS'
export const HR_DOWNLOAD_DOC_EMPLOYEE_FAIL = 'HR_DOWNLOAD_DOC_EMPLOYEE_FAIL'
export const HR_DOWNLOAD_DOC_EMPLOYEE_RESET = 'HR_DOWNLOAD_DOC_EMPLOYEE_RESET'

export const HR_ADD_DEPARTMENT_EMPLOYEE_REQUEST =
  'HR_ADD_DEPARTMENT_EMPLOYEE_REQUEST'
export const HR_ADD_DEPARTMENT_EMPLOYEE_SUCCESS =
  'HR_ADD_DEPARTMENT_EMPLOYEE_SUCCESS'
export const HR_ADD_DEPARTMENT_EMPLOYEE_FAIL = 'HR_ADD_DEPARTMENT_EMPLOYEE_FAIL'
export const HR_ADD_DEPARTMENT_EMPLOYEE_RESET =
  'HR_ADD_DEPARTMENT_EMPLOYEE_RESET'

export const HR_UPDATE_DEPARTMENT_EMPLOYEE_REQUEST =
  'HR_UPDATE_DEPARTMENT_EMPLOYEE_REQUEST'
export const HR_UPDATE_DEPARTMENT_EMPLOYEE_SUCCESS =
  'HR_UPDATE_DEPARTMENT_EMPLOYEE_SUCCESS'
export const HR_UPDATE_DEPARTMENT_EMPLOYEE_FAIL =
  'HR_UPDATE_DEPARTMENT_EMPLOYEE_FAIL'
export const HR_UPDATE_DEPARTMENT_EMPLOYEE_RESET =
  'HR_UPDATE_DEPARTMENT_EMPLOYEE_RESET'

export const HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_REQUEST =
  'HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_REQUEST'
export const HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_SUCCESS =
  'HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_SUCCESS'
export const HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_FAIL =
  'HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_FAIL'
export const HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_RESET =
  'HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_RESET'

export const HR_EXIT_EMPLOYEE_REQUEST = 'HR_EXIT_EMPLOYEE_REQUEST'
export const HR_EXIT_EMPLOYEE_SUCCESS = 'HR_EXIT_EMPLOYEE_SUCCESS'
export const HR_EXIT_EMPLOYEE_FAIL = 'HR_EXIT_EMPLOYEE_FAIL'
export const HR_EXIT_EMPLOYEE_RESET = 'HR_EXIT_EMPLOYEE_RESET'

export const HR_REJOIN_EMPLOYEE_REQUEST = 'HR_REJOIN_EMPLOYEE_REQUEST'
export const HR_REJOIN_EMPLOYEE_SUCCESS = 'HR_REJOIN_EMPLOYEE_SUCCESS'
export const HR_REJOIN_EMPLOYEE_FAIL = 'HR_REJOIN_EMPLOYEE_FAIL'
export const HR_REJOIN_EMPLOYEE_RESET = 'HR_REJOIN_EMPLOYEE_RESET'

export const GET_TODAY_ATTENDANCE_REQUEST = 'GET_TODAY_ATTENDANCE_REQUEST'
export const GET_TODAY_ATTENDANCE_SUCCESS = 'GET_TODAY_ATTENDANCE_SUCCESS'
export const GET_TODAY_ATTENDANCE_FAIL = 'GET_TODAY_ATTENDANCE_FAIL'
export const GET_TODAY_ATTENDANCE_RESET = 'GET_TODAY_ATTENDANCE_RESET'

export const UPDATE_ATTENDANCE_REQUEST = 'UPDATE_ATTENDANCE_REQUEST'
export const UPDATE_ATTENDANCE_SUCCESS = 'UPDATE_ATTENDANCE_SUCCESS'
export const UPDATE_ATTENDANCE_FAIL = 'UPDATE_ATTENDANCE_FAIL'
export const UPDATE_ATTENDANCE_RESET = 'UPDATE_ATTENDANCE_RESET'

export const SEARCH_ATTENDANCE_REQUEST = 'SEARCH_ATTENDANCE_REQUEST'
export const SEARCH_ATTENDANCE_SUCCESS = 'SEARCH_ATTENDANCE_SUCCESS'
export const SEARCH_ATTENDANCE_FAIL = 'SEARCH_ATTENDANCE_FAIL'
export const SEARCH_ATTENDANCE_RESET = 'SEARCH_ATTENDANCE_RESET'

export const FINALIZE_ATTENDANCE_REQUEST = 'FINALIZE_ATTENDANCE_REQUEST'
export const FINALIZE_ATTENDANCE_SUCCESS = 'FINALIZE_ATTENDANCE_SUCCESS'
export const FINALIZE_ATTENDANCE_FAIL = 'FINALIZE_ATTENDANCE_FAIL'
export const FINALIZE_ATTENDANCE_RESET = 'FINALIZE_ATTENDANCE_RESET'

export const UPDATE_FINALIZE_ATTENDANCE_REQUEST =
  'UPDATE_FINALIZE_ATTENDANCE_REQUEST'
export const UPDATE_FINALIZE_ATTENDANCE_SUCCESS =
  'UPDATE_FINALIZE_ATTENDANCE_SUCCESS'
export const UPDATE_FINALIZE_ATTENDANCE_FAIL = 'UPDATE_FINALIZE_ATTENDANCE_FAIL'
export const UPDATE_FINALIZE_ATTENDANCE_RESET =
  'UPDATE_FINALIZE_ATTENDANCE_RESET'

export const PROCESS_FINALIZE_ATTENDANCE_REQUEST =
  'PROCESS_FINALIZE_ATTENDANCE_REQUEST'
export const PROCESS_FINALIZE_ATTENDANCE_SUCCESS =
  'PROCESS_FINALIZE_ATTENDANCE_SUCCESS'
export const PROCESS_FINALIZE_ATTENDANCE_FAIL =
  'PROCESS_FINALIZE_ATTENDANCE_FAIL'
export const PROCESS_FINALIZE_ATTENDANCE_RESET =
  'PROCESS_FINALIZE_ATTENDANCE_RESET'

export const HR_GET_MASTER_DEPARTMENTS_REQUEST =
  'HR_GET_MASTER_DEPARTMENTS_REQUEST'
export const HR_GET_MASTER_DEPARTMENTS_SUCCESS =
  'HR_GET_MASTER_DEPARTMENTS_SUCCESS'
export const HR_GET_MASTER_DEPARTMENTS_FAIL = 'HR_GET_MASTER_DEPARTMENTS_FAIL'
export const HR_GET_MASTER_DEPARTMENTS_RESET = 'HR_GET_MASTER_DEPARTMENTS_RESET'

export const HR_ADD_MASTER_DEPARTMENT_REQUEST =
  'HR_ADD_MASTER_DEPARTMENT_REQUEST'
export const HR_ADD_MASTER_DEPARTMENT_SUCCESS =
  'HR_ADD_MASTER_DEPARTMENT_SUCCESS'
export const HR_ADD_MASTER_DEPARTMENT_FAIL = 'HR_ADD_MASTER_DEPARTMENT_FAIL'
export const HR_ADD_MASTER_DEPARTMENT_RESET = 'HR_ADD_MASTER_DEPARTMENT_RESET'

export const HR_UPDATE_MASTER_DEPARTMENT_REQUEST =
  'HR_UPDATE_MASTER_DEPARTMENT_REQUEST'
export const HR_UPDATE_MASTER_DEPARTMENT_SUCCESS =
  'HR_UPDATE_MASTER_DEPARTMENT_SUCCESS'
export const HR_UPDATE_MASTER_DEPARTMENT_FAIL =
  'HR_UPDATE_MASTER_DEPARTMENT_FAIL'
export const HR_UPDATE_MASTER_DEPARTMENT_RESET =
  'HR_UPDATE_MASTER_DEPARTMENT_RESET'

export const CREATE_EMPLOYEE_PDF_REQUEST = 'CREATE_EMPLOYEE_PDF_REQUEST'
export const CREATE_EMPLOYEE_PDF_SUCCESS = 'CREATE_EMPLOYEE_PDF_SUCCESS'
export const CREATE_EMPLOYEE_PDF_FAIL = 'CREATE_EMPLOYEE_PDF_FAIL'
export const CREATE_EMPLOYEE_PDF_RESET = 'CREATE_EMPLOYEE_PDF_RESET'

export const HR_REPORTS_REQUEST = 'HR_REPORTS_REQUEST'
export const HR_REPORTS_SUCCESS = 'HR_REPORTS_SUCCESS'
export const HR_REPORTS_FAIL = 'HR_REPORTS_FAIL'
export const HR_REPORTS_RESET = 'HR_REPORTS_RESET'

export const HR_ATTENDANCE_REPORTS_REQUEST = 'HR_ATTENDANCE_REPORTS_REQUEST'
export const HR_ATTENDANCE_REPORTS_SUCCESS = 'HR_ATTENDANCE_REPORTS_SUCCESS'
export const HR_ATTENDANCE_REPORTS_FAIL = 'HR_ATTENDANCE_REPORTS_FAIL'
export const HR_ATTENDANCE_REPORTS_RESET = 'HR_ATTENDANCE_REPORTS_RESET'
