import {
  DATA_SEARCH_FAIL,
  DATA_SEARCH_REQUEST,
  DATA_SEARCH_RESET,
  DATA_SEARCH_SUCCESS,
  DATA_UPLOAD_FAIL,
  DATA_UPLOAD_REQUEST,
  DATA_UPLOAD_RESET,
  DATA_UPLOAD_SUCCESS,
} from '../constants/dataConstants'

export const dataUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case DATA_UPLOAD_REQUEST:
      return { loading: true }
    case DATA_UPLOAD_SUCCESS:
      return { loading: false, result: action.payload }
    case DATA_UPLOAD_FAIL:
      return { loading: false, error: action.payload }
    case DATA_UPLOAD_RESET:
      return {}
    default:
      return state
  }
}

export const dataSearchReducer = (state = {}, action) => {
  switch (action.type) {
    case DATA_SEARCH_REQUEST:
      return { loading: true }
    case DATA_SEARCH_SUCCESS:
      return { loading: false, result: action.payload }
    case DATA_SEARCH_FAIL:
      return { loading: false, error: action.payload }
    case DATA_SEARCH_RESET:
      return {}
    default:
      return state
  }
}
