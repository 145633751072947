import {
  ROLES_REQUEST,
  ROLES_SUCCESS,
  ROLES_FAIL,
  ROLES_RESET,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
  DELETE_ROLE_RESET,
  ADD_ROLE_REQUEST,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAIL,
  ADD_ROLE_RESET,
  SETTINGS_REQUEST,
  SETTINGS_SUCCESS,
  SETTINGS_FAIL,
  SETTINGS_RESET,
  SETTINGS_UPDATE_TDS_REQUEST,
  SETTINGS_UPDATE_TDS_SUCCESS,
  SETTINGS_UPDATE_TDS_FAIL,
  SETTINGS_UPDATE_TDS_RESET,
  SETTINGS_ADD_BANK_REQUEST,
  SETTINGS_ADD_BANK_SUCCESS,
  SETTINGS_ADD_BANK_FAIL,
  SETTINGS_ADD_BANK_RESET,
  SETTINGS_DELETE_BANK_REQUEST,
  SETTINGS_DELETE_BANK_SUCCESS,
  SETTINGS_DELETE_BANK_FAIL,
  SETTINGS_DELETE_BANK_RESET,
} from '../constants/settingConstants'

export const rolesReducer = (state = {}, action) => {
  switch (action.type) {
    case ROLES_REQUEST:
      return { loading: true }
    case ROLES_SUCCESS:
      return { loading: false, rolesData: action.payload }
    case ROLES_FAIL:
      return { loading: false, error: action.payload }
    case ROLES_RESET:
      return {}
    default:
      return state
  }
}

export const deleteRoleReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_ROLE_REQUEST:
      return { loading1: true }
    case DELETE_ROLE_SUCCESS:
      return { loading1: false, deleteRoleData: action.payload }
    case DELETE_ROLE_FAIL:
      return { loading1: false, error1: action.payload }
    case DELETE_ROLE_RESET:
      return {}
    default:
      return state
  }
}

export const addRoleReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_ROLE_REQUEST:
      return { loading: true }
    case ADD_ROLE_SUCCESS:
      return { loading: false, addRoleData: action.payload }
    case ADD_ROLE_FAIL:
      return { loading: false, error: action.payload }
    case ADD_ROLE_RESET:
      return {}
    default:
      return state
  }
}

export const settingsReducer = (state = {}, action) => {
  switch (action.type) {
    case SETTINGS_REQUEST:
      return { loading: true }
    case SETTINGS_SUCCESS:
      return { loading: false, settingsData: action.payload }
    case SETTINGS_FAIL:
      return { loading: false, error: action.payload }
    case SETTINGS_RESET:
      return {}
    default:
      return state
  }
}

export const settingsUpdateTDSReducer = (state = {}, action) => {
  switch (action.type) {
    case SETTINGS_UPDATE_TDS_REQUEST:
      return { loading: true }
    case SETTINGS_UPDATE_TDS_SUCCESS:
      return { loading: false, updateTDS: action.payload }
    case SETTINGS_UPDATE_TDS_FAIL:
      return { loading: false, error: action.payload }
    case SETTINGS_UPDATE_TDS_RESET:
      return {}
    default:
      return state
  }
}

export const settingsAddBankReducer = (state = {}, action) => {
  switch (action.type) {
    case SETTINGS_ADD_BANK_REQUEST:
      return { loading: true }
    case SETTINGS_ADD_BANK_SUCCESS:
      return { loading: false, addBank: action.payload }
    case SETTINGS_ADD_BANK_FAIL:
      return { loading: false, error: action.payload }
    case SETTINGS_ADD_BANK_RESET:
      return {}
    default:
      return state
  }
}

export const settingsDeleteBankReducer = (state = {}, action) => {
  switch (action.type) {
    case SETTINGS_DELETE_BANK_REQUEST:
      return { loading1: true }
    case SETTINGS_DELETE_BANK_SUCCESS:
      return { loading1: false, deleteBank: action.payload }
    case SETTINGS_DELETE_BANK_FAIL:
      return { loading1: false, error1: action.payload }
    case SETTINGS_DELETE_BANK_RESET:
      return {}
    default:
      return state
  }
}
