import { makeStyles, Typography, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Controls from './controls/Controls'
import Notification from './Notification'
import { Form, useForm } from './useForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  hrAddDepartmentAction,
  hrDepartmentsAction,
  hrUpdateDepartmentAction,
} from '../actions/hrActions'
import {
  HR_ADD_DEPARTMENT_RESET,
  HR_UPDATE_DEPARTMENT_RESET,
} from '../constants/hrConstants'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const initialValues = {
  id: '',
  master_department: '',
  name: '',
  type: '',
  category: '',
  security: '',
  product: '',
  bankType: '',
  supervisors: [],
  alreadySupervisors: [],
}

const AddDepartment = ({
  setOpenPopup,
  departmentForEdit,
  masterDepartments,
  employees,
}) => {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

  const classes = useStyles()
  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('master_department' in fieldValues)
      temp.master_department =
        fieldValues.master_department.length !== 0
          ? ''
          : 'This field is required'
    if ('name' in fieldValues)
      temp.name = fieldValues.name ? '' : 'This field is required'
    if ('type' in fieldValues)
      temp.type = fieldValues.type.length !== 0 ? '' : 'This field is required'
    setErrors({
      ...temp,
    })
    if (fieldValues === values) return Object.values(temp).every((x) => x == '')
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate)

  const dispatch = useDispatch()
  const hrAddDpt = useSelector((state) => state.hrAddDpt)
  const { loading, error, addDepartment } = hrAddDpt

  const hrUpdateDpt = useSelector((state) => state.hrUpdateDpt)
  const { loading1, error1, updateDepartment } = hrUpdateDpt

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      if (departmentForEdit) {
        if (
          (values.type === 'Collections' && values.category === '') ||
          (values.type === 'Verifications' && values.category === '')
        ) {
          setNotify({
            isOpen: true,
            message: error,
            type: 'Select a valid category from the list',
          })
        } else {
          dispatch(
            hrUpdateDepartmentAction(
              values.id,
              values.master_department,
              values.name,
              values.type,
              values.category,
              values.security,
              values.product,
              values.bankType,
              values.supervisors
            )
          )
        }
      } else {
        if (
          (values.type === 'Collections' && values.category === '') ||
          (values.type === 'Verifications' && values.category === '')
        ) {
          setNotify({
            isOpen: true,
            message: error,
            type: 'Select a valid category from the list',
          })
        } else {
          if (values.supervisors.length > 0) {
            dispatch(
              hrAddDepartmentAction(
                values.master_department,
                values.name,
                values.type,
                values.category,
                values.security,
                values.product,
                values.bankType,
                values.supervisors
              )
            )
          } else {
            setNotify({
              isOpen: true,
              message: error,
              type: 'Select atleast one supervisor from the list',
            })
          }
        }
      }
    }
  }

  useEffect(() => {
    dispatch({ type: HR_ADD_DEPARTMENT_RESET })
    if (addDepartment) {
      setNotify({
        isOpen: true,
        message: addDepartment.msg,
        type: 'success',
      })
      setTimeout(() => {
        setOpenPopup(false)
        dispatch(hrDepartmentsAction())
      }, 2000)
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [dispatch, addDepartment, error])

  useEffect(() => {
    dispatch({ type: HR_UPDATE_DEPARTMENT_RESET })
    if (updateDepartment) {
      setNotify({
        isOpen: true,
        message: updateDepartment.msg,
        type: 'success',
      })
      setTimeout(() => {
        setOpenPopup(false)
        dispatch(hrDepartmentsAction())
      }, 2000)
    } else if (error1) {
      setNotify({
        isOpen: true,
        message: error1,
        type: 'error',
      })
    }
  }, [dispatch, updateDepartment, error1])

  useEffect(() => {
    if (departmentForEdit != null) {
      setValues({
        ...departmentForEdit,
        master_department: departmentForEdit.master_department._id,
        supervisors: [],
        alreadySupervisors: departmentForEdit.supervisors,
      })
    }
  }, [departmentForEdit])

  return (
    <>
      <Typography style={{ marginBottom: 10, fontWeight: 'bold' }}>
        Already Superisors: {values.alreadySupervisors.length}
      </Typography>
      {values.alreadySupervisors.map((item) => (
        <Grid container spacing={3} key={item.id}>
          <Grid item xs={6}>
            <Typography>{item.title}</Typography>
          </Grid>
          <Grid sm item />
        </Grid>
      ))}
      <Form autoComplete='off' onSubmit={handleSubmit}>
        <Controls.Select
          label='Master Department'
          name='master_department'
          value={values.master_department}
          onChange={handleInputChange}
          options={masterDepartments}
        />
        <Controls.Input
          label='Department Name'
          name='name'
          value={values.name}
          onChange={handleInputChange}
          error={errors.name}
        />
        <Controls.Select
          label='Type'
          name='type'
          value={values.type}
          onChange={handleInputChange}
          options={[
            { id: 'Process', title: 'Process' },
            { id: 'Collections', title: 'Collections' },
            { id: 'Verifications', title: 'Verifications' },
          ]}
        />
        <Controls.Select
          label='Category'
          name='category'
          value={values.category}
          onChange={handleInputChange}
          options={
            values.type === 'Collections'
              ? [
                  { id: 'Flows', title: 'Flows' },
                  { id: 'Recoveries', title: 'Recoveries' },
                ]
              : values.type === 'Verifications'
              ? [{ id: 'Verifications', title: 'Verifications' }]
              : []
          }
          disabled={values.type === 'Process' || values.type === ''}
        />
        <Controls.Select
          label='Security'
          name='security'
          value={values.security}
          onChange={handleInputChange}
          options={
            values.type === 'Collections' || values.type === 'Verifications'
              ? [
                  { id: 'Secured', title: 'Secured' },
                  { id: 'Unsecured', title: 'Unsecured' },
                ]
              : []
          }
          disabled={values.type === 'Process' || values.type === ''}
        />
        <Controls.Select
          label='Product'
          name='product'
          value={values.product}
          onChange={handleInputChange}
          options={
            values.type === 'Collections' || values.type === 'Verifications'
              ? [
                  { id: 'Auto Loan', title: 'Auto Loan' },
                  { id: 'Commercial Vehicle', title: 'Commercial Vehicle' },
                  { id: 'Personal Loan', title: 'Personal Loan' },
                  { id: 'Business Loan', title: 'Business Loan' },
                  { id: 'Credit Card', title: 'Credit Card' },
                ]
              : []
          }
          disabled={values.type === 'Process' || values.type === ''}
        />
        <Controls.Select
          label='Bank Type'
          name='bankType'
          value={values.bankType}
          onChange={handleInputChange}
          options={
            values.type === 'Collections' || values.type === 'Verifications'
              ? [
                  { id: 'Bank', title: 'Bank' },
                  { id: 'NBFC', title: 'NBFC' },
                ]
              : []
          }
          disabled={values.type === 'Process' || values.type === ''}
        />
        <Controls.Select
          label='Supervisors'
          name='supervisors'
          value={values.supervisors}
          onChange={handleInputChange}
          options={employees}
          multiple
        />
        <Controls.Button
          type='submit'
          fullWidth
          text='submit'
          className={classes.submit}
          loading={loading || loading1}
        />
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}

export default AddDepartment
