import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Controls from './controls/Controls'
import Notification from './Notification'
import { Form, useForm } from './useForm'
import { useDispatch, useSelector } from 'react-redux'
import { userChangePassword } from '../actions/userActions'
import { USER_CHANGE_PASSWORD_RESET } from '../constants/userConstants'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const initialValues = {
  oldPassword: '',
  newPassword: '',
}

const ChangePassword = ({ setOpenPopup }) => {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

  const classes = useStyles()

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('oldPassword' in fieldValues)
      temp.oldPassword = fieldValues.oldPassword ? '' : 'This field is required'
    if ('newPassword' in fieldValues)
      temp.newPassword =
        fieldValues.newPassword.length > 5
          ? ''
          : 'Password must of more than 6 characters'
    setErrors({
      ...temp,
    })
    if (fieldValues === values) return Object.values(temp).every((x) => x == '')
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate)

  const dispatch = useDispatch()
  const userCP = useSelector((state) => state.userChangePassword)
  const { loading, error, changePassword } = userCP

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      dispatch(userChangePassword(values.oldPassword, values.newPassword))
    }
  }

  useEffect(() => {
    if (changePassword) {
      dispatch({ type: USER_CHANGE_PASSWORD_RESET })
      setNotify({
        isOpen: true,
        message: changePassword.msg,
        type: 'success',
      })
      setTimeout(() => {
        setOpenPopup(false)
      }, 2000)
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [dispatch, changePassword, error])

  return (
    <>
      <Form autoComplete='off' onSubmit={handleSubmit}>
        <Controls.Input
          label='Current Password'
          name='oldPassword'
          value={values.oldPassword}
          onChange={handleInputChange}
          error={errors.oldPassword}
          type='password'
        />
        <Controls.Input
          name='newPassword'
          label='New Password'
          value={values.newPassword}
          onChange={handleInputChange}
          error={errors.newPassword}
          type='password'
        />
        <Controls.Button
          type='submit'
          fullWidth
          text='submit'
          className={classes.submit}
          loading={loading}
        />
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}

export default ChangePassword
