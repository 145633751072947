import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Notification from '../components/Notification'
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import PageHeader from '../components/PageHeader'
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import Controls from '../components/controls/Controls'
import { useForm, Form } from '../components/useForm'
import {
  billingGetDailyMISAction,
  billingDailyMISAction,
} from '../actions/billingActions'
import { GET_BILLING_DAILY_MIS_RESET } from '../constants/billingConstants'
import MaterialTable from 'material-table'

const initialValues = {
  date: null,
  time: '',
  departmentType: '', // Collections or Verificatios
  department: [],
}

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    overflowX: 'auto',
  },
}))

const BillingDailyMIS = ({ history }) => {
  const classes = useStyles()

  const [departments, setDepartments] = useState([])

  // Requirements
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('date' in fieldValues)
      temp.date = fieldValues.date ? '' : 'This field is required'
    if ('time' in fieldValues)
      temp.time = fieldValues.time ? '' : 'This field is required'
    if ('departmentType' in fieldValues)
      temp.departmentType =
        fieldValues.departmentType.length !== 0 ? '' : 'This field is required'
    setErrors({
      ...temp,
    })
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '')
  }

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  )

  useEffect(() => {
    dispatch(billingGetDailyMISAction())
  }, [])

  const billingGetDailyMIS = useSelector((state) => state.billingGetDailyMIS)
  const { loading, error, billingDailyMISData } = billingGetDailyMIS

  useEffect(() => {
    dispatch({ type: GET_BILLING_DAILY_MIS_RESET })
    if (billingDailyMISData) {
      if (
        billingDailyMISData.role === 'Admin' ||
        billingDailyMISData.role === 'Billing'
      ) {
        setDepartments(billingDailyMISData.departments)
      } else {
        history.push('/dashboard')
      }
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [billingDailyMISData, error])

  const [results, setResults] = useState([])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      dispatch(
        billingDailyMISAction(
          values.date,
          values.time,
          values.departmentType,
          values.department
        )
      )
    }
  }

  const billingDailyMISPost = useSelector((state) => state.billingDailyMISPost)
  const { loading1, error1, billingDailyMISResult } = billingDailyMISPost

  useEffect(() => {
    if (billingDailyMISResult) {
      setResults(billingDailyMISResult)
    } else if (error1) {
      setNotify({
        isOpen: true,
        message: error1,
        type: 'error',
      })
    }
  }, [billingDailyMISResult, error1])

  return (
    <div>
      <PageHeader
        title='Daily MIS'
        subtitle='Shows daily MIS of all the departments'
        icon={<PeopleOutlineTwoToneIcon fontSize='medium' />}
      />
      <Paper className={classes.pageContent}>
        <Grid container spacing={3}>
          <Grid item>
            <Typography>Search</Typography>
          </Grid>
          <Grid sm item />
        </Grid>
        <Form autoComplete='off' onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={3}>
              <Controls.DatePicker
                label='Date *'
                name='date'
                fullWidth
                value={values.date}
                onChange={handleInputChange}
                error={errors.date}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Controls.Select
                label='Time *'
                name='time'
                fullWidth
                value={values.time}
                onChange={handleInputChange}
                error={errors.time}
                options={[
                  { id: '11:00:00', title: '11:00:00' },
                  { id: '14:00:00', title: '14:00:00' },
                  { id: '18:00:00', title: '18:00:00' },
                ]}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Controls.Select
                label='Department Type *'
                name='departmentType'
                fullWidth
                value={values.departmentType}
                onChange={handleInputChange}
                error={errors.departmentType}
                options={[
                  { id: 'Collections', title: 'Collections' },
                  // { id: 'Verifications', title: 'Verifications' },
                ]}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Controls.Select
                label='Department'
                name='department'
                fullWidth
                value={values.department}
                onChange={handleInputChange}
                error={errors.department}
                options={departments}
                multiple
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Controls.Button
                type='submit'
                text='Search'
                className={classes.submit}
                loading={loading1}
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>
      {results.map((item) => (
        <MaterialTable
          style={{ margin: '20px' }}
          title={item.department + ' (' + item.supervisor + ')'}
          columns={item.headCells}
          data={item.content}
          options={{
            search: false,
          }}
        />
      ))}
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  )
}

export default BillingDailyMIS
