import axios from 'axios'
import {
  DATA_SEARCH_FAIL,
  DATA_SEARCH_REQUEST,
  DATA_SEARCH_SUCCESS,
  DATA_UPLOAD_FAIL,
  DATA_UPLOAD_REQUEST,
  DATA_UPLOAD_SUCCESS,
} from '../constants/dataConstants'

export const dataUploadAction = (content) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DATA_UPLOAD_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      '/api/data/upload/web',
      { content },
      config
    )

    dispatch({
      type: DATA_UPLOAD_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DATA_UPLOAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const dataSearchAction =
  (searchBy, name, address, mobile) => async (dispatch, getState) => {
    try {
      dispatch({
        type: DATA_SEARCH_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        '/api/data/search/web',
        { searchBy, name, address, mobile },
        config
      )

      dispatch({
        type: DATA_SEARCH_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: DATA_SEARCH_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
