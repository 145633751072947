import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hrReportsAction } from '../actions/hrActions'
import Notification from '../components/Notification'
import Progress from '../components/Progress'
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import PageHeader from '../components/PageHeader'
import { makeStyles } from '@material-ui/core'
import MaterialTable from 'material-table'

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    overflowX: 'auto',
  },
  searchInput: {
    width: '75%',
  },
  link: {
    textDecoration: 'none',
  },
  download: {
    background: '#fff',
    padding: theme.spacing(1.25, 2),
    outline: 'none',
    border: '1px solid #333',
    borderRadius: 4,
    fontSize: 14,
    marginLeft: theme.spacing(2),
  },
}))

const HRReports = ({ match, location, history }) => {
  const {
    params: { type },
  } = match

  const classes = useStyles()

  const [heads, setHeads] = useState([])
  const [reportData, setReportData] = useState([])

  // Requirements
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const hrReports = useSelector((state) => state.hrReports)
  const { loading, error, report } = hrReports

  useEffect(() => {
    dispatch(hrReportsAction(type))
  }, [])

  useEffect(() => {
    if (report) {
      if (report.role === 'Admin' || report.role === 'HR') {
        setHeads(report.headCells)
        setReportData(report.data)
      } else {
        history.push('/dashboard')
      }
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [report, error])

  return (
    <div>
      {loading ? (
        <Progress />
      ) : (
        <>
          {error ? (
            <Notification notify={notify} setNotify={setNotify} />
          ) : (
            <>
              <PageHeader
                title='HR Reports'
                subtitle='Shows fetched reports'
                icon={<PeopleOutlineTwoToneIcon fontSize='medium' />}
              />
              <MaterialTable
                style={{ margin: '0 20px' }}
                title='HR Report'
                columns={heads}
                data={reportData}
                options={{
                  exportButton: true,
                  search: true,
                  exportAllData: true,
                }}
              />
            </>
          )}
        </>
      )}
    </div>
  )
}

export default HRReports
