import React, { useEffect, useState } from 'react'
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import Notification from '../components/Notification'
import Controls from '../components/controls/Controls'
import PageHeader from '../components/PageHeader'
import { useDispatch, useSelector } from 'react-redux'
import SearchIcon from '@material-ui/icons/Search'
import { useForm, Form } from '../components/useForm'
import { Link } from 'react-router-dom'
import { dataSearchAction } from '../actions/dataActions'
import { DATA_SEARCH_RESET } from '../constants/dataConstants'
import MaterialTable from 'material-table'

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    overflowX: 'scroll',
  },
  searchInput: {
    width: '75%',
  },
  link: {
    textDecoration: 'none',
  },
  submit: {
    margin: theme.spacing(2, 0, 0),
  },
  download: {
    background: '#fff',
    padding: theme.spacing(1.25, 2),
    outline: 'none',
    border: '1px solid #333',
    borderRadius: 4,
    fontSize: 14,
    marginLeft: theme.spacing(2),
  },
  upload: {
    background: '#253053',
    padding: theme.spacing(1.25, 2),
    outline: 'none',
    borderRadius: 4,
    fontSize: 14,
    color: 'white',
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
}))

const initialValues = {
  searchBy: '',
  name: '',
  address: '',
  mobile: '',
}

const DataSearch = ({ history }) => {
  const classes = useStyles()

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const userDashboard = useSelector((state) => state.userDashboard)
  const { userData } = userDashboard

  useEffect(() => {
    if (!userData || userData.role !== 'Admin') {
      history.push('/dashboard')
    }
  }, [dispatch, history, userData])

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    setErrors({
      ...temp,
    })
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '')
  }

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  )

  const [results, setResults] = useState([])

  const handleSubmit = (e) => {
    e.preventDefault()
    // console.log(values)
    // if (values.name === '' || values.address === '') {
    //   setNotify({
    //     isOpen: true,
    //     message: 'Enter atleast 1 parameter to find',
    //     type: 'error',
    //   })
    // } else {
    dispatch(
      dataSearchAction(
        values.searchBy,
        values.name,
        values.address,
        values.mobile
      )
    )
    // }
  }

  const dataSearch = useSelector((state) => state.dataSearch)
  const { loading, error, result } = dataSearch

  useEffect(() => {
    dispatch({ type: DATA_SEARCH_RESET })
    if (result) {
      setNotify({
        isOpen: true,
        message: result.msg,
        type: 'success',
      })
      setResults(result.data)
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [result, error])

  // Table
  const headCells = [
    { field: 'applicant_name', title: 'Name' },
    { field: 'customer_address', title: 'Address' },
    { field: 'latitude', title: 'Latitude' },
    { field: 'longitude', title: 'Longitude' },
    { field: 'location', title: 'Location' },
    { field: 'status', title: 'Status' },
    { field: 'reason', title: 'Reason' },
    { field: 'comments', title: 'Comments' },
    { field: 'mobile_1', title: 'Mobile 1' },
    { field: 'mobile_2', title: 'Mobile 2' },
    { field: 'mobile_3', title: 'Mobile 3' },
    { field: 'mobile_4', title: 'Mobile 4' },
    { field: 'mobile_5', title: 'Mobile 5' },
    { field: 'mobile_6', title: 'Mobile 6' },
    { field: 'mobile_7', title: 'Mobile 7' },
    { field: 'mobile_8', title: 'Mobile 8' },
    { field: 'mobile_9', title: 'Mobile 9' },
    { field: 'mobile_10', title: 'Mobile 10' },
    { field: 'completed', title: 'Completed On' },
  ]

  return (
    <div>
      <>
        <PageHeader
          title='Data Search'
          subtitle='Searches data available'
          icon={<SearchIcon fontSize='medium' />}
        />
        <Paper className={classes.pageContent}>
          <Grid container spacing={3}>
            <Grid item>
              <Typography>Search</Typography>
            </Grid>
            <Grid sm item />
            <Grid item>
              <Link className={classes.upload} to='/data/upload'>
                Upload
              </Link>
            </Grid>
          </Grid>
          <Form autoComplete='off' onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Controls.Select
                  label='Search By'
                  name='searchBy'
                  value={values.searchBy}
                  onChange={handleInputChange}
                  options={[
                    { id: 'Name', title: 'Name' },
                    { id: 'Address', title: 'Address' },
                    { id: 'Mobile', title: 'Mobile' },
                  ]}
                />
              </Grid>
              {values.searchBy === 'Name' && (
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Name'
                    name='name'
                    value={values.name}
                    onChange={handleInputChange}
                  />
                </Grid>
              )}
              {values.searchBy === 'Address' && (
                <Grid item xs={12} lg={8}>
                  <Controls.Input
                    label='Address'
                    name='address'
                    value={values.address}
                    onChange={handleInputChange}
                  />
                </Grid>
              )}
              {values.searchBy === 'Mobile' && (
                <Grid item xs={12} lg={8}>
                  <Controls.Input
                    label='Mobile'
                    name='mobile'
                    value={values.mobile}
                    onChange={handleInputChange}
                    type='number'
                    inputProps={{
                      min: 0,
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <Controls.Button
              type='submit'
              text='Find'
              className={classes.submit}
              loading={loading}
            />
          </Form>
        </Paper>
        {results.length > 0 && (
          <MaterialTable
            style={{ margin: '0 20px' }}
            title='Search Results'
            columns={headCells}
            data={results}
            options={{
              exportButton: true,
              search: true,
              exportAllData: true,
            }}
          />
        )}
        <Notification notify={notify} setNotify={setNotify} />
      </>
    </div>
  )
}

export default DataSearch
