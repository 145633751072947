import {
  HR_DASHBOARD_REQUEST,
  HR_DASHBOARD_SUCCESS,
  HR_DASHBOARD_FAIL,
  HR_ASEG_REQUEST,
  HR_ASEG_SUCCESS,
  HR_ASEG_FAIL,
  HR_ASEP_REQUEST,
  HR_ASEP_SUCCESS,
  HR_ASEP_FAIL,
  HR_AE_REQUEST,
  HR_AE_FAIL,
  HR_AE_SUCCESS,
  HR_NAE_REQUEST,
  HR_NAE_FAIL,
  HR_NAE_SUCCESS,
  HR_GET_DEPARTMENTS_REQUEST,
  HR_GET_DEPARTMENTS_SUCCESS,
  HR_GET_DEPARTMENTS_FAIL,
  HR_ADD_DEPARTMENT_REQUEST,
  HR_ADD_DEPARTMENT_SUCCESS,
  HR_ADD_DEPARTMENT_FAIL,
  HR_UPDATE_DEPARTMENT_REQUEST,
  HR_UPDATE_DEPARTMENT_SUCCESS,
  HR_UPDATE_DEPARTMENT_FAIL,
  HR_GET_EMPLOYEE_BY_ID_REQUEST,
  HR_GET_EMPLOYEE_BY_ID_SUCCESS,
  HR_GET_EMPLOYEE_BY_ID_FAIL,
  HR_UPDATE_BASIC_INFO_EMPLOYEE_REQUEST,
  HR_UPDATE_BASIC_INFO_EMPLOYEE_SUCCESS,
  HR_UPDATE_BASIC_INFO_EMPLOYEE_FAIL,
  HR_UPDATE_BANK_INFO_EMPLOYEE_REQUEST,
  HR_UPDATE_BANK_INFO_EMPLOYEE_SUCCESS,
  HR_UPDATE_BANK_INFO_EMPLOYEE_FAIL,
  HR_UPDATE_REFERENCE_INFO_EMPLOYEE_REQUEST,
  HR_UPDATE_REFERENCE_INFO_EMPLOYEE_SUCCESS,
  HR_UPDATE_REFERENCE_INFO_EMPLOYEE_FAIL,
  HR_UPDATE_EDUCATION_INFO_EMPLOYEE_REQUEST,
  HR_UPDATE_EDUCATION_INFO_EMPLOYEE_SUCCESS,
  HR_UPDATE_EDUCATION_INFO_EMPLOYEE_FAIL,
  HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_REQUEST,
  HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_SUCCESS,
  HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_FAIL,
  HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_REQUEST,
  HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_SUCCESS,
  HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_FAIL,
  HR_UPDATE_VEHICLE_INFO_EMPLOYEE_REQUEST,
  HR_UPDATE_VEHICLE_INFO_EMPLOYEE_SUCCESS,
  HR_UPDATE_VEHICLE_INFO_EMPLOYEE_FAIL,
  HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_REQUEST,
  HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_SUCCESS,
  HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_FAIL,
  HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_REQUEST,
  HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_SUCCESS,
  HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_FAIL,
  HR_UPDATE_SETTINGS_INFO_EMPLOYEE_REQUEST,
  HR_UPDATE_SETTINGS_INFO_EMPLOYEE_SUCCESS,
  HR_UPDATE_SETTINGS_INFO_EMPLOYEE_FAIL,
  HR_RESET_PASSWORD_INFO_EMPLOYEE_REQUEST,
  HR_RESET_PASSWORD_INFO_EMPLOYEE_SUCCESS,
  HR_RESET_PASSWORD_INFO_EMPLOYEE_FAIL,
  HR_RESET_DEVICE_ID_INFO_EMPLOYEE_REQUEST,
  HR_RESET_DEVICE_ID_INFO_EMPLOYEE_SUCCESS,
  HR_RESET_DEVICE_ID_INFO_EMPLOYEE_FAIL,
  HR_ASSIGN_EXTENSION_EMPLOYEE_REQUEST,
  HR_ASSIGN_EXTENSION_EMPLOYEE_SUCCESS,
  HR_ASSIGN_EXTENSION_EMPLOYEE_FAIL,
  HR_REMOVE_EXTENSION_EMPLOYEE_REQUEST,
  HR_REMOVE_EXTENSION_EMPLOYEE_SUCCESS,
  HR_REMOVE_EXTENSION_EMPLOYEE_FAIL,
  HR_ACTIVATE_EXTENSION_EMPLOYEE_REQUEST,
  HR_ACTIVATE_EXTENSION_EMPLOYEE_SUCCESS,
  HR_ACTIVATE_EXTENSION_EMPLOYEE_FAIL,
  HR_DEACTIVATE_EXTENSION_EMPLOYEE_REQUEST,
  HR_DEACTIVATE_EXTENSION_EMPLOYEE_SUCCESS,
  HR_DEACTIVATE_EXTENSION_EMPLOYEE_FAIL,
  HR_UPLOAD_DOC_EMPLOYEE_REQUEST,
  HR_UPLOAD_DOC_EMPLOYEE_SUCCESS,
  HR_UPLOAD_DOC_EMPLOYEE_FAIL,
  HR_DELETE_DOC_EMPLOYEE_REQUEST,
  HR_DELETE_DOC_EMPLOYEE_SUCCESS,
  HR_DELETE_DOC_EMPLOYEE_FAIL,
  HR_DOWNLOAD_DOC_EMPLOYEE_REQUEST,
  HR_DOWNLOAD_DOC_EMPLOYEE_SUCCESS,
  HR_DOWNLOAD_DOC_EMPLOYEE_FAIL,
  HR_ADD_DEPARTMENT_EMPLOYEE_REQUEST,
  HR_ADD_DEPARTMENT_EMPLOYEE_SUCCESS,
  HR_ADD_DEPARTMENT_EMPLOYEE_FAIL,
  HR_UPDATE_DEPARTMENT_EMPLOYEE_REQUEST,
  HR_UPDATE_DEPARTMENT_EMPLOYEE_SUCCESS,
  HR_UPDATE_DEPARTMENT_EMPLOYEE_FAIL,
  HR_EXIT_EMPLOYEE_REQUEST,
  HR_EXIT_EMPLOYEE_SUCCESS,
  HR_EXIT_EMPLOYEE_FAIL,
  HR_UPDATE_ATTENDANCE_REQUEST,
  HR_UPDATE_ATTENDANCE_SUCCESS,
  HR_UPDATE_ATTENDANCE_FAIL,
  HR_GET_EVERYDAY_ATTENDANCE_REQUEST,
  HR_GET_EVERYDAY_ATTENDANCE_SUCCESS,
  HR_GET_EVERYDAY_ATTENDANCE_FAIL,
  GET_TODAY_ATTENDANCE_REQUEST,
  GET_TODAY_ATTENDANCE_SUCCESS,
  GET_TODAY_ATTENDANCE_RESET,
  GET_TODAY_ATTENDANCE_FAIL,
  UPDATE_ATTENDANCE_REQUEST,
  UPDATE_ATTENDANCE_SUCCESS,
  UPDATE_ATTENDANCE_FAIL,
  SEARCH_ATTENDANCE_REQUEST,
  SEARCH_ATTENDANCE_SUCCESS,
  SEARCH_ATTENDANCE_FAIL,
  FINALIZE_ATTENDANCE_REQUEST,
  FINALIZE_ATTENDANCE_SUCCESS,
  FINALIZE_ATTENDANCE_FAIL,
  UPDATE_FINALIZE_ATTENDANCE_REQUEST,
  UPDATE_FINALIZE_ATTENDANCE_SUCCESS,
  UPDATE_FINALIZE_ATTENDANCE_FAIL,
  HR_GET_MASTER_DEPARTMENTS_REQUEST,
  HR_GET_MASTER_DEPARTMENTS_SUCCESS,
  HR_GET_MASTER_DEPARTMENTS_FAIL,
  HR_ADD_MASTER_DEPARTMENT_REQUEST,
  HR_ADD_MASTER_DEPARTMENT_SUCCESS,
  HR_ADD_MASTER_DEPARTMENT_FAIL,
  HR_UPDATE_MASTER_DEPARTMENT_REQUEST,
  HR_UPDATE_MASTER_DEPARTMENT_SUCCESS,
  HR_UPDATE_MASTER_DEPARTMENT_FAIL,
  PROCESS_FINALIZE_ATTENDANCE_REQUEST,
  PROCESS_FINALIZE_ATTENDANCE_SUCCESS,
  PROCESS_FINALIZE_ATTENDANCE_FAIL,
  HR_REJOIN_EMPLOYEE_REQUEST,
  HR_REJOIN_EMPLOYEE_SUCCESS,
  HR_REJOIN_EMPLOYEE_FAIL,
  HR_REPORTS_REQUEST,
  HR_REPORTS_SUCCESS,
  HR_REPORTS_FAIL,
  HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_REQUEST,
  HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_SUCCESS,
  HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_FAIL,
  HR_ATTENDANCE_REPORTS_REQUEST,
  HR_ATTENDANCE_REPORTS_SUCCESS,
  HR_ATTENDANCE_REPORTS_FAIL,
} from '../constants/hrConstants'
import axios from 'axios'
import { logout } from '../actions/userActions'
import download from 'js-file-download'
import JsFileDownloader from 'js-file-downloader'

export const hrDashboardAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: HR_DASHBOARD_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/hr/dashboard/web', config)

    dispatch({
      type: HR_DASHBOARD_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: HR_DASHBOARD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const hrAsegAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: HR_ASEG_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/hr/employees/add/web', config)

    dispatch({
      type: HR_ASEG_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: HR_ASEG_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const hrAsepAction = (info) => async (dispatch, getState) => {
  try {
    dispatch({
      type: HR_ASEP_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post('/api/hr/employees/add/web', info, config)

    dispatch({
      type: HR_ASEP_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: HR_ASEP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const hrAeAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: HR_AE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/hr/employees/active/web', config)

    dispatch({
      type: HR_AE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: HR_AE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const hrNaeAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: HR_NAE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/hr/employees/not_active/web', config)

    dispatch({
      type: HR_NAE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: HR_NAE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const hrDepartmentsAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: HR_GET_DEPARTMENTS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/hr/departments/web', config)

    dispatch({
      type: HR_GET_DEPARTMENTS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: HR_GET_DEPARTMENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const hrAddDepartmentAction =
  (
    master_department,
    name,
    type,
    category,
    security,
    product,
    bankType,
    supervisors
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_ADD_DEPARTMENT_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        '/api/hr/department/add/web',
        {
          master_department,
          name,
          type,
          category,
          security,
          product,
          bankType,
          supervisors,
        },
        config
      )

      dispatch({
        type: HR_ADD_DEPARTMENT_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_ADD_DEPARTMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const hrUpdateDepartmentAction =
  (
    id,
    master_department,
    name,
    type,
    category,
    security,
    product,
    bankType,
    supervisors
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_UPDATE_DEPARTMENT_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/department/${id}/update/web`,
        {
          master_department,
          name,
          type,
          category,
          security,
          product,
          bankType,
          supervisors,
        },
        config
      )

      dispatch({
        type: HR_UPDATE_DEPARTMENT_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_UPDATE_DEPARTMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const hrGetEmployeeByIdAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: HR_GET_EMPLOYEE_BY_ID_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/hr/employee/${id}/web`, config)

    dispatch({
      type: HR_GET_EMPLOYEE_BY_ID_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: HR_GET_EMPLOYEE_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const hrUpdtBIEAction =
  (
    id,
    first_name,
    middle_name,
    last_name,
    current_address_line_1,
    current_address_line_2,
    current_state,
    current_country,
    current_pincode,
    permanent_address_line_1,
    permanent_address_line_2,
    permanent_state,
    permanent_country,
    permanent_pincode,
    dob,
    mobile,
    alternate_number,
    father_name,
    father_mobile,
    father_occupation,
    mother_name,
    mother_mobile,
    mother_occupation,
    personal_email_address,
    blood_group,
    gender,
    marital_status,
    spouse_name,
    spouse_mobile,
    marriage_date
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_UPDATE_BASIC_INFO_EMPLOYEE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/employee/${id}/update/basic/web`,
        {
          first_name,
          middle_name,
          last_name,
          current_address_line_1,
          current_address_line_2,
          current_state,
          current_country,
          current_pincode,
          permanent_address_line_1,
          permanent_address_line_2,
          permanent_state,
          permanent_country,
          permanent_pincode,
          dob,
          mobile,
          alternate_number,
          father_name,
          father_mobile,
          father_occupation,
          mother_name,
          mother_mobile,
          mother_occupation,
          personal_email_address,
          blood_group,
          gender,
          marital_status,
          spouse_name,
          spouse_mobile,
          marriage_date,
        },
        config
      )

      dispatch({
        type: HR_UPDATE_BASIC_INFO_EMPLOYEE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_UPDATE_BASIC_INFO_EMPLOYEE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const hrUpdtBkIEAction =
  (id, name_as_per_bank, bank_name, account_number, ifsc_code) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_UPDATE_BANK_INFO_EMPLOYEE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/employee/${id}/update/bank/web`,
        {
          name_as_per_bank,
          bank_name,
          account_number,
          ifsc_code,
        },
        config
      )

      dispatch({
        type: HR_UPDATE_BANK_INFO_EMPLOYEE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_UPDATE_BANK_INFO_EMPLOYEE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const hrUpdtRIEAction =
  (
    id,
    reference_1_name,
    reference_1_mobile,
    reference_1_relation,
    reference_1_address,
    reference_2_name,
    reference_2_mobile,
    reference_2_relation,
    reference_2_address
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_UPDATE_REFERENCE_INFO_EMPLOYEE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/employee/${id}/update/reference/web`,
        {
          reference_1_name,
          reference_1_mobile,
          reference_1_relation,
          reference_1_address,
          reference_2_name,
          reference_2_mobile,
          reference_2_relation,
          reference_2_address,
        },
        config
      )

      dispatch({
        type: HR_UPDATE_REFERENCE_INFO_EMPLOYEE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_UPDATE_REFERENCE_INFO_EMPLOYEE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const hrUpdtEIEAction =
  (id, education, school_name, credential, graduated_on) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_UPDATE_EDUCATION_INFO_EMPLOYEE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/employee/${id}/update/education/web`,
        {
          education,
          school_name,
          credential,
          graduated_on,
        },
        config
      )

      dispatch({
        type: HR_UPDATE_EDUCATION_INFO_EMPLOYEE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_UPDATE_EDUCATION_INFO_EMPLOYEE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const hrUpdtEmpIEAction =
  (
    id,
    doj,
    employee_type,
    job_title,
    employee_zone,
    punch_in_at,
    tracking_policy,
    experience_in_years,
    referred_by_name,
    referred_by_mobile,
    application_file,
    original_file,
    employee_code,
    email_address,
    access_control
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/employee/${id}/update/employee/web`,
        {
          doj,
          employee_type,
          job_title,
          employee_zone,
          punch_in_at,
          tracking_policy,
          experience_in_years,
          referred_by_name,
          referred_by_mobile,
          application_file,
          original_file,
          employee_code,
          email_address,
          access_control,
        },
        config
      )

      dispatch({
        type: HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_UPDATE_EMPLOYEE_INFO_EMPLOYEE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const hrUpdtExpIEAction =
  (
    id,
    previous_company_name,
    previous_designation,
    previous_left_on,
    previous_left_reason
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/employee/${id}/update/experience/web`,
        {
          previous_company_name,
          previous_designation,
          previous_left_on,
          previous_left_reason,
        },
        config
      )

      dispatch({
        type: HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_UPDATE_EXPERIENCE_INFO_EMPLOYEE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const hrUpdtVIEAction =
  (id, vehicle_name, registration_number, registered_name) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_UPDATE_VEHICLE_INFO_EMPLOYEE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/employee/${id}/update/vehicle/web`,
        {
          vehicle_name,
          registration_number,
          registered_name,
        },
        config
      )

      dispatch({
        type: HR_UPDATE_VEHICLE_INFO_EMPLOYEE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_UPDATE_VEHICLE_INFO_EMPLOYEE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const hrUpdtEmgIEAction =
  (
    id,
    emergency_contact_name,
    emergency_contact_number,
    emergency_contact_relation,
    emergency_contact_address
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/employee/${id}/update/emergency_contact/web`,
        {
          emergency_contact_name,
          emergency_contact_number,
          emergency_contact_relation,
          emergency_contact_address,
        },
        config
      )

      dispatch({
        type: HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_UPDATE_EMERGENCY_INFO_EMPLOYEE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const hrUpdtDIEAction =
  (
    id,
    aadhar_card_number,
    pan_card_number,
    voter_id_card_number,
    driving_license_number,
    photos_collected,
    house_documents_status,
    house_documents_name,
    original_documents_isRequired,
    original_documents_isSubmitted,
    original_documents_name,
    police_verification_isRequired,
    police_verification_isSubmitted,
    police_verification_valid_upto,
    dra_isRequired,
    dra_isSubmitted,
    dra_number
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/employee/${id}/update/documents/web`,
        {
          aadhar_card_number,
          pan_card_number,
          voter_id_card_number,
          driving_license_number,
          photos_collected,
          house_documents_status,
          house_documents_name,
          original_documents_isRequired,
          original_documents_isSubmitted,
          original_documents_name,
          police_verification_isRequired,
          police_verification_isSubmitted,
          police_verification_valid_upto,
          dra_isRequired,
          dra_isSubmitted,
          dra_number,
        },
        config
      )

      dispatch({
        type: HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_UPDATE_DOCUMENTS_INFO_EMPLOYEE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const hrUpdtSIEAction =
  (id, login_type, verification_status, app_calling, web_calling) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_UPDATE_SETTINGS_INFO_EMPLOYEE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/employee/${id}/update/settings/web`,
        {
          login_type,
          verification_status,
          app_calling,
          web_calling,
        },
        config
      )

      dispatch({
        type: HR_UPDATE_SETTINGS_INFO_EMPLOYEE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_UPDATE_SETTINGS_INFO_EMPLOYEE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const hrResetPIEAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: HR_RESET_PASSWORD_INFO_EMPLOYEE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      `/api/hr/employee/${id}/reset/password/web`,
      {},
      config
    )

    dispatch({
      type: HR_RESET_PASSWORD_INFO_EMPLOYEE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: HR_RESET_PASSWORD_INFO_EMPLOYEE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const hrResetDevIDIEAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: HR_RESET_DEVICE_ID_INFO_EMPLOYEE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      `/api/hr/employee/${id}/reset/device_id/web`,
      {},
      config
    )

    dispatch({
      type: HR_RESET_DEVICE_ID_INFO_EMPLOYEE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: HR_RESET_DEVICE_ID_INFO_EMPLOYEE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const hrAssignExtensionAction =
  (id, extension) => async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_ASSIGN_EXTENSION_EMPLOYEE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/employee/${id}/extension/assign/web`,
        { extension },
        config
      )

      dispatch({
        type: HR_ASSIGN_EXTENSION_EMPLOYEE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_ASSIGN_EXTENSION_EMPLOYEE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const hrRemoveExtensionAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: HR_REMOVE_EXTENSION_EMPLOYEE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      `/api/hr/employee/${id}/extension/remove/web`,
      {},
      config
    )

    dispatch({
      type: HR_REMOVE_EXTENSION_EMPLOYEE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: HR_REMOVE_EXTENSION_EMPLOYEE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const hrActivateExtensionAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: HR_ACTIVATE_EXTENSION_EMPLOYEE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      `/api/hr/employee/${id}/extension/activate/web`,
      {},
      config
    )

    dispatch({
      type: HR_ACTIVATE_EXTENSION_EMPLOYEE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: HR_ACTIVATE_EXTENSION_EMPLOYEE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const hrDeactivateExtensionAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_DEACTIVATE_EXTENSION_EMPLOYEE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/employee/${id}/extension/deactivate/web`,
        {},
        config
      )

      dispatch({
        type: HR_DEACTIVATE_EXTENSION_EMPLOYEE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_DEACTIVATE_EXTENSION_EMPLOYEE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const hrUploadDocAction =
  (id, formData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_UPLOAD_DOC_EMPLOYEE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/employee/${id}/doc/upload/web`,
        formData,
        config
      )

      dispatch({
        type: HR_UPLOAD_DOC_EMPLOYEE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_UPLOAD_DOC_EMPLOYEE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const hrDeleteDocAction = (id, docID) => async (dispatch, getState) => {
  try {
    dispatch({
      type: HR_DELETE_DOC_EMPLOYEE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      `/api/hr/employee/${id}/doc/delete/web`,
      { docID },
      config
    )

    dispatch({
      type: HR_DELETE_DOC_EMPLOYEE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: HR_DELETE_DOC_EMPLOYEE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const hrDownloadDocAction = (docID) => async (dispatch, getState) => {
  try {
    dispatch({
      type: HR_DOWNLOAD_DOC_EMPLOYEE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    new JsFileDownloader({
      url: `/api/hr/employee/${docID}/doc/download/web`,
    })
      .then(function () {
        // Called when download ended
        dispatch({
          type: HR_DOWNLOAD_DOC_EMPLOYEE_SUCCESS,
          payload: 'Success',
        })
      })
      .catch(function (error) {
        dispatch({
          type: HR_DOWNLOAD_DOC_EMPLOYEE_FAIL,
          payload: 'An error occurred while downloading the file',
        })
      })
  } catch (error) {
    dispatch({
      type: HR_DOWNLOAD_DOC_EMPLOYEE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const hrAddDepartmentEmployeeAction =
  (id, department, supervisor, joined_on) => async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_ADD_DEPARTMENT_EMPLOYEE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/employee/${id}/department/add/web`,
        { department, supervisor, joined_on },
        config
      )

      dispatch({
        type: HR_ADD_DEPARTMENT_EMPLOYEE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_ADD_DEPARTMENT_EMPLOYEE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const hrUpdateDepartmentEmployeeAction =
  (
    employeeID,
    id,
    current_supervisor,
    supervisor,
    joined_on,
    status,
    left_on,
    id_card,
    id_card_number,
    id_card_issued_on,
    id_card_expiry_on,
    id_card_returned,
    id_card_returned_on
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_UPDATE_DEPARTMENT_EMPLOYEE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/employee/${employeeID}/department/update/web`,
        {
          id,
          current_supervisor,
          supervisor,
          joined_on,
          status,
          left_on,
          id_card,
          id_card_number,
          id_card_issued_on,
          id_card_expiry_on,
          id_card_returned,
          id_card_returned_on,
        },
        config
      )

      dispatch({
        type: HR_UPDATE_DEPARTMENT_EMPLOYEE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_UPDATE_DEPARTMENT_EMPLOYEE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const hrMakeDepartmentPrimaryEmployeeAction =
  (employeeID, department) => async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/employee/${employeeID}/department/primary/web`,
        {
          department,
        },
        config
      )

      dispatch({
        type: HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_MAKE_DEPARTMENT_PRIMARY_EMPLOYEE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const hrExitEmployeeAction =
  (id, date, reason, exit_status, details) => async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_EXIT_EMPLOYEE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/employee/${id}/exit/web`,
        {
          date,
          reason,
          exit_status,
          details,
        },
        config
      )

      dispatch({
        type: HR_EXIT_EMPLOYEE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_EXIT_EMPLOYEE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

// Rejoin employee
export const hrRejoinEmployeeAction =
  (id, date) => async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_REJOIN_EMPLOYEE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/employee/${id}/rejoin/web`,
        {
          date,
        },
        config
      )

      dispatch({
        type: HR_REJOIN_EMPLOYEE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_REJOIN_EMPLOYEE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const todaysAttendanceAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_TODAY_ATTENDANCE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/hr/attendance/today/web', config)

    dispatch({
      type: GET_TODAY_ATTENDANCE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: GET_TODAY_ATTENDANCE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateAttendanceAction =
  (id, status) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_ATTENDANCE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/attendance/${id}/update/web`,
        { status },
        config
      )

      dispatch({
        type: UPDATE_ATTENDANCE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: UPDATE_ATTENDANCE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const searchAttendanceAction = (date) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SEARCH_ATTENDANCE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      '/api/hr/attendance/search/web',
      { date },
      config
    )

    dispatch({
      type: SEARCH_ATTENDANCE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: SEARCH_ATTENDANCE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const finalizeAttendanceAction =
  (month, year) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FINALIZE_ATTENDANCE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        '/api/hr/attendance/finalize/web',
        { month, year },
        config
      )

      dispatch({
        type: FINALIZE_ATTENDANCE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: FINALIZE_ATTENDANCE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateFinalizeAttendanceAction =
  (id, hrFinal, year, month, leavesAvailable, comments) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_FINALIZE_ATTENDANCE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/attendance/finalize/${id}/web`,
        {
          hrFinal,
          year,
          month,
          leavesAvailable,
          comments,
        },
        config
      )

      dispatch({
        type: UPDATE_FINALIZE_ATTENDANCE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: UPDATE_FINALIZE_ATTENDANCE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const processFinalizeAttendanceAction =
  (year, month) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PROCESS_FINALIZE_ATTENDANCE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/attendance/process/web`,
        {
          year,
          month,
        },
        config
      )

      dispatch({
        type: PROCESS_FINALIZE_ATTENDANCE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: PROCESS_FINALIZE_ATTENDANCE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const hrMasterDepartmentsAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: HR_GET_MASTER_DEPARTMENTS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get('/api/hr/master_departments/web', config)

    dispatch({
      type: HR_GET_MASTER_DEPARTMENTS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: HR_GET_MASTER_DEPARTMENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const hrAddMasterDepartmentAction =
  (name) => async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_ADD_MASTER_DEPARTMENT_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        '/api/hr/master_department/add/web',
        { name },
        config
      )

      dispatch({
        type: HR_ADD_MASTER_DEPARTMENT_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_ADD_MASTER_DEPARTMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const hrUpdateMasterDepartmentAction =
  (id, name) => async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_UPDATE_MASTER_DEPARTMENT_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/master_department/${id}/update/web`,
        { name },
        config
      )

      dispatch({
        type: HR_UPDATE_MASTER_DEPARTMENT_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_UPDATE_MASTER_DEPARTMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const hrReportsAction = (type) => async (dispatch, getState) => {
  try {
    dispatch({
      type: HR_REPORTS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/hr/reports/${type}/web`, config)

    dispatch({
      type: HR_REPORTS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(logout())
      return
    }
    dispatch({
      type: HR_REPORTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const hrAttendanceReportsAction =
  (type, date, month, year) => async (dispatch, getState) => {
    try {
      dispatch({
        type: HR_ATTENDANCE_REPORTS_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/hr/attendance/reports/${type}/web`,
        { date, month, year },
        config
      )

      dispatch({
        type: HR_ATTENDANCE_REPORTS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: HR_ATTENDANCE_REPORTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
