import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core'
import Controls from './controls/Controls'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    paddding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(5),
    minWidth: '500px',
  },
}))

const Popup = ({ title, children, openPopup, setOpenPopup }) => {
  const classes = useStyles()

  return (
    <Dialog
      classes={{ paper: classes.dialogWrapper }}
      open={openPopup}
      maxWidth='md'
    >
      <DialogTitle>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant='h6' component='div' style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <Controls.ActionButton
            color='secondary'
            onClick={() => setOpenPopup(false)}
          >
            <CloseIcon />
          </Controls.ActionButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  )
}

export default Popup
