export const BILLING_TAGS_REQUEST = 'BILLING_TAGS_REQUEST'
export const BILLING_TAGS_SUCCESS = 'BILLING_TAGS_SUCCESS'
export const BILLING_TAGS_FAIL = 'BILLING_TAGS_FAIL'
export const BILLING_TAGS_RESET = 'BILLING_TAGS_RESET'

export const BILLING_ADD_TAG_REQUEST = 'BILLING_ADD_TAG_REQUEST'
export const BILLING_ADD_TAG_SUCCESS = 'BILLING_ADD_TAG_SUCCESS'
export const BILLING_ADD_TAG_FAIL = 'BILLING_ADD_TAG_FAIL'
export const BILLING_ADD_TAG_RESET = 'BILLING_ADD_TAG_RESET'

export const BILLING_UPDATE_TAG_REQUEST = 'BILLING_UPDATE_TAG_REQUEST'
export const BILLING_UPDATE_TAG_SUCCESS = 'BILLING_UPDATE_TAG_SUCCESS'
export const BILLING_UPDATE_TAG_FAIL = 'BILLING_UPDATE_TAG_FAIL'
export const BILLING_UPDATE_TAG_RESET = 'BILLING_UPDATE_TAG_RESET'

export const BILLING_GET_DEPARTMENTS_REQUEST = 'BILLING_GET_DEPARTMENTS_REQUEST'
export const BILLING_GET_DEPARTMENTS_SUCCESS = 'BILLING_GET_DEPARTMENTS_SUCCESS'
export const BILLING_GET_DEPARTMENTS_FAIL = 'BILLING_GET_DEPARTMENTS_FAIL'
export const BILLING_GET_DEPARTMENTS_RESET = 'BILLING_GET_DEPARTMENTS_RESET'

export const BILLING_GET_DEPARTMENT_BY_ID_REQUEST =
  'BILLING_GET_DEPARTMENT_BY_ID_REQUEST'
export const BILLING_GET_DEPARTMENT_BY_ID_SUCCESS =
  'BILLING_GET_DEPARTMENT_BY_ID_SUCCESS'
export const BILLING_GET_DEPARTMENT_BY_ID_FAIL =
  'BILLING_GET_DEPARTMENT_BY_ID_FAIL'
export const BILLING_GET_DEPARTMENT_BY_ID_RESET =
  'BILLING_GET_DEPARTMENT_BY_ID_RESET'

export const SUBMIT_DEPARTMENT_BILLING_DATA_REQUEST =
  'SUBMIT_DEPARTMENT_BILLING_DATA_REQUEST'
export const SUBMIT_DEPARTMENT_BILLING_DATA_SUCCESS =
  'SUBMIT_DEPARTMENT_BILLING_DATA_SUCCESS'
export const SUBMIT_DEPARTMENT_BILLING_DATA_FAIL =
  'SUBMIT_DEPARTMENT_BILLING_DATA_FAIL'
export const SUBMIT_DEPARTMENT_BILLING_DATA_RESET =
  'SUBMIT_DEPARTMENT_BILLING_DATA_RESET'

export const UPDATE_DEPARTMENT_BILLING_DATA_REQUEST =
  'UPDATE_DEPARTMENT_BILLING_DATA_REQUEST'
export const UPDATE_DEPARTMENT_BILLING_DATA_SUCCESS =
  'UPDATE_DEPARTMENT_BILLING_DATA_SUCCESS'
export const UPDATE_DEPARTMENT_BILLING_DATA_FAIL =
  'UPDATE_DEPARTMENT_BILLING_DATA_FAIL'
export const UPDATE_DEPARTMENT_BILLING_DATA_RESET =
  'UPDATE_DEPARTMENT_BILLING_DATA_RESET'

export const GET_BILLING_DATA_BY_ID_REQUEST = 'GET_BILLING_DATA_BY_ID_REQUEST'
export const GET_BILLING_DATA_BY_ID_SUCCESS = 'GET_BILLING_DATA_BY_ID_SUCCESS'
export const GET_BILLING_DATA_BY_ID_FAIL = 'GET_BILLING_DATA_BY_ID_FAIL'
export const GET_BILLING_DATA_BY_ID_RESET = 'GET_BILLING_DATA_BY_ID_RESET'

export const GET_BILLING_REPORTS_REQUEST = 'GET_BILLING_REPORTS_REQUEST'
export const GET_BILLING_REPORTS_SUCCESS = 'GET_BILLING_REPORTS_SUCCESS'
export const GET_BILLING_REPORTS_FAIL = 'GET_BILLING_REPORTS_FAIL'
export const GET_BILLING_REPORTS_RESET = 'GET_BILLING_REPORTS_RESET'

export const BILLING_REPORTS_REQUEST = 'BILLING_REPORTS_REQUEST'
export const BILLING_REPORTS_SUCCESS = 'BILLING_REPORTS_SUCCESS'
export const BILLING_REPORTS_FAIL = 'BILLING_REPORTS_FAIL'
export const BILLING_REPORTS_RESET = 'BILLING_REPORTS_RESET'

export const BILLING_DELETE_DATA_BY_ID_REQUEST =
  'BILLING_DELETE_DATA_BY_ID_REQUEST'
export const BILLING_DELETE_DATA_BY_ID_SUCCESS =
  'BILLING_DELETE_DATA_BY_ID_SUCCESS'
export const BILLING_DELETE_DATA_BY_ID_FAIL = 'BILLING_DELETE_DATA_BY_ID_FAIL'
export const BILLING_DELETE_DATA_BY_ID_RESET = 'BILLING_DELETE_DATA_BY_ID_RESET'

export const BILLING_MASTER_REPORT_REQUEST = 'BILLING_MASTER_REPORT_REQUEST'
export const BILLING_MASTER_REPORT_SUCCESS = 'BILLING_MASTER_REPORT_SUCCESS'
export const BILLING_MASTER_REPORT_FAIL = 'BILLING_MASTER_REPORT_FAIL'
export const BILLING_MASTER_REPORT_RESET = 'BILLING_MASTER_REPORT_RESET'

export const GET_BILLING_DAILY_MIS_REQUEST = 'GET_BILLING_DAILY_MIS_REQUEST'
export const GET_BILLING_DAILY_MIS_SUCCESS = 'GET_BILLING_DAILY_MIS_SUCCESS'
export const GET_BILLING_DAILY_MIS_FAIL = 'GET_BILLING_DAILY_MIS_FAIL'
export const GET_BILLING_DAILY_MIS_RESET = 'GET_BILLING_DAILY_MIS_RESET'

export const BILLING_DAILY_MIS_REQUEST = 'BILLING_DAILY_MIS_REQUEST'
export const BILLING_DAILY_MIS_SUCCESS = 'BILLING_DAILY_MIS_SUCCESS'
export const BILLING_DAILY_MIS_FAIL = 'BILLING_DAILY_MIS_FAIL'
export const BILLING_DAILY_MIS_RESET = 'BILLING_DAILY_MIS_RESET'
