import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Controls from './controls/Controls'
import Notification from './Notification'
import { Form, useForm } from './useForm'
import { useDispatch, useSelector } from 'react-redux'
import { addRoleAction, rolesAction } from '../actions/settingActions'
import { ADD_ROLE_RESET } from '../constants/settingConstants'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const initialValues = {
  employee: '',
  role: '',
}

const roles = [
  { id: 'Admin', title: 'Admin' },
  { id: 'HR', title: 'HR' },
  { id: 'Accounts', title: 'Accounts' },
  { id: 'Verification Supervisor', title: 'Verification Supervisor' },
  { id: 'Billing', title: 'Billing' },
]

const AddRole = ({ setOpenPopup, employees }) => {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

  const classes = useStyles()
  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('employee' in fieldValues)
      temp.employee = fieldValues.employee ? '' : 'This field is required'
    if ('role' in fieldValues)
      temp.role = fieldValues.role ? '' : 'This field is required'
    setErrors({
      ...temp,
    })
    if (fieldValues === values) return Object.values(temp).every((x) => x == '')
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate)

  const dispatch = useDispatch()
  const settingsRoleAdd = useSelector((state) => state.settingsRoleAdd)
  const { loading, error, addRoleData } = settingsRoleAdd

  // const hrUpdateDpt = useSelector((state) => state.hrUpdateDpt)
  // const { loading1, error1, updateDepartment } = hrUpdateDpt

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      dispatch(addRoleAction(values.employee, values.role))
    }
  }

  useEffect(() => {
    dispatch({ type: ADD_ROLE_RESET })
    if (addRoleData) {
      setNotify({
        isOpen: true,
        message: addRoleData.msg,
        type: 'success',
      })
      setTimeout(() => {
        setOpenPopup(false)
        dispatch(rolesAction())
      }, 2000)
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [dispatch, addRoleData, error])

  // useEffect(() => {
  //   dispatch({ type: HR_UPDATE_DEPARTMENT_RESET })
  //   if (updateDepartment) {
  //     setNotify({
  //       isOpen: true,
  //       message: updateDepartment.msg,
  //       type: 'success',
  //     })
  //     setTimeout(() => {
  //       setOpenPopup(false)
  //       dispatch(hrDepartmentsAction())
  //     }, 2000)
  //   } else if (error1) {
  //     setNotify({
  //       isOpen: true,
  //       message: error1,
  //       type: 'error',
  //     })
  //   }
  // }, [dispatch, updateDepartment, error1])

  // useEffect(() => {
  //   if (departmentForEdit != null) {
  //     setValues({
  //       ...departmentForEdit,
  //     })
  //   }
  // }, [departmentForEdit])

  return (
    <>
      <Form autoComplete='off' onSubmit={handleSubmit}>
        <Controls.Select
          label='Role'
          name='role'
          value={values.role}
          onChange={handleInputChange}
          error={errors.role}
          options={roles}
        />
        <Controls.Select
          label='Employee'
          name='employee'
          value={values.employee}
          onChange={handleInputChange}
          error={errors.employee}
          options={employees}
        />
        <Controls.Button
          type='submit'
          fullWidth
          text='submit'
          className={classes.submit}
          loading={loading}
        />
      </Form>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}

export default AddRole
