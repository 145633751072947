import React, { useState, useEffect } from 'react'
import Avatar from '@material-ui/core/Avatar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { useForm, Form } from '../components/useForm'
import Controls from '../components/controls/Controls'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../actions/userActions'
import Notification from '../components/Notification'
import { IconButton, InputAdornment } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://mser.co.in/'>
        MSER Ventures Private Limited
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const initialValues = {
  mobile: '',
  password: '',
}

export default function SignIn({ history }) {
  const classes = useStyles()
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('mobile' in fieldValues)
      temp.mobile =
        fieldValues.mobile.length > 9 ? '' : 'Mobile must be of 10 digits'
    if ('password' in fieldValues)
      temp.password = fieldValues.password ? '' : 'This field is required'
    setErrors({
      ...temp,
    })
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '')
  }

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  )

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      dispatch(login(values.mobile, values.password))
    }
  }

  useEffect(() => {
    if (userInfo) {
      setNotify({
        isOpen: true,
        message: 'Login successful',
        type: 'success',
      })
      history.push('/dashboard')
    } else if (error) {
      setNotify({
        isOpen: true,
        message: error,
        type: 'error',
      })
    }
  }, [history, userInfo, error])

  return (
    <>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Sign in
          </Typography>
          <Form autoComplete='off' onSubmit={handleSubmit}>
            <Controls.Input
              label='Mobile'
              name='mobile'
              value={values.mobile}
              onChange={handleInputChange}
              error={errors.mobile}
              type='number'
            />
            <Controls.Input
              name='password'
              label='Password'
              value={values.password}
              onChange={handleInputChange}
              error={errors.password}
              type='password'
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Controls.Button
              type='submit'
              fullWidth
              text='submit'
              className={classes.submit}
              loading={loading}
            />
          </Form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}
