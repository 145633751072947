import React, { useState, useEffect } from 'react'
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import { hrAsepAction, hrAsegAction } from '../actions/hrActions'
import { useDispatch, useSelector } from 'react-redux'
import Notification from '../components/Notification'
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import PageHeader from '../components/PageHeader'
import Controls from '../components/controls/Controls'
import { useForm, Form } from '../components/useForm'
import Progress from '../components/Progress'
import { HR_ASEP_RESET } from '../constants/hrConstants'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(0, 1.5, 2, 1.5),
    padding: theme.spacing(2),
  },
  checkbox: {
    '& .makeStyles-form-18 .MuiFormControl-root': {
      margin: 0,
    },
  },
  submit: {
    margin: theme.spacing(2, 0, 0, 0),
  },
}))

const initialValues = {
  first_name: '',
  middle_name: '',
  last_name: '',
  current_address_line_1: '',
  current_address_line_2: '',
  current_state: '',
  current_country: 'India',
  current_pincode: '',
  permanent_address_line_1: '',
  permanent_address_line_2: '',
  permanent_state: '',
  permanent_country: 'India',
  permanent_pincode: '',
  dob: null,
  mobile: '',
  alternate_number: '',
  father_name: '',
  father_mobile: '',
  father_occupation: '',
  mother_name: '',
  mother_mobile: '',
  mother_occupation: '',
  personal_email_address: '',
  blood_group: '',
  gender: '',
  marital_status: '',
  spouse_name: '',
  spouse_mobile: '',
  marriage_date: new Date(),
  reference_1_name: '',
  reference_1_mobile: '',
  reference_1_address: '',
  reference_1_relation: '',
  reference_2_name: '',
  reference_2_mobile: '',
  reference_2_address: '',
  reference_2_relation: '',
  name_as_per_bank: '',
  bank_name: '',
  account_number: '',
  ifsc_code: '',
  salary: 0,
  aadhar_card_number: '',
  pan_card_number: '',
  voter_id_card_number: '',
  driving_license_number: '',
  photos_collected: '',
  house_documents_status: 'No',
  house_documents_name: '',
  original_documents_isRequired: 'Not Required',
  original_documents_isSubmitted: '',
  original_documents_name: '',
  police_verification_isRequired: 'Not Required',
  police_verification_isSubmitted: '',
  police_verification_valid_upto: '',
  dra_isRequired: 'Not Required',
  dra_isSubmitted: '',
  dra_number: '',
  education: '',
  credential: '',
  school_name: '',
  graduated_on: null,
  organisation: '',
  punch_in_at: '09:45:00',
  doj: new Date(),
  employee_type: 'Office',
  job_title: '',
  employee_zone: '',
  tracking_policy: 'Image Capture',
  experience_in_years: '',
  referred_by_name: '',
  referred_by_mobile: '',
  department: '',
  supervisor: '',
  previous_company_name: '',
  previous_designation: '',
  previous_left_on: null,
  previous_left_reason: '',
  vehicle_name: '',
  registered_name: '',
  registration_number: '',
  emergency_contact_name: '',
  emergency_contact_number: '',
  emergency_contact_relation: '',
  emergency_contact_address: '',
  application_file: '',
  original_file: '',
}

const bloodGroups = [
  { id: 'A+', title: 'A+' },
  { id: 'A-', title: 'A-' },
  { id: 'B+', title: 'B+' },
  { id: 'B-', title: 'B-' },
  { id: 'O+', title: 'O+' },
  { id: 'O-', title: 'O-' },
  { id: 'AB+', title: 'AB+' },
  { id: 'AB-', title: 'AB-' },
]
const genders = [
  { id: 'Male', title: 'Male' },
  { id: 'Female', title: 'Female' },
  { id: 'Others', title: 'Others' },
]
const maritalStatus = [
  { id: 'Single', title: 'Single' },
  { id: 'Married', title: 'Married' },
  { id: 'Divorcee', title: 'Divorcee' },
  { id: 'Widow', title: 'Widow' },
]
const educationOptions = [
  { id: 'PhD', title: 'PhD' },
  { id: 'Masters', title: 'Masters' },
  { id: 'Bachelors', title: 'Bachelors' },
  { id: 'Diploma', title: 'Diploma' },
  { id: '12th', title: '12th' },
  { id: '10th', title: '10th' },
  { id: 'Below 10th', title: 'Below 10th' },
]

const employeeTypes = [
  { id: 'Office', title: 'Office' },
  { id: 'Field', title: 'Field' },
]

const employeeZones = [
  { id: 'North', title: 'North' },
  { id: 'South', title: 'South' },
  { id: 'East', title: 'East' },
  { id: 'West', title: 'West' },
  { id: 'Central', title: 'Central' },
  { id: 'Gurugram', title: 'Gurugram' },
  { id: 'Faridabad', title: 'Faridabad' },
  { id: 'Ghaziabad', title: 'Ghaziabad' },
]

const trackingPolicy = [
  { id: 'Image Capture', title: 'Image Capture' },
  // { id: 'Live Location', title: 'Live Location' },
]

const relations = [
  { id: 'Relative', title: 'Relative' },
  { id: 'Friend', title: 'Friend' },
]

const jobTitles = [
  { id: 'Tele Caller', title: 'Tele Caller' },
  { id: 'Coordinator', title: 'Coordinator' },
  { id: 'Supervisor', title: 'Supervisor' },
  { id: 'HR Head', title: 'HR Head' },
  { id: 'Billing Head', title: 'Billing Head' },
  { id: 'Accountant', title: 'Accountant' },
  { id: 'IT Head', title: 'IT Head' },
  { id: 'Full Stack Developer', title: 'Full Stack Developer' },
  { id: 'Runner', title: 'Runner' },
  { id: 'Cashier', title: 'Cashier' },
  { id: 'Office Boy', title: 'Office Boy' },
  { id: 'Manager', title: 'Manager' },
  { id: 'CEO', title: 'CEO' },
]

const HRAddEmployee = ({ history }) => {
  const classes = useStyles()
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })

  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (!userInfo) {
      history.push('/')
    }
  }, [dispatch, history, userInfo])

  const hrAseg = useSelector((state) => state.hrAseg)
  const { loading, error, hrAddEmployeeGet } = hrAseg

  const [banks, setBanks] = useState([])
  const [departments, setDepartments] = useState([])
  const [organisations, setOrganisations] = useState([])

  useEffect(() => {
    dispatch(hrAsegAction())
  }, [])

  useEffect(() => {
    if (hrAddEmployeeGet) {
      if (hrAddEmployeeGet.role === 'Admin' || hrAddEmployeeGet.role == 'HR') {
        setBanks(hrAddEmployeeGet.banks)
        setDepartments(hrAddEmployeeGet.departments)
        setOrganisations(hrAddEmployeeGet.organisations)
      } else {
        history.push('/dashboard')
      }
    }
  }, [hrAddEmployeeGet])

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('first_name' in fieldValues)
      temp.first_name = fieldValues.first_name ? '' : 'This field is required'
    if ('last_name' in fieldValues)
      temp.last_name = fieldValues.last_name ? '' : 'This field is required'
    if ('current_address_line_1' in fieldValues)
      temp.current_address_line_1 = fieldValues.current_address_line_1
        ? ''
        : 'This field is required'
    if ('current_state' in fieldValues)
      temp.current_state = fieldValues.current_state
        ? ''
        : 'This field is required'
    if ('current_country' in fieldValues)
      temp.current_country = fieldValues.current_country
        ? ''
        : 'This field is required'
    if ('current_pincode' in fieldValues)
      temp.current_pincode = fieldValues.current_pincode
        ? ''
        : 'This field is required'
    if ('permanent_address_line_1' in fieldValues)
      temp.permanent_address_line_1 = fieldValues.permanent_address_line_1
        ? ''
        : 'This field is required'
    if ('permanent_state' in fieldValues)
      temp.permanent_state = fieldValues.permanent_state
        ? ''
        : 'This field is required'
    if ('permanent_country' in fieldValues)
      temp.permanent_country = fieldValues.permanent_country
        ? ''
        : 'This field is required'
    if ('permanent_pincode' in fieldValues)
      temp.permanent_pincode = fieldValues.permanent_pincode
        ? ''
        : 'This field is required'
    if ('doj' in fieldValues)
      temp.doj = fieldValues.doj ? '' : 'This field is required'
    if ('mobile' in fieldValues)
      temp.mobile =
        fieldValues.mobile.length > 9
          ? ''
          : 'Mobile number must be of 10 digits only'
    if ('alternate_number' in fieldValues)
      temp.alternate_number =
        fieldValues.alternate_number.length > 9
          ? ''
          : 'Alternate Mobile number must be of 10 digits only'
    if ('father_name' in fieldValues)
      temp.father_name = fieldValues.father_name ? '' : 'This field is required'
    if ('mother_name' in fieldValues)
      temp.mother_name = fieldValues.mother_name ? '' : 'This field is required'
    if ('gender' in fieldValues)
      temp.gender =
        fieldValues.gender.length !== 0 ? '' : 'This field is required'
    if ('marital_status' in fieldValues)
      temp.marital_status =
        fieldValues.marital_status.length !== 0 ? '' : 'This field is required'
    if ('reference_1_name' in fieldValues)
      temp.reference_1_name = fieldValues.reference_1_name
        ? ''
        : 'This field is required'

    if ('reference_1_mobile' in fieldValues)
      temp.reference_1_mobile =
        fieldValues.reference_1_mobile.length > 9
          ? ''
          : 'Number must be of 10 digits only'

    if ('reference_1_relation' in fieldValues)
      temp.reference_1_relation =
        fieldValues.reference_1_relation.length !== 0
          ? ''
          : 'This field is required'

    if ('reference_1_address' in fieldValues)
      temp.reference_1_address = fieldValues.reference_1_address
        ? ''
        : 'This field is required'

    if ('reference_2_name' in fieldValues)
      temp.reference_2_name = fieldValues.reference_2_name
        ? ''
        : 'This field is required'

    if ('reference_2_mobile' in fieldValues)
      temp.reference_2_mobile =
        fieldValues.reference_2_mobile.length > 9
          ? ''
          : 'Number must be of 10 digits only'

    if ('reference_2_relation' in fieldValues)
      temp.reference_2_relation =
        fieldValues.reference_2_relation.length !== 0
          ? ''
          : 'This field is required'

    if ('reference_2_address' in fieldValues)
      temp.reference_2_address = fieldValues.reference_2_address
        ? ''
        : 'This field is required'

    if ('name_as_per_bank' in fieldValues)
      temp.name_as_per_bank = fieldValues.name_as_per_bank
        ? ''
        : 'This field is required'

    if ('bank_name' in fieldValues)
      temp.bank_name =
        fieldValues.bank_name.length !== 0 ? '' : 'This field is required'

    if ('account_number' in fieldValues)
      temp.account_number =
        fieldValues.account_number.length > 5 ? '' : 'This field is required'

    if ('ifsc_code' in fieldValues)
      temp.ifsc_code =
        fieldValues.ifsc_code.length > 10 ? '' : 'IFSC Code must of 11 digits'

    if ('salary' in fieldValues)
      temp.salary = fieldValues.salary ? '' : 'This field is required'

    if ('photos_collected' in fieldValues)
      temp.photos_collected = fieldValues.photos_collected
        ? ''
        : 'This field is required'

    if ('house_documents_status' in fieldValues)
      temp.house_documents_status =
        fieldValues.house_documents_status.length !== 0
          ? ''
          : 'This field is required'

    if ('original_documents_isRequired' in fieldValues)
      temp.original_documents_isRequired =
        fieldValues.original_documents_isRequired.length !== 0
          ? ''
          : 'This field is required'

    if ('police_verification_isRequired' in fieldValues)
      temp.police_verification_isRequired =
        fieldValues.police_verification_isRequired.length !== 0
          ? ''
          : 'This field is required'

    if ('dra_isRequired' in fieldValues)
      temp.dra_isRequired =
        fieldValues.dra_isRequired.length !== 0 ? '' : 'This field is required'

    if ('education' in fieldValues)
      temp.education =
        fieldValues.education.length !== 0 ? '' : 'This field is required'

    if ('school_name' in fieldValues)
      temp.school_name = fieldValues.school_name ? '' : 'This field is required'

    if ('credential' in fieldValues)
      temp.credential = fieldValues.credential ? '' : 'This field is required'

    if ('organisation' in fieldValues)
      temp.organisation =
        fieldValues.organisation.length !== 0 ? '' : 'This field is required'

    if ('employee_type' in fieldValues)
      temp.employee_type =
        fieldValues.employee_type.length !== 0 ? '' : 'This field is required'

    if ('job_title' in fieldValues)
      temp.job_title =
        fieldValues.job_title !== 0 ? '' : 'This field is required'

    if ('tracking_policy' in fieldValues)
      temp.tracking_policy =
        fieldValues.tracking_policy.length !== 0 ? '' : 'This field is required'

    if ('experience_in_years' in fieldValues)
      temp.experience_in_years = fieldValues.experience_in_years
        ? ''
        : 'This field is required'

    if ('department' in fieldValues)
      temp.department =
        fieldValues.department.length !== 0 ? '' : 'This field is required'

    if ('supervisor' in fieldValues)
      temp.supervisor =
        fieldValues.supervisor.length !== 0 ? '' : 'This field is required'

    if ('emergency_contact_name' in fieldValues)
      temp.emergency_contact_name = fieldValues.emergency_contact_name
        ? ''
        : 'This field is required'

    if ('emergency_contact_number' in fieldValues)
      temp.emergency_contact_number = fieldValues.emergency_contact_number
        ? ''
        : 'This field is required'

    if ('emergency_contact_relation' in fieldValues)
      temp.emergency_contact_relation = fieldValues.emergency_contact_relation
        ? ''
        : 'This field is required'

    if ('emergency_contact_address' in fieldValues)
      temp.emergency_contact_address = fieldValues.emergency_contact_address
        ? ''
        : 'This field is required'

    if ('personal_email_address' in fieldValues)
      temp.personal_email_address = /$^|.+@.+..+/.test(
        fieldValues.personal_email_address
      )
        ? ''
        : 'Email is not valid.'

    if ('application_file' in fieldValues)
      temp.application_file = fieldValues.application_file
        ? ''
        : 'This field is required'

    setErrors({
      ...temp,
    })
    if (fieldValues === values) return Object.values(temp).every((x) => x == '')
  }

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate)

  const submitHandler = (e) => {
    e.preventDefault()
    if (validate()) {
      dispatch(hrAsepAction(values))
    }
  }

  const hrAsep = useSelector((state) => state.hrAsep)
  const { loading1, error1, hrAddEmployeePost } = hrAsep

  useEffect(() => {
    dispatch({ type: HR_ASEP_RESET })
    if (hrAddEmployeePost) {
      setNotify({
        isOpen: true,
        message: hrAddEmployeePost.msg,
        type: 'success',
      })
      setTimeout(() => {
        history.push('/hr/dashboard')
      }, 2000)
    } else if (error1) {
      setNotify({
        isOpen: true,
        message: error1,
        type: 'error',
      })
    }
  }, [hrAddEmployeePost, error1])

  const [supervisors, setSupervisors] = useState([])

  const departmentChange = (e) => {
    setValues({
      ...values,
      department: e.target.value,
    })
    let departmentChosen = departments.filter((dpt) => dpt.id == e.target.value)
    setSupervisors(departmentChosen[0].supervisors)
  }

  return (
    <>
      {loading ? (
        <Progress />
      ) : (
        <>
          <PageHeader
            title='Add Employee'
            subtitle='Fill out the below form for adding an employee'
            icon={<PeopleOutlineTwoToneIcon fontSize='medium' />}
          />
          <Form onSubmit={submitHandler}>
            <Paper className={classes.pageContent}>
              <Typography variant='h6'>Basic Form</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='First Name *'
                    name='first_name'
                    value={values.first_name}
                    onChange={handleInputChange}
                    error={errors.first_name}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Middle Name *'
                    name='middle_name'
                    value={values.middle_name}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Last Name *'
                    name='last_name'
                    value={values.last_name}
                    onChange={handleInputChange}
                    error={errors.last_name}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Controls.Input
                    label='Current Address Line 1 *'
                    name='current_address_line_1'
                    value={values.current_address_line_1}
                    onChange={handleInputChange}
                    error={errors.current_address_line_1}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Controls.Input
                    label='Current Address Line 2 *'
                    name='current_address_line_2'
                    value={values.current_address_line_2}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Current State *'
                    name='current_state'
                    value={values.current_state}
                    onChange={handleInputChange}
                    error={errors.current_state}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Current Country *'
                    name='current_country'
                    value={values.current_country}
                    onChange={handleInputChange}
                    error={errors.current_country}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Current Pincode *'
                    name='current_pincode'
                    value={values.current_pincode}
                    onChange={handleInputChange}
                    error={errors.current_pincode}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Controls.Input
                    label='Permanent Address Line 1 *'
                    name='permanent_address_line_1'
                    value={values.permanent_address_line_1}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Controls.Input
                    label='Permanent Address Line 2 *'
                    name='permanent_address_line_2'
                    value={values.permanent_address_line_2}
                    onChange={handleInputChange}
                    error={errors.permanent_address_line_2}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Permanent State *'
                    name='permanent_state'
                    value={values.permanent_state}
                    onChange={handleInputChange}
                    error={errors.permanent_state}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Permanent Country *'
                    name='permanent_country'
                    value={values.permanent_country}
                    onChange={handleInputChange}
                    error={errors.permanent_country}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Permanent Pincode *'
                    name='permanent_pincode'
                    value={values.permanent_pincode}
                    onChange={handleInputChange}
                    error={errors.permanent_pincode}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Controls.DatePicker
                    label='Date of Birth *'
                    name='dob'
                    value={values.dob}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Mobile *'
                    name='mobile'
                    value={values.mobile}
                    onChange={handleInputChange}
                    error={errors.mobile}
                    type='number'
                    inputProps={{
                      min: 0,
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Alternate Number *'
                    name='alternate_number'
                    value={values.alternate_number}
                    onChange={handleInputChange}
                    error={errors.alternate_number}
                    type='number'
                    inputProps={{
                      min: 0,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label="Father's Name *"
                    name='father_name'
                    value={values.father_name}
                    onChange={handleInputChange}
                    error={errors.father_name}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label="Father's Mobile"
                    name='father_mobile'
                    value={values.father_mobile}
                    onChange={handleInputChange}
                    type='number'
                    inputProps={{
                      min: 0,
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label="Father's Occupation"
                    name='father_occupation'
                    value={values.father_occupation}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label="Mother's Name *"
                    name='mother_name'
                    value={values.mother_name}
                    onChange={handleInputChange}
                    error={errors.mother_name}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label="Mother's Mobile"
                    name='mother_mobile'
                    value={values.mother_mobile}
                    onChange={handleInputChange}
                    type='number'
                    inputProps={{
                      min: 0,
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label="Mother's Occupation"
                    name='mother_occupation'
                    value={values.mother_occupation}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Personal Email Address'
                    name='personal_email_address'
                    value={values.personal_email_address}
                    onChange={handleInputChange}
                    error={errors.personal_email_address}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Select
                    label='Blood Group'
                    name='blood_group'
                    value={values.blood_group}
                    onChange={handleInputChange}
                    error={errors.blood_group}
                    options={bloodGroups}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Select
                    label='Gender *'
                    name='gender'
                    value={values.gender}
                    onChange={handleInputChange}
                    error={errors.gender}
                    options={genders}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Controls.Select
                    label='Marital Status *'
                    name='marital_status'
                    value={values.marital_status}
                    onChange={handleInputChange}
                    error={errors.marital_status}
                    options={maritalStatus}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controls.Input
                    label='Spouse Name'
                    name='spouse_name'
                    value={values.spouse_name}
                    onChange={handleInputChange}
                    error={errors.spouse_name}
                    disabled={values.marital_status !== 'Married'}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controls.Input
                    label='Spouse Mobile'
                    name='spouse_mobile'
                    value={values.spouse_mobile}
                    onChange={handleInputChange}
                    error={errors.spouse_mobile}
                    type='number'
                    inputProps={{
                      min: 0,
                    }}
                    disabled={values.marital_status !== 'Married'}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controls.DatePicker
                    label='Marriage Date'
                    name='marriage_date'
                    value={values.marriage_date}
                    onChange={handleInputChange}
                    disabled={values.marital_status !== 'Married'}
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.pageContent}>
              <Typography variant='h6'>Reference Information</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Reference 1 Name *'
                    name='reference_1_name'
                    value={values.reference_1_name}
                    onChange={handleInputChange}
                    error={errors.reference_1_name}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Reference 1 Mobile *'
                    name='reference_1_mobile'
                    value={values.reference_1_mobile}
                    onChange={handleInputChange}
                    error={errors.reference_1_mobile}
                    type='number'
                    inputProps={{
                      min: 0,
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Select
                    label='Reference 1 Relation *'
                    name='reference_1_relation'
                    value={values.reference_1_relation}
                    onChange={handleInputChange}
                    error={errors.reference_1_relation}
                    options={relations}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Controls.Input
                    label='Reference 1 Address *'
                    name='reference_1_address'
                    value={values.reference_1_address}
                    onChange={handleInputChange}
                    error={errors.reference_1_address}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Reference 2 Name *'
                    name='reference_2_name'
                    value={values.reference_2_name}
                    onChange={handleInputChange}
                    error={errors.reference_2_name}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Reference 2 Mobile *'
                    name='reference_2_mobile'
                    value={values.reference_2_mobile}
                    onChange={handleInputChange}
                    error={errors.reference_2_mobile}
                    type='number'
                    inputProps={{
                      min: 0,
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Select
                    label='Reference 2 Relation *'
                    name='reference_2_relation'
                    value={values.reference_2_relation}
                    onChange={handleInputChange}
                    error={errors.reference_2_relation}
                    options={relations}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Controls.Input
                    label='Reference 2 Address *'
                    name='reference_2_address'
                    value={values.reference_2_address}
                    onChange={handleInputChange}
                    error={errors.reference_2_address}
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.pageContent}>
              <Typography variant='h6'>Bank Information</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Name as per Bank *'
                    name='name_as_per_bank'
                    value={values.name_as_per_bank}
                    onChange={handleInputChange}
                    error={errors.name_as_per_bank}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Select
                    label='Bank Name *'
                    name='bank_name'
                    value={values.bank_name}
                    onChange={handleInputChange}
                    error={errors.bank_name}
                    options={banks}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Account Number *'
                    name='account_number'
                    value={values.account_number}
                    onChange={handleInputChange}
                    error={errors.account_number}
                    type='number'
                    inputProps={{
                      min: 0,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='IFSC Code *'
                    name='ifsc_code'
                    value={values.ifsc_code}
                    onChange={handleInputChange}
                    error={errors.ifsc_code}
                    inputProps={{
                      maxLength: 11,
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Salary *'
                    name='salary'
                    value={values.salary}
                    onChange={handleInputChange}
                    error={errors.salary}
                    type='number'
                    inputProps={{
                      min: 0,
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.pageContent}>
              <Typography variant='h6'>Documents</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Controls.Input
                    label='Aadhar'
                    name='aadhar_card_number'
                    value={values.aadhar_card_number}
                    onChange={handleInputChange}
                    type='number'
                    inputProps={{
                      min: 0,
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controls.Input
                    label='PAN'
                    name='pan_card_number'
                    value={values.pan_card_number}
                    onChange={handleInputChange}
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controls.Input
                    label='Voter ID'
                    name='voter_id_card_number'
                    value={values.voter_id_card_number}
                    onChange={handleInputChange}
                    error={errors.voter_id_card_number}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controls.Input
                    label='Driving License'
                    name='driving_license_number'
                    value={values.driving_license_number}
                    onChange={handleInputChange}
                    error={errors.driving_license_number}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Photos Collected *'
                    name='photos_collected'
                    value={values.photos_collected}
                    onChange={handleInputChange}
                    error={errors.photos_collected}
                    type='number'
                    inputProps={{
                      min: 0,
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Select
                    label='House Documents Collected *'
                    name='house_documents_status'
                    value={values.house_documents_status}
                    onChange={handleInputChange}
                    error={errors.house_documents_status}
                    options={[
                      { id: 'Yes', title: 'Yes' },
                      { id: 'No', title: 'No' },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='House Documents Name *'
                    name='house_documents_name'
                    value={values.house_documents_name}
                    onChange={handleInputChange}
                    error={errors.house_documents_name}
                    disabled={values.house_documents_status != 'Yes'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Controls.Select
                    label='Original Documents *'
                    name='original_documents_isRequired'
                    value={values.original_documents_isRequired}
                    onChange={handleInputChange}
                    error={errors.original_documents_isRequired}
                    options={[
                      { id: 'Required', title: 'Required' },
                      { id: 'Not Required', title: 'Not Required' },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Select
                    label='Original Documents Submitted'
                    name='original_documents_isSubmitted'
                    value={values.original_documents_isSubmitted}
                    onChange={handleInputChange}
                    options={[
                      { id: 'Yes', title: 'Yes' },
                      { id: 'No', title: 'No' },
                    ]}
                    disabled={
                      values.original_documents_isRequired != 'Required'
                    }
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Original Documents Name'
                    name='original_documents_name'
                    value={values.original_documents_name}
                    onChange={handleInputChange}
                    disabled={values.original_documents_isSubmitted != 'Yes'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Controls.Select
                    label='Police Verification *'
                    name='police_verification_isRequired'
                    value={values.police_verification_isRequired}
                    onChange={handleInputChange}
                    error={errors.police_verification_isRequired}
                    options={[
                      { id: 'Required', title: 'Required' },
                      { id: 'Not Required', title: 'Not Required' },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Select
                    label='Police Verification Submitted'
                    name='police_verification_isSubmitted'
                    value={values.police_verification_isSubmitted}
                    onChange={handleInputChange}
                    options={[
                      { id: 'Yes', title: 'Yes' },
                      { id: 'No', title: 'No' },
                    ]}
                    disabled={
                      values.police_verification_isRequired != 'Required'
                    }
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Police Verifications Valid Upto'
                    name='police_verification_valid_upto'
                    value={values.police_verification_valid_upto}
                    onChange={handleInputChange}
                    disabled={values.police_verification_isSubmitted != 'Yes'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Controls.Select
                    label='DRA *'
                    name='dra_isRequired'
                    value={values.dra_isRequired}
                    onChange={handleInputChange}
                    error={errors.dra_isRequired}
                    options={[
                      { id: 'Required', title: 'Required' },
                      { id: 'Not Required', title: 'Not Required' },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Select
                    label='DRA Submitted'
                    name='dra_isSubmitted'
                    value={values.dra_isSubmitted}
                    onChange={handleInputChange}
                    options={[
                      { id: 'Yes', title: 'Yes' },
                      { id: 'No', title: 'No' },
                    ]}
                    disabled={values.dra_isRequired != 'Required'}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='DRA Number'
                    name='dra_number'
                    value={values.dra_number}
                    onChange={handleInputChange}
                    disabled={values.dra_isSubmitted != 'Yes'}
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.pageContent}>
              <Typography variant='h6'>Education Information</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Controls.Select
                    label='Education *'
                    name='education'
                    value={values.education}
                    onChange={handleInputChange}
                    error={errors.education}
                    options={educationOptions}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controls.Input
                    label='School Name *'
                    name='school_name'
                    value={values.school_name}
                    onChange={handleInputChange}
                    error={errors.school_name}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controls.Input
                    label='Credential Name *'
                    name='credential'
                    value={values.credential}
                    onChange={handleInputChange}
                    error={errors.credential}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controls.DatePicker
                    label='Graduated On *'
                    name='graduated_on'
                    value={values.graduated_on}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.pageContent}>
              <Typography variant='h6'>Employee Information</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Controls.Select
                    label='Organisation *'
                    name='organisation'
                    value={values.organisation}
                    onChange={handleInputChange}
                    error={errors.organisation}
                    options={organisations}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controls.DatePicker
                    label='Date of Joining *'
                    name='doj'
                    value={values.doj}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controls.Select
                    label='Employee Type *'
                    name='employee_type'
                    value={values.employee_type}
                    onChange={handleInputChange}
                    error={errors.employee_type}
                    options={employeeTypes}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controls.Select
                    label='Job Title *'
                    name='job_title'
                    value={values.job_title}
                    onChange={handleInputChange}
                    error={errors.job_title}
                    options={
                      values.employee_type === 'Office'
                        ? jobTitles
                        : values.employee_type === 'Field'
                        ? [{ id: 'Field Officer', title: 'Field Officer' }]
                        : []
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Controls.Select
                    label='Employee Zone'
                    name='employee_zone'
                    value={values.employee_zone}
                    onChange={handleInputChange}
                    error={errors.employee_zone}
                    options={employeeZones}
                    disabled={values.employee_type !== 'Field'}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controls.Input
                    label='Punch In At *'
                    name='punch_in_at'
                    value={values.punch_in_at}
                    onChange={handleInputChange}
                    type='time'
                    inputProps={{
                      min: '08:00',
                      max: '19:00',
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controls.Select
                    label='Tracking Policy *'
                    name='tracking_policy'
                    value={values.tracking_policy}
                    onChange={handleInputChange}
                    error={errors.tracking_policy}
                    options={trackingPolicy}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controls.Input
                    label='Experience in Years *'
                    name='experience_in_years'
                    value={values.experience_in_years}
                    onChange={handleInputChange}
                    error={errors.experience_in_years}
                    type='number'
                    inputProps={{
                      min: 0,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Controls.Input
                    label='Referred By Name'
                    name='referred_by_name'
                    value={values.referred_by_name}
                    onChange={handleInputChange}
                    error={errors.referred_by_name}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controls.Input
                    label='Referred By Mobile'
                    name='referred_by_mobile'
                    value={values.referred_by_mobile}
                    onChange={handleInputChange}
                    error={errors.referred_by_mobile}
                    type='number'
                    inputProps={{
                      min: 0,
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controls.Select
                    label='Department *'
                    name='department'
                    value={values.department}
                    onChange={departmentChange}
                    error={errors.department}
                    options={departments}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controls.Select
                    label='Supervisor *'
                    name='supervisor'
                    value={values.supervisor}
                    onChange={handleInputChange}
                    error={errors.supervisor}
                    options={supervisors}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Controls.Input
                    label='Application File Number *'
                    name='application_file'
                    value={values.application_file}
                    onChange={handleInputChange}
                    error={errors.application_file}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controls.Input
                    label='Original Documents File Number'
                    name='original_file'
                    value={values.original_file}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.pageContent}>
              <Typography variant='h6'>Previous Company Information</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Controls.Input
                    label='Company Name'
                    name='previous_company_name'
                    value={values.previous_company_name}
                    onChange={handleInputChange}
                    disabled={
                      values.experience_in_years == 0 ||
                      !values.experience_in_years
                    }
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controls.Input
                    label='Company Designation'
                    name='previous_designation'
                    value={values.previous_designation}
                    onChange={handleInputChange}
                    disabled={
                      values.experience_in_years == 0 ||
                      !values.experience_in_years
                    }
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controls.DatePicker
                    label='Left On'
                    name='previous_left_on'
                    value={values.previous_left_on}
                    onChange={handleInputChange}
                    disabled={
                      values.experience_in_years == 0 ||
                      !values.experience_in_years
                    }
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controls.Input
                    label='Left Reason'
                    name='previous_left_reason'
                    value={values.previous_left_reason}
                    onChange={handleInputChange}
                    disabled={
                      values.experience_in_years == 0 ||
                      !values.experience_in_years
                    }
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.pageContent}>
              <Typography variant='h6'>Vehicle Information</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Vehicle Name'
                    name='vehicle_name'
                    value={values.vehicle_name}
                    onChange={handleInputChange}
                    disabled={values.vehicle_name !== 'Field'}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Registered Name'
                    name='registered_name'
                    value={values.registered_name}
                    onChange={handleInputChange}
                    disabled={values.employee_type !== 'Field'}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Registration Number'
                    name='registration_number'
                    value={values.registration_number}
                    onChange={handleInputChange}
                    disabled={values.employee_type !== 'Field'}
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.pageContent}>
              <Typography variant='h6'>
                Emergency Contact Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Person Name *'
                    name='emergency_contact_name'
                    value={values.emergency_contact_name}
                    onChange={handleInputChange}
                    error={errors.emergency_contact_name}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Person Number *'
                    name='emergency_contact_number'
                    value={values.emergency_contact_number}
                    onChange={handleInputChange}
                    error={errors.emergency_contact_number}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Controls.Input
                    label='Person Relation *'
                    name='emergency_contact_relation'
                    value={values.emergency_contact_relation}
                    onChange={handleInputChange}
                    error={errors.emergency_contact_relation}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Controls.Input
                    label='Person Address *'
                    name='emergency_contact_address'
                    value={values.emergency_contact_address}
                    onChange={handleInputChange}
                    error={errors.emergency_contact_address}
                  />
                </Grid>
              </Grid>
              <Controls.Button
                type='submit'
                text='submit'
                className={classes.submit}
                loading={loading1}
              />
            </Paper>
          </Form>
        </>
      )}
      <Notification notify={notify} setNotify={setNotify} />
    </>
  )
}

export default HRAddEmployee
